import React from "react";

import {useNavigate} from "react-router-dom";

import accountCreated from "./../../../../../assets/images/mainIcons/createAccount/accountCreated.svg";

import styles from "./setupComplete.module.css";

const SetupComplete=()=>{
    const navigate = useNavigate();

    const handleButtonClickAction = ()=>{
        navigate("/");
    }

    return (
        <div className={styles.container}>
            <div className={styles.upperContainer}>
                <img src={accountCreated} className={styles.mainIcon} />
            </div>
            <div className={styles.lowerContainer}>
                <div className={styles.mainTextContainer}>
                    <div className={styles.primaryText}>Account Created Successfully</div>
                    <div className={styles.secondaryText}>If you don’t see it, you may need to check your spam folder.</div>
                </div>
                <div className={styles.buttonOuterContainer} onClick={handleButtonClickAction}>
                    <div className={styles.buttonInnerContainer}>
                        Go to Dashboard
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SetupComplete