import React from "react";
import cx from "classnames";

import styles from "./modal.module.css"

const Modal = ({HeaderComponent = ()=><></>,BodyComponent= ()=><></>,FooterComponent= ()=><></>,className="", toggleModal, crossIcon = require("../../../assets/images/cross.svg").default,...rest})=>{
    const {headerProps ={} , bodyProps ={}, footerProps ={}} = rest ;

    const CloseComponent = () =>{
        return (<div onClick={toggleModal} className={styles.closeIcon}>
            <img src={crossIcon} />
        </div>)
    }

    return (
        <div className={cx(styles.modalContainer, className)}>
            <div className={styles.headerContainer}>
                <HeaderComponent {...headerProps} {...rest} CloseComponent={CloseComponent}/>
            </div>
            <BodyComponent {...bodyProps} {...rest}/>
            <FooterComponent {...footerProps} {...rest}/>
        </div>
    )
}

Modal.propTypes={

}

Modal.defaultProps={

}

export default Modal;