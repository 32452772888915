import React from "react";

import Header from "./components/Header";

import styles from "./body.module.css";

const Body = ({Component, patientInviteAction, headerProps, rest}) =>{

    return (
        <div className={styles.container}>
            <Header {...headerProps}/>
            <Component patientInviteAction={patientInviteAction} {...rest} />
        </div>
    )
}

export default Body;