import React, { useState } from "react";
import moment from "moment";

import notesIcon from "./../../../../../../../../../../../assets/images/notes/notes.svg";
import rightArrow from "./../../../../../../../../../../../assets/images/arrow/basicRightArrow.svg";

import styles from "./notesCard.module.css";
import NotesCardPopup from "../NotesCardPopup";
import { getNotesToPopupContentFormat } from "./notesCard.helper";

const NotesCard =(props) =>{

    const [notesPopupDisplay, setNotesPopupDisplay] = useState( false);

    const {clinicalInterpretation = "", plan="", dateOfReview="", addedByCareProUserName=""} = props;

    const handleNotesDisplayOpen = () =>{
        setNotesPopupDisplay(true);
    }

    const handleNotesDisplayClose = ()=>{
        setNotesPopupDisplay(false);
    }

    return (
        <>
            {notesPopupDisplay && 
                <NotesCardPopup 
                    handleNotesDisplayClose={handleNotesDisplayClose} 
                    notes={getNotesToPopupContentFormat(props)} 
                    date={moment(props?.dateOfReview || "").format('ddd, MMM DD')}
                    userName={addedByCareProUserName}
                />}
            <div className={styles.container} onClick={handleNotesDisplayOpen} >
                <img src={notesIcon} className={styles.notesIcon} />
                <div className={styles.mainOuterContainer}>
                    <div className={styles.headingText}>
                        {clinicalInterpretation}
                    </div>
                    <div className={styles.secondaryText}>
                        {plan}
                    </div>
                    <div className={styles.tertiaryText}>
                        <div>
                            {moment(dateOfReview).format('ddd, MMM DD')}
                        </div>
                        <div>
                            {addedByCareProUserName}
                        </div>
                    </div>
                </div>
                <img src={rightArrow} className={styles.showMoreIcon}/>
            </div>
        </>
    )
}

export default NotesCard