import React from "react";

import emptyIcon from "./../../../../../assets/images/empty_bag.svg";

import StyledButton from "../../../../molecules/baseComponents/buttons/styledButton/StyledButton";
import BaseTable from "../../../tables/BaseTable/BaseTable";
import { INVITE_PATIENT_STATUS } from "../../../../fieldMapping/invitePatients";
import withPagination from "../../../../hoc/withPagination";

import styles from "./body.module.css";

const Body = ({patients, tableFields,wrapperFields,inviteButtonAction, dispatch, status,tableDataProps,columnWrapperFields}) =>{

    const TableComponent = withPagination(BaseTable);

    if(patients?.length>0)
    {
        return (
        <div className={styles.outerContainer}>
            <TableComponent className={styles.tableContainer} limit={10} tableDataProps={tableDataProps} dispatch={dispatch} wrapperFields={wrapperFields} columnProps={{className : styles.rowColumnHeader}} tableData={patients} columnsData={tableFields} columnWrapperFields={columnWrapperFields}/>
        </div>
        )
    }
    
    return (
        <div className={styles.outerContainer}>
            <div className={styles.innerContainer}>
                <img src={emptyIcon} className={styles.icon} />
                <div className={styles.primaryText}>
                    It’s empty in here
                </div>
                <div className={styles.secondaryText}>
                    Get started by inviting your patients to track and monitor securely
                </div>
                { status == INVITE_PATIENT_STATUS.ACTIVE && <StyledButton onClick={inviteButtonAction} content={"Invite"} className={styles.inviteButton}/>}
            </div>
        </div>
    )
}

export default Body;