import { BASE_URL } from "../../../../constants/api";

const archivePractice = async({practiceId})=>{
    try{
        const response = await fetch(`${BASE_URL}/dashboard/admin/api/rrxsuperboss/users/archivePractice/${practiceId}`,{
            method : "GET",
            headers:{
                'content-type':'application/json',
                "authorization" : "Bearer " + window?.localStorage?.getItem("Authorization"),
            },
        })

        return response.status;
    }
    catch(e){
        console.log('Error is : ',e);
        return e;
    }
}

export default archivePractice;
