import React from "react";
import {map} from "lodash";

import TableRow from "../tableRow";

import styles from "./tableBody.module.css";

const TableBody = (props) =>{
    const tableRows = [1,2,3,4];
    return (<div className={`${styles["log-chart-div"]} ${styles["first-row-log-chart-div"]}`}>
        <TableRow viewMore={true} {...props}/>
        {map(tableRows,(row)=>{
            return <TableRow {...props}/>
        })}
    </div>)
}

export default TableBody;