import moment from "moment";

const getNotesToPopupContentFormat = (data)=>{
    let arr = [];
    arr.push({
        heading: 'Time Spent',
        content : data?.['timeSpent']
    })
    arr.push({
        heading: 'Clinical Interpretation',
        content : data?.['clinicalInterpretation']
    })
    arr.push({
        heading: 'Plan (Treatment Recommendations)',
        content : data?.['plan']
    })
    arr.push({
        heading: 'Patient Communication',
        content : data?.['patientCommunication']
    })
    arr.push({
        heading: 'Follow-up',
        content : data?.['followUp'] && moment(data?.['followUp']).diff(moment(),"weeks")+" weeks "+moment(data?.['followUp']).format('ddd, MMM DD')
    })

    return arr;
}

export {
    getNotesToPopupContentFormat
}