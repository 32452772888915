import React from "react";

import logo from "../../../../assets/images/logo.svg"
import renewrxTextLogo from "../../../../assets/images/renewrx-text-logo.svg";

import styles  from "./header.module.css";

const LeftHeaderContainer = () =>{
    return (
        <div className={styles}>
            <img className={styles.image} src={logo} />
            <img className={styles.textlogo} src={renewrxTextLogo}/>
        </div>
    )
}

export default LeftHeaderContainer;