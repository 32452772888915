
const BASIC_COLORS ={
    RED : "#FEDCE7",
    GREEN : "#D9F7EB",
    YELLOW : "#FFF0A8"
}

const RANGE_BASIC_COLORS ={
    below : "#FEDCE7",
    inRange : "#D9F7EB",
    above: "#FFF0A8"
}

const CARD_BAR_CHART_COLOR_TYPES = {
    RED : "#F6B4A7",
    GREEN : "#9EDBC5",
    YELLOW : "#EAC56B"
}

const COMPARISON_ANALYSIS_TABLE_GRAPH = {
    inRange : "#9EDBC5",
    below : "#FFF0A8",
    above : "#F6B4A7",
}

export {
    BASIC_COLORS,
    CARD_BAR_CHART_COLOR_TYPES,
    COMPARISON_ANALYSIS_TABLE_GRAPH,
    RANGE_BASIC_COLORS
}