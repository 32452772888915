import React,{useEffect} from "react";
import PracticeCreateAccountCard from "./PracticeCreateAccountCard";

import Toast from "../../molecules/toast";

import actions from "./practiceCreateAccountCard.actions";
import withActionState from "../../hooks/withActionState";
import actionTypes from "./practiceCreateAccountCard.actionTypes";

const PracticeCreateAccountCardContainer = ({dispatch, displayToast, toastProps, ...props})=>{
        
    useEffect(()=>{
        dispatch({actionType: actionTypes.INIT_ACTION})
    },[]);

    return(
        <>
            {displayToast && <Toast {...toastProps} />}
            <PracticeCreateAccountCard {...props} dispatch={dispatch}/>
        </>
    )
}

export default withActionState({Component: PracticeCreateAccountCardContainer, action: actions});