import React, { useEffect } from "react";
import { isNull } from "lodash";
import {moment} from "moment";
import { useNavigate } from "react-router-dom";
import { API_STATUS_CODES, BASE_URL } from "./components/constants/api";
import { LOCAL_STORAGE_KEYS } from "./components/constants/keyConstants";

const ImperativeRoutes = ({path, ChildElement}) =>{
    const navigate = useNavigate();
    
    const handleRouteInit = () =>{
        try{
            fetch(`${BASE_URL}/api/dashboard/users/checkAuthenticationToken`,{
                method : "GET",
                headers :{
                    "Authorization" : "Bearer "+ window.localStorage.getItem("Authorization")
                }
            })
            .then((response)=>{
                if(response.status == API_STATUS_CODES.ANAUTHORIZED || isNull(window?.localStorage?.getItem("id"))){
                    window?.localStorage?.clear();
                    navigate("/login");
                }
            })
            .catch((e)=>{
                console.log(e);
            }) 
        }
        catch(e)
        {
            console.log("error is ",e)
        }
    }

    useEffect(()=>{
        handleRouteInit();

        if(window?.location?.pathname?.includes("superUser") && window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.USER_ROLE)!="SUPER_ADMIN"){
            navigate("/");
        }
        
    },[])

    // const localUsername = window?.localStorage?.getItem("username");
    
    // if(isNull(localUsername) || isEmpty(localUsername))
    // {
    //     return <></>
    // }

    return(
        <>
        {ChildElement}
        </>
    )
}

export default ImperativeRoutes;