import React from "react";
import cx from "classnames";

import label from "../../../../../../../../atoms/label";
import CustomDataBlock from "./components/CustomDataBlock/CustomDataBlock";
import sortIcon from "./../../../../../../../../../assets/images/sort/sort.svg"

import DateDisplayContent from "./components/DateDisplayContent";

import styles from "./trendAnalysisTable.module.css";

const getTrendAnalysisTableWrapperFields = () =>{
    return [{
        label: "date",
        field : DateDisplayContent
    },
    {
        label : "fasting",
        field : CustomDataBlock
    },
    {
        label : "breakfast",
        field : CustomDataBlock
    },
    {
        label : "lunch",
        field : CustomDataBlock
    },
    {
        label : "dinner",
        field : CustomDataBlock
    },
]
}

const getTrendAnalysisTableFields = () =>{
    return [{
        id : 1,
        label: <div className={styles.dateColumnContainer}>DATE <img src={sortIcon} className={styles.dateSortIcon} /></div>,
        name : "date",
        className : styles.tableColumnContainer,
        innerClassName : styles.innerClassName
    },
    {
        id : 2,
        name : "fasting",
        label : "FASTING",
        className : styles.tableColumnContainer,
        innerClassName : styles.innerClassName
    },
    {
        id : 3,
        name : "breakfast",
        label : "PP BREAKFAST",
        className : styles.tableColumnContainer,
        innerClassName : styles.innerClassName
    },
    {
        id : 3,
        name : "lunch",
        label : "PP LUNCH",
        className : styles.tableColumnContainer,
        innerClassName : styles.innerClassName
    },
    {
        id : 3,
        name : "dinner",
        label : "PP DINNER",
        className : styles.tableColumnContainer,
        innerClassName : styles.innerClassName
    }
]
}

export {
    getTrendAnalysisTableFields,
    getTrendAnalysisTableWrapperFields
}