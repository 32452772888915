import React from "react";
import moment from "moment";

import styles from "./header.module.css"

const Header = ({CloseComponent=()=><></>, label, date=""}) =>{
    return (
        <div className={styles.container}>
            <div className={styles.leftContainer}>
                <div className={styles.primaryText}>
                    {label}
                </div>
                <div className={styles.secondaryText}>
                    { moment(date).format("DD MMM, ddd")}
                </div>
            </div>
            <CloseComponent />
        </div>
    )
}

export default Header;