import {map} from "lodash"
import { NOTES_FORM_FIELDS, NOTES_FORM_FIELDS_BASE_NAMES } from "./notesFormPopup.constants"
import Multiselect from "../../../../molecules/Multiselect"
import TextArea from "../../../../reusables/formManager/fieldComponents/TextArea/TextArea";
import ErrorComponent from "../../../../atoms/errorComponent/ErrorComponent";

import styles from "./notesFormPopup.module.css"
import SingleSelect from "./component/SingleSelect";

const getCustomFieldProps = (name)=>{
    switch(name){
        case NOTES_FORM_FIELDS_BASE_NAMES.timeSpent :
            return {
                CustomInput : Multiselect,
                isMulti: false,
                PlaceHolder: "Choose Time Spent",
                options : [
                    {id:1,value:"5 min"},
                    {id:2,value:"10 min"},
                    {id:3,value:"15 min"},
                    {id:4,value:"20 min"},
                    {id:5,value:"25 min"},
                    {id:6,value:"30 min"}
                ],
                dropdownContainer : styles.dropdownContainer
            };
            break;
        case NOTES_FORM_FIELDS_BASE_NAMES.clinicalInterpretation :
            return {
                CustomInput : TextArea,
                placeHolder : "Enter interpreted data"
            }
            break;
        case NOTES_FORM_FIELDS_BASE_NAMES.plan : 
            return {
                CustomInput : TextArea,
                placeHolder : "Enter treatment recommendation plans"
            }
            break;
        case NOTES_FORM_FIELDS_BASE_NAMES.patientCommunication : 
            return {
                CustomInput : TextArea,
                placeHolder : "Enter additional notes for patients"
            }
            break;
        case NOTES_FORM_FIELDS_BASE_NAMES.followUp :
            return {
                CustomInput: SingleSelect,
                isMulti: false,
                placeHolder : "Choose next follow-up date"
            }


    }
}

const getNotesPopupFields = () =>{
    return map(NOTES_FORM_FIELDS,({name, label})=>{

        return {
            name,
            label,
            className : styles.formFieldContainer,
            ErrorComponent : ErrorComponent,
            onFocus:()=>{},
            onBlur:()=>{},
            ...getCustomFieldProps(name)
        }
    })
}

export {
    getNotesPopupFields
}