import React from "react";
import cx from "classnames";

import hideValue from "../../../assets/images/hide_value.svg";
import displayValue from "../../../assets/images/display_values.svg";

import styles from "./displayToggle.module.css";

const DisplayToggle = ({display = false,toggleValue,classname=""})=>{

    return (
        <div onClick={toggleValue} className={cx(styles.container,classname)}>
            <img src={display ? displayValue : hideValue} />
        </div>
    )
}

export default DisplayToggle;