import React from "react";
import penMarker from "./../../../../../../../assets/images/pen/highLightedMarker.svg";

import styles from "./header.module.css"

const Header = ({handlePhoneOtpModalToggle, countryCode , phoneNumber})=>{
    return (
        <div className={styles.container}>
            <div className={styles.leftContainer}>
                <div className={styles.mainContentTypo}>
                    Verify Phone Number
                </div>
                <div className={styles.secondaryMainContent}>
                    Please enter the code received to <span className={styles.highlightedSecondaryMainContent}> {countryCode} {phoneNumber}</span>
                </div>
            </div>
            <div className={styles.rightContainer} onClick={handlePhoneOtpModalToggle}>
                <span>Edit Number</span>
                <img src={penMarker} className={styles.penMarkerIcon} />
            </div>
        </div>
    )
}

export default Header;