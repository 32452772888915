const PROGRESS_MONITORING_PRIMARY_CARD_TYPES=[{
        name : "averageGlucose",
        label: "Average Glucose",
        suffix: "mg/dL",
        goal : '< 154 mg/dL'
    },
    {
        name : "gmi",
        label: "GMI",
        suffix: "%",
        goal: "< 7 %"
    },
    {
        name : "standardDeviation",
        label: "Standard Deviation",
        suffix: "mg/dL",
        goal : "12 - 16 mg/dL"
    },
    {
        name : "glucoseVariability",
        label: "Glucose Variability",
        suffix: "%",
        goal : "< 36 %"
    }
];

export {
    PROGRESS_MONITORING_PRIMARY_CARD_TYPES
}