import React from 'react'
import {useNavigate } from "react-router-dom";
import Header from '../Header'
import "../../css/manage-action-plan.css";
import profile_image from "../../assets/images/profile_image.png";
import arrow_image from "../../assets/images/manage-action-page.svg"
import coming_soon from "../../assets/images/comming-soon.svg"
import Sidebar from '../Sidebar';
export default function ManageActionPlan() {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/actionPlan');
  };
  return (
    <div className="manageActionPlanContainer">     
     <main>
     <section class="profile-first-section">
    <div class="profile-first-section-main-div">
      <div class="profile-image-div">
        <img src={profile_image} style={{width:'48px',height:'48px',borderRadius:'10px'}} />
      </div>
      <div class="profile-name-div">
        <span class="profile-name-span">Manage Liz Samsonite’s Action Plans</span>
      </div>
    </div>
  </section>

  <section class="action-plan-section" style={{marginBottom:'500px'}}>
    <div class="first-div-action-plan">
      <span class="first-span-action-plan">Coach Action Plans</span>
    </div>

    <div class="second-div-action-plan">
      <div class="second-div-action-plan-main-div">
        <div class="second-div-action-plan-main-div-first">
          <span class="second-div-action-plan-main-div-first-span">Looks like this patient is ready for their first action plan</span>
        </div>
        <div class="second-div-action-plan-main-div-second">
          <span class="second-div-action-plan-main-div-second-span">Click here to get started!</span>
        </div>
      </div>
      <div class="second-div-action-plan-img-div">
            <img class="second-div-action-plan-image" src={arrow_image} />
      </div>
      <div class="second-div-action-plan-last-div-main">
          <div onClick={handleButtonClick}>
            <span class="second-div-action-plan-last-div-main-span">Create Action Plan</span>
          </div>
      </div>
    </div>

    <div class="third-div-action-plan">
      <span class="second-span-action-plan">Provider Action Plans</span>
    </div>

    <div class="fourth-div-action-plan">
      <div  class="coming-soon-div">
        <img src={coming_soon} />
      </div>

    </div>

    <div class="fifth-action-plan-div">
      <span class="third-span-action-plan-div">Specialist Action Plans</span>
    </div>
    <div class="sixth-div-action-plan">
      <div  class="coming-soon-div">
        <img src={coming_soon} />
      </div>

    </div>

  </section>
  </main>
    </div>
  )
}
