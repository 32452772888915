import ErrorComponent from "../../atoms/errorComponent/ErrorComponent";
import { ERROR_STATUS } from "../../constants/errorStatus";
import CustomPasswordInput from "../../molecules/CustomPasswordInput";
import ErrorPopup from "../../molecules/ErrorPopup/ErrorPopup";

import styles from "./resetPassword.module.css";

const resetPasswordFields = {
    EmailField:()=>([{
       label:"Email",
       name:"email",
       PlaceHolder:"name@gmail.com",
       errorTypes: [{type: ERROR_STATUS.IS_EMAIL}],
       fieldType:"email",
       autoFocus:true,
       ErrorComponent:ErrorComponent,
       onBlur:()=>{},
       onfocus:()=>{},
    }]),
    PasswordField:()=>([{
        label:"Password",
        name:"password",
        fieldType:"password",
        PlaceHolder:"Enter new Password",
        errorTypes:[{type:ERROR_STATUS.HAS_NUMBERS},{type:ERROR_STATUS.HAS_UPPERCASE},{type:ERROR_STATUS.HAS_LOWERCASE},{type:ERROR_STATUS.MIN_LENGTH,length:8}],
        ErrorComponent:ErrorPopup,
        CustomInput: CustomPasswordInput,
        inputFieldRow: styles.inputFieldRow,
        errorProps: {
            className: styles.errorClassName
        },
        fieldClassname: styles.passwordInputField,    
        onBlur:()=>{},
    }]),
}

export {resetPasswordFields}