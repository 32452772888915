import React, { useState } from "react";
import {map} from "lodash";
import cx from "classnames";
import tableActionTypes from "./../../../../../constants/tableActions.contants";

import styles from "./baseTableRowHoc.module.css";

const getBaseTableRowHoc = (Component, withCheckBox) => ({
    rowData = [],
    columnsData=[],
    rowProps,
    dispatch = ()=>{},
    unfilteredData = {}
}) =>{

    const [blurActiveRow,setBlurActiveRow] = useState(false);

    const {className = ""} = rowProps;

    const {id} =  unfilteredData;

    const handleBlurActive = ()=>{
        setBlurActiveRow(()=>true);
    }

    const handleBlurRemove = () =>{
        setBlurActiveRow(()=>false);
    }

    const handleTableRowClickAction = (event)=>{
        event.preventDefault();
        dispatch({actionType : tableActionTypes.TABLE_ROW_CLICK, payload : id})
    }

    return (
        <div className={cx(styles.container,className)} onClick={handleTableRowClickAction} onMouseEnter={handleBlurActive} onMouseOut={handleBlurRemove}>
            {withCheckBox && <div className={styles.checkbox} ><input type="checkbox"/></div>}
            {
                map(columnsData,(data, key)=>{
                    const {className : innerClass} = rowProps;
                    return <Component className={cx(styles.innerClass, innerClass)} {...rowProps} {...rowData[key]} activeRow={blurActiveRow} />
                })
            }
        </div>
    )
}

export default getBaseTableRowHoc