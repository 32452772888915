import React from "react";

import styles from "./body.module.css";

const Body = () =>{
    return (
        <div className={styles.bodyContainer}>
            <div className={styles.container}>
                <div className={styles.bodyContent}>
                    Let’s quickly introduce you to few concepts in account setup
                </div>
                <div className={styles.bodyItem}>
                    <div className={styles.bodyContentLabel}>
                        Individual Practitioner
                    </div>
                    <div className={styles.bodyContent}>
                        An Individual Practitioner refers to a healthcare professional who practices medicine or delivers healthcare services independently. This practitioner might work in a private office, clinic, or even provide telehealth services without being part of a larger organisational structure dedicated to healthcare services.
                    </div>
                </div>
                <div className={styles.bodyItem}>
                    <div className={styles.bodyContentLabel}>
                        Group Practitioner
                    </div>
                    <div className={styles.bodyContent}>
                        A Group Practitioner involves multiple healthcare professionals who come together to form a practice. This group can include specialists from various fields, general practitioners, or a mix of both, working collaboratively to offer comprehensive healthcare services.
                    </div>
                </div>
            </div>
            <div className={styles.bodyFooter}>
                <div className={styles.bodyFooterLabel}>
                    Did this article solve your issue?
                </div>
                <div className={styles.bodyFooterButtonContainer}>
                    <div className={styles.bodyFooterButtons}>
                        Not really
                    </div>
                    <div  className={styles.bodyFooterButtons}>
                        Yes, it did 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Body;