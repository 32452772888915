import { BASE_URL } from "../../../../constants/api";
import { LOCAL_STORAGE_KEYS } from "../../../../constants/keyConstants";
import { GLUCOSE_METRICS_TYPES } from "../../../../constants/metricTypes";
import { getWeeklyFormattedWeeklyDates } from "../../../../helpers/dateFormatter";
import { getTimeInRangeFormatted } from "./overviewTab.helpers";

const getInitAction = async({state, setState}) =>{
    try{

        const url = new URL(window.location.href);
        const pathname = url.pathname.split("/");

        const {startDate, endDate} = getWeeklyFormattedWeeklyDates({prev:0,format:"YYYY-MM-DD"})

        const billingRequest = await fetch(`${BASE_URL}/api/dashboard/users/getBills`,{
            method:"POST",
            headers:{
                'content-type':"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
            body:JSON.stringify({
                userId : pathname?.[2]
            })
        });

        const billingList = await billingRequest?.json();

        setState((prev)=>{
            return {
                ...prev,
                billingList : billingList?.billData,
            }
        })

        const balancePlateResponse = await fetch(`${BASE_URL}/nourishmentNotebook/dashboard/macronutrientsInsights/${pathname?.[2]}/${startDate}/${endDate}`,{
            method:"GET",
            headers:{
                'content-type':"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            }
        });

        const microNutritionalValues = await balancePlateResponse?.json();

        const response = await fetch(`${BASE_URL}/dashboard/glucoseMetrics`,{
            method:"POST",
            headers:{
                "content-type":"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
            body: JSON.stringify({
                userId: pathname?.[2],
                metricRequests: [
                        {
                            metricType:"FASTING_TIME_IN_RANGE",
                            numberOfDays: 7,
                        },
                        {
                            metricType: GLUCOSE_METRICS_TYPES.POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE,
                            numberOfDays : 7,
                            mealType: "BREAKFAST"
                        },
                        {
                            metricType:GLUCOSE_METRICS_TYPES.POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE,
                            numberOfDays: 7,
                            mealType :"LUNCH"
                        },
                        {
                            metricType:GLUCOSE_METRICS_TYPES.POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE,
                            numberOfDays: 7,
                            mealType :"DINNER"
                        }
                    ]
            })
        });

        const data = await response.json();

        const balencePlateResponse = await fetch(`${BASE_URL}/nourishmentNotebook/dashboard/macronutrientsInsights/${pathname?.[2]}/${startDate}/${endDate}`,{
            method:"GET",
            headers:{
                'content-type':"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            }
        });

        const balancePlateValues = await balencePlateResponse?.json();

        setState((prev)=>{
            return {
                ...prev,
                ...microNutritionalValues,
                balancePlateValues,
                bloodGlucoseMetrics: getTimeInRangeFormatted(data.metricsResponses),
            }
        })

    }
    catch(e){
        setState((prev)=>{
            return {
                ...prev,
                error:e,
            }
        })
    }
}

export default {
    INIT_ACTION : getInitAction
}