import { useNavigate } from "react-router-dom";
import styles from "./login.module.css";

const getPasswordLabel = (label,navigate)=>{

    const handleForgotPassword = ()=>{
        navigate("/changePassword");
    }
    
    return (
        <div className={styles.passwordLabelContainer}>
            <div className={styles.passwordLabelContent}>
                {label}
            </div>
            <button type="link" onClick={handleForgotPassword} className={styles.passwordLabelButton}>
                Forgot Password?
            </button>
        </div>
    )
}

export {getPasswordLabel}