import React from "react";
import cx from "classnames";

import tip from "../../../assets/images/tip.svg";
import styles from "./popup.module.css";

const Popup = ({PopupContent = ()=><></>, className, innerBody, popupBodyClassname,...rest }) =>{
    return (
        <div className={cx(styles.container, className)}>
            <img src={tip} className={styles.tipImage} />
            <div className={cx(styles.popupBody,innerBody,popupBodyClassname)}>
                <PopupContent {...rest} />
            </div>
        </div>
    )
}

export default Popup;