const getHeaderOptions = ({handlePageUpdateAction, logOutAction, logOutClass }) => [
    {
        label: "Log Out",
        name: 'logOut',
        action: handlePageUpdateAction(logOutAction),
        className: logOutClass
    }
];

export {
    getHeaderOptions
}