import React from "react";
import cx from "classnames";

import {map} from "lodash";

import styles from "./leftContainer.module.css";

const LeftContainer = ({options, className, label,textClassName, containerAction=()=>{}, currentTabOption}) => {

    const handleItemClick = ()=>{
        containerAction(label)
    }

    return (
        <div className={className}>
            <div className={cx(textClassName,{[styles.activeText]:currentTabOption == label})} onClick={containerAction!=undefined && handleItemClick}>
                {label}
            </div>
            {map(options,(option)=>{
                return <LeftContainer {...option} currentTabOption={currentTabOption}/>
            })}
        </div>
    )
}

export default LeftContainer;
