import React, { useState } from "react";

import styles from "./inputWithPrefix.module.css";

const InputWithPrefix = ({inputType , value = "", prefixText, placeHolder,handleFieldChange,name}) =>{

    const [currentValue,setCurrentValue] = useState(value);

    const handleValueChange = (event)=>{
        setCurrentValue(event?.target?.value);
        handleFieldChange({label:name})(`${prefixText} ${event?.target?.value}`);
    }

    return (
        <div className={styles.container}>
            <div className={styles.prefixContainer}>
                {prefixText}
            </div>
            <input onChange={handleValueChange} type={inputType} value={currentValue} className={styles.inputBox} placeholder={placeHolder}/>
        </div>
    )
}

export default InputWithPrefix;