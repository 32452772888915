import React, { useEffect } from "react";
import {map} from "lodash";
import cx from "classnames";

import { getCreateAccountPage } from "../../practiceCreateAccountCard.pages";

import styles from "./practiceCardContainer.module.css";
import { CURRENT_SPAN, TOTAL_STEPS } from "../../practiceCreateAccountCard.constants";

const CardLeftContainer = ({currentPage =1, handleSetUpCompletedAction, dispatch,handlePageSubmit,handleFormValueChange,handleFormSubmit,handlePhoneOtpStage,...rest}) =>{

    const formProps = getCreateAccountPage(dispatch,handleSetUpCompletedAction,handlePageSubmit,handleFormValueChange,handleFormSubmit,handlePhoneOtpStage)[currentPage-1];
    const {Component = ()=><></>} = formProps || {}

    const totalCount= window.localStorage.getItem('userRole') =="RENEWRX_CARE_PRO_ADMIN" || window.localStorage.getItem('userRole') =="RENEWRX_CARE_PRO" ? 4 :  5;
    const totalSpans = window.localStorage.getItem('userRole') =="RENEWRX_CARE_PRO_ADMIN" || window.localStorage.getItem('userRole') =="RENEWRX_CARE_PRO"  ? [1,2,3,4]: [1,2,3,4,5];

    return (
        <div className={styles.leftCardContainer}>
            <div className={styles.leftCardHeader}>
                <div className={styles.leftCardHeaderText}>
                    Steps {currentPage}/{totalCount}
                </div>
                <div className={styles.progressStepperContainer}>
                    {map(totalSpans,(val)=>{
                        return (
                            <div className={cx(styles.nonActiveProgressStepper,{[styles.progressStepper]: val <= currentPage})}>
                            </div>
                        )
                    })}
                </div>
            </div>
            
            <Component {...rest} formProps={formProps} />
        </div>
    )
}

CardLeftContainer.defaultProps+{
    currentPage: 1,
}

export default CardLeftContainer;