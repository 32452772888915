import React,{useCallback, useState} from "react";
import { Form } from "react-bootstrap";

import styles from "./inputWithSuffix.module.css";

const InputWithSuffix = ({
        onChange, 
        name, 
        handleFieldChange,
        errorTypes,
        checkErrorState, 
        codeCombined=true,
        countryCodeLabel="",
        ...rest
    }) =>{

    const { value = "" } = rest;

    const [currentValue,setCurrentValue] = useState(value);

    const [currentPrefix,setCurrentPrefix] = useState("+1");

    const handlePrefixChange = useCallback((event) =>{
        setCurrentPrefix(event?.target?.value);
        if(!codeCombined){
            handleFieldChange({label: countryCodeLabel})(event?.target?.value);
        }
    },[setCurrentPrefix]);

    const handleInputFieldChange = (event)=>{
        if(event?.target?.value?.length>10){
            return;
        }
        setCurrentValue(event?.target?.value);
        if(codeCombined){
            handleFieldChange({label:name})(currentPrefix+","+event?.target?.value);
        }
        else{
            handleFieldChange({label:name})(event?.target?.value);
            handleFieldChange({label: countryCodeLabel})(currentPrefix);
        }
        checkErrorState(currentPrefix+event?.target?.value, name, errorTypes);
    }

    return (
        <div className={styles.container}>
            <Form.Select className={styles.prefixContainer} onChange={handlePrefixChange}>
                <option value="+1">+1</option>
                <option value="+91">+91</option>
                <option value="+2">+2</option>
            </Form.Select>
            <input type="number" className={styles.inputField} {...rest}  value={currentValue} onChange={handleInputFieldChange}/>
        </div>
    )
}

export default InputWithSuffix;