import React, { useCallback, useState } from "react";
import { handleErrorStatus } from "../helpers/ErrorStatusCheck.helper";

const withError = ({Component, onErrorCheck = ()=>{}}) => (props)=>{

    const [error,setError] = useState({});

    const checkErrorState = useCallback((value, label,errorTypes=[])=>{
        if(errorTypes.length == 0)
        {
            return;
        }
                const errors = handleErrorStatus({value, errorStatus: errorTypes}) || {};
                setError((prev)=>{
            return {...prev,[label]:errors}
        })
    },[onErrorCheck,setError,handleErrorStatus])

    return (
        <Component {...props} fieldError={error} checkErrorState={checkErrorState}/>
    )
}

export default withError