import React from "react";

import basicExclamation from "./../../../../../../../../../../../../../assets/images/exclamation/basicExclamation.svg";
import styles from "./header.module.css";

const Header = ({CloseComponent=()=><></>, date="" , userName=""}) =>{
    return (
        <div className={styles.container}>
            <div className={styles.leftContainer}>
                <div className={styles.primaryContent}>
                    <p>Notes</p>
                    <img src={basicExclamation} className={styles.headerIcon}/>
                </div>
                <div className={styles.secondaryContent}>
                    <p>{date}</p>
                    <div className={styles.verticalDivider} />
                    <p>{userName}</p>
                </div>
            </div>
            <CloseComponent />
        </div>
    )
}

export default Header;