import React from "react";
import {map} from "lodash";

import ThreeRowDataDisplay from "../../../../../threeRowDataDisplay/ThreeRowDataDisplay";
import infoIcon from "./../../../../../../../../../assets/images/info_icon.svg";
import { PROGRESS_MONITORING_PRIMARY_CARD_TYPES } from "../../../../progressMonitoring.options";

import styles from "./glucoseMetricsCard.module.css";

const GlucoseMetricsCard = ({options=PROGRESS_MONITORING_PRIMARY_CARD_TYPES, data={} }) =>{

    const GetInfoIcon = (keys)=>{
        return (<>{keys}{"   "}<img src={infoIcon} className={styles.icon}/></>)
    }

    const { primaryCard = []} = data;
    
    return (
        <div className={styles.container}>
            {map(options,(option)=>{
                const {name,suffix="",label,goal} = option;
                return <ThreeRowDataDisplay primaryClassname={styles.primaryText} secondaryClassname={styles.secondaryText} tertiaryClassname={styles.tertiaryText} primaryContent={GetInfoIcon(label)} secondaryContent={primaryCard?.[name] && `${primaryCard?.[name] || "-"} ${primaryCard?.[name] ? suffix : ""}`} tertiaryContent={goal && `Goal < ${goal}`}/>
            })}
        </div>
    )
}

export default GlucoseMetricsCard;