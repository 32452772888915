import React from "react";
import Modal from "../../../../../../molecules/modals/Modal";
import Header from "./components/Header";
import Body from "./components/Body";

import styles from "./notesDiscardPopup.module.css";
import { NOTES_DISPLAY_STATUS } from "../../../../patientProfile.constants";

const NotesDiscardPopup = ({handleNotesDisplayAction , handleDiscardNotes}) =>{

    const handleDisplayNotes = ()=>{
        handleNotesDisplayAction(NOTES_DISPLAY_STATUS.DISPLAY);
    }

    const handleDiscrdNotesDisplay = () =>{
        handleDiscardNotes(false);
    }

    return (
        <div className={styles.container}>
            <Modal 
                crossIcon={require("./../../../../../../../assets/images/cancel/transparent_cacel.svg").default} 
                HeaderComponent={Header} 
                BodyComponent={Body} 
                toggleModal={handleDisplayNotes}
                bodyProps={{
                    handleDisplayNotes,
                    handleDiscrdNotesDisplay
                }}
                className={styles.modalContainer}
            />
        </div>
    )
}

export default NotesDiscardPopup