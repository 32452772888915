import React from "react";
import moment from "moment";

import exclamationIcon from "./../../../../../../../../../../../assets/images/exclamation/greyExclamation.svg";

import styles from "./header.module.css";

const Header = ({CloseComponent=()=><></>}) =>{
    return (
        <div className={styles.container}>
            <div className={styles.leftContainer}>
                <div className={styles.primaryText}>
                    <span className={styles.primaryMainText}>Add New Bill</span>
                    <img src={exclamationIcon} className={styles.exclamationIcon} />
                </div>
                <div className={styles.secondaryText}>
                    {moment().format("DD MMM, ddd")}
                </div>
            </div>
            <CloseComponent />
        </div>
    )
}

export default Header;