import { MONTH } from "./tableContent.constants";

export function formatDate(dateStr) {
  
  const date = new Date(dateStr);

  const months = [MONTH.JAN, MONTH.FEB, MONTH.MAR, MONTH.APR, MONTH.MAY, MONTH.JUN, MONTH.JUL, MONTH.AUG, MONTH.SEP, MONTH.OCT, MONTH.NOV, MONTH.DEC];

  const month = months[date.getMonth()];
  
  const day = date.getDate();

  return `${month} ${day}`;
}