import React from "react";
import {map} from "lodash";

import styles from "./userDetailsCardComponents.module.css";

const UserDetailsCardContent = ({label,options: optionList = [""]}) =>{
    let options = optionList?.length <=0 ? [""] : optionList;
    return (
        <div className={styles.cardContentContainer}>
            <div className={styles.cardContentText}>
                {label}
            </div>
            <div className={styles.optionsContainer}>
                {map(options,(val)=>{
                    return (<div className={styles.option}>
                        {val}
                    </div>)
                })}
            </div>
        </div>
    )
}

export default UserDetailsCardContent;