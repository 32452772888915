
import styles from "./resetPassword.module.css";

const getEmailCardProps = (ResetPasswordEmailFormField, getEmailConfirmationAction) =>{
    return {
        formContent : ResetPasswordEmailFormField,
        formHeaderProps :{
            primaryHeaderText: "Reset Password",
            secondaryHeaderText: "Enter the email address of your account so that we can send you reset instructions",
            footerContainerClassname: styles.footerContainerClassname,
            secondaryHeaderClassname: styles.secondaryHeaderClassname
        },
        formFooterProps:{
            butttonText : "Send Reset Instructions",
            enabledClassname: styles.enabledClassname
        },
        formContainerProps:{
            containerClassname : styles.formContainer
        },
        formSubmitAction : getEmailConfirmationAction,
    }
}

const getPasswordCardProps = (ResetPasswordFormField,getPasswordResetAction) =>{
    return {
        formContent : ResetPasswordFormField,
        formHeaderProps :{
            primaryHeaderText: "Set New Password",
            secondaryHeaderText: "Enter a new password to reset",
            footerContainerClassname: styles.footerContainerClassname,
            secondaryHeaderClassname: styles.secondaryHeaderClassname
        },
        formFooterProps:{
            butttonText : "Reset Password",
            enabledClassname: styles.enabledClassname
        },
        formContainerProps:{
            containerClassname : `${styles.formContainer} ${styles.passwordFormContainer}`
        },
        formSubmitAction : getPasswordResetAction,
    }
}

export {getEmailCardProps, getPasswordCardProps}