const TREND_ANALYSIS_COLOR_CODING = {
    BALANCED : "#E5FAF2",
    CRITICAL : "#FEEFEB",
    UNBALANCED : "#FFF6BD"
};

const TREND_ANALYSIS_BORDER_COLOR_CODING = {
    BALANCED : "#7ACCAE",
    CRITICAL : "#EE9887",
    UNBALANCED : "#E4A944",
}

export {
    TREND_ANALYSIS_COLOR_CODING,
    TREND_ANALYSIS_BORDER_COLOR_CODING
}