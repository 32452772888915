import React from "react";
import cx from "classnames";

import styles from "./patientNameBlock.module.css";

const PatientNameBlock = ({label = "",data = "", className = "",activeRow}) =>{
    return <div className={cx(styles.container,{[styles.activeRow]: activeRow})}>
        {label || ""} {data || ""}
    </div>
}

export default PatientNameBlock;