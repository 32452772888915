import React from "react";

import styles from "./header.module.css"

const Header = ({label,icon, buttonContent, handleNotesDisplayAction}) =>{
    return (
        <div className={styles.container}>
            <div className={styles.leftContainer}>
                <img src={icon} className={styles.imgIcon} />
                <div className={styles.primaryText}>{label}</div>
            </div>
            <div className={styles.button} onClick={handleNotesDisplayAction}>
                {buttonContent}
            </div>
        </div>
    )
}

export default Header;