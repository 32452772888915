import React from "react";
import cx from "classnames";

import styles from "./formContent.module.css";

const FormHeader = ({containerClassname="",primaryHeaderText="",primaryHeaderClassname="",secondaryHeaderText="", secondaryHeaderClassname}) =>{
    return (
        <div className={cx(styles.formHeaderContainer,containerClassname)}>
            <div className={cx(styles.primaryHeader,primaryHeaderClassname)}>
                {primaryHeaderText}
            </div>
            <div className={cx(styles.secondaryHeader,secondaryHeaderClassname)}>
                {secondaryHeaderText}
            </div>
        </div>
    )
}

export default FormHeader;