import React from "react";

import care_team_image from "../../../../../assets/images/ic_profile.jpeg";

import styles from "./careTeam.module.css";

const CareTeam = () =>{
    return (<div className={styles["team_info"]}>
                <p className={styles["care_team_heading"]}>Care Team</p>
                <div className={styles["images"]}>
                  <img className={styles["profile_image_one"]} src={care_team_image} />
                  <img className={styles["profile_image_two"]} src={care_team_image} />
                  <img className={styles["profile_image_two"]} src={care_team_image} />
                  <img className={styles["profile_image_two"]} src={care_team_image} />

                  <div className={styles["invite"]}>
                    <img
                      src={require("../../../../../assets/images/invite.svg").default}
                    />
                  </div>
                </div>
                <div className={styles["view-all"]}>
                  <p className={styles["view-all-text"]}>View all</p>
                  <img
                    className={styles["view-all_image"]}
                    src={require("../../../../../assets/images/arrow_right.svg").default}
                  />
                </div>
              </div>)
}

export default CareTeam;