import { useCallback } from "react";
import styles from "./search.module.css"

const Search = ({updateSearchText}) => {

    const handleTextChange = useCallback((event)=>{
        event.preventDefault();
        updateSearchText(event?.target?.value);
    },[updateSearchText]);

    return (

        <div className={styles.searchContainer}>
            <div className={styles.search}>
                <input onChange={handleTextChange} placeholder="Search by Practice Name, Email ID" className={styles.searchBar}></input>
                <img src={require("../../../../../assets/images/search-icon.svg").default} className={styles.searchLogo}></img>
            </div>
        </div>

    )
}

export default Search;