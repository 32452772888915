import { BASE_URL } from "../../constants/api";
import { getRequestBody } from "./ganttOverviewTable.helper";


const getInitialColumnsData = async({state,setState,payload})=>{
    try{
        const {calenderUpdate = 0} = payload;
        const requestBody = getRequestBody({type:"OVERALL_TIME_IN_RANGE",numberOfDays: 8,mealType:"BREAKFAST"});
        const response = await fetch(`${BASE_URL}/dashboard/glucoseMetrics`,{
                method:"POST",
                headers:{
                    "content-type":"application/json",
                    'authorization' : "Bearer " + window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION)
                },
                body: JSON.stringify(requestBody),
            })
        // if(response.status == API_STATUS_CODES.ANAUTHORIZED || response.status == API_STATUS_CODES.FORBIDDEN)
        // {
        //     navigate("/login")
        // }
        const data = await response.json();
        setState({...state,timeInRange:data.metricsResponses})    
    }
    catch(e)
    {
        setState({error:e})
    }
}

export default{
    GET_COLUMNS_DATA : getInitialColumnsData,
}