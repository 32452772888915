import styles from "./emptyBag.module.css";
import { useState } from "react";
import AddNewPracticeFormStepOne from "../form/AddNewPracticeFormStepOne";
import AddNewPracticeFormStepTwo from "../form/AddNewPracticeFormStepTwo";
import AddNewPracticeFormStepThree from "../form/AddNewPracticeFormStepThree";

const EmptyBag = ({formValues,updateFormValues, resetForm = ()=>{}}) => {
    const [open, setIsOpen] = useState(false);

    const openForm = () => setIsOpen(!open);

    const handleFormCloseAction = (value)=>{
        if(!value){
            resetForm();
        }
        setIsOpen(value)
    }

    const [stepCounter, setStepCounter] = useState("ONE");

    return (

        <div className={styles.outerContainer}>
            <div className={styles.innerContainer}>
                <img src={require("../../../../../assets/images/empty_bag.svg").default} className={styles.icon} />
                <div className={styles.primaryText}>
                    It’s empty in here
                </div>
                <div className={styles.secondaryText}>
                    Get started by inviting your patients to track and monitor securely
                </div>
                <div className={styles.buttonAddNewPractice} onClick={openForm}>Add New Practice</div>
                {open && <div className={styles.formBackground} >
                {stepCounter==="ONE" && <AddNewPracticeFormStepOne updateFormValues={updateFormValues} formValues={formValues} setIsOpen={handleFormCloseAction} setStepCounter={setStepCounter} className={styles.addNewPracticeFormStepOne}/>}
                {stepCounter==="TWO" && <AddNewPracticeFormStepTwo updateFormValues={updateFormValues} formValues={formValues} setIsOpen={handleFormCloseAction} setStepCounter={setStepCounter} className={styles.addNewPracticeFormStepOne}/>}
                {stepCounter==="THREE" && <AddNewPracticeFormStepThree updateFormValues={updateFormValues} formValues={formValues} setIsOpen={handleFormCloseAction} setStepCounter={setStepCounter} className={styles.addNewPracticeFormStepOne}/>}
                    </div>}
            </div>
        </div>

    )
}

export default EmptyBag;
