import React from "react";
import cx from "classnames";

import styles from "./columnHeader.module.css"

const ColumnHeader = ({className, data}) =>{

    return (
        <div className={cx(className, styles.container)}>
            {data}
        </div>
    )
}

export default ColumnHeader