import { useState } from "react";
import { BASE_URL } from "../../../../constants/api";

const getResendInviteAction = async({baseId})=>{
    try{
        const response = await fetch(`${BASE_URL}/dashboard/admin/api/rrxsuperboss/users/resendAddNewMemberInviteEmail/${baseId}`,{
            method : "GET",
            headers:{
                'content-type':'application/json',
                "authorization" : "Bearer " + window?.localStorage?.getItem("Authorization"),
            },
        })

        return response.status;
    }
    catch(e){
        console.log('Error is : ',e);
        return e;
    }
}

const useMemberForm = (practiceId, defaultWorksite)=>{
    const [formValues, setFormValues] = useState({
        role: 'NON_RENEWRX_CARE_PRO_ADMIN',
        divisionId: defaultWorksite
    });

    const submitFormValues = async()=>{
        const response = await fetch(`${BASE_URL}/dashboard/admin/api/rrxsuperboss/users/addNewMember`,{
            method : "POST",
            headers:{
                'content-type':'application/json',
                "authorization" : "Bearer " + window?.localStorage?.getItem("Authorization"),
            },
            body:JSON.stringify({
                ...formValues,
                practiceId: practiceId,
            }),
        });
        return response.status == '201' || response.status == '200' || response.status == '204'
    }

    const updateFormValues = ({type, value})=>{
        setFormValues({
            ...formValues,
            [type]: value
        })

    }

    return [
        formValues,
        submitFormValues,
        updateFormValues
    ]
}

export {
    getResendInviteAction,
    useMemberForm
}
