import React from "react";

import styles from "./header.module.css";

const TermsAndConditions = ({setModalDisplay, displayModal, setModalType}) =>{

    const handleTermsAndConditionsClick =(label)=>()=>{
        setModalDisplay(!displayModal);
        setModalType(label)
    }
    
    return(
        <div className={styles.termsAndConditionsContainer}>
            By signing up, you acknowledge that you have read and understood, and agree to RenewRx’s <p className={styles.linkText} onClick={handleTermsAndConditionsClick("terms")}> Terms </p> and <span className={styles.linkText} onClick={handleTermsAndConditionsClick('privacyPolicy')}>Privacy Policy</span> .
        </div>
    )
}

export default TermsAndConditions;