import DashBoardInformation from "../DashBoardInformation"

const getTabs = () =>{
    return [{
        id:1,
        content: "Overview",
        ChildComponent:DashBoardInformation
    },{
        id:2,
        content: "Patent information",
        ChildComponent: DashBoardInformation
    },{
        id:3,
        content: "Smart Tracking",
        ChildComponent:DashBoardInformation
    },{
        id:4,
        content: "Consent & Patient Preferences",
        ChildComponent:DashBoardInformation
    }]
}

export {
    getTabs,
}