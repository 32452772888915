import { useEffect, useState } from "react";
import { map } from "lodash";
import {useNavigate} from "react-router-dom";

import { useWorksiteManagement } from "./customHooks/useWorksiteManagement";
import { getCoachList } from "./form.async";

import styles from "./form.module.css"
import { TOAST_MESSAGES } from "./newWorksiteForm.constants";

const AddNewPracticeFormStepTwo = ({setOpen, setIsLoading, updateToast }) => {

    const [membersList , setMembersList] = useState([]);

    const navigate = useNavigate();

    useEffect(()=>{
        getCoachList()
        .then((data)=>{
            setMembersList(data?.dashboardUserDataList || [])
        })
        .catch((e)=>{
            console.log('ERROR IS : ',e);
        })
    },[])

    const {formValues, updateFormValues, submitFormValues} = useWorksiteManagement({setIsLoading});

    const closeForm = () => setOpen(false);

    const handleFieldUpdateAction = (type)=>(event)=>{
        switch(type){
            case "zipcode":
                if(event.target.value?.length>5){
                    return;
                }
                break;
            default:
                break;
        }
        updateFormValues(type, event.target.value);
    }

    const handleAdminSelect = (event)=>{
        let fieldValue = event.target.value;
        fieldValue=fieldValue.split(",")
        updateFormValues("divisionAdminName",fieldValue[0]);
        updateFormValues("divisionAdminEmail",fieldValue[1]);
    }

    const handleSubmitAction = ()=>{
        closeForm();
        submitFormValues()
        .then((status)=>{
            if(status){
                updateToast('success',TOAST_MESSAGES)
            }
            else{
                updateToast('failed',TOAST_MESSAGES);
            }
            navigate(0);
        })
        .catch((e)=>{
            console.log('ERROR IS : ',e)
            updateToast('failed',TOAST_MESSAGES);
        })
    }

    return (

        <div className={styles.container}>
            <div className={styles.upperContainer}>
                <div className={styles.formName}>Add new worksite</div>
                <img className={styles.cancel} src={require("../../../../../assets/images/cancel/transparent_cacel.svg").default} onClick={closeForm}></img>
            </div>
            <div className={styles.bottomContainer}>
                

                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        Country
                    </div>
                    <div className={styles.dropdown}>
                        <select value={formValues?.['country']} className={styles.fieldValueDropdown} onChange={handleFieldUpdateAction("country")}>
                            <option className={styles.fieldValueDropdown}>United States of America</option>
                        </select>
                        <img className={styles.dropdownArrow} src={require("../../../../../assets/images/dropdown_arrow.svg").default}></img>
                    </div>
                </div>

                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        Address 1
                    </div>
                    <input value={formValues?.['addressOne']} onChange={handleFieldUpdateAction("addressOne")} className={styles.fieldValue} placeholder="Enter Practice Street Address"></input>
                </div>

                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        Address 2
                    </div>
                    <input value={formValues?.['addressTwo']} onChange={handleFieldUpdateAction("addressTwo")} className={styles.fieldValue} placeholder="Enter Practice Street Address"></input>
                </div>

                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        City
                    </div>
                    <div className={styles.dropdown}>
                        <select value={formValues?.['city']} className={styles.fieldValueDropdown} onChange={handleFieldUpdateAction('city')}>
                            <option className={styles.fieldValueDropdown}>Littleton</option>
                        </select>
                        <img className={styles.dropdownArrow} src={require("../../../../../assets/images/dropdown_arrow.svg").default}></img>
                    </div>
                </div>

                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        State
                    </div>
                    <div className={styles.dropdown}>
                        <select value={formValues?.["state"]} className={styles.fieldValueDropdown} onChange={handleFieldUpdateAction("state")}>
                            <option className={styles.fieldValueDropdown}>New York</option>
                        </select>
                        <img className={styles.dropdownArrow} src={require("../../../../../assets/images/dropdown_arrow.svg").default}></img>
                    </div>
                </div>

                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        Zipcode
                    </div>
                    <input value={formValues?.['zipcode']} className={styles.fieldValue} onChange={handleFieldUpdateAction("zipcode")} placeholder="Enter Practice Zipcode"></input>
                </div>

                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        Admin
                    </div>
                    <div className={styles.dropdown}>
                        <select className={styles.fieldValueDropdown} onChange={handleAdminSelect}>
                            <option className={styles.fieldValueDropdown}>Choose Admin for the worksite</option>
                            {map(membersList,({firstName="", lastName="",email=""})=>{
                                return (
                                    <option value={firstName+" "+lastName+","+email} className={styles.fieldValueDropdown}>{firstName+" "+lastName}</option>
                                )
                            })}
                        </select>
                        <img className={styles.dropdownArrow} src={require("../../../../../assets/images/dropdown_arrow.svg").default}></img>
                    </div>
                </div>

                <div className={styles.worksiteId}>
                    <img className={styles.worksiteIdInfo} src={require("../../../../../assets/images/alert_message.svg").default}></img>
                    <div className={styles.workisteIdContent}>Worksite ID</div>
                    <div className={styles.worksiteIdValue}>pg-littleton01</div>
                </div>

                <div className={styles.continue} onClick={handleSubmitAction}>
                    Continue
                </div>
            </div>
        </div>

    )
}

export default AddNewPracticeFormStepTwo;