import {map} from "lodash";
import { AVERAGE_PLATE_SUMMARY_BORDER_COLOR, AVERAGE_PLATE_SUMMARY_COLOR, AVERAGE_PLATE_SUMMARY_LABEL } from "./averagePlateSummary.constants";

const getMapFormattedData = (nutritionalValues) =>{
    return map(Object.keys(nutritionalValues),(key)=>{
        return {
            color : AVERAGE_PLATE_SUMMARY_COLOR?.[key],
            borderColor : AVERAGE_PLATE_SUMMARY_BORDER_COLOR?.[key],
            y : nutritionalValues?.[key],
            name : AVERAGE_PLATE_SUMMARY_LABEL?.[key]
        }
    })
}

export {
    getMapFormattedData,
}