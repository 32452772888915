import { BASE_URL } from "../../constants/api";

const getFormSubmitAction = async({state, payload, setState, navigate}) =>{

    const {toggleModal, handleToastOpenAction} = state;
    try{
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+window?.localStorage?.getItem("Authorization"));

        const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({...payload, email : payload?.email?.toLowerCase()}),
        redirect: "follow"
        };

        fetch("https://api.renewrx.health/api/dashboard/users/patientInvite", requestOptions)
        .then((response) => {
            if(response.status == 409){
                throw new Error("conflict");
            }
            if(response.status!=200 && response.status!=201){
                throw new Error("failed");
            }
            return response.text()
        })
        .then((result) => {
            handleToastOpenAction({toastMessage:"Invite Sent Successfully", type:"success"})
            navigate(0);
        })
        .catch((error) => {
            switch(error.message){
                case 'conflict':
                    handleToastOpenAction({toastMessage: "User already joined your practice", type: 'failed'})
                    break;
                default:
                    handleToastOpenAction({toastMessage: "Invite Failed", type: 'failed'})
                    break;

            }
            console.error(error)
        });

        toggleModal();
    }
    catch(e)
    {
        setState((prev)=>{
            return {
                ...prev,
                error :e
            }
        })
    }
}

export default {
    FORM_SUBMIT_ACTION : getFormSubmitAction,
}