import React, { useState } from "react";

import styles from "./loginPinCard.module.css"
import Header from "./component/Header";
import CardBody from "./component/CardBody";
import CardFooter from "./component/CardFooter";
import { useNavigate } from "react-router-dom";
import EmailConfirmationCard from "../emailConfirmationCard/EmailConfirmationCard";

const LoginPinCard = ({ secondaryFormProps, stage, email }) =>{

    const navigate = useNavigate();

    const {formSubmitAction = () =>{},resendInvite=()=>{}} = secondaryFormProps;

    const [enableButton,setEnableButton] = useState(false);

    const [inputValue,setInputValue] = useState(new Array(6));

    const handleButtonEnabled = (val) =>{
        setEnableButton(val);
    }

    const handleButtonSubmit = ()=>{
        formSubmitAction(inputValue.join(""))
        window?.localStorage?.setItem("username","xyz")
    }

    if(!stage)
    {
        return <></>
    }

    if(stage == "EMAIL_VERIFICATION_PENDING")
    {
        return <EmailConfirmationCard secondaryFormProps={secondaryFormProps} email={email}/>
    }

    return (
        <div className={styles.loginPinCardContainer}>
            <Header email={email} />
            <CardBody setEnableButton={handleButtonEnabled} handleValueChange={setInputValue} inputValue={inputValue} />
            <CardFooter handleButtonSubmit={handleButtonSubmit} enableButton={enableButton} resendInvite={resendInvite}/>
        </div>
    )
}

export default LoginPinCard;