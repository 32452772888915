import { v4 as uuidv4 } from 'uuid';

import SearchPatients from './components/SearchPatients/SearchPatients';
import CoachSelect from './components/CoachSelect';

const getFormFields=()=>{
    return [
        [{
            id: uuidv4(),
            fieldLabel : "Search Patient",
            key : "patients",
            Component : SearchPatients,
        }],
        [{
            id: uuidv4(),
            fieldLabel : "Assign Primary Health Coach",
            key : "primaryCoach",
            Component : CoachSelect
        }]
    ]
}

export {
    getFormFields
}