import React, { useState } from "react";

import dropdown from './../../../../../../../assets/images/dropDownBalancedPlate.svg';

import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";

import "./singleSelect.module.css";
import styles from "./singleSelect.module.css";

const SingleSelect = ({
        placeHolder,
        value="",
        handleFieldChange = ()=>{}, 
        name = "",
        checkErrorState = ()=>{},
        errorTypes = [],
    }) =>{

    const [inputValue, setInputValue] = useState(value); 

    const handleFieldSelect = (value)=>{
        setInputValue(value);
        handleFieldChange({label:name})(value);
        checkErrorState(value, name, errorTypes);            
    };

    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    return (
        <div className={styles.container}>
            <div className={styles.inputContainer}>
                <div className={styles.inputFieldContainer}>
                    <DatePicker
                        selected={inputValue}
                        onChange={handleFieldSelect}
                        customInput={
                            <input type="text" value={inputValue} placeHolder={"Choose next follow-up date"} className={styles.input}/>
                        }
                        minDate={tomorrow}
                        showYearDropdown
                        showMonthDropdown
                        popperPlacement="top"
                        showPopperArrow={false}
                    />
                </div>
                <div className={styles.iconContainer}>
                    <img src={dropdown} className={styles.icon} />
                </div>
            </div>
        </div>
    )
}

export default SingleSelect;