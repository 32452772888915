import React, { useEffect, useState } from "react";
import moment from "moment";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import noDataToDisplay from 'highcharts/modules/no-data-to-display';
import drillDown from "highcharts/modules/drilldown";

import "./trendAnalysis.module.css";
import { CHART_NO_DATA } from "../../constants/noData";
import { hasChartData } from "../../helpers/chart";

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
noDataToDisplay(Highcharts);
drillDown(Highcharts);

// Disable exporting module
Highcharts.setOptions({
  exporting: {
    enabled: false
  }
});

const TrendAnalysis = ({ comparisonAnalysisData = [], categories = [] }) => {
  const normalPlotBands =[{
      from: 63,
      to: 140,
      color: '#84EBC514',
      label: {
          text: '',
          style: {
              color: '#84EBC514'
          }
      }
    }];
  
  const normalPlotLines = [{
        value: 63,
        color: '#9EDBC5',
        dashStyle: 'longdash',
        width: 1,
      },
      {
        value: 140,
        color: '#9EDBC5',
        dashStyle: 'longdash',
        width: 1,
  }];
    
  const plotBands = comparisonAnalysisData.length>0 && hasChartData(comparisonAnalysisData) ? normalPlotBands: []

  const plotLines = comparisonAnalysisData.length>0 && hasChartData(comparisonAnalysisData) ? normalPlotLines : [];

  const options={
    chart: {
      type: "spline",
      height: 336,
      plotBorderWidth: 1,
      plotBorderColor: "#EAE7EC",
      events: {
        load: function() {
          if (!hasChartData(this.series[0].data)) {
            this.showNoData("<p style='text-align: center; font-weight:500; font-family: Founders Grotesk;'><b>"+ CHART_NO_DATA.PRIMARY +"<b><br><p style='font-weight:50; font-size:12px;font-family: Founders Grotesk;'>"+ CHART_NO_DATA.SECONDARY +"</p></p>");
          }
        },
        update :function(){
          if (!hasChartData(this.series[0].data)) {
            this.showNoData("<p style='text-align: center; font-weight:500; font-family: Founders Grotesk;'><b>"+ CHART_NO_DATA.PRIMARY +"<b><br><p style='font-weight:50; font-size:12px;font-family: Founders Grotesk;'>"+ CHART_NO_DATA.SECONDARY +"</p></p>");
          }
        }
      }
    },
    title: {
      text: null,
    },
    xAxis: {
        title: {
          text: null,
        },
        categories: categories,
        lineColor: "#EAE7EC",
        labels: {
          step:1,
          rotation:0
        },
    },
    yAxis: {
        lineColor: "#EAE7EC",
        title: {
          text: null,
        },
        tickPositions: [0, 63, 100, 140, 200, 300],
        min: 0,
        max: 200,
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        alternateGridColor: null,
        labels: {
          useHTML:true,
          formatter: function () {
              if(this.value==63 || this.value==140){
                return '<span style="color: #7ACCAE; font-weight: bold;">' + this.value + '</span>';
              }
              return '<span style="color: black">' + this.value + '</span>';                ;
          }
      },
      plotBands: plotBands,
        plotLines
      },
      tooltip: {
        valueSuffix: ' mg/dL',
        formatter: function () {
          return '<b>' + moment(comparisonAnalysisData?.[this?.point?.index]?.timestamp).format("MMM DD") + " - " + moment(comparisonAnalysisData?.[this?.point?.index]?.timestamp).format('LT') + ' : ' + Math.round(this.y) + " mg/dL ";
        }
      },
      plotOptions: {
        spline: {
          lineWidth: 1.5,
          states: {
            hover: {
              lineWidth: 2
            }
          },
          marker: {
            enabled: false
          },
          pointInterval: 1,
          pointStart: 0
        }
      },
      series: [{
        name: "",
        data: hasChartData(comparisonAnalysisData) ?  comparisonAnalysisData?.map((value) => (value?.["glucose_level"] || null)) : [],
        zones: [{
          value: 63,
          color: '#E4A944'
        }, {
          value: 140,
          color: '#7ACCAE'
        }, {
          value: 280,
          color: '#EE9887'
        }],
        showInLegend: false
      }],
    lang: {
      noData:"<p style='text-align: center; font-weight:500; font-family: Founders Grotesk;'><b>"+ CHART_NO_DATA.PRIMARY +"<b><br><p style='font-weight:50; font-size:12px;font-family: Founders Grotesk;'>"+ CHART_NO_DATA.SECONDARY +"</p></p>",
    },
    noData: {
      useHTML: true,
      style: {
        color: '#66636D',
        minWidth:"250px",
        display:"flex",
        justifyContent:"center"
      },
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
  };

  const chartProps= (hasChartData(comparisonAnalysisData) && comparisonAnalysisData.length >0) ? {highCharts:Highcharts}: {highcharts:Highcharts};

  return (
    <div className={"container"} style={{ minWidth: "100%" }}>
      <HighchartsReact
        {...chartProps}
        options={options}
      />
    </div>
  );
}

export default TrendAnalysis;