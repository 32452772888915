import React from "react";
import cx from "classnames";

import styles from "./styledButton.module.css";

const StyledButton = ({content, className, handleButtonAction, ...rest}) =>{
    return (
        <div className={cx(styles.container,className)} >
            {content}
        </div>
    )
}

export default StyledButton;