import React from "react";

import GanttTableDateColumn from "./columns/GanttTableDateColumn/GanttTableDateColumn";

import styles from "./tableHeader.module.css";

const TableHeader = ({setCalenderUpdate,calenderUpdate})=>{
    const days = [1,2,3,4,5,6,7,8,9,10,11,12,13,14]

    const handlerCalenderUpdate = (val) => () =>{
      setCalenderUpdate(calenderUpdate+val);
    }

    return (
        <div className="calender">
        <div className="calender-top">
          <div className="calender-top-week-one">
            <span className="week-one-text">WEEK 28</span>
          </div>
          <div className="calender-top-week-two">
            <span className="week-two-text">WEEK 29</span>
          </div>
        </div>
        <div className="calender-bottom">
          <div className="left-arrow-div" onClick={handlerCalenderUpdate(-1)}>
            <div>
              <img
                src={
                  require("../../../../../assets/images/left-arrow-gantt-chart.svg")
                    .default
                }
              />
            </div>
          </div>
          <div className={styles["date-div"]}>
            {
                days?.map((val)=>{
                    return <GanttTableDateColumn day={val} dayJump={calenderUpdate}/> 
                })
            }
          </div>
          <div className="right-arrow-div" onClick={handlerCalenderUpdate(1)}>
            <div>
              <img
                src={
                  require("../../../../../assets/images/right-arrow-gantt-chart.svg")
                    .default
                }
              />
            </div>
          </div>
        </div>
      </div>
    )
}

export default TableHeader;