
const getFormattedGlucoseData= (glucoseData={})=>{
    let glucoseChartData =[];
    let offset=0;
    Object.keys(glucoseData).forEach((key)=>{
        let datedGlucoseData = glucoseData?.[key] || [];

        let currentDate = key.split("-");

        datedGlucoseData?.forEach((dayGlucose)=>{
            let time = dayGlucose?.["localTime"].split(":");
            let currentTime = Date.UTC(currentDate?.[0],currentDate?.[1]-1,currentDate?.[2],time?.[0],time?.[1],time?.[2])
            if(offset==0){
                offset= (new Date(dayGlucose?.["timestamp_in_utc"])).getTime()-(new Date(dayGlucose?.["timestamp"])).getTime();
            }
            let currentGlucoseValue=[];
            currentGlucoseValue.push(currentTime);
            currentGlucoseValue.push(dayGlucose?.['glucose_level']);
            glucoseChartData.push(currentGlucoseValue);

        });
    })

    return {glucoseChartData, offset}
}

const getFormattedMealLogs = (meals=[]) =>{
    return meals?.map((val)=>{
        let formattedDate = val?.['logged_time'].split("T");
        let currentDate = formattedDate[0].split("-");
        let currentTime = formattedDate[1].split(":");

        return [
            Date.UTC(currentDate?.[0],currentDate?.[1]-1,currentDate?.[2],currentTime?.[0],currentTime?.[1],currentTime?.[2]) ,
            19
        ]
    })
}

export {
    getFormattedGlucoseData,
    getFormattedMealLogs
}