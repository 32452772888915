
const GLUCOSE_METRICS_TYPES = {
    AVG_FASTING_GLUCOSE :'AVG_FASTING_GLUCOSE',
    FASTING_TIME_IN_RANGE:'FASTING_TIME_IN_RANGE',
    POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE:"POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
    POST_PRANDIAL_TWO_HOUR_TIME_IN_RANGE:"POST_PRANDIAL_TWO_HOUR_TIME_IN_RANGE",
}

const GLUCOSE_METRICS_SOURCE_TYPES = {
    BGM : "BGM",
    CGM : "CGM",
    CGM_BASED_ON_USER_MEAL : "CGM_BASED_ON_USER_MEAL",
    CGM_BASED_ON_WAKE_UP_TIME : "CGM_BASED_ON_WAKE_UP_TIME",
    CGM_BASED_ON_SYSTEM_MEAL_DETECTION_LOGIC : "CGM_BASED_ON_SYSTEM_MEAL_DETECTION_LOGIC",
    DEFAULT_VALUE : "DEFAULT_VALUE"
};

const MEDICATION_TRACER_FORMAT ={
    OVERALL : "Overall",
    WEEKLY : "Weekly",
    DAILY : "Daily",
    MONTHLY : "Monthly"
}

export {
    GLUCOSE_METRICS_TYPES,
    MEDICATION_TRACER_FORMAT,
    GLUCOSE_METRICS_SOURCE_TYPES
}