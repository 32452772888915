import React from "react";

import profile_image from "./../../../../../assets/images/profile_image.png";

import styles from "./userCard.module.css"

const UserCard = () =>{
    return (<div className={styles.cardContainer}>
        <img className={styles.imageContainer} src={profile_image} />
        <div className={styles.userInfo}>
            <div className={styles.userName}>LIZ SAMSONITE</div>
            <div className={styles.deliveryInfo}>24 weeks 2 Days • Age 31</div>
        </div>
        <div className={styles.generalInfo}>
            <div className={styles.generalInfoHeading}>
                General Info
            </div>
            <div className={styles.generalInfoContent}>
                <div className={styles.generalInfoContentHeading}>
                    Estimated Due Date
                </div>
                <div className={styles.generalInfoContentInfo}>
                    January 22nd 2024
                </div>
            </div>
            <div className={styles.generalInfoContent}>
                <div className={styles.generalInfoContentHeading}>
                    Location
                </div>
                <div className={styles.generalInfoContentInfo}>
                    Denver, CO
                </div>
            </div>
            <div className={styles.generalInfoContent}>
                <div className={styles.generalInfoContentHeading}>
                    GTPAL
                </div>
                <div className={styles.generalInfoContentInfo}>
                    G1PO
                </div>
            </div>
        </div>
    </div>)
}

export default UserCard;