import React from "react";
import cx from "classnames";

import styles from "./label.module.css";

const Label = ({label = "",data = "", className = ""}) =>{
    return <div className={cx(styles.container, className)}>
        {label} {data}
    </div>
}

export default Label;