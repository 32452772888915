import React from "react";

import styles from "./header.module.css";
import { useNavigate } from "react-router-dom";

const RightHeaderContainer = ({rightHeaderText ="Existing User?",buttonProps ={}}) =>{
    const {buttonContent = "Login", ...rest} = buttonProps;

    const navigate = useNavigate();

    const handleButtonClick = () =>{
        if(buttonContent === "Login")
        {
            navigate("/login")
        }
    }
    return (
        <div className={styles.rightContainer}>
            <div className={styles.rightContainerText}>{rightHeaderText}</div>
            <div className={styles.rightContainerButton}>
                <button className={styles.rightButton} onClick={handleButtonClick}  {...rest}>{buttonContent}</button>
            </div>
        </div>
    )
}

export default RightHeaderContainer;