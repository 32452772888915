import {map} from "lodash";
import { getUpperCaseFormatter } from "../../helpers/TextManipulations.helpers";

const getFormattedData = (data=[])=>{
    return map(data,(row)=>{
        return {
        user : getUpperCaseFormatter(row?.["first-name"]+" "+row?.["last-name"]),
        phoneNumber : row?.["mobile-number"],
        email : row?.email,
        status : row?.status,
        action:"",
        id:row?.["patient-id"],
        rowId : row?.["id"],
        lastActive : row?.["last-active-string"]=="NO DATA" ? "" : row?.["last-active-string"],
    }})
}

const getDataByTab = (status, patients)=>{
    return getFormattedData(patients?.[status])
}

export {
    getFormattedData,
    getDataByTab
}