import React,{ forwardRef } from 'react';

import dropdownIcon from "./../../../../../assets/images/dropdown_normal.svg";

import styles from "./userProfile.module.css"

const UserProfile = (props, ref) =>{

    const {toggleDropdown=()=>{}} = props;

    return (
        <div className={styles.container} ref={ref}  onClick={toggleDropdown}>
            <img src={require("./../../../../../assets/images/male_icon.svg").default} className={styles.profileAvatar}/>
            <div className={styles.textContainer}>
                <div className={styles.userHeader}>
                    {window?.localStorage?.getItem("firstName")+" "+window?.localStorage?.getItem("lastName") }
                </div>
            </div>
            <img src={dropdownIcon} className={styles.dropdownIcon}/>
        </div>
    )
}

export default forwardRef(UserProfile);