import React from "react";

import exclamation from "./../../../../../../../../../assets/images/exclamation/basicExclamation.svg";

import styles from "./header.module.css"

const Header = ({CloseComponent=()=><></>}) =>{
    return (
        <div className={styles.container}>
            <div className={styles.primaryTextContainer}>
                <div className={styles.headerTypography}>
                    Confirm Discard?
                </div>
                <img src={exclamation} className={styles.icon} />
            </div>
            <CloseComponent/>
        </div>
    )
}

export default Header