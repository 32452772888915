import React from "react";
import { map } from "lodash";

import styles from "./baseContent.module.css";

const BaseContent = ({options=[], image_url="",title,description }) =>{
    return (
        <div className={styles.container}>
            <img className={styles.imgContainer} src={image_url}  />
            <div className={styles.leftContainer}>

                <div className={styles.textContainer}>
                    <div className={styles.labelTypo}>MEAL DESCRIPTION</div>
                    {title ? <div className={styles.mainText}>{title}</div> : <div className={styles.noData}>No Data</div>}
                </div>
                
                {/* <div className={styles.textContainer}>
                    <div className={styles.labelTypo}>Description</div>
                    {description ? <div className={styles.mainText}>{description}</div> : <div className={styles.noData}>No Data</div>}
                </div>
 */}
                {/* <div className={styles.primaryText}> LOGGED ITEM</div>
                {options?.length == 0  && <div className={styles.noData}>No Data</div>} */}

                {/* {map(options,(option)=>{
                    const { quantity="", measure=""} = option;
                    return (
                        <div className={styles.contentCard}>
                            <div className={styles.contentCardText}>
                                {option?.["food_name"]}
                            </div>
                            <div className={styles.contentCardText}>
                                {Math.round(quantity)+"   "+ measure}
                            </div>
                        </div>
                    )
                })} */}
            </div>
        </div>
    )
}

export default BaseContent;