
import styles from "./notesFormPopup.module.css";

const getRespectiveFormData = (formField, getPrimaryFormSubmit=()=>{},handleNotesFormUpdate) =>{

    return {
        formContent : formField,
        formFooterProps:{
            butttonText : "Add Notes",
            footerContainerClassname: styles.footerContainerClass ,
            enabledClassname: styles.enabledClassname,
            buttonClassname : styles.buttonClassname
        },
        formContainerProps:{
            containerClassname : styles.formContainer
        },
        handleValueChange : handleNotesFormUpdate,
        formSubmitAction : getPrimaryFormSubmit,
    }
}

export {
    getRespectiveFormData
}