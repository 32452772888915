import React from "react";

import cx from "classnames";

import { STATUS_MAPPINGS } from "./customStatus.constants";
import styles from "./customStatus.module.css";

const STATUS_STYLES = {
    UNSENT: styles.unsentStatus,
    PENDING: styles.pendingStatus
}

const CustomStatus = ({status = "Invite Sent"}) =>{


    return (
        <div className={styles.container}>
            <div className={cx(styles.icon, STATUS_STYLES[status])}>
                {STATUS_MAPPINGS?.[status]}
            </div>
        </div>
    )
}

export default CustomStatus;