import React, { useState } from "react";
import { TOAST_MESSAGE } from "../practiceManagement.constants";

const useToast = ()=>{
    const [displayToast,setToastDisplay] = useState(false);
    const [toastType, setToastType] = useState("");
    const [toastMessage, setToastMessage] = useState("");

    const handleOpenToastDisplay=(type, message="")=>{
        setToastDisplay(true);
        setToastType(type);
        setToastMessage(message.length > 0 ? message :TOAST_MESSAGE?.[type]);
    }

    const handleCloseToastDisplay = ()=>{
        setToastDisplay(false);
    }

    return [
        handleCloseToastDisplay,
        handleOpenToastDisplay,
        toastType,
        displayToast,
        toastMessage
    ]
}

export default useToast;