import React from "react";

import Header from "./components/Header";
import Body from "./components/Body";

import styles from "./nutritionalCard.module.css";

const NutritionalCard = ({headerProps, bodyProps}) =>{
    return (
        <div className={styles.container}>
            <Header {...headerProps} />
            <Body {...bodyProps} />
        </div>
    )
}

export default NutritionalCard;