import styles from "./search.module.css";
import AddNewMemberForm from "../../../form/AddNewMemberForm";
import { useState } from "react";
import AddNewPatientForm from "../form/AddNewPatientForm";

const Search = ({handleListReFetch, updateToast, setIsLoading}) => {

    const [open, setIsOpen] = useState(false);

    const openForm = () => setIsOpen(!open);

    return (

        <div className={styles.searchContainer}>
            <div className={styles.search}>
                <input placeholder="Search by Practice Name, Email ID" className={styles.searchBar}></input>
                <img src={require("../../../../../../../assets/images/search-icon.svg").default} className={styles.searchLogo}></img>
            </div>
            <div className={styles.rightActions}>
                <div className={styles.dropdown}>
                    <select className={styles.fieldValueDropdown}>
                        <option className={styles.fieldValueDropdown}>All Worksites</option>
                    </select>
                    <img className={styles.dropdownArrow} src={require("../../../../../../../assets/images/dropdown_arrow.svg").default}></img>
                </div>

                <div className={styles.addNewMemberBorder} onClick={openForm}>
                        <div className={styles.addNewMemberStyle}>Add New Patient</div>
                </div>
                {open && <div className={styles.formBackground}>
                    <AddNewPatientForm updateToast={updateToast} handleListReFetch={handleListReFetch} setIsLoading={setIsLoading} setIsOpen={setIsOpen} className={styles.addNewPracticeFormStepOne}/>
                    </div>}
            </div>

        </div>

    )
}

export default Search;