import React from "react";

import logo from "./../../../../../assets/images/logo.svg";
import textLogo from "./../../../../../assets/images/textLogo.svg";

import styles from "./header.module.css";

const Header = () =>{
    return (
        <div className={styles.container}>
            <div className={styles.logoOuterContainer}>
                <img src={logo} className={styles.imgLogo}/>
                <img src={textLogo} className={styles.textLogo}/>
            </div>
        </div>
    )
}

export default Header;