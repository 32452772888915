import React, { useState } from "react";
import cx from "classnames";

import Header from "./components/Header";

import styles from "./signUpBasePage.module.css";
import TermsAndConditions from "./components/TermsAndConditions";
import withActionState from "../../hooks/withActionState";
import TermsAndConditionsModal from "../../molecules/TermsAndConditionsModal";

const SignUpBasePage = ({ initialPageState = 1,FormComponent = ()=><></>,SecondaryComponent=()=><></>, secondaryFormProps, formProps, buttonProps,rightHeaderText , hasTermsAndConditions = true, ...rest}) =>{

    const [displayModal,setModalDisplay] = useState(false);

    const [modalType,setModalType] = useState("");

    return (
        <div className={cx(styles.container,styles.containerBackgroundStyles)}>
            <Header buttonProps={buttonProps} rightHeaderText={rightHeaderText}/>
            <div className={styles.bodyContainer}>
                {
                    initialPageState == 1 || !SecondaryComponent ? <FormComponent externalFormSubmitAction={()=>{}} formProps={formProps}/> : <SecondaryComponent secondaryFormProps={secondaryFormProps} {...rest}/>
                }
            </div>
            {hasTermsAndConditions && <TermsAndConditions setModalType={setModalType} displayModal={displayModal} setModalDisplay={setModalDisplay}/>}
            {displayModal && <TermsAndConditionsModal setModalType={setModalType} modalType={modalType} displayModal={displayModal} setModalDisplay={setModalDisplay} />}
        </div>
    )
}

export default withActionState({Component:SignUpBasePage});