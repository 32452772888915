import React from "react";

import PatientsInvite from "../../organisms/patientsInvite";

import styles from "./patients.module.css";

const Patients = () => {
    return (
        <div className={styles.container}>
            <PatientsInvite />
        </div>
    )
}

export default Patients;