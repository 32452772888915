import React from "react";
import RightContainer from "./RightContainer";

const MainContainer = (props)=>{
    return (
        <>
        <RightContainer {...props} />
        </>
    )
}

export default MainContainer;