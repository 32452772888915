import React, { useCallback, useMemo, useState } from "react";
import { map } from "lodash";
import cx from "classnames";

import notFoundIcon from "./../../../../../../../../../assets/images/notFound/notFoundBunnyIcon.svg";

import { getActivePatientListToMap, getSearchFilteredText } from "./searchPatients.helpers";

import { PATIENTS_SEARCH_BOX, PATIENT_LIST_STATUS } from "./searchPatients.constants";
import { getTextInitials } from "../../../../../../../../helpers/TextManipulations.helpers";
import styles from "./searchPatients.module.css";

const SearchPatients = ({
        patientsList=[], 
        activePatientList=[], 
        fieldLabel,
        handleAddPatientToList,
        handleRemovePatientsFromList
    }) =>{
    
    const [searchText, setSearchText] = useState("");

    const handleUpdateSearchText = useCallback((event)=>{
        event.preventDefault()
        setSearchText(event.target.value);
    },[setSearchText]);

    const filteredList = useMemo(()=>{
        return getSearchFilteredText(searchText, patientsList);
    },[searchText,patientsList]) 

    const patientMap = useMemo(()=>getActivePatientListToMap(activePatientList),[activePatientList]);

    const handleListUpdateAction = (id) => ()=>{
        if(patientMap.has(id)){
            handleRemovePatientsFromList(id);
            return ;
        }
        handleAddPatientToList(id);
    }

    return (
        <div className={styles.container}>
            <div className={styles.fieldLabel}>
                {fieldLabel}
            </div>
            <input className={styles.inputContainer} onChange={handleUpdateSearchText} placeholder={PATIENTS_SEARCH_BOX.PLACEHOLDER}/>
            <div className={styles.listContainer}>
                {map(filteredList,(listItem)=>{
                    const {firstName="", lastName="", mobileNumber="",id="", isAlreadyAdded=false } = listItem;
                    return (
                        <div className={styles.filteredListContainer}>
                            <div className={styles.listOptionLeftContainer}>
                                <div className={styles.initialsContainer}>
                                    {getTextInitials(firstName+" "+lastName)}
                                </div>
                                <div className={styles.listMainTextContainer}>
                                    <div className={styles.listPrimaryText}>{firstName+" "+lastName}</div>
                                    <div className={styles.listSecondaryText}>{mobileNumber || "-"}</div>
                                </div>
                            </div>
                            <div className={styles.listOptionRightContainer}>
                                {isAlreadyAdded ? <div className={styles.alreadyAddedTypo}>Already Added</div> :
                                <div 
                                    className={cx(styles.buttonContainer, {[styles.activeListOptionContainer]: patientMap.has(id)})}
                                    onClick={handleListUpdateAction(id)}
                                >
                                    <div 
                                        className={cx(styles.button, {[styles.activeListOption]: patientMap.has(id)})}
                                    >
                                        {patientMap.has(id) ? PATIENT_LIST_STATUS.REMOVE : PATIENT_LIST_STATUS.ADD}
                                    </div>
                                </div>}
                            </div>
                        </div>
                    )
                })}
            </div>
            {filteredList?.length == 0 && 
                <div className={styles.noItemListContainer}>
                    <img src={notFoundIcon} className={styles.notFoundIcon} />
                    <div className={styles.noItemContentContainer}>
                        <div className={styles.noItemPrimaryText}>No Match Found</div>
                        <div className={styles.noItemSecondaryText}>Try again with different spelling or phone number</div>
                    </div>
                </div>
            }
        </div>
    )
}

export default SearchPatients;