import React from "react";

import styles from "./modalBody.module.css";
import BaseContent from "./components/BaseContent/BaseContent";
import NutritionaDetails from "./components/NutritionalDetails/NutritionaDetails";
import { getRespectiveSpikeAnalysis, getRespectiveMicroNutrients } from "./modalBody.helpers";
import noFood from "../../../../../../../../../../../../../assets/images/noFood.svg"
import Footer from "./components/Footer";

const ModalBody = ({meal ={}, spikeAnalysis=[]}) =>{
    
    const {food_items, micro_nutrients_response, image_url, note, title, description} = meal || {};
    const nutritionalBreakdown = getRespectiveMicroNutrients(micro_nutrients_response);
    const displayImageUrl = image_url ? image_url : noFood;

    return (
        <div className={styles.container}>
            <BaseContent 
                options={food_items} 
                image_url={displayImageUrl} 
                title={title} 
                description={description}
            />
            <NutritionaDetails nutritionalBreakdown={nutritionalBreakdown} spikeAnalysis={getRespectiveSpikeAnalysis(spikeAnalysis)}/>
            <Footer note={note || "No Note Added By Patient"}/>
        </div>
    )
}

export default ModalBody;