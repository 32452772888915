import React from "react";
import {map} from "lodash";

import styles from "./footerLabelBlock.module.css";

const FooterLabelBlock = ({label, options })=>{
    return (
        <div className={styles.container}>
            <div className={styles.leftContainer}>
                {label}
            </div>
            <div className={styles.rightContainer}>
                {map(options,(option)=>{
                    const {icon, label=""} = option || {};
                    return (
                        <div className={styles.optionContainer}>
                            <img src={icon} className={styles.iconStyling} />
                            <div className={styles.optionContent}>
                                {label}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default FooterLabelBlock;