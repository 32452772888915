import React from "react";

import BaseTable from "../../../../../../../../../tables/BaseTable";
import WeeklyFilter from "../../../../../WeeklyFilter";
import { getWeeklyFormattedWeeklyDates } from "../../../../../../../../../../helpers/dateFormatter";
import { getTableFields } from "./comparisonAnalysis.columns";

import styles from "./comparisonAnalysis.module.css";
import { getWrapperFields } from "./comparisonAnalysis.fields";
import { getFormattedData } from "./comparisonAnalysis.helpers";

const ComparisonAnalysis = ({
    avgPlateScoreByMealMap,
    numberOfMealsByMealMap,
    previousWeekMicroNutritionalValues,
    weeklyFilter = {},
    getFilterChangeAction
  })=>{

    const { comparisonAnalysis=0 } = weeklyFilter;

    const columnData = getTableFields(comparisonAnalysis, getFilterChangeAction);
    
    const mainTableData = getFormattedData(previousWeekMicroNutritionalValues,{avgPlateScoreByMealMap},numberOfMealsByMealMap);

    return (
        <div className={styles.container}>
            <div className={styles.tableContainer}>
                <BaseTable tableData={mainTableData} columnsData={columnData} columnProps={{ className : styles.columnRow}} wrapperFields={getWrapperFields()}/>
            </div>
        </div>
    )
}

export default ComparisonAnalysis;