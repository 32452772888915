import React, { useState } from 'react';
import cx from "classnames";
import styles from './tableContent.module.css';
import rightArrow from "../../../../../../../../../../../assets/images/rightArrow.svg";
import noFood from "../../../../../../../../../../../assets/images/noFood.svg"
import { formatDate } from './formatLoggedDate';
import { getBalancedPlateMealStatus } from './getBalancedPlateMealStatus';
import Modal from '../../../../../../../../../../molecules/modals/Modal';
import actionTypes from "./tableContent.actionTypes";
import actions from "./tableContent.action";
import crossIcon from "./../../../../../../../../../../../assets/images/cancel/transparent_cacel.svg";

import { getRespectiveModelContent } from './tableContent.helpers';
import withActionState from '../../../../../../../../../../hooks/withActionState';
import { map } from 'lodash';

const ONE_HOUR_PP_STYLES = {
  GREEN: styles.green,
  RED : styles.red,
  YELLOW : styles.yellow
}

function TableContent({ meal, responseMetrics, dispatch, oneHourPP  }) {

  const [isModalVisible , setModalVisibility] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const { logged_time_date, meal_type_name, food_items, logged_time_hour_min, image_url, logged_time , title, description, micro_nutrients_response, balanced_plate_meal_status } = meal;

  const { plateScore="" } = micro_nutrients_response;


  const formattedDate = formatDate(logged_time_date);

  const handleModalToggle = ()=>{
    if(!isModalVisible){
      dispatch({actionType : actionTypes.MODAL_INIT_ACTION})
    }
    setModalVisibility(!isModalVisible);
  }


  const MicronutrientData = ({ nutrient }) => (
    <div className={styles.micronutrientDataDiv}>
      {nutrient && <span className={ cx(styles.micronutrientDataContent, nutrient?.className )}>{nutrient.value}</span>}
    </div>
  );

  const micronutrients = [
    { type : "oneHourPP", value : oneHourPP?.filter((val)=> val?.metricRequest?.mealType == meal_type_name?.toUpperCase() )?.[0]?.glucoseDetails?.glucose || '-', className : ONE_HOUR_PP_STYLES?.[oneHourPP?.filter((val)=> val?.metricRequest?.mealType == meal_type_name?.toUpperCase() )?.[0]?.glucoseDetails?.['glucose-color']?.split("_")?.[0]]},
    {type : 'calories', value : micro_nutrients_response?.calories?.value},
    { type: 'carbohydrates', value: micro_nutrients_response?.carbohydrates?.value },
    { type: 'proteins', value: micro_nutrients_response?.proteins?.value },
    { type: 'fats', value: micro_nutrients_response?.fats?.value },
    { type: 'fiber', value: micro_nutrients_response?.fiber?.value },
    { type: 'sodium', value: micro_nutrients_response?.sodium?.value },
  ];

  const modalContent = getRespectiveModelContent({loggedTime :logged_time});

  return (
    <>
    { isModalVisible && <div className={styles.modalContainer}>
      <Modal crossIcon={crossIcon} toggleModal={handleModalToggle} {...modalContent} spikeAnalysis={responseMetrics} meal={meal} className={styles.modalMainContainer} />
    </div>}
    <div  className={`${styles.container} ${isHovered ? styles.hovered : ''}`} 
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleModalToggle}>
            <div className={styles.dateDataDiv}>
            <span className={styles.dateDataContent}>
              {meal_type_name}
            </span>
            <span>
              {logged_time_hour_min?.toUpperCase()}
            </span>
          </div>

          <div className={styles.mealDataDiv}>
            <div className={styles.mainContaine}>
              <img src={image_url? image_url : noFood} className={styles.mealImage} />
            </div>
            <div className={styles.mealName}>
              <div className={styles.mealTitle}>
                {title}
              </div>
              {/* <div className={styles.mealDescription}>
                {description}
              </div> */}
            </div>
          </div>

        <div className={styles.statusDataDiv}>
            <div className={styles.plateScoreText}>{plateScore}/18</div>
            {getBalancedPlateMealStatus(balanced_plate_meal_status)}
          </div>

          {micronutrients.map((nutrient, index) => (
            <MicronutrientData key={index} nutrient={nutrient} />
          ))}


      </div>

    </>
  );
}

export default withActionState({Component: TableContent, action: actions})