import React from "react";

import Modal from "../../../../../../../../molecules/modals/Modal";
import cancelIcon from "./../../../../../../../../../assets/images/cancel/transparent_cacel.svg"
import Header from "./components/Header";
import Body from "./components/Body";

import styles from "./billingPopup.module.css";

const BillingPopup = ({handleModalToggle, handleSubmitBillingPopup})=>{
    return (
        <div className={styles.container}>
            <Modal toggleModal={handleModalToggle} handleModalToggle={handleSubmitBillingPopup} className={styles.modalContainer} HeaderComponent={Header} BodyComponent={Body} crossIcon={cancelIcon}/>
        </div>
    )
}

export default BillingPopup