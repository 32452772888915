import React from "react";
import cx from "classnames";

import styles from "./tabDisplay.module.css";

const TabDisplay =({label, activeTab, handleTabChange, activeClass = styles.activeClass, icon=""}) =>{

    const handleTabClick = (label)=>()=>{
        handleTabChange({label})
    }
    
    return (
        <div className={cx(styles.container,{[activeClass]: activeTab==label})} onClick={handleTabClick(label)}>
            <img src={icon} className={styles.iconPaint} />
            <div>
                {label}
            </div>
        </div>
    )
}

export default TabDisplay;