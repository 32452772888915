import ProgressChart from "../progressChart"
import styles from "./overViewTab.module.css"

const getUserFieldData = ()=>{
    return [{
        label : "day 0",
        field :({data})=> <ProgressChart {...data}/>
    },
    {
        label : "day 1",
        field : ({data})=> <ProgressChart {...data}/>
    },
    {
        label : "day 2",
        field : ({data})=> <ProgressChart {...data}/>
    },
    {
        label : "day 3",
        field : ({data})=> <ProgressChart {...data}/>
    },
    {
        label : "day 4",
        field : ({data})=> <ProgressChart {...data}/>
    },
    {
        label : "day 5",
        field :({data})=> <ProgressChart {...data}/>
    },
    {
        label : "day 6",
        field :({data})=> <ProgressChart {...data}/>
    }]
}

export {
    getUserFieldData,
}