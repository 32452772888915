import moment from "moment";
import {map} from "lodash";
import { TREND_ANALYSIS_BORDER_COLOR_CODING, TREND_ANALYSIS_COLOR_CODING } from "./trendAnalysis.constants";

const getRespecticeColumns = (prev=0) =>{
    let data = [0,1,2,3,4,5,6];
    return map(data,(val)=>{
        return {
            day: "DAY "+ val,
            date : moment().subtract(6-val+(7*prev),'d').format("MMM DD"),
            fullFormattedData : moment().subtract(6-val+(7*prev),'d').format("MMM DD,YYYY")
        }
    })
}

const getMapFormattedData = (nutritionalInsightsDaily)=>{
    let keys = Object.keys(nutritionalInsightsDaily).sort((a,b)=>{
        return a?.split("-")?.[2]-b?.split("-")?.[2]
    })
    let data = map(keys,(key)=>{

        const { avgPlateScore = 0, plateScoreByMealMap, balancedPlateMealStatus } = nutritionalInsightsDaily?.[key] || {};

        return {
            plateScoreByMealMap : plateScoreByMealMap,
            avgPlateScore: avgPlateScore || 0,
            data : [avgPlateScore || 0],
            name : "",
            showInLegend: false,
            color : TREND_ANALYSIS_COLOR_CODING?.[balancedPlateMealStatus],
            borderColor : TREND_ANALYSIS_BORDER_COLOR_CODING?.[balancedPlateMealStatus]
        }
    })

    return data;
}

export {
    getMapFormattedData,
    getRespecticeColumns
}