import React from "react";
import {map} from "lodash";
import cx from "classnames";

import styles from "./stepBasedHeader.module.css";

const StepBasedHeader = ({currentPage,currentSpan}) =>{
    return (
        <div className={styles.leftCardHeader}>
            <div className={styles.leftCardHeaderText}>
                Steps {currentPage}/3
            </div>
            <div className={styles.progressStepperContainer}>
                {map(currentSpan,(val)=>{
                    return (
                        <div className={cx(styles.nonActiveProgressStepper,{[styles.progressStepper]: val <= currentPage})}>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default StepBasedHeader;