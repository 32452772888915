
const MANDATORY_FIELDS =[[
        "practiceName",
        "practiceType"
    ],
    [
        'practiceCountry',
        "practiceAddressOne",
        "practiceAddressTwo",
        "practiceCity",
        "practiceState",
        'practiceZipcode'
    ],
    [
        "practiceAdminName",
        "practiceAdminEmail"
    ]
]

export {
    MANDATORY_FIELDS
}