import React, { useState } from "react";
import {map} from "lodash";

import { getTextInitials } from "../../../../../../helpers/TextManipulations.helpers";

import styles from "./table.module.css"

const Table = ({tableData:patientList=[]}) => {

    return (
        <>
        <div className={styles.practiceListContainer}>
            <div className={styles.tableHeader}>
                <div className={styles.tableColumn}>NAME<img src={require("../../../../../../../assets/images/sort-icon.svg").default} className={styles.searchLogo}></img></div>
                <div className={styles.tableColumn}>EMAIL</div>
                <div className={styles.tableColumn}>PHONE NUMBER</div>
            </div>
            <div className={styles.tableColumnContainer}>

                {map(patientList,(patient)=>{
                    const {email=""} = patient || {};
                    const nameInitials = getTextInitials((patient?.["first-name"] || "")+" "+(patient?.["last-name"] || ""));

                    return (
                        <div className={styles.tableColumnData}>
                        <div className={styles.tableColumnDataContainer}>
                            <div className={styles.avatar}>
                                <div className={styles.avatarData}>
                                    {nameInitials}
                                </div>
                            </div>
                            <div className={styles.nameAndUrlContainer}>
                                <div className={styles.practiceName}>
                                    {(patient?.["first-name"] || "")+" "+(patient?.["last-name"] || "")}
                                </div>
                            </div>
                        </div>
    
                        <div className={styles.tableColumnDataContainer}>
                            <div className={styles.nameAndUrlContainer}>
                                <div className={styles.patientInfoStyle}>
                                    {email}
                                </div>
                            </div>
                        </div>
    
                        <div className={styles.tableColumnDataContainer}>
                            <div className={styles.nameAndUrlContainer}>
                                <div className={styles.patientInfoStyle}>
                                    {(patient?.["mobile-number"] || "")}
                                </div>
                            </div>
                        </div>
                    </div>    
                    )
                })}

            </div>
        </div>
        </>

    )
}

export default Table;
