import React, { useEffect, useState } from "react";
import moment from "moment";

import dropDown from "./../../../../../../../assets/images/dropDownBalancedPlate.svg";

import DatePicker from 'react-datepicker';
import Form from 'react-bootstrap/Form';

import "react-datepicker/dist/react-datepicker.css";
import styles from "./secondaryHeader.module.css";

const SecondaryHeader = ({value, handleFormValuesUpdate}) =>{

    const [currentDate , setCurrentDate ] = useState(value || new Date());

    useEffect(()=>{
        handleFormValuesUpdate({name : "dateOfReview", value : value || new Date()})
    },[])
    
    const handleDateChange = (value)=>{
        setCurrentDate(value);
        handleFormValuesUpdate({name : "dateOfReview", value})
    }

    return (
        <div className={styles.container}>
            <div className={styles.mainTypography}> Date of review</div>
            <div className={styles.dateContainer}>
                <div className={styles.dateContent}>
                    {moment(currentDate).format('ddd - MMM DD,YY')}
                </div>
                <DatePicker
                    selected={currentDate}
                    onChange={handleDateChange}
                    showYearDropdown
                    showMonthDropdown
                    maxDate={new Date()}
                    customInput={
                        <img src={dropDown} className={styles.dropdown} />
                    }
                    popperPlacement="bottom"
                    showPopperArrow={false}
                    dropdownMode={"scroll"}
                />
            </div>
        </div>
    )
}

export default SecondaryHeader;