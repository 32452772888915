import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { progressMonitoringType } from '../../../../../../../store/progressMonitoringAtoms/progressMonitoringType';
import WeeklyFilter from '../WeeklyFilter';
import { getWeeklyFormattedWeeklyDates } from '../../../../../../helpers/dateFormatter';

import styles from './header.module.css';

export default function Header({
    progressMonitoringType: incomingProgressMonitoringType,
    handleProgressMonitoringDurationChange,
    filterDuration
  }) {

  const [monitoringType,setMonitoringType]=useRecoilState(progressMonitoringType);

  useEffect(()=>{
    setMonitoringType(incomingProgressMonitoringType);
  },[])

  const handleFilterDurationChange = useCallback((_ , prev)=>{
    handleProgressMonitoringDurationChange({ previous : prev} )
  },[handleProgressMonitoringDurationChange]);

  return (
    <div className={styles.container}>
      <div className={styles.headingMain}>
        <div className={styles.headingMainText}>
          Glucose
        </div>
        <div className={styles.filterContainer}>
          <WeeklyFilter
            handlePrimaryDurationChange={handleFilterDurationChange}
            {...getWeeklyFormattedWeeklyDates({prev: filterDuration,format: "ddd, MMM DD"})}
            previous={filterDuration}
          />
        </div>
      </div>
    </div>
  );
}
