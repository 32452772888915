import React from "react";

import Header from "./components/Header/Header";
import Body from "./components/Body/Body";
import styles from "./toggableSideBar.module.css"

const ToggableSideBar = ({handleDisplayIndividualInfo}) =>{
    return (
        <div className={styles.containerBackground}>
            <div className={styles.container}>
                <Header handleDisplayIndividualInfo={handleDisplayIndividualInfo} />
                <Body />
            </div>
        </div>
    )
}

export default ToggableSideBar;