import React from "react";
import {map} from "lodash";

import styles from "./userDetailsCard.module.css";
import UserDetailsCardHeader from "./components/UserDetailsCardHeader";
import UserDetailsCardContent from "./components/UserDetailsCardContent";

const UserDetailsCard = (props) =>{
    const {formValues} = props;
    const {firstName,middleName,lastName, speciality,credentials, profilePhoto, gender} = formValues || {};
    const cardContent = [
        {label: "Speciality",options: speciality},
        {label:"Credentials", options: credentials}
    ]
     
    return (
        <div className={styles.container}>
            <UserDetailsCardHeader profilePhoto={profilePhoto} firstName={firstName} middleName={middleName} lastName={lastName} gender={gender}/>
            {map(cardContent,(val)=>{
                return <UserDetailsCardContent {...val} />
            })}
        </div>
    )
}

export default UserDetailsCard;