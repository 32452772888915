import React, { Suspense, useLayoutEffect } from "react";

import GlucoseLevelDisplayModal from "./GlucoseLevelDisplayModal";
import actions from "./glucoseLevelDisplayModal.actions";
import actionType from "./glucoseLevelDisplayModal.actionTypes";

import withActionState from "../../hooks/withActionState";

const GlucoseLevelDisplayModalContainer = ({state,dispatch,id,calenderUpdate,...rest}) =>{

    useLayoutEffect(()=>{
        dispatch({actionType : actionType.GET_PRE_PRANDIAL,payload:{ day : id, calenderUpdate} })
    },[])

    return (
        <Suspense fallback={<></>}>
            <GlucoseLevelDisplayModal {...rest} {...state} day={id} />
        </Suspense>
    )
}

export default withActionState({Component:GlucoseLevelDisplayModalContainer, action : actions});