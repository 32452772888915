import { forEach } from "lodash";

const hasChartData=(incomingChartData)=>{
    let status=false;
    forEach(incomingChartData,(data)=>{
        if(data !=null || data != undefined)
        {
            if(Array.isArray(data))
            {
                status=status || hasChartData(data);
            }
            else{
                status=status ||true;
            }
        }
    });
    return status;
}

export { hasChartData}