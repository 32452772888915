
const NOTES_FORM_FIELDS_BASE_NAMES = {
    timeSpent : "timeSpent",
    clinicalInterpretation:"clinicalInterpretation",
    plan : "plan",
    patientCommunication : "patientCommunication",
    followUp : "followUp"
}

const NOTES_FORM_FIELDS = [{
        name : "timeSpent",
        label : "Time spent"
    },
    {
        name : "clinicalInterpretation",
        label : "Clinical Interpretation"
    },
    {
        name : "plan",
        label : "Plan (Treatment Recommendations)"
    },
    {
        name : "patientCommunication",
        label : "Patient Communication"
    },
    {
        name : "followUp",
        label : "Follow-up"
    }
]

export {
    NOTES_FORM_FIELDS,
    NOTES_FORM_FIELDS_BASE_NAMES
}