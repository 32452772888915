import {map} from "lodash";

const getRespectiveColor = (data,min,max)=>{
    return data>min && data<max ? {
        color:'#7ACCAE',
        targetOptions: {
            color: '#7ACCAE'
        }}: (data<=min ? {
            color:"#EE9887",
            targetOptions: {
                color:'#7ACCAE',
            }
        }:{
            color:"#CA9416",
            targetOptions: {
                color:'#7ACCAE',
            }
        });
}

const getDataToChartFormatting = (data, target,min,max) =>{
    return map(data,(val)=>{
        return {
            y: val,
            target,
            ...getRespectiveColor(data,min,max)
        }
    })
}

export {
    getDataToChartFormatting,
}