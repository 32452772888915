import { API_STATUS_CODES, BASE_URL } from "../../constants/api";

const getInitState = async({state, setState, payload})=>{
    try{
        setState((state)=>({...state,isLoading : true}))
        const urlParam = new URLSearchParams(window.location.search);
        if(urlParam?.has("token"))
        {
            const request = await fetch(`${BASE_URL}/api/dashboard/users/changePassword?token=${urlParam.get("token")}`,{
                method : "GET",
                headers:{
                    "content-type" : "application/json",
                }
            })
            if(request?.status === API_STATUS_CODES.SUCCES)
            {
                setState((prev)=>{
                    return {
                        ...prev,
                        page : 0,
                    }
                   });
            }
        }
        setState((state)=>({...state,isLoading : false}))
    }
    catch(e)
    {
        setState((state)=>({...state,isLoading : false}))
        setState({...state,error:e});
    }
}

const getEmailConfirmationAction = async({state, setState, payload}) =>{
    try{
        setState((prev)=>{
            return {
                ...prev,
                isLoading : true,
            }
        })
        const {initialPageState = 1} = state;
        let { email } = state;
        if(!email)
        {
            email=payload?.email || {}
        }

        await fetch(`${BASE_URL}/api/dashboard/users/forgotPassword`,{
            method : "POST",
            headers : {
                "content-type":"application/json"
            },
            body : JSON.stringify({email : email})
        });

        setState((prev)=>{
            return {
                ...prev, initialPageState : initialPageState +1, email : email,
                isLoading: false
            }
        })
    }
    catch(e)
    {
        setState({...state, error : e, isLoading: false})
    }
}

const getPasswordConfirmationAction = async({state, setState, payload, navigate}) =>{
    try{
        setState((prev)=>{
            return {
                ...prev,
                isLoading : true,
            }
        })
        const urlParam = new URLSearchParams(window.location.search);
        await fetch(`${BASE_URL}/api/dashboard/users/updatePassword`,{
            method : "POST",
            headers : {
                "content-type":"application/json"
            },
            body : JSON.stringify({password : payload?.password, token : urlParam.get("token")})
        });
        setState((prev)=>{
            return {
                ...prev,
                isLoading : false,
            }
        })
        navigate("/login");
    }
    catch(e)
    {
        setState({...state, error : e, isLoading: false})
    }
}

export default {
    EMAIL_CONFIRMATION_ACTION : getEmailConfirmationAction,
    PASSWORD_CONFIRMATION_ACTION : getPasswordConfirmationAction,
    INIT_ACTION : getInitState,
}