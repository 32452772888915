import moment from "moment";
import {map} from "lodash";

import { CARD_BAR_CHART_COLOR_TYPES } from "../../../../fieldMapping/color";
import { MAPPED_METRIC_TYPES, MEAL_TYPES } from "../../../../fieldMapping/metricTypes";
import { balancedPlateGroup } from "./overviewTab.data";
import { BALANCED_PLATE_MACRO_NUTRIETS, BALANCED_PLATE_MACRO_NUTRIETS_SUFFIX, BALANCED_PLATE_TYPE_TO_COLOR_MAPPING } from "./overviewTab.constants";
import IconedValue from "./components/IconedValue";

const getFastedFormattedData = (data={},type) =>{
    return {
        value : data[type]?.["glucose"],
        color : data[type]?.["glucose-color-value"]?.split(" ")[0].toLowerCase(),
    }
}

const getRespectiveColor = (key="") =>{
    return key?.includes("IN_RANGE_BETWEEN") ? "GREEN" : (key?.includes("LESS_THAN") ? "RED" : "YELLOW") ;
}

const getObjectToArrayFormat = (metricsNameGlucoseDetailsMap={})=>{
    let arr=[];
    Object.keys(metricsNameGlucoseDetailsMap).forEach((key)=>{
        arr.push({...metricsNameGlucoseDetailsMap?.[key], "glucose-color": getRespectiveColor(key)});
    })
    return arr;
}

const getTimeInRangeFormatted = (metrics) =>{
    return map(metrics,({metricRequest ,metricsNameGlucoseDetailsMap })=>{
        const { metricType="", mealType } = metricRequest;
        return {
            label : MAPPED_METRIC_TYPES?.[metricType]+" "+(MEAL_TYPES?.[mealType] || ""),
            allData: getObjectToArrayFormat(metricsNameGlucoseDetailsMap),
            type: "Time-In-Range",
            value: metricsNameGlucoseDetailsMap[Object.keys(metricsNameGlucoseDetailsMap).filter((val)=> val.includes("BETWEEN"))?.[0]]?.glucose,
            suffix: "%",
            color: metricsNameGlucoseDetailsMap[Object.keys(metricsNameGlucoseDetailsMap).filter((val)=> val.includes("BETWEEN"))?.[0]]?.["glucose-color"]?.toLowerCase() || "",
        };
    });
}

const getFormattedStatDays = ({metrics = [],selectedMetrics = [],handleTabChange})=>{
    let responseMetrics = []; 
    metrics.forEach(({metricRequest ,glucoseDetails,metricsNameGlucoseDetailsMap })=>{
        const { metricType="", date } = metricRequest;
        if(selectedMetrics.includes(metricType) && typeof metricType == "string" && date  )
        {
            if(metricType == "OVERALL_TIME_IN_RANGE"){
                let initData = getFastedFormattedData(metricsNameGlucoseDetailsMap,"OVERALL_TIME_IN_RANGE_BETWEEN_63_TO_140");
                responseMetrics.push({
                    label : metricType,
                    value: initData?.value,
                    color : initData?.color,
                    date,
                    rowLabel : "Blood Glucose",
                    handleTabChange
                })
            }
            else{
                responseMetrics.push({
                    label : metricType,
                    handleTabChange,
                    value : glucoseDetails?.glucose,
                    coolor : glucoseDetails?.["glucose-color-value"]?.split(" ")?.[0].toLowerCase(),
                })    
            }
        }
    })
    return responseMetrics;
}

const getWeeklyFormat = ({numberOfDays})=>{
    let metrics =[]
    for(let x =1 ; x<=numberOfDays;x++)
    {
        metrics.push(moment().subtract(7-(x%8),'d').format("dd").charAt(0).toUpperCase());
    }
    return metrics;
}

const getFormattedStat = ({metrics = [],selectedMetrics = []})=>{
    let responseMetrics = [];
    metrics.forEach(({metricRequest ,glucoseDetails,metricsNameGlucoseDetailsMap })=>{
        const { metricType="", numberOfDays } = metricRequest;
        if(selectedMetrics.includes(metricType) && typeof metricType == "string" && numberOfDays  )
        {
            if(metricType == "OVERALL_TIME_IN_RANGE"){
                let initData = getFastedFormattedData(metricsNameGlucoseDetailsMap,"FASTING_TIME_IN_RANGE_BETWEEN_63_TO_140"); 
                responseMetrics.push({
                    label : metricType,
                    value: initData?.value,
                    color : CARD_BAR_CHART_COLOR_TYPES[initData?.color?.toUpperCase()]
                })
            }
            else if(metricType == "FASTING_TIME_IN_RANGE")
            {
                let initData = getFastedFormattedData(metricsNameGlucoseDetailsMap,"FASTING_TIME_IN_RANGE_BETWEEN_63_TO_95"); 
                responseMetrics.push({
                    label : metricType,
                    value: initData?.value,
                    suffix : "%",
                    color : CARD_BAR_CHART_COLOR_TYPES[initData?.color?.toUpperCase()]
                })
            }
            else{
                responseMetrics.push({
                    label : metricType,
                    value : glucoseDetails?.glucose,
                    coolor : CARD_BAR_CHART_COLOR_TYPES[glucoseDetails?.["glucose-color-value"]?.split(" ")?.[0]],
                })    
            }
        }
    })
    return responseMetrics;
}

const getFormattedChartStats = ({metrics = [], chartType =""})=>{
    let data = [];
    metrics.forEach(({metricRequest, glucoseDetails , metricsNameGlucoseDetailsMap})=>{
        const { metricType="" , date} = metricRequest;
        if(metricType == chartType && date)
        {
            if(metricType == "FASTING_TIME_IN_RANGE")
            {
                let initData = getFastedFormattedData(metricsNameGlucoseDetailsMap,"FASTING_TIME_IN_RANGE_BETWEEN_63_TO_95"); 
                data.push({
                    y: initData?.value,
                    color : initData?.color
                })
            }
            else{
                data.push({
                    y : glucoseDetails?.glucose,
                    color : CARD_BAR_CHART_COLOR_TYPES[glucoseDetails?.["glucose-color-value"]?.split(" ")?.[0]],
                })    
            }
        }
    })
    return data;
}

const getBalancedPlateContainerTypes = (metric,data,type)=>{
     switch(metric){
        case "avgPlateScore": return <IconedValue value={data} type={type}/>
    }
    return data
}

const getformattedGroupedfield= (balancedPlateGroups,data, type)=>{
    return map(balancedPlateGroups,(group)=>{
        return map(group,(item)=> {
            return{
               value : getBalancedPlateContainerTypes(item,Math.floor((data?.[item] || 0)*10)/10,type?.charAt(0)+type?.substring(1,type?.length)?.toLowerCase()),
               label : BALANCED_PLATE_MACRO_NUTRIETS?.[item],
               suffix : BALANCED_PLATE_MACRO_NUTRIETS_SUFFIX?.[item] || "g"
            }
        })
    })
}

const getFormattedBalacedPlateWeeklyData = (macronutrientsInsights,handleTabChange) =>{
    return map(Object.keys(macronutrientsInsights).sort(),(key)=>{
        const {avgPlateScore} = macronutrientsInsights?.[key];
        return {
            label : "AVG_PLATE_SCORE",
            value: Math.floor(avgPlateScore*10)/10,
            color : BALANCED_PLATE_TYPE_TO_COLOR_MAPPING[macronutrientsInsights?.[key]?.balancedPlateMealStatus],
            date: key,
            rowLabel : "Balanced Plate",
            suffix:"",
            max:18,
            type:'Balanced Plate',
            modalMetrics : getformattedGroupedfield(balancedPlateGroup,{...macronutrientsInsights?.[key]?.totalValueByMicroNutrientMap, avgPlateScore: macronutrientsInsights?.[key]?.avgPlateScore,},macronutrientsInsights?.[key]?.balancedPlateMealStatus),
            balancedPlateData :  macronutrientsInsights?.[key],
            handleTabChange
        }
    })
}

const getDailyMonitoringData = ({metrics, tableFields = [],handleTabChange,micronutrientsInsights })=>{
    let mainData = tableFields.map((data)=>{
        return getFormattedStatDays({metrics:metrics,selectedMetrics : [data],handleTabChange})
    });
    mainData.push(getFormattedBalacedPlateWeeklyData(micronutrientsInsights, handleTabChange));

    return mainData
}

export {
    getFormattedStat,
    getDailyMonitoringData,
    getFormattedChartStats,
    getWeeklyFormat,
    getTimeInRangeFormatted
}