import { PRIMARY_FORM_TYPES } from "./practiceAccountSetup.constants";

const getFormUpdate = ({state,setState, payload})=>{

    const {formType, ...rest} = payload;
    setState((previous)=>{
        const previousFormState = previous?.[formType];
        return {
            ...previous,
            [formType]:{
                ...previousFormState,
                ...rest,
            }
        }
    })
}

const getPrimaryFormSubmit = ({state,payload,setState})=>{
    const { pageType } = payload;


    switch(pageType){
        case PRIMARY_FORM_TYPES.NEW_USER :
            setState((previousState)=>{
                return {
                    ...previousState,
                    currentForm : 1,
                }
            })
        case PRIMARY_FORM_TYPES.EXISTING_USER :
            setState((previousState)=>{
                return {
                    ...previousState,
                    success: true,
                }
            })
    }

}

const getModalSubmit = ({state,payload,setState}) =>{
    setState((previous)=>{
        return {
            ...previous,
            submitSuccess: true
        }
    })
}

const getSecondaryFormSubmit = ({state,payload,setState}) =>{
    setState((previous)=>{
        return {
            ...previous,
        }
    });

    getModalSubmit({state,payload,setState})
}

export default {
    PRIMARY_FORM_SUBMIT : getPrimaryFormSubmit,
    UPDATE_FORM_STATE : getFormUpdate,
    SECONDARY_FORM_SUBMIT : getSecondaryFormSubmit,
}