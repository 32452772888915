const getRespectiveSvg = ({val=98, glucoseColor}) =>{
    if(glucoseColor == "YELLOW_IN_TARGET" || glucoseColor == "YELLOW")
    {
        return  (<> <path opacity="0.54" d="M54.1161 18.2661C48.3909 12.5407 40.6257 9.32416 32.5289 9.32416C24.4322 9.32416 16.667 12.5407 10.9417 18.2661C5.21643 23.9915 2 31.7568 2 39.8538" stroke="#D5D9DE" stroke-width="2.44234" stroke-linecap="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M26.5393 9.81679C26.4134 9.15422 26.8485 8.51501 27.511 8.38909C35.7837 6.81684 44.3422 8.59527 51.3037 13.3331C51.8612 13.7126 52.0056 14.4722 51.6262 15.0298C51.2467 15.5873 50.4871 15.7317 49.9295 15.3522C43.5035 10.9788 35.6033 9.33718 27.967 10.7885C27.3045 10.9144 26.6653 10.4794 26.5393 9.81679Z" fill="#FFB200"/>
        <ellipse cx="34.8479" cy="8.51913" rx="3.66347" ry="3.66356" transform="rotate(13.4875 34.8479 8.51913)" fill="white" stroke="#FFB200" stroke-width="1.22117"/>
        </>)
    }
    else if(glucoseColor == "GREEN_IN_TARGET" || glucoseColor == "GREEN")
    {
        return (<>
            <path opacity="0.54" d="M54.1161 10.9462C48.3909 5.22078 40.6257 2.00427 32.5289 2.00427C24.4322 2.00427 16.667 5.22078 10.9417 10.9462C5.21643 16.6716 2 24.4369 2 32.5339" stroke="#D5D9DE" stroke-width="2.44234" stroke-linecap="round"/>
            <path d="M29.0934 2.3794C21.0338 3.15564 13.6126 7.1017 8.4624 13.3495" stroke="#00CC91" stroke-width="2.44234" stroke-linecap="round"/>
            <ellipse cx="18.3363" cy="5.37535" rx="3.66347" ry="3.66356" fill="white" stroke="#00CC91" stroke-width="1.22117"/>
            </> )
    }
    return (
        <>
            <path opacity="0.54" d="M54.1161 10.4419C48.3909 4.71651 40.6257 1.5 32.5289 1.5C24.4322 1.5 16.667 4.7165 10.9417 10.4419C5.21643 16.1673 2 23.9327 2 32.0296" stroke="#D5D9DE" stroke-width="2.44234" stroke-linecap="round"/>
            <path d="M63.0575 32.0292C63.0575 23.9323 59.8411 16.1669 54.1158 10.4415" stroke="#ED4C5C" stroke-width="2.44234" stroke-linecap="round"/>
            <ellipse cx="61.8364" cy="22.8711" rx="3.66347" ry="3.66356" fill="white" stroke="#ED4C5C" stroke-width="1.22117"/>
        </>
    )
}

const getRespectiveViewBox = (val)=>{
    if(val>=95 && val<120)
    {
        return "0 0 56 42"
    }
    else if(val<95 && val>60)
    {
        return "0 0 56 34"
    }
    return "0 0 67 42"
}

const getRespectiveStroke = (val) =>{
    const color ={
        GREEN_IN_TARGET:"#00CC91",
        RED_IN_TARGET:"#ED4C5C",
        YELLOW_IN_TARGET:"#FFB200",
        GREEN:"#00CC91",
        RED:"#ED4C5C",
        YELLOW:"#FFB200",
        red: "#ED4C5C",
    }
    return color[val]
}

export {
    getRespectiveSvg,
    getRespectiveViewBox,
    getRespectiveStroke
}