import React from "react";

const ExclamationTriangle = ({color="#AE4B37"}) =>{
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
            <g clip-path="url(#clip0_2130_12051)">
                <path d="M15.9328 11.6669L10.5328 2.33357C9.99949 1.40024 8.73282 1.0669 7.79949 1.60024C7.46615 1.80024 7.19949 2.0669 7.06615 2.33357L1.66615 11.6669C1.13282 12.6002 1.46615 13.8669 2.39949 14.4002C2.73282 14.6002 3.06615 14.6669 3.39949 14.6669H14.1328C15.2662 14.6669 16.1328 13.7336 16.1328 12.6669C16.1995 12.2669 16.0662 11.9336 15.9328 11.6669ZM8.79949 12.0002C8.39949 12.0002 8.13282 11.7336 8.13282 11.3336C8.13282 10.9336 8.39949 10.6669 8.79949 10.6669C9.19949 10.6669 9.46615 10.9336 9.46615 11.3336C9.46615 11.7336 9.19949 12.0002 8.79949 12.0002ZM9.46615 8.6669C9.46615 9.0669 9.19949 9.33357 8.79949 9.33357C8.39949 9.33357 8.13282 9.0669 8.13282 8.6669V6.00024C8.13282 5.60024 8.39949 5.33357 8.79949 5.33357C9.19949 5.33357 9.46615 5.60024 9.46615 6.00024V8.6669Z" fill={color}/>
            </g>
            <defs>
                <clipPath id="clip0_2130_12051">
                <rect width="16" height="16" fill="white" transform="translate(0.799805)"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default ExclamationTriangle