import moment from "moment"

const getRequestBody = ({type,numberOfDays,mealType})=>{
    let metrics =[]
    for(let x =1 ; x<=numberOfDays;x++)
    {
        metrics.push({
            "metricType":type,
            "date": moment().subtract(8-(x%9),'d').format("YYYY-MM-DD"),
            "mealType": mealType
        })
    }
    return {
        "userId": "47ba148a-ed0e-436e-8a45-f357a54667fc",
        "metricRequests" : metrics,
    }
}

export { getRequestBody}