import React from "react";

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import noDataToDisplay from 'highcharts/modules/no-data-to-display';
import drillDown from "highcharts/modules/drilldown";

import styles from "./trendAnalysis.module.css";
import { getMapFormattedData, getRespecticeColumns } from "./trendAnalysis.helpers";
import { map } from "lodash";

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
noDataToDisplay(Highcharts);
drillDown(Highcharts);

Highcharts.setOptions({
    exporting: {
      enabled: false
    }
});  

const TrendAnalysis = ({nutritionalInsightsDaily=[], prev=0}) =>{

    const mainData = getMapFormattedData(nutritionalInsightsDaily);

    const dateData = getRespecticeColumns(prev);
    
    const options ={
        chart: {
            type: 'column',
            plotBorderWidth: 1,
            maxWidth: 1222,
            plotBorderColor: "#EAE7EC",      
        },
        title: {
            text: null,
        },
        xAxis: {
            lineColor: "#EAE7EC",
            categories: dateData,
            labels: {
                useHTML: true,
                formatter: function() {
                    return '<div style="font-weight:bold">' + this.value?.date + '</div><br ><div styles="text-align:center; justify-content:conter;color: var(--Base-Text-grey-lowemp, #C0BAC7); font-family: "Founders Grotesk"; font-size: 14px; font-style: normal; font-weight: 400; line-height: 140%; letter-spacing: 0.084px;">'+this?.value?.day+'</div>';
                }
            }
        },
        yAxis: {
            gridLineWidth: 0,
            title: {
                text: null
            },
            startOnTick: false,
            min:-0.05,
            max:18,
            tickPositions: [0, 3, 6, 9, 12, 15, 18]
        },
        tooltip: {
            backgroundColor: '#2A292D',
            width:"243px",
            style: {
                width: 400,
                display: "flex",
                minWidth:"243",
                padding: "12px",
                flexDirection: "column",
                gap: "12px",
                // borderRadius: '8px',
                color: 'white',
                width: "243px"
            },
            formatter: function(data) {
                return '<div style="min-width:243px; padding:12px; border-radius:8px; text-align:center;">'+"<div>"+ dateData[this.point.index]?.fullFormattedData +"</div><br />" +
                "<div style='min-width: 205px' >BreakFast : "+ (mainData[this.point.index]?.plateScoreByMealMap?.Breakfast || "No Data")  +"</div><br />" +
                "<div style='min-width: 205px'>Lunch : "+ (mainData[this.point.index]?.plateScoreByMealMap?.Lunch || "No Data") +"</div><br />" +
                "<div style='min-width: 205px'>Dinner :  "+ (mainData[this.point.index]?.plateScoreByMealMap?.Dinner || "No Data") +"</div><br />" +
                ' </div>';
            }
        },
        plotOptions: {
            column: {
                borderRadius : 5,
                pointWidth: 20
            }
        },
        series: [{
            name: "",
            data: map(mainData,(val)=>{
                return { y:val?.avgPlateScore, color: val?.color, borderColor : val?.borderColor}
            }),
            zones: [{
                value: 63,
                color: '#EE9887'
              }, {
                value: 140,
                color: '#FFF0A8'
              }, {
                value: 180,
                color: '#D9F7EB'
              }],
              showInLegend: false      
        }],
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        }
    }

    return (
        <div className={styles.container}>
            <HighchartsReact highCharts={Highcharts} options={options} />
        </div>
    )
}

export default TrendAnalysis;