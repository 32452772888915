import React, { useCallback, useEffect } from "react";

import withActionState from "../../hooks/withActionState";

import Header from "./components/Header";
import Body from "./components/Body";
import actions from "./patientInviteConnect.actions";
import actionTypes from "./patientInviteConnect.actionTypes";

import { getCardBodyComponent } from "./patientInviteConnect.helper";
import { ACTIVE_CARD_STATE } from "./patientInviteConnect.constants";

import styles from "./patientInvite.module.css";
import DefaultSpinner from "../../atoms/spinner/Spinner";
import Footer from "./components/Footer";

const PatientInviteConnect = ({dispatch, cardState = ACTIVE_CARD_STATE.CONCENT_STATE, isLoading}) =>{
    
    useEffect(()=>{
        dispatch({actionType : actionTypes.INIT_ACTION})
    },[]);

    const getInvitationAction = useCallback((payload)=>{
        dispatch({actionType : actionTypes.INVITE_CONNECT_ACTION, payload})
    },[])

    const currentCardProps = getCardBodyComponent(cardState)

    return (
        <div className={styles.container}>
            {isLoading && <DefaultSpinner />}
            <div className={styles.innerContainer}>
                <Header />
                <Body {...currentCardProps} patientInviteAction={getInvitationAction} />
            </div>
            <Footer />
        </div>
    )
}

export default withActionState({Component: PatientInviteConnect, action : actions });