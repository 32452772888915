import React, { useEffect, useState } from "react";
import moment from "moment";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import noDataToDisplay from 'highcharts/modules/no-data-to-display';
import drillDown from "highcharts/modules/drilldown";
import MealIcon from "./../../../../../assets/images/meals/foodLogMarker.svg";

import "./dailyGlucoseChart.module.css";
import { CHART_NO_DATA } from "./../../../../constants/noData";
import { hasChartData } from "./../../../../helpers/chart";

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
noDataToDisplay(Highcharts);
drillDown(Highcharts);

// Disable exporting module
Highcharts.setOptions({
  exporting: {
    enabled: false
  }
});

const DailyGlucoseChart = ({ 
    // offset=0,
   comparisonAnalysisData = [],
   originalMealLogs=[],
    categories = [] ,
    mealLogs =[],
    date="" }) => {

  let newDate = date.split("-");

  let offset=0;


  let minTimeRange = new Date(date);

  minTimeRange = Date.UTC(newDate?.[0],newDate?.[1]-1,newDate?.[2],0,0,0,0)

  const normalPlotBands =[{
      from: 63,
      to: 140,
      color: '#84EBC514',
      label: {
          text: '',
          style: {
              color: '#84EBC514'
          }
      }
    }];

    let xAxisPlotLines = new Array(23).fill({
      value: minTimeRange + offset,
      color: '#F1EFF3',
      dashStyle: 'longdash',
      width: 1,
    })

    xAxisPlotLines=xAxisPlotLines.map((val,index)=>{
      return {
        ...val,
        value : val?.value+ (3600000*index)
      }
    })


  const normalPlotLines = [{
        value: 63,
        color: '#9EDBC5',
        dashStyle: 'longdash',
        width: 1,
      },
      {
        value: 140,
        color: '#9EDBC5',
        dashStyle: 'longdash',
        width: 1,
  }];
    
  const plotBands = comparisonAnalysisData.length>0 && hasChartData(comparisonAnalysisData) ? normalPlotBands: []

  const plotLines = comparisonAnalysisData.length>0 && hasChartData(comparisonAnalysisData) ? normalPlotLines : [];

  const options={
    chart: {
      type: "spline",
      height: 400,
      plotBorderWidth: 1,
      padding:0,
      plotBorderColor: "#EAE7EC",
      events: {
        load: function() {
          if (!hasChartData(this.series[0].data)) {
            // this?.showNoData("<p style='text-align: center; font-weight:500; font-family: Founders Grotesk;'><b>"+ CHART_NO_DATA.PRIMARY +"<b><br><p style='font-weight:50; font-size:12px;font-family: Founders Grotesk;'>"+ CHART_NO_DATA.SECONDARY +"</p></p>");
          }
        },
        update :function(){
          if (!hasChartData(this.series[0].data)) {
            // this?.showNoData("<p style='text-align: center; font-weight:500; font-family: Founders Grotesk;'><b>"+ CHART_NO_DATA.PRIMARY +"<b><br><p style='font-weight:50; font-size:12px;font-family: Founders Grotesk;'>"+ CHART_NO_DATA.SECONDARY +"</p></p>");
          }
        }
      }
    },
    title: {
      text: null,
    },
    xAxis: [
      {
        title: {
          text: null,
        },
        type:"datetime",
        gridLineWidth: 0,
        labels: {
          useHTML: true,
          formatter: function() {
              return `<span style="color:#66636D;font-size: 14px;font-style: normal;font-weight: 400;line-height: 140%;letter-spacing: 0.084px;">`+ Highcharts.dateFormat('%I:%M %p', new Date(this.value))+`</span>`
          },
          step:1,
          rotation:0
        },
        tickPositions: [
          minTimeRange + offset ,  
          minTimeRange + offset + 4*3600000,
          minTimeRange + offset + 11*3600000,
          minTimeRange + offset + 17*3600000,
          minTimeRange + offset + 23*3600000,
        ],
        min : minTimeRange + offset,
        max : minTimeRange + offset +86400000 ,
        lineColor: "#EAE7EC",
        gridLineWidth: 0,
        tickLength: 0,
        lineWidth: 0,
        plotLines: xAxisPlotLines,
        plotBands:[{
          from: minTimeRange + offset + 4*3600000,
          to: minTimeRange + offset + 11*3600000,
          color: '#FAF9FB',
          label: {
              text: '',
              style: {
                  color: '#FAF9FB'
              }
          }
        },
        {
          from: minTimeRange+ offset + 17*3600000,
          to: minTimeRange+ offset + 23*3600000,
          color: '#FAF9FB',
          label: {
              text: '',
              style: {
                  color: '#FAF9FB'
              }
          }
        }
        ]
      }
    ],
    yAxis: {
        lineColor: "#EAE7EC",
        title: {
          text: null,
        },
        tickPositions: [0, 63, 100, 140, 200, 250, 300],
        min: 0,
        max: 200,
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        alternateGridColor: null,
        labels: {
          useHTML:true,
          formatter: function () {
              if(this.value==63 || this.value==140){
                return '<span style="color: #7ACCAE; font-weight: bold;">' + this.value + '</span>';
              }
              return '<span style="color: black">' + this.value + '</span>';                ;
          }
      },
      tooltip:{
        shared: false,
        crosshairs: false,
      },
      plotBands: plotBands,
        plotLines
      },
      plotOptions: {
        spline: {
          lineWidth: 1.5,
          states: {
            hover: {
              lineWidth: 2
            }
          },
          marker: {
            enabled: false
          },
          pointInterval: 1,
          pointStart: 0
        }
      },
      series: [
      {
        name :"",
        data: comparisonAnalysisData,
        zones: [{
          value: 63,
          color: '#E4A944'
        }, {
          value: 140,
          color: '#7ACCAE'
        }, {
          value: 280,
          color: '#EE9887'
        }],
        tooltip:{
          enabled:true,
        },
        showInLegend: false
      },
      {
        name :"",
        data: mealLogs,
        marker: {
          enabled: true,
          symbol: `url(${MealIcon})`, 
          radius: 32,
          fillColor: "#F8F0FE"
        },
        fillColor: "#F8F0FE",
        lineWidth:0,
        states: {
          hover: {
              enabled: false
          }
        },
        tooltip: {
            useHTML: true,
            backgroundColor: '#2A292D',
            width:"243px",
            style: {
                width: 400,
                display: "flex",
                minWidth:"243",
                padding: "12px",
                flexDirection: "column",
                gap: "12px",
                color: 'white',
                width: "243px"
            },
            pointFormatter: function() {
              return '<div style="min-width:243px; padding:12px; border-radius:8px; text-align:center;">'+"<div>"+ (originalMealLogs?.[this.index]?.micro_nutrients_response?.plateScore || 'No Data') +"</div><br />" +
              ' </div>';
          }
        },
        showInLegend: false
      }
    ],
    lang: {
      noData:"<p style='text-align: center; font-weight:500; font-family: Founders Grotesk;'><b>"+ CHART_NO_DATA.PRIMARY +"<b><br><p style='font-weight:50; font-size:12px;font-family: Founders Grotesk;'>"+ CHART_NO_DATA.SECONDARY +"</p></p>",
    },
    noData: {
      useHTML: true,
      style: {
        color: '#66636D',
        minWidth:"250px",
        display:"flex",
        justifyContent:"center"
      },
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
  };

  const chartProps= (hasChartData(comparisonAnalysisData) && comparisonAnalysisData.length >0) ? {highCharts:Highcharts}: {highcharts:Highcharts};

  return (
    <div className={"container"} style={{ minWidth: "100%" }}>
      <HighchartsReact
        {...chartProps}
        options={options}
      />
    </div>
  );
}

export default DailyGlucoseChart;