import React from "react";
import styles from "./header.module.css";

const Header = () =>{
    return (
        <div className={styles.container}>
            <div className={styles.header}>Sign Up</div>
            <div className={styles.subHeading}>Get Started with Monitoring</div>
        </div>
    )
}

export default Header;