import React from "react";
import cx from "classnames";

import Header from "./components/Header";
import Body from "./components/Body";

import styles from "./recentActivity.module.css";

const RecentActivity = ({headerProps={}, bodyProps={}, recentTrends, className="", ...rest}) =>{
    return (
        <div className={cx(styles.container, className)}>
            <Header {...headerProps}/>
            <Body {...bodyProps} {...rest} activities={recentTrends}/>
        </div>
    )
}

export default RecentActivity;