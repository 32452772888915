import React from "react";
import cx from "classnames";

import dropdownIcon from "./../../../../../../../assets/images/dropdown_arrow.svg";
import cancelIcon from "./../../../../../../../assets/images/cancel/transparent_cacel.svg";

import styles from "./header.module.css";
import { NOTES_DISPLAY_STATUS } from "../../../../patientProfile.constants";

const Header = ({handleNotesDisplayClose, handleNotesPartialDisplay, displayStatus}) =>{
    
    const handleCloseAction = (event)=>{
        event?.stopPropagation();
        handleNotesDisplayClose();
    }
    return (
        <div className={styles.container} onClick={displayStatus == NOTES_DISPLAY_STATUS.PARTIAL_DISPLAY ? handleNotesPartialDisplay : ()=>{}}>
            <div className={cx(styles.leftContainer, styles.leftContainerTypography)}>
                New Note
            </div>
            <div className={styles.rightContainer}>
                <img 
                    src={dropdownIcon} 
                    className={cx(styles.iconDimensions,{[styles.closed]:displayStatus == NOTES_DISPLAY_STATUS.PARTIAL_DISPLAY})} 
                    onClick={handleNotesPartialDisplay}
                />
                <img src={cancelIcon} className={styles.iconDimensions} onClick={handleCloseAction}/>
            </div>
        </div>
    )
}

export default Header;