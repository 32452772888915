import { checkIsEmailError, checkMaxCharacterError, checkMinCharacterError, hasLowerCase, hasNumber, hasSpecialCharanters, hasUpperCase } from "../helpers/ErrorStatusCheck.helper"

const ERROR_STATUS = {
    MIN_LENGTH : "MIN_LENGTH",
    MAX_LENGTH : "MAX_LENGTH",
    IS_EMAIL : "IS_EMAIL",
    HAS_LOWERCASE : "HAS_LOWERCASE",
    HAS_UPPERCASE : "HAS_UPPERCASE",
    HAS_NUMBERS : "HAS_NUMBERS",
    HAS_SPECIAL_CHARACTER : "HAS_SPECIAL_CHARACTERS",
    IS_REQUIRED:"IS_REQUIRED",
    IS_PHONE_NUMBER:"IS_PHONE_NUMBER",
    IS_CHECKED : "IS_CHECKED",
}

const ERROR_MESSAGES = {
    MIN_LENGTH : "The Length of Characters is short",
    MAX_LENGTH : "The Length of Characters is too long",
    IS_EMAIL : "Enter a valid email (e.g. name@gmail.com)",
    HAS_LOWERCASE : "Input Must Contain lowerCase characters",
    HAS_UPPERCASE : "Input Must Contain UpperCase characters",
    HAS_NUMBERS : "Input Must Contain Numbers",
    HAS_SPECIAL_CHARACTER : "Input Must Contain Special Characters",
    IS_REQUIRED :"Please Enter the Value",
    IS_PHONE_NUMBER : "Please Enter a phone number",
    IS_CHECKED  : "Please accept the check box",
}

const PASSWORD_ERROR_MESSAGES = {
    MIN_LENGTH: "a minimum of 8 characters",
    HAS_LOWERCASE: "a lower case character",
    HAS_UPPERCASE: "an upper case character",
    HAS_NUMBERS: "a number",
}

export {
    ERROR_STATUS,
    ERROR_MESSAGES,
    PASSWORD_ERROR_MESSAGES
}