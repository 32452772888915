import React from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import drillDown from "highcharts/modules/drilldown";


HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);

// Disable exporting module
Highcharts.setOptions({
  exporting: {
    enabled: false
  }
});

const VerticalSingleLineChart = ({data=[]}) =>{

    drillDown(Highcharts);

    const options = {
        chart: {
            type: 'column',
            height : 300,
            width : 40,
            margin: 0,
            padding:0,
        },
        title: {
            text: null,
        },
        xAxis: {
            visible: false
        },
        yAxis: {
            visible: false
        },
        plotOptions: {
            series: {
                stacking: 'percentage'
            }
        },
        series: data,
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        tooltip: {
            enabled: false
        }
    }

    return (
        <div className={"container"}>
            <HighchartsReact highcharts={Highcharts} options={options}/>
        </div>
    )
}

export default VerticalSingleLineChart;