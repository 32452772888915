import React from "react"

import DashBoardConfigurator from "../../organisms/DashBoardConfigurator/DashBoardConfigurator";

import styles from "./dashBoard.module.css";

const DashBoard = () =>{
    return (
        <div className={styles.container}>
            <DashBoardConfigurator />
        </div>
    )
}

export default DashBoard;