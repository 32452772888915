import React from "react";
import sucessTick from "./../../../../../../../assets/images/success_tick.svg";
import failedIcon from "./../../../../../../../assets/images/cancel/red_colored_cancel.svg"

import styles from "./body.module.css";

const Body = ({message,type,displayIcon}) =>{

    const ICON_TYPES={
        success: sucessTick,
        failed : failedIcon
    }

    return (
        <div className={styles.container}>
            {displayIcon && <img src={ICON_TYPES[type]} />}
            <span className={styles.content}>{message}</span>
        </div>
    )
}

export default Body;