import React from "react";
import cx from "classnames";

import styles from "./viewMoreDetails.module.css";

const ViewMoreDetails = () =>{
    return (
        <div className={cx(styles.container, styles.typography)}>
            View Details
        </div>
    )
}

export default ViewMoreDetails