import { API_STATUS_CODES, BASE_URL } from "../../constants/api";
import { ACTIVE_CARD_STATE } from "./patientInviteConnect.constants";


const getInitAction = async({state, setState, payload}) =>{
    try{

    }
    catch(e)
    {
        setState((prev)=>{
            return {
                ...prev,
                error : e
            }
        })
    }
}

const getInviteConnectAction = async({state, setState, payload}) =>{
    try{
        setState((prev)=>{
            return {
                ...prev,
                isLoading : true,
            }
        })

        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('token');
        const response = await fetch(`${BASE_URL}/api/dashboard/users/invitePatientConnect`,{
            method : "POST",
            body : JSON.stringify({
                "token" : myParam,
                "patient-invite-action" : payload
            }),
            headers : {
                "content-type" : "application/json",
            }
        })

        if((response.status == API_STATUS_CODES.NO_CONTENT || response.status == API_STATUS_CODES.CREATED || response.status == API_STATUS_CODES.SUCCES) && payload === "ACCEPT")
        {
            setState((prev)=>{
                return {
                    ...prev,
                    cardState : ACTIVE_CARD_STATE.COMPLETE_STATE,
                    isLoading : false,
                }
            })
        }
        else{
            setState((prev)=>{
                return {
                    ...prev,
                    cardState : ACTIVE_CARD_STATE.REJECTED_STATE,
                    isLoading : false,
                }
            })
        }
    }
    catch(e)
    {
        setState((prev)=>{
            return {
                ...prev,
                error : e,
                isLoading : false
            }
        })
    }
}

export default {
    INIT_ACTION : getInitAction,
    INVITE_CONNECT_ACTION : getInviteConnectAction
}