

const getFormProps = ({currentPage, currentTab, handlePrimaryFormFieldChange, handleSecondaryFormFieldChange, primaryFormSubmit, secondaryFormSubmit})=>{
    return {
        handleFieldChange : currentPage == 0 ? handlePrimaryFormFieldChange : handleSecondaryFormFieldChange,
        formSubmitHandler : currentPage == 0 && currentTab == 0 ? primaryFormSubmit : secondaryFormSubmit
    }
}

export {
    getFormProps
}