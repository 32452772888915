import React from "react";

import styles from "./indicatorOption.module.css";

const IndicatorOption = ({color,content}) =>{

    const colorOption = {
        inRange : styles.inRange,
        belowRange : styles.belowRange,
        aboveRange : styles.aboveRange
    }

    return (
        <div className={styles.container}>
            <div className={`${styles.strip} ${colorOption[color]}`} />
            <div>
                {content}
            </div>
        </div>
    )
}

export default IndicatorOption;