import React from "react";

const Content = ({content="", className=""}) =>{
    return (
        <span className={className}>
            {content}
        </span>
    )
}

export default Content