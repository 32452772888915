import moment from "moment";

const getWeeklyFormattedWeeklyDates = ({prev=0,format})=>{
    return {
        endDate : moment().subtract((7*prev),'d').format(format),
        startDate : moment().subtract(6+(7*prev),'d').format(format)
    }
}

export {
    getWeeklyFormattedWeeklyDates
}