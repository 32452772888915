import React from "react";

import styles from "./userDetailsCardComponents.module.css";
import { GENDER_TYPES } from "../userDetailsCard.constants";
import { getFullName } from "../userDetailsCard.helpers";

const UserDetailsCardHeader = ({firstName, profilePhoto, middleName = "", lastName,gender="Male"}) =>{
    const fullname = getFullName(firstName,middleName,lastName);

    const mainPhoto = profilePhoto ?  URL.createObjectURL(profilePhoto)  : GENDER_TYPES[gender];

    return (
        <div className={styles.cardHeader}>
            <img src={mainPhoto} className={styles.cardHeaderImage} style={{'minWidth':"46px"}} />
            <div className={styles.cardHeaderContent}>
                {fullname}
            </div>
        </div>
    )
}

UserDetailsCardHeader.defaultProps={
    firstName:"",
    lastName:""
}

export default UserDetailsCardHeader;