import { PROGRESS_MONITORING_PRIMARY_CARD_BASIC_METRICS } from "./progressMonitoring.constants"

const BASIC_PRECISION_MAPPINGS ={
    [PROGRESS_MONITORING_PRIMARY_CARD_BASIC_METRICS.AVERAGE_GLUCOSE]: Math.round,
    [PROGRESS_MONITORING_PRIMARY_CARD_BASIC_METRICS.GLUCOSE_VERIABILITY]: Math.round,
    [PROGRESS_MONITORING_PRIMARY_CARD_BASIC_METRICS.GMI]:(num)=> Math.round(num*10)/10,
    [PROGRESS_MONITORING_PRIMARY_CARD_BASIC_METRICS.STANDARD_DEVIATION]: Math.round
}

export {
    BASIC_PRECISION_MAPPINGS
}