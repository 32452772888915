import React from "react";
import cx from "classnames";

import { BAR_TYPE } from "./indicator.constants";

import styles from "./indicator.module.css"

const IndicatorBar = ({type, viewMoreDisplay,...rest}) =>{
    return(
        <div className={cx(styles.barImage,BAR_TYPE[type])}  {...rest}>
            {viewMoreDisplay && <div className={styles.viewMoreContainer}>
                <span className="view-more-div-span">View</span>
                <img
                    className="view-more-div-image"
                    src={require("../../../assets/images/eye.svg").default}
                />
            </div>}
        </div>
    )
}

export default IndicatorBar;