import React, { useEffect } from "react";
import SignUpBasePage from "../../organisms/SignUpBasePage";
import { getEmailCardProps, getPasswordCardProps } from "./resetPassword.helpers";
import { resetPasswordFields } from "./resetPassword.fields";
import formManager from "../../reusables/formManager";
import EmailConfirmationCard from "../../molecules/emailConfirmationCard/EmailConfirmationCard";

import actionTypes from "./resetPassword.actionTypes";
import actions from "./resetPassword.actions"

import styles from "./resetPassword.module.css";
import withActionState from "../../hooks/withActionState";
import DefaultSpinner from "../../atoms/spinner/Spinner";

const ResetPassword = ({page = 1, dispatch, isLoading = true, initialPageState, email = ''}) =>{

    useEffect(()=>{
      dispatch({actionType : actionTypes.INIT_ACTION})
    },[]);

    const getEmailConfirmationAction = (payload) =>{
      dispatch({actionType : actionTypes.EMAIL_CONFIRMATION_ACTION, payload})
    }

    const getPasswordResetAction = (payload)=>{
      dispatch({actionType : actionTypes.PASSWORD_CONFIRMATION_ACTION, payload})
    }

    const secondaryFormProps = {
      primaryHeading : "Check mail for Reset link", 
      secondaryHeading : "Password reset link has been successfully sent to ",
      tertiaryHeading : "",
      formSubmitAction : getEmailConfirmationAction
    }

    const emailCardFormProps = getEmailCardProps(resetPasswordFields.EmailField(), getEmailConfirmationAction);
    const passwordCardFormProps = getPasswordCardProps(resetPasswordFields.PasswordField(), getPasswordResetAction);

    
    return (
        <div className={styles.resetPasswordContainer}>
          {isLoading && <DefaultSpinner />}
          <SignUpBasePage email={email} secondaryFormProps={secondaryFormProps} initialPageState={initialPageState} SecondaryComponent={page === 1 && EmailConfirmationCard} FormComponent={formManager} formProps={ page == 1 ? emailCardFormProps : passwordCardFormProps} />
        </div>
    )
}

export default withActionState({Component: ResetPassword, action : actions});