import React from 'react';

import arrowLeft from "./../../../../../../../assets/images/arrow/basicLeftArrow.svg";
import arrowRight from "./../../../../../../../assets/images/arrow/basicRightArrow.svg";
import activeArrowRight from "./../../../../../../../assets/images/arrow/activeBasicRightArrow.svg";

import styles from "./weeklyFilter.module.css";

const WeeklyFilter =  ({startDate="", endDate="", name, handlePrimaryDurationChange=()=>{},previous}) =>{

    const handleLeftArrowClick = (event)=>{
        event.preventDefault();
        handlePrimaryDurationChange(name,previous+1)
    }

    const handleRightArrowClick = (event) =>{
        event.preventDefault();
        if(previous>0)
            handlePrimaryDurationChange(name, previous-1);
    }

    return (
        <div className={styles.container}>
            <img src={arrowLeft} onClick={handleLeftArrowClick} className={styles.arrowIcon}/>
            <div className={styles.innerContainer}>
                <span>{startDate}</span>
                <span>-</span>
                <span>{endDate}</span>
            </div>
            <img src={ previous>0 ? activeArrowRight : arrowRight} onClick={handleRightArrowClick} className={styles.arrowIcon}/>
        </div>
    )
}

export default WeeklyFilter;