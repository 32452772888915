import React from 'react';
import moment from 'moment';

import arrowLeft from "./../../../../../../../../../../../assets/images/arrow/basicLeftArrow.svg";
import arrowRight from "./../../../../../../../../../../../assets/images/arrow/basicRightArrow.svg";
import activeArrowRight from "./../../../../../../../../../../../assets/images/arrow/activeBasicRightArrow.svg";

import styles from "./weeklyFilter.module.css";

export default function WeeklyFilter({
    handleFilterUpdate,
    start,
    previous,
  }) {

    const handlePreviousMovement = ()=>{
    handleFilterUpdate(previous+1)
  }

  const handleForwardMovement = () =>{
    if(previous == 0)
      handleFilterUpdate(previous-1)
  }

  return (
    <div className={styles.container}>
      <img src={arrowLeft} onClick={handlePreviousMovement} className={styles.arrowIcon} />
      <div className={styles.innerContainer}>
        { moment(start).format("ddd, MMM DD")}
      </div>
      <img src={ previous == 0 ? arrowRight : activeArrowRight} onClick={handleForwardMovement} className={styles.arrowIcon} />
    </div>
  );
}
