import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsAccessibility from 'highcharts/modules/accessibility';

// Initialize additional modules
HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsAccessibility(Highcharts);

export default function MyChart() {
  useEffect(() => {
    const ranges = [
      [15.9, 28.6],
      [14.9, 27.5],
      [9.5, 26.0],
      [11.5, 22.4],
      [8.6, 21.1],
      [12.9, 21.7],
      [13.6, 20.9],
      [9.6, 23.9],
      [8.6, 22.7],
      [7.5, 25.7],
      [5.5, 24.3],
      [10.4, 21.2],
    ];

    const averages = [
      [21.5],
      [19.8],
      [17.6],
      [16.8],
      [15.6],
      [16.7],
      [16.3],
      [17.2],
      [16.0],
      [16.9],
      [16.1],
      [14.5],
    ];

    Highcharts.chart('container', {
      
      title: {
        text: null,
      },
      xAxis: {
        type: 'datetime',
        tickInterval: 2 * 3600 * 1000,
        labels: {
          format: '{value:%l %p}',
        },
        accessibility: {
          rangeDescription: 'Time of Day',
        },
        endOnTick: false,
        max: Date.UTC(2022, 6, 2, 0, 0),
      },
      yAxis: {
        title: {
          text: null,
        },
      },
      tooltip: {
        enabled: false, // Disable tooltip on hover
      },
      plotOptions: {
        series: {
          pointStart: Date.UTC(2022, 6, 1, 0, 0),
          pointInterval: 2 * 3600 * 1000,
        },
      },
      series: [
        {
          name: '',
          data: averages,
          zIndex: 1,
          marker: {
            fillColor: 'white',
            lineWidth: 1,
            lineColor: 'black',
          },
          color: 'black',
        },
        {
          name: 'Range',
          data: ranges,
          type: 'arearange',
          lineWidth: 0,
          linkedTo: ':previous',
          color: 'rgb(110, 84, 134)',
          fillOpacity: 0.3,
          zIndex: 0,
          marker: {
            enabled: false,
          },
        },
      ],
    });
  }, []);
  return (
    <>
      <figure className="highcharts-figure">
        <div id="container"></div>
      </figure>
    </>
  )
}
