import React from "react";

import Modal from "../../molecules/modals/Modal"
import { getChangePasswordFormProps } from "./changePasswordModal.helper";
import { CHANGE_PASSWORD_FIELDS } from "./changePasswordModal.fields";
import Header from "./components/Header/Header";
import Body from "./components/Body/Body";

import styles from "./changePasswordModal.module.css"

const ChangePasswordModal = ({
    toggleModal,
    headerProps,
    bodyProps
    }) =>{

    return (
        <div className={styles.container}>
            <Modal toggleModal={toggleModal} className={styles.modalContainer} headerProps={headerProps} bodyProps={bodyProps} HeaderComponent={Header} BodyComponent={Body} />
        </div>
    )
}

export default ChangePasswordModal;