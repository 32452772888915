import React from "react";

import transparentCancel from "./../../../../../../../../../../../assets/images/cancel/transparent_cacel.svg"

import  styles from "./notesCardpopup.module.css";
import Modal from "../../../../../../../../../../molecules/modals/Modal";
import Header from "./components/Header";
import Body from "./components/Body";

const NotesCardPopup = ({handleNotesDisplayClose, notes, date="" , userName=""})=>{
    return (
        <div className={styles.container}>
            <Modal 
                toggleModal={handleNotesDisplayClose}
                crossIcon={transparentCancel}
                HeaderComponent={Header}
                BodyComponent={Body}
                bodyProps={{ cardContent :notes}}
                headerProps={{
                    date, userName
                }}
            />
        </div>
    )
}

export default NotesCardPopup;