import React,{useState} from "react";
import Header from "./components/Header/Header";

import styles from "./phoneOtpPopup.moduel.css"
import Body from "./components/Body/Body";
import Footer from "./components/Footer/footer";
import { getCodeVerificationAction, getResendInviteAction } from "./phoneOtpPopup.async";

const PhoneOtpPopup = ({countryCode,phoneNumber, handleLoaderUpdate, handlePhoneOtpModalToggle, handlePhoneOtpSucess})=>{

    const [enableButton,setEnableButton] = useState(false);

    const [inputValue,setInputValue] = useState(new Array(6));

    const handleButtonEnabled = (val) =>{
        setEnableButton(val);
    }

    const handleResendInvite = ()=>{
        handleLoaderUpdate(true);
        getResendInviteAction({userId : window.localStorage.getItem("id"), countryCode, phoneNumber})
        .then(()=>{

        })
        .catch((e)=>{
            console.log('ERROR IS : ', e);
        })
        .finally(()=>{
            handleLoaderUpdate(false);
        })
    }

    const handleButtonSubmit = ()=>{
        handleLoaderUpdate(true);
        getCodeVerificationAction({userId : window.localStorage.getItem("id"),countryCode, phoneNumber, code: inputValue.join("")})
        .then((requestStatus)=>{
            if(requestStatus=='200' || requestStatus=='201' || requestStatus=='202' || requestStatus=='204'){
                handlePhoneOtpSucess();
            } 
            handleLoaderUpdate(false);
        })
        .catch((e)=>{
            console.log('ERROR IS : ', e);
        })
        .finally(()=>{
            handlePhoneOtpModalToggle();
            handleLoaderUpdate(false);
        })
    }

    return (
        <div className={styles.loginPinCardContainer}>
            <Header countryCode={countryCode} phoneNumber={phoneNumber} handlePhoneOtpModalToggle={handlePhoneOtpModalToggle}/>
            <Body setEnableButton={handleButtonEnabled} handleValueChange={setInputValue} inputValue={inputValue} />
            <Footer inputValue={inputValue} handleResendInvite={handleResendInvite} handleButtonSubmit={handleButtonSubmit}/>
        </div>
    )

}

export default PhoneOtpPopup;