
import glucose from "./../../../../../assets/images/glucose.svg";
import foodLog from './../../../../../assets/images/food_log.svg';
import redirectArrow from "./../../../../../assets/images/redirect_arrow.svg";
// import welcomeBack from "./../../../../../assets/images/welcome_back.svg";
import ChangeFactor from "./components/changefactor/ChangeFactor";

// import ComingSoon from "../ComingSoon";
import { PROGRESS_MONITORING_TYPE } from "../progressMonitoring/progressMonitoring.constants";
import BloodGlucose from "./components/bloodGlucose/BloodGlucose";

import styles from "./overViewTab.module.css";
import BalancePlateCard from "./components/balancePlateCard/BalancePlateCard";

const balancedPlateGroup = [['avgPlateScore'],["CALORIES","CARBOHYDRATES","PROTEINS","FATS","FIBER","SODIUM"]];

const statCards = [
    {
        headerProps : {
            labelPrefix : <img src={glucose} />,
            label : "Blood Glucose",
            RedirectComponent : <img src={redirectArrow} />
        },
        CustomComponent : BloodGlucose,
        footerProps :{
            FooterComponent : ChangeFactor,
        },
        bodyStatFields :[
            'AVG_FASTING_GLUCOSE',
            'FASTING_TIME_IN_RANGE',
        ],
        chartFields : "FASTING_GLUCOSE",
        name : PROGRESS_MONITORING_TYPE.BLOOD_GLUCOSE,
    },
    {
        headerProps : {
            labelPrefix : <img src={foodLog} />,
            label : "Balanced Plate",
            RedirectComponent : <img src={redirectArrow} />
        },
        CustomComponent : BalancePlateCard,
        footerProps :{
            FooterComponent : ChangeFactor,
        },
        bodyStatFields :[
            'AVG_BALANCED_PLATE',
            'AVG_BALANCED_PLATE',
        ],
        chartFields : "OVERALL_TIME_IN_RANGE",
        name : PROGRESS_MONITORING_TYPE.BALANCED_PLATE,
        statistics : [
            {
              "name": "avgPlateScore",
              label :"Avg. Plate Score/day",
              "color": "#EAC56B"
            },
            {
              "name": "avgCalories",
              label: "Avg. Calories/day",
              "color": "#F6B4A7"
            }
          ]
    },
];

export {
    statCards,
    balancedPlateGroup
}