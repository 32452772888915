const AVERAGE_PLATE_SUMMARY_BORDER_COLOR = {
    UNBALANCED : "#E4A944",
    BALANCED : "#7ACCAE",
    CRITICAL : "#EE9887",
}

const AVERAGE_PLATE_SUMMARY_COLOR = {
    UNBALANCED : "#FFF6BD",
    BALANCED : "#E5FAF2",
    CRITICAL : "#FEEFEB",
}

const AVERAGE_PLATE_SUMMARY_LABEL = {
    UNBALANCED : "Unbalanced Plate",
    BALANCED : 'Balanced Plate',
    CRITICAL : 'Critical Plate',
}

export {
    AVERAGE_PLATE_SUMMARY_BORDER_COLOR,
    AVERAGE_PLATE_SUMMARY_COLOR,
    AVERAGE_PLATE_SUMMARY_LABEL
}