
import styles from "./patientInviteModal.module.css";

const getFormProps = (formField, getPrimaryFormSubmit) =>{
    return {
        formContent : formField,
        // formHeaderProps :{
        //     primaryHeaderText: "Signup",
        //     secondaryHeaderText: "Get Started With Monitoring",
        //     footerContainerClassname: styles.footerContainerClassname,
        //     secondaryHeaderClassname: styles.secondaryHeaderClassname
        // },
        formFooterProps:{
            butttonText : "Invite Patients",
            footerContainerClassname: styles.footerContainerClass ,
            enabledClassname: styles.enabledClassname
        },
        formContainerProps:{
            containerClassname : styles.formContainer
        },
        formSubmitAction : getPrimaryFormSubmit,
    }
}

export {
    getFormProps,
}