import React, { useLayoutEffect } from "react";

import GanttOverViewTable from "./GanttOverViewTable";
import actions from "./ganttOverviewTable.actions";
import actionTypes from "./ganttOverviewTable.actionTypes";
import withActionState from "../../hooks/withActionState";

const GanttOverViewTableContainer = ({dispatch,...props}) =>{
    
    useLayoutEffect(()=>{
        dispatch({actionType: actionTypes.GET_COLUMNS_DATA, payload:{} })
    },[])
    
    return (
        <GanttOverViewTable {...props} />
    )
}

export default withActionState({Component:GanttOverViewTableContainer, action: actions})