import DateDisplay from "../../../../../../../../atoms/dateDisplay/DateDisplay"
import TableGraphBlock from "../TableGraphBlock"
import TablePrimaryBlockCard from "../TablePrimaryBlockCard"


const getWrapperFields =()=>{
    return [{
        label : "gant1",
        field : TableGraphBlock,
    },
    {
        label : "gant2",
        field : TableGraphBlock,
    }
]
}

const getColumnWrapperFields = () =>{
    return [{
        label : "gant1",
        field : DateDisplay,
    },
    {
        label : "gant1",
        field : DateDisplay,        
    }
]
}

export {
    getWrapperFields,
    getColumnWrapperFields
}