import React, { useEffect, useState } from "react";
import {map, isNaN} from "lodash";
import cx from "classnames";

import { getPaginationControls } from "../helpers/hocs";
import CircularLabel from "../atoms/circularLabel/CircularLabel";

import basicLeftArrow from "./../../assets/images/arrow/basicLeftArrow.svg";
import basicRightArrow from "./../../assets/images/arrow/active/basicRightArrow.svg";

import styles from "./../styles/hoc/withPagination.module.css";

const withPagination = (Component) =>({
    limit=0,
    tableData,
    ...rest
})=>{

    const [currentStart,setCurrentStart] = useState(1);
    
    const [listItems,setListItem ] = useState([]);

    useEffect(()=>{
        setListItem(getPaginationControls({items : tableData,limit,currentIndex : currentStart}));
    },[])

    const setParticularControl = (value)=> () =>{
        if(isNaN(+value)){
            return
        }
        setCurrentStart(value);
        setListItem(getPaginationControls({items : tableData,limit,currentIndex : value}));        
    }

    const getBackwardAction = ()=>{
        if(currentStart>1){
            setCurrentStart(currentStart-1);
            setListItem(getPaginationControls({items : tableData,limit,currentIndex : currentStart-1}));    
        }
    }

    const getForwardAction = () =>{
        if(currentStart < Math.ceil(tableData.length/limit)){
            setCurrentStart(currentStart+1);
            setListItem(getPaginationControls({items : tableData,limit,currentIndex : currentStart+1}));    
        }
    }

    return (
        <div className={styles.container}>
            <Component {...rest} tableData={tableData.slice(((currentStart-1)*limit),currentStart*limit)}/>
            <div className={styles.paginationContainer}>
                <CircularLabel className={cx({[styles.disabledIcon]: currentStart == 1})}  content={<img src={basicLeftArrow} className={styles.iconClass} />} onClick={getBackwardAction}  />
                {map(listItems,(item)=>{
                    return <CircularLabel className={currentStart == item ? styles.activeControl :  ""} content={item} onClick={setParticularControl(item)}/>
                })}
                <CircularLabel className={cx({[styles.disabledIcon]: currentStart*limit>=tableData?.length})} content={<img src={basicRightArrow} className={cx(styles.iconClass)} />}  onClick={getForwardAction} />
            </div>
        </div>
    )
}

export default withPagination;