import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import DefaultSpinner from "../../atoms/spinner/Spinner";

import Heading from "./components/heading/Heading";
import PageHeaderContext from "./components/pageHeaderContext/PageHeaderContext";
import Tabs from "./components/tabs/Tabs";

import {getMemberList, getPracticeData, getWorksiteList} from "./practiceDetail.async";
import { MEMBERS_STATUS } from "./practiceDetail.constants";

import styles from "./practiceDetail.module.css";
import useToast from "./customHooks/useToast";
import BasicToast from "../../molecules/baseComponents/toasts/BasicToast";

const PracticeDetail = (props) => {

    const { practiceId, practiceName } = useParams();

    const [activeMemberList = [], setActiveMemberList] = useState();

    const [invitedMemberList = [], setInvitedMemberList] = useState();

    const [practiceData, setPracticeData] = useState();

    const [worksiteList = [], setWorksiteList] = useState();

    const [isLoading, setIsLoading] = useState(false);

    const { displayToast, toastMessage, toastType, updateToast, removeToast } = useToast();

    const [refreshMemberList, setRefreshMemberList] = useState(false);

    useEffect(() => {
        getMemberList(practiceId, MEMBERS_STATUS.INVITED, setInvitedMemberList);
        getMemberList(practiceId, MEMBERS_STATUS.ACTIVE, setActiveMemberList);
        getPracticeData(practiceId, setPracticeData);
        getWorksiteList(practiceId, setWorksiteList);
    }, []);

    useEffect(() => {
        getMemberList(practiceId, MEMBERS_STATUS.INVITED, setInvitedMemberList);
        getMemberList(practiceId, MEMBERS_STATUS.ACTIVE, setActiveMemberList);
        setRefreshMemberList(false);
    }, [refreshMemberList]);

    return (
        <>
            {isLoading && <DefaultSpinner className={styles.spinnerContainer}/>}
            {displayToast && 
                <BasicToast
                    message={toastMessage}
                    type={toastType}
                    display={displayToast}
                    handleToastToggle={removeToast}
                 />
            }
            <div className={styles.mainContainer}>
            <PageHeaderContext practiceName={practiceName} />
            <Heading practiceName={practiceName} practiceId={practiceId} updateToast={updateToast} setIsLoading={setIsLoading} />
            <Tabs updateToast={updateToast} memberList={activeMemberList.concat(invitedMemberList)} practiceData={practiceData} worksiteList={worksiteList} setIsLoading={setIsLoading} setRefreshMemberList={setRefreshMemberList} />
        </div>
        </>

    )
}

export default PracticeDetail