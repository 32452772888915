import React,{useEffect, useState} from "react";
import {map} from "lodash";
import cx from "classnames";

import styles from "./labeledSingleSelect.module.css";

const LabeledSingleSelect = ({options = [], name, handleFieldChange, containerClassname = "", optionClassname="",activeOptionClassname=""}) =>{

    const [currentSelect,setCurrentSelect] = useState(options[0]?.option);

    useEffect(()=>{
        handleFieldChange({label:name})(options[0]?.option);
    },[handleFieldChange]);

    const handleOptionClick = (value)=>()=>{
        setCurrentSelect(value);
        handleFieldChange({label:name})(value);
    }

    return (
        <div className={cx(styles.container, containerClassname)}>
            {map(options,({id,option})=>{
                return (
                    <div className={cx(styles.optionBox, optionClassname,{[styles.activeOption]: currentSelect == option,[ activeOptionClassname]: currentSelect == option})} onClick={handleOptionClick(option)}>
                        {option}
                      </div>
                )
            })}
        </div>
    )
}

export default LabeledSingleSelect;