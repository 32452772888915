import React from "react";
import {map} from "lodash";

import { getNutritionalDetailsOptions } from "./nutritionalDetails.helpers";

import styles from "./nutritionalDetails.module.css";

const NutritionaDetails = ({nutritionalBreakdown,spikeAnalysis})=>{

    const options = getNutritionalDetailsOptions({nutritionalBreakdown, spikeAnalysis})

    return (
        <div className={styles.container}>
            {map(options,(option)=>{
                const {Component, ...rest} = option
                return (
                    <Component {...rest} />
                )
            })}
        </div>
    )
}

export default NutritionaDetails;