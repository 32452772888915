import React, { useState } from "react";

import styles from "./concentCheckBox.module.css";

const ConsentCheckBox = ({handleFieldChange, name, checkErrorState, errorTypes }) =>{

    const [currentState,setCurrentState] = useState(false);

    const handleInputChange =(event)=>{
        setCurrentState(!currentState);
        handleFieldChange({label: name})(!currentState);
        checkErrorState(!currentState,name , errorTypes)
    }

    return (
        <div className={styles.container}>
            <input type="checkbox" value={"concent"} className={styles.checkBox} onChange={handleInputChange} />
            <label className={styles.concentTypography}>I hereby give consent to the concerned the Care Pro coach to view my health records</label>
        </div>
    )
}

export default ConsentCheckBox;