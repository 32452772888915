import styles from "./tabs.module.css"
import { useState } from "react";
const Tabs = ({updateCurrentTab, currentTab}) => {

    const handleClick = (currentValue) =>() => {
        updateCurrentTab(currentValue);
    };

    return (

        <div className={styles.tabOptions}>
            <div className={currentTab == "ACTIVE" ? styles.tabActive : styles.tab}
                onClick={handleClick("ACTIVE")}>Active</div>
            <div className={currentTab == "INVITED" ? styles.tabActive : styles.tab}
                onClick={handleClick("INVITED")}>Invited</div>
            <div className={currentTab == "ARCHIVED" ? styles.tabActive : styles.tab}
                onClick={handleClick("ARCHIVED")}>Archived</div>
        </div>

    )
}

export default Tabs;
