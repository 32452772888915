import { API_STATUS_CODES, BASE_URL } from "../../constants/api";
import { LOCAL_STORAGE_KEYS } from "../../constants/keyConstants";
import { NOTES_DISPLAY_STATUS } from "./patientProfile.constants";
import { getDurationChangeRequestBody, getNotesFormDataFormatter, getRecentTrendsFormatter, getRequestBody } from "./patientProfile.helper";

const getRecentTrends = async({setState})=>{
    try{
        setState((prev)=>{
            return {
                ...prev,
                isLoading : true,
            }
        })
        const url = new URL(window.location.href)
        const pathname = url.pathname.split("/");
        const request = await fetch(`${BASE_URL}/dashboard/glucoseDataTrend`,{
            method:"POST",
            headers:{
                "content-type":"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
            body: JSON.stringify({
                userId: pathname?.[2],
                metricRequests: [
                    {
                        "metricType": "FASTING_GLUCOSE",
                        "mealType":"BREAKFAST"
                    },
                     {
                        "metricType": "AVERAGE_TWO_HOUR_POST_PRANDIAL",
                        "mealType":"BREAKFAST"
                    },
                     {
                        "metricType": "AVERAGE_TWO_HOUR_POST_PRANDIAL",
                        "mealType":"LUNCH"
                     },
                     {
                        "metricType": "AVERAGE_TWO_HOUR_POST_PRANDIAL",
                        "mealType":"DINNER"
                    }
                ]
            })
        })

        const recentTrends = await request.json();

        setState((prev)=>{
            return {
                ...prev,
                recentTrends : getRecentTrendsFormatter(recentTrends) ,
                isLoading : false,
            }
        })
    }
    catch(e)
    {
        setState((prev)=>{
            return {
                ...prev,
                error:e,
                isLoading : false,
            }
        })
    }
}

const getInitAction = async({state,setState,navigate}) =>{
    try{
        setState((prev)=>{
            return {
                ...prev,
                isLoading : true,
            }
        })

        const url = new URL(window.location.href)
        const pathname = url.pathname.split("/");
        const requestBody = getRequestBody({type:"FASTING_GLUCOSE",numberOfDays: 8,mealType:"BREAKFAST"});
        const greenPlatesRequestBody = getRequestBody({type: "OVERALL_TIME_IN_RANGE",numberOfDays: 8,mealType : "BREAKFAST"})
        const response = await fetch(`${BASE_URL}/dashboard/glucoseMetrics`,{
            method:"POST",
            headers:{
                "content-type":"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
            body: JSON.stringify({
                userId: pathname?.[2],
                metricRequests: [
                        {
                            metricType: "AVG_FASTING_GLUCOSE",
                            numberOfDays : 7,
                            mealType: "BREAKFAST"
                        },
                        {
                            metricType:"FASTING_TIME_IN_RANGE",
                            numberOfDays: 7,
                            mealType :"BREAKFAST"
                        },
                        // {
                        //     metricType:"AVG_PLATE_RATING",
                        //     numberOfDays: 7,
                        //     mealType :"BREAKFAST"
                        // },
                        ...requestBody,
                        ...greenPlatesRequestBody
                    ]
            })
        });

        if(response.status == API_STATUS_CODES.ANAUTHORIZED || response.status == API_STATUS_CODES.FORBIDDEN)
        {
            navigate("/login")
        }

        const patientDetailsResponse = await fetch(`${BASE_URL}/api/users/dashboard/getUserRequest`,{
            method : "POST",
            body: JSON.stringify({
                "user_id": pathname?.[2],
            }),
            headers :{
                "content-type" : "application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            }
        });

        // if(patientDetailsResponse.status == API_STATUS_CODES.ANAUTHORIZED || patientDetailsResponse.status == API_STATUS_CODES.FORBIDDEN)
        // {
        //     navigate("/login")
        // }

        const notesResponse = await fetch(`${BASE_URL}/api/dashboard/users/getNotes`,{
            method : "POST",
            headers : {
                'content-type':"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
            body: JSON.stringify({
                userId : pathname?.[2],
                careProUserId : window?.localStorage?.getItem('id'),
            })
        })

        const notesData = await notesResponse.json();

        const patienData = await patientDetailsResponse.json();
    
        const data = await response.json();

        setState({ 
            notes : notesData?.notes,
            patientId : pathname?.[2],
            metricsResponse : data?.metricsResponses, patientDetails : patienData, isLoading : false});

        getRecentTrends({setState});
    }
    catch(e)
    {
        setState({error:e ,isLoading : false})
    }
}

const getTabChangeAction = async({setState, payload}) =>{
    try{
        const {label ="", ...rest} = payload;

        setState((prev)=>{
            return {
                ...prev,
                activeTab : label,
                ...rest
            }
        })
    }
    catch(e)
    {
        setState((prev)=>{
            return {
                ...prev,
                error:e
            }
        })
    }
}

const getNotesDiscardToggle = ({state, setState, payload})=>{
    try{
        const { noteStatus } = state;
        if( noteStatus != NOTES_DISPLAY_STATUS.HIDDEN){
            setState((prev)=>{
                return {
                    ...prev,
                    notesDiscardCallback : payload?.callback
                }
            })
        }
        else {
            if(!payload?.accept){
                const { notesDiscardCallback = ()=>{} } = state;
                notesDiscardCallback();    
            }
        }
        setState((prev)=>{
            return {
                ...prev,
                notesDiscardDisplay : payload?.notesDiscardDisplay!=undefined ? payload?.notesDiscardDisplay : !prev?.notesDiscardDisplay
            }
        })
    }
    catch(e){
        setState((prev)=>{
            return {
                ...prev,
                error:e
            }
        })
    }
}

const getDurationChange = async({state,setState, payload})=>{
    try{
        setState((prev)=>({
            ...prev,
            isLoading : true,
        }));

        const {name , ...rest} = payload;

        const url = new URL(window.location.href)
        const pathname = url.pathname.split("/");
        const requests = getDurationChangeRequestBody({payload:rest, userId: pathname?.[2], name});
        Promise.allSettled(
            requests?.map((val)=>{
                const {url: basicUrl , requestBody, method = "POST" } = val;
                
                return fetch(`${BASE_URL}/${basicUrl}`,{
                    method,
                    headers:{
                        "content-type":"application/json",
                        'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
                    },
                    body: requestBody && JSON.stringify({
                        userId: pathname?.[2],
                        metricRequests: requestBody
                    })
                })
                .then((response)=>{
                    if(response.status == API_STATUS_CODES.ANAUTHORIZED || response.status == API_STATUS_CODES.FORBIDDEN)
                    {
                        navigate("/login")
                    }
            
                    return  response.json();    
                })
            })
        )
        .then((data)=>{
            const {prev: previous } = payload;

            setState((prev )=>({ ...prev,
                metricsResponse : data?.[0]?.value?.metricsResponses ,
                balancePlateValues : data?.[1]?.value,
                isLoading : false, 
                overViewDurationTypes:{[name] : previous},
                overviewDuration : payload})
            );
        })

    }
    catch(e){
        setState((prev)=>({
            ...prev,
            error : e,
            isLoading : false
        }))
    }
}

const getLoaderToggleAction = ({setState, payload})=>{
    try{
        const{ isLoading : newIsLoading} = payload
        setState(({isLoading,...rest})=>{
            return {
                isLoading : newIsLoading,
                ...rest
            }
        })
    }
    catch(e)
    {
        setState((prev)=>({
            ...prev,
            error : e,
            isLoading : false
        }))
    }
}

const getNotesDisplayAction = ({state, setState, payload}) =>{
    try{        
        setState((prev)=>{
            return {
                ...prev,
                noteStatus : payload?.status || NOTES_DISPLAY_STATUS.DISPLAY
            }
        })
    }
    catch(e){
        setState((prev)=>{
            return {
                ...prev,
                erorr : e
            }
        })
    }
}

const getNotesFormSubmitAction = async({state, setState, payload, navigate})=>{
    try{

        setState((prev)=>{
            return {
                ...prev, isLoading : true,enableToast : false
            }
        });

        const url = new URL(window.location.href)
        const pathname = url.pathname.split("/");

        const { careProUserId = window.localStorage.getItem("id")} = state;

        const requestPayload = getNotesFormDataFormatter(payload, pathname?.[2], careProUserId)

        await fetch(`${BASE_URL}/api/dashboard/users/addNewNote`,{
            method : "POST",
            headers:{
                "content-type":"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
            body:JSON.stringify(requestPayload),
        });

        const notesResponse = await fetch(`${BASE_URL}/api/dashboard/users/getNotes`,{
            method : "POST",
            headers : {
                'content-type':"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
            body: JSON.stringify({
                userId : pathname?.[2],
                careProUserId : window?.localStorage?.getItem('id'),
            })
        })

        const notesData = await notesResponse.json();

        setState((prev)=>{
            return {
                ...prev,
                isLoading : false,
                noteStatus : NOTES_DISPLAY_STATUS.HIDDEN,
                notes : notesData?.notes,
                enableToast : true,
                toastProps:{
                    message : "Notes Added Successfully",
                    type : "success"
                }
            }
        })
    }
    catch(e){
        setState((prev)=>{
            return {
                ...prev,
                error : e,
                isLoading : false
            }
        })
    }
}

const getNotesFormDataUpdate = ({state, setState, payload})=>{
    try{
        const {formState} = state;
        setState((prev)=>{
            return {
                ...prev,
                formState : {
                    ...formState,
                    ...payload
                }
            }
        })
    }
    catch(e){
        setState((prev)=>{
            return {
                ...prev,
                error : e,
            }
        })
    }
}

const getToastUpdateAction = ({setState,payload={}})=>{
    try{
        setState((prev)=>{
            const {enableToast = false} = prev;
            return {
                ...prev,
                enableToast: !enableToast,
                ...payload
            }
        })
    }
    catch(e){
        setState((prev)=>{
            return {
                ...prev,
                error : e
            }
        })
    }
}

const getToastCloseAction = ({setState})=>{
    try{
        setState((prev)=>{
            return {
                ...prev,
                enableToast: false,
            }
        })
    }
    catch(e){
        setState((prev)=>{
            return {
                ...prev,
                error : e
            }
        })
    }
}

export default {
    INIT_ACTION : getInitAction,
    CHANGE_TAB_ACTION : getTabChangeAction,
    OVERVIEW_DURATION_CHANGE : getDurationChange,
    HANDLE_LOADER_TOGGLE : getLoaderToggleAction,
    HANDLE_NOTES_DISPLAY : getNotesDisplayAction,
    HANDLE_NOTES_STATE_UPDATE : getNotesDisplayAction,
    HANDLE_NOTES_DISCARD_POPUP_TOGGLE : getNotesDiscardToggle,
    NOTES_FORM_SUBMIT : getNotesFormSubmitAction,
    HANDLE_NOTES_FORM_DATA_UPDATE : getNotesFormDataUpdate,
    HANDLE_TOAST_UPDATE : getToastUpdateAction,
    HANDLE_TOAST_CLOSE : getToastCloseAction,
}