import React from "react";
import { map } from "lodash";

import styles from "./container.module.css"
import TabDisplay from "../components/TabDisplay/TabDisplay";

const LowerContainer = ({options,notes,recentTrends, handleToastUpdateAction, metricsResponse,patientDetails, handleTabChange,activeTab, Component, correspondingTabProps, handleToggleLoaderAction, userName}) =>{
    return (
        <div className={styles.container}>
            <div className={styles.tabOptions}>
                {
                    map(options , (option)=>{
                        return <TabDisplay {...option} handleTabChange={handleTabChange} activeTab={activeTab}/>
                    })
                }
            </div>
            <Component handleToastUpdateAction={handleToastUpdateAction} notes={notes} userName={userName} recentTrends={recentTrends} {...correspondingTabProps} patientDetails={patientDetails} metricsResponse={metricsResponse} handleToggleLoaderAction={handleToggleLoaderAction}/>
        </div>
    )
}

export default LowerContainer;