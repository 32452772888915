
const getPaginationControls = ({items=[],limit, currentIndex})=>{
    let totalCount = Math.ceil(items.length/limit);

    let start = Math.floor((currentIndex*limit)/limit);

    if(totalCount<5){
        let arr=[];
        for(let x=1;x<=totalCount;x++){
            arr.push(x);
        }
        return arr;
    }

    if((start+2)>=totalCount){
        if((start+2)==totalCount){
            return [start-1,start,start+1,totalCount];
        }
        else{
            if(start==totalCount){
                return [1,'...',start-1,start];
            }
            return [1,'...',start,start+1]
        }
    }

    return [start,start+1,"....", totalCount]
}

export {
    getPaginationControls,
}