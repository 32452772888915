
const PRIMARY_FORM_SUBMIT = "PRIMARY_FORM_SUBMIT";

const SECONDARY_FORM_SUBMIT = "SECONDARY_FORM_SUBMIT";

const INIT_ACTION = "INIT_ACTION";

const RESEND_INVITE = "RESEND_INVITE";

export default {
    PRIMARY_FORM_SUBMIT,
    SECONDARY_FORM_SUBMIT,
    INIT_ACTION,
    RESEND_INVITE
}