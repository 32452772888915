import { BASE_URL } from "../../constants/api";

const getResendInviteAction = async({baseId})=>{
    try{
        const response = await fetch(`${BASE_URL}/dashboard/admin/api/rrxsuperboss/users/resendAddNewPracticeInviteEmail/${baseId}`,{
            method : "GET",
            headers:{
                'content-type':'application/json',
                "authorization" : "Bearer " + window?.localStorage?.getItem("Authorization"),
            },
        })

        return response.status;
    }
    catch(e){
        console.log('Error is : ',e);
        return e;
    }
}

export {
    getResendInviteAction
}
