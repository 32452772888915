import React from 'react';
import cx from "classnames";

import TirSingleLineChart from '../../../../../../../../charts/TirSingleLineChart';

import styles from "./tableGraphBlock.module.css";
import { getDataInTableFormat } from './tableGraphBlock.helper';

const TableGraphBlock = ({primaryText="",secondaryText="", data, ...rest}) =>{

    const {inRange = {}, glucose, suffix = "mg/dL"} = data || {};

    if(!inRange.glucose && !glucose)
    {
        return <div className={cx(styles.noDataContainer,styles.fullContainer)}>No Data</div>
    }

    return (
        <div className={styles.container}>
            <div className={styles.primaryText}>
                {"TIR  "}<span className={styles.secondaryText}>{`${inRange?.glucose || ""} ${inRange?.glucose ? "%" : ""}`}</span>
            </div>
            <TirSingleLineChart tableData={getDataInTableFormat(data)} />
            <div className={styles.primaryText}>
                {!glucose && <div className={styles.noDataContainer}>No Glucose Data</div>}
                {glucose && <>{"Avg  "}<span className={styles.secondaryText}>{`${glucose || ""} ${glucose ? suffix : ""}`}</span></>}
            </div>
        </div>
    )
}

export default TableGraphBlock;