import React from "react";
import cx from "classnames";

import styles from "./borderedButton.module.css";

const BorderedButton = ({content, className, handleClickAction}) =>{

    const handleButtonClick = (event)=>{
        event.preventDefault();
        handleClickAction();
    }

    return (
        <div className={cx(styles.container, className)} onClick={handleButtonClick}>
            <div className={styles.innerContainer}>
                {content}
            </div>
        </div>
    )
}

export default BorderedButton;