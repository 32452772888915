import React from "react";

import MultiSelect from "../../../../../../../../molecules/Multiselect/MultiSelect";
import { BASE_PLACEHOLDER } from "./coachSelect.constants";

import styles from "./coachSelect.module.css";

const CoachSelect = ()=>{
    return (
        <div className={styles.container}>
            <MultiSelect isMulti={false} placeholder={BASE_PLACEHOLDER}/>
        </div>
    )
}

export default CoachSelect