import React, { useState } from "react";

import postCard from "../../../assets/images/postCard.svg";

import styles from "./emailConfirmartionCard.module.css";
import Toast from "../toast";

const EmailConfirmationCard = ({secondaryFormProps, email})=>{
    
    const [displayResendToast,setDisplayResendToast] = useState(false);

    const { 
        formSubmitAction=()=>{}, 
        primaryHeading = "Please Verify Your Email", 
        secondaryHeading = "You’re almost there! The Verification link is sent to",
        tertiaryHeading = "Just click on the link in the email to complete your signup ."
       } = secondaryFormProps;

    const handleButtonClickHandler = ()=>{
        setDisplayResendToast(false);
        formSubmitAction();
        setDisplayResendToast(true);
    }

    const toastProps = {
        headerText : "Verification Email Sent",
        bodyText : "Verification Email has been sent to your email.",
        color : "success",
    }
    return (
        <>
            {displayResendToast && <Toast {...toastProps} />}
            <div className={styles.cardContainer}>
                <div className={styles.imageContainer}>
                    <img src={postCard} className={styles.cardImage} />
                </div>
                <div className={styles.cardTextContainer}>
                    <div className={styles.bodyHeading}>
                        {primaryHeading}
                    </div>
                    <div className={styles.bodyText}>
                        {secondaryHeading} <span className={styles.boldText}>{email}</span>
                    </div>
                    <div className={styles.bodyText}>
                        {tertiaryHeading}If you don’t see it, you may need to <span className={styles.boldText}>check your spam</span> folder.
                    </div>
                </div>
                    <div className={styles.buttonContainer}>
                        <button className={styles.cardButton} onClick={handleButtonClickHandler}>Resend</button>
                    </div>
            </div>
        </>
    )
}

export default EmailConfirmationCard;