import CheckBox from "./components/CheckBox/CheckBox"

const BILLING_BODY_POPUP = [{
    id: 1,
    label : "CGM Interpretation",
    value : "95251 - CGM Interpretation",
    numericalHint : 95251 
}]

const BILLING_GROUP_LABELS = {
    "95251 - CGM Interpretation" : "95251 - CGM Interpretation"
}

const BILLING_VALUE_GROUP = {
    "95251 - CGM Interpretation":[
    {
        id:1,
        Component : CheckBox,
        type: "checkbox",
        label : "Data Reviewed",
        content : "Reviewed 72+ hours of reference data (Glucose Summary Data)",
    },
    {
        id:2,
        Component : CheckBox,
        label : "Interpretation Data",
        content : "Open Text Box with Clinical interpretation notes from Provider.",
        type: "checkbox",
    },
    {
        id:3,
        Component : CheckBox,
        label : "Set a Plan",
        content : "Streamlined a action plan according to the interpretated data.",
        type: "checkbox",
    },
    {
        id:4,
        Component : CheckBox,
        label : "Added to EHR",
        content : "Streamlined a action plan according to the interpretated data.",
        type: "checkbox",
    }
    ]
}

export {
    BILLING_BODY_POPUP,
    BILLING_VALUE_GROUP,
    BILLING_GROUP_LABELS
}