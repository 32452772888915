import React from "react";
import {map} from "lodash";

import AlertCard from "../AlertCard";
import NoDataFallback from "../noDataFallback";

import medicationIcon from "./../../../../../../../assets/images/medication.svg"
import glucoseIcon from "./../../../../../../../assets/images/glucose.svg";

import styles from "./body.module.css";

const Body = ({activities =[], CustomComponent, data}) =>{
    
    if(!CustomComponent && activities?.length == 0)
    {
        return <NoDataFallback />
    }

    return (
        <div className={styles.container}>
            {CustomComponent && <CustomComponent data={data} />}
            {!CustomComponent &&
                map(activities,(activity)=>{
                    return (
                        <AlertCard {...activity}/>
                    )
                })
            }
        </div>
    )
}

export default Body;