import ListDisplay from "./components/ListDisplay"

const USER_SETTINGS_MAPPING = {
    // profilePhoto:"",
    speciality: ListDisplay,
    credentials: ListDisplay
}

export {
    USER_SETTINGS_MAPPING
}