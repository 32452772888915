import {map} from "lodash";
import { TIR_RANGE_IDEAL_VALUES, TIR_RANGE_TYPES } from "./tirCard.constants";
import { RANGE_BASIC_COLORS } from "../../../../../../../../fieldMapping/color";

const getDataInTableFormat = (data={}) =>{
    let arr=[],sum=0;
    sum+=(data?.['above']?.glucose ||0)+(data?.['inRange']?.glucose ||0)+(data?.['below']?.glucose || 0);
        arr.push({
            name : "",
            showInLegend: false,
            color : RANGE_BASIC_COLORS?.['above'],
            data:[data?.['above']?.glucose || 0],            
        })
        arr.push({
            name : "",
            showInLegend: false,
            color : RANGE_BASIC_COLORS?.['inRange'],
            data:[data?.['inRange']?.glucose],            
        })
        arr.push({
            name : "",
            showInLegend: false,
            color : RANGE_BASIC_COLORS?.['below'],
            data:[data?.['below']?.glucose || 0],            
        })
    return {formattedData: arr, glucoseSum: sum};
}

const getDataValueFields = (data={},types)=>{
    return map(types,(type)=>{
        return {
            primaryText : TIR_RANGE_TYPES[type],
            rangeText : TIR_RANGE_IDEAL_VALUES[type],
            currentValue : data[type]?.glucose ? `${data[type]?.glucose} %` : `0 %`,
            type,
        }
    })
}

export {
    getDataInTableFormat,
    getDataValueFields
}