import ErrorComponent from "../../atoms/errorComponent/ErrorComponent"
import { ERROR_STATUS } from "../../constants/errorStatus"
import { PATIENT_DETAIL } from "../../fieldMapping/patientDetails"
import InputWithSuffix from "../../molecules/InputWithSuffix"

const INDIVIDUAL_INVITE_FIELDS =[{
    name:PATIENT_DETAIL.FIRST_NAME,
    label: "First Name",
    errorTypes : [{type :ERROR_STATUS.IS_REQUIRED}],
    fieldType : "text",
    autoFocus:true,
    ErrorComponent : ErrorComponent,
    PlaceHolder: "Enter Patient First Name",
    onChange:()=>{},
    onFocus:()=>{}
},
{
    name:PATIENT_DETAIL.LAST_NAME,
    label: "Last Name",
    errorTypes : [{type :ERROR_STATUS.IS_REQUIRED}],
    fieldType : "text",
    ErrorComponent : ErrorComponent,
    PlaceHolder: "Enter Patient Last Name",
    onChange:()=>{},
    onFocus:()=>{}
},
{
    name:PATIENT_DETAIL.PHONE_NUMBER,
    label: "Patient Number",
    CustomInput: InputWithSuffix ,
    fieldType:"number",
    ErrorComponent: ErrorComponent,
    PlaceHolder: "Enter Patient Number",
    // onChange:()=>{},
    onFocus:()=>{},
    onBlur:()=>{}
},
{
    name:PATIENT_DETAIL.EMAIL,
    label: "Patient Email",
    fieldType : "email",
    ErrorComponent : ErrorComponent,
    errorTypes:[{type:ERROR_STATUS.IS_EMAIL}],
    PlaceHolder: "Enter Patient Email Address",
    onChange:()=>{},
    onFocus:()=>{}
}]

export {
    INDIVIDUAL_INVITE_FIELDS,
}