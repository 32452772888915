import React, { useCallback, useState, useRef } from "react";
import {map} from "lodash";
import cx from "classnames";

import searchIcon from "../../../../../assets/images/search-icon.svg"

import styles from "./secondaryHeader.module.css";
import { INVITE_PATIENT_STATUS } from "../../../../fieldMapping/invitePatients";

const SecondaryHeader = ({
    options=[{label:"Active", name: INVITE_PATIENT_STATUS.ACTIVE, id:1},{label:"Pending",name: INVITE_PATIENT_STATUS.PENDING, id:2}],
    status="Active",
    statusChangeAction,
    getFilteredTableDataAction}) =>{

    const [currentValue, setCurrentValue] = useState("");
    const [inputFocusedText, setInputFocusedText] = useState("Find");

    const inputRef = useRef(null);

    const handleMaunualInputFocus = ()=>{
        inputRef.current.focus();
    }

    const handleInputFocus = useCallback(()=>{
        setInputFocusedText("Search user by Name or MRN number");
    },[setInputFocusedText]);

    const handleInputBlur = useCallback(()=>{
        setInputFocusedText('Find');
    },[]);

    const handleStatusClick = useCallback((action)=> ()=>{
        statusChangeAction(action)
    },[statusChangeAction]);

    const getValueChange = (e)=>{
        e.preventDefault();
        getFilteredTableDataAction(e.target.value);
        setCurrentValue(e.target.value);
    }

    return (
        <div className={styles.container}>
            <div className={styles.optionsContainer}>
                {map(options,({label, className, id, name})=>{
                    return (
                        <div onClick={handleStatusClick(name)} className={cx(styles.option,className,{[styles.active]:status == name})} key={id}>
                            {label}
                        </div>
                    )
                })}
            </div>
            <div className={cx(styles.textBoxContainer,{ [styles.textBoxActiveContainer]: inputFocusedText!='Find'})}>
                <img src={searchIcon} className={styles.icon} onClick={handleMaunualInputFocus} />
                <input 
                    value={currentValue} 
                    onChange={getValueChange} 
                    type="text" 
                    onFocus={handleInputFocus} 
                    onBlur={handleInputBlur}
                    className={styles.textBox} 
                    placeholder={inputFocusedText}
                    ref={inputRef}
                />
            </div>
        </div>
    )
}

export default SecondaryHeader;