import React from "react";
import {map} from "lodash";

import styles from "./averagePlateSummary.module.css"
import AveragePlateSummaryDonut from "../../../../../../../../../../../../charts/patientProfile/progressMonitoring/balancePlate/averagePlateSummaryDonut/averagePlateSummaryDonut";
import { AVERAGE_PLATE_SUMMARY_STYLES_MAPPING } from "./averagePlateSummary.mapping";
import CardNoDataFallback from "../../../../../../../../../../../../molecules/cardNoDataFallback";
import { getMapFormattedData } from "./averagePlateSummary.helpers";

const AveragePlateSummary = ({plateTypes=[
    {label: "Balanced Plate", name: "BALANCED"},
    {label: "Unbalanced Plate", name: "UNBALANCED"},
    {label: "Critical Plate", name: "CRITICAL"}
    ],
    nutritionalValues
    }) =>{

    if(Object.keys(nutritionalValues).length == 0){
        return <CardNoDataFallback textClass={styles.secondaryTextClass} containerClass={styles.noDataContainerClass}/>
    }

    return (
        <div className={styles.container}>
            <AveragePlateSummaryDonut className={styles.donutChartClass} data={getMapFormattedData(nutritionalValues)} />
            <div className={styles.secondaryContainer}>
                {map(plateTypes,(plateType)=>{
                    const {label ,name } = plateType;
                    return (
                        <div className={styles.plateCard}>
                            <div className={styles.cardSecondaryContainer}>
                                <div className={styles.cardLabelText}>
                                    <div className={AVERAGE_PLATE_SUMMARY_STYLES_MAPPING?.[name]} />
                                    {label}
                                </div>
                                <div className={styles.cardMainText}>
                                    {(Math.round(nutritionalValues?.[name]*10)/10 || 0)+" %"}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default AveragePlateSummary;