import React, { useCallback } from "react";

import withActionState from "../../hooks/withActionState";

import Modal from "../../molecules/modals/Modal";
import { getFormProps } from "./patientInviteModal.helper";
import { INDIVIDUAL_INVITE_FIELDS } from "./patientInviteModal.fields";
import Header from "./components/Header";
import Body from "./components/Body/Body";
import actions from "./patientInviteModal.actions";
import actionTypes from "./patientInviteModal.actionTypes";

import styles from "./patientInviteModal.module.css";

const PatientInviteModal = ({toggleModal, dispatch}) =>{

    const getFormSubmitAction = useCallback((payload)=>{
        dispatch({actionType : actionTypes.FORM_SUBMIT_ACTION, payload })
    },[])

    const bodyProps ={
        formProps : getFormProps(INDIVIDUAL_INVITE_FIELDS,getFormSubmitAction)
    };

    const headerProps={
        content : "Invite Patients"
    }

    return (
        <div className={styles.container}>
            <Modal toggleModal={toggleModal} className={styles.modalContainer} HeaderComponent={Header} BodyComponent={Body} bodyProps={bodyProps} headerProps={headerProps}/>
        </div>
    )
}

export default withActionState({Component: PatientInviteModal, action : actions});