const RRX_PRACTICE_ID = "802b1a26-439c-045d-a383-8fba95174773";

const TOAST_MESSAGE={
    success : "Member Added successfully",
    failed : "Failed To Add"
}

const RRX_MEMBER_ROLES = {
    RENEWRX_CARE_PRO_ADMIN : "Admin",
    RENEWRX_CARE_PRO : "Member"
}

export {
    RRX_PRACTICE_ID,
    TOAST_MESSAGE,
    RRX_MEMBER_ROLES
}