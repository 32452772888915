import React from "react";

import styles from "./footer.module.css";

const Footer = ({note="No Note Added by Patient"}) =>{
    return (
        <div className={styles.container}>
            <div className={styles.primaryText}>
                Patient Note
            </div>
            <div className={styles.secondaryText}>
                {note}
            </div>
        </div>
    )
}

export default Footer;