import React from "react";
import TermsAndConditions from "./components/TermsAndConditions";
import Header from "./components/Header";
import cx from "classnames";
import styles from "./signUp.module.css";
import { useState } from "react";
import { useEffect } from "react";
import TermsAndConditionsModal from "../../molecules/TermsAndConditionsModal";
import Form from "./components/form/Form";
import verifyToken from "./signUp.async";

const SignUp = () => {

    const [displayModal, setModalDisplay] = useState(false);

    const [modalType, setModalType] = useState("");

    const [isTokenExpired, setIsTokenExpired] = useState(false);

    useEffect(() => {verifyToken(setIsTokenExpired);}, []);

    return (
        <div>
            {!isTokenExpired || 
            <div className={cx(styles.container, styles.containerBackgroundStyles)}>
                <Header />
                <Form />
                <TermsAndConditions setModalType={setModalType} displayModal={displayModal} setModalDisplay={setModalDisplay} />
                {displayModal && <TermsAndConditionsModal setModalType={setModalType} modalType={modalType} displayModal={displayModal} setModalDisplay={setModalDisplay} />}
            </div>}
        </div>


    )
}

export default SignUp;