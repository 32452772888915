import { BASIC_COLORS, COMPARISON_ANALYSIS_TABLE_GRAPH } from "../../../../../../../../fieldMapping/color";

const getDataInTableFormat = (data={}) =>{
    let arr=[];
    // Object.keys(data).forEach((key)=>{
    //     arr.push({
    //         name : "",
    //         showInLegend: false,
    //         color : COMPARISON_ANALYSIS_TABLE_GRAPH[key],
    //         data:[data?.[key]?.glucose],            
    //     })
    // })
        arr.push({
            name : "",
            showInLegend: false,
            color : COMPARISON_ANALYSIS_TABLE_GRAPH['below'],
            data:[data?.['below']?.glucose],            
        })
        arr.push({
            name : "",
            showInLegend: false,
            color : COMPARISON_ANALYSIS_TABLE_GRAPH['inRange'],
            data:[data?.['inRange']?.glucose],            
        })
        arr.push({
            name : "",
            showInLegend: false,
            color : COMPARISON_ANALYSIS_TABLE_GRAPH['above'],
            data:[data?.['above']?.glucose],            
        })
    return arr;
}

export {
    getDataInTableFormat,
}