import React,{useState} from "react";
import {map} from "lodash";
import cx from "classnames";

import styles from "./practiceSetupBaseForm.module.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer/Footer";
import { getFooterButtonText } from "./practiceSetupBaseForm.helper";

const PracticeSetupBaseForm = ({body, currentTab =0, handleTabSwitch, formSubmitHandler, ...rest})=>{

    const {Component,...formContent} = body[currentTab];

    const footerText = getFooterButtonText()[currentTab];

    const handleTabClick = (id)=> ()=>{
        handleTabSwitch(id);
    }

    return (
        <>
            <Header />
            <div className={styles.bodyContainer}>
                <div className={styles.tabContainer}>
                    {
                        map(body,({label,id})=>{
                            const activeStyle = currentTab == id ? styles.activeTab : styles.tabs;
                            return (
                                <div onClick={handleTabClick(id)} className={activeStyle}>{label}</div>
                            )
                        })
                    }
                </div>
                <Component {...formContent} formState={{}} fieldError={{}} {...rest}/>
            </div>
            <Footer buttonText={footerText} formSubmitHandler={formSubmitHandler} />
        </>
    )
}

export default PracticeSetupBaseForm;