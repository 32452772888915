import React, { useState } from "react";
import {map} from "lodash";
import { Form } from "react-bootstrap";
import cx from "classnames";

import styles from "./basicDropdown.module.css";

const BasicDropdown = ({metricTypes, errorTypes={}, handleFieldChange=()=>()=>{}, checkErrorState=()=>{}, componentContainer="",selectClassname="", handleMetricTypeChange=()=>{}, value="1 Hr PP", name}) =>{

    const [currentValue , setCurrentValue]= useState(value);

    const handleOptionChange = (event)=>{
        event.preventDefault();
        setCurrentValue(event?.target?.value)
        handleMetricTypeChange(name ,event?.target?.value)
        handleFieldChange({label:name})(event?.target?.value)
        checkErrorState(event?.target?.value,name,errorTypes)
    }

    return (
        <div className={cx(styles.container,componentContainer)}>
            <Form.Select className={cx(styles.selectDropdown,selectClassname)} onChange={handleOptionChange} value={currentValue}>
                {
                    map(metricTypes,({value,label})=>{
                        return <option value={value}>{label}</option>
                    })
                }
            </Form.Select>
        </div>
    )
}

export default BasicDropdown;