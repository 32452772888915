import React from "react";

import styles from "./body.module.css";
import FormManager from "../../../../reusables/formManager/FormManager";

const Body = ({tabOptions = [{label : "Invidual Invite", isActive : true}], ...rest}) =>{
    return (
        <div className={styles.container}>
            <FormManager {...rest} />
        </div>
    )
}

export default Body;