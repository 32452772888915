import { CREATE_ACCOUNT_FIELD } from "../../fieldMapping/createAccountFields"

const getSanitizedValues = (formValues) =>{
    return {
        ...formValues,
        practiceEmail : formValues?.practiceEmail?.toLowerCase(),
        workEmail : formValues?.workEmail?.toLowerCase(),
        gender : formValues?.gender?.[0],
        ethnicity : formValues?.ethnicity?.[0],
        city : formValues?.city?.[0],
        state : formValues?.state?.[0]
    }
}

const getRequestPayload = (formVal = {})=>{
    let requestBody = {};
    const formData = new FormData();
    const formValues = getSanitizedValues(formVal)
    Object.keys(formValues).map((key)=>{
        let field = CREATE_ACCOUNT_FIELD[key];
        requestBody[field] = formValues[key];
        if(Array.isArray(formValues[key]))
        {
            formData.set(key,JSON.stringify(formValues[key]));
        }
        else
        {
            formData.set(key,formValues[key]);
        }
        return {
            [field] : formValues[key] 
        }
    });

    return formData;

}

export {
    getRequestPayload,
    getSanitizedValues
}