import React, { useState } from "react";

import styles from "./accountSetup.module.css";
import { OverlayTrigger } from "react-bootstrap";
import TooltipContent from "./components/TooltipContent";


const AccountSetup = ({handleDisplayIndividualInfo, handleModalDisplay = ()=>{}}) =>{
    
    return (
        <>
            <div className={styles.container}>
                <div className={styles.textContainer}>
                    Select the option that best describes your role in the healthcare ecosystem.
                </div>
                <div className={styles.setupActions}>
                    <div className={styles.setupButtonContainer} onClick={handleModalDisplay}>
                        <div className={styles.setupButton}>
                            Setup
                        </div>
                    </div>
                    <button type="link" className={styles.linkButton}>
                        I'm an individual practictitioner
                    </button>
                    <OverlayTrigger       
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={TooltipContent }
                    >
                        <img className={styles.infoIcon} src={require("../../../../../../../assets/images/info_icon.svg").default} />
                    </OverlayTrigger>
                </div>
                <button className={styles.infoButton} onClick={handleDisplayIndividualInfo}>
                    What’s the difference? Know more about group & individual practictitioner
                    <img src={require("./../../../../../../../assets/images/redirect_arrow.svg").default} className={styles.redirectArrow} />
                </button>
            </div>
        </>
    )
}

export default AccountSetup