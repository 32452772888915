import {forEach} from "lodash";

const getWebsiteCheckAction = (website)=>{
    let urlRegex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+)\.([a-zA-Z]{2,})(\/[^\s]*)?$/;

    return urlRegex.test(website);
}

const hasFields = (fields=[],data)=>{
    let check = true;
    forEach(fields,(field)=>{
        if(!data[field]){
            check=false;
        }
    });
    return check;
}

export {
    getWebsiteCheckAction,
    hasFields
}