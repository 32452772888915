import React from "react";

import { getRespectiveStroke, getRespectiveSvg, getRespectiveViewBox } from "./rangeSlider.component";

import styles from "./rangeSlider.module.css"

const RangeSlider = ({val, glucoseColor, ...rest}) =>{
    return (
        <div className={styles.container}>
            <svg xmlns="http://www.w3.org/2000/svg" width="63" height="42"  viewBox={getRespectiveViewBox(val)} fill="none">
                {getRespectiveSvg({val,glucoseColor})}
                <text fontFamily="Founders Grotesk" fontWeight={"500"} fontSize="14.654px" x="52%" y="65%" text-anchor="middle" stroke={getRespectiveStroke(glucoseColor)} stroke-width="1px" dy="
                .3em">
                    {val}
                </text>
                <text fontFamily="Founders Grotesk" fontWeight={"500"}  font-size="7.327px" x="52%" y="92%" text-anchor="middle" stroke="black">
                    mg/dL
                </text>
            </svg>
            <div className={styles.averageText}>
                Average
            </div>
        </div>
    )
}

export default RangeSlider;