import React from "react";

import styles from "./../accountSetup.module.css";
import { Tooltip } from "react-bootstrap";

const TooltipContent = (props) =>{
    return (
        <Tooltip id="button-tooltip" {...props}>
                <div className={styles.tooltipContent}>
                    For solo medical professionals managing their practice independently.
                </div>
                <div className={styles.tooltipLabel}>
                    Know More
                </div>
        </Tooltip>
    )
}

export default TooltipContent;