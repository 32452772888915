import styles from "./search.module.css"

const Search = () => {

    return (

        <div className={styles.searchContainer}>
            <div className={styles.search}>
                <input placeholder="Search by Practice Name, Email ID" className={styles.searchBar}></input>
                <img src={require("../../../../../assets/images/search-icon.svg").default} className={styles.searchLogo}></img>
            </div>
            <div className={styles.rightActions}>
                <div className={styles.dropdown}>
                    <select className={styles.fieldValueDropdown}>
                        <option className={styles.fieldValueDropdown}>All Divisions</option>
                    </select>
                    <img className={styles.dropdownArrow} src={require("../../../../../assets/images/dropdown_arrow.svg").default}></img>
                </div>

                <div className={styles.dropdown}>
                    <select className={styles.fieldValueDropdown}>
                        <option className={styles.fieldValueDropdown}>All Roles</option>
                    </select>
                    <img className={styles.dropdownArrow} src={require("../../../../../assets/images/dropdown_arrow.svg").default}></img>
                </div>
            </div>

        </div>

    )
}

export default Search;