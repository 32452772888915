import { ERROR_STATUS } from "../../../../constants/errorStatus"
import InputWithPrefix from "../../../../molecules/InputWithPrefix"
import InputWithSuffix from "../../../../molecules/InputWithSuffix"
import LabeledSingleSelect from "../../../../molecules/LabeledSingleSelect/LabeledSingleSelect"

const getCurrentFormFields = ()=>{
    return [[{
        label:"Website",
        name: "website",
        fieldType:"text",
        PlaceHolder:"www.website.com",
        errorTypes:[{type:ERROR_STATUS.IS_REQUIRED}],
        onFocus:()=>{},
        onBlur:()=>{},
    }],
    [
        {
            label:"Worksite name",
            name: "websiteName",
            fieldType:"text",
            PlaceHolder:"Enter worksite name",
            errorTypes:[{type:ERROR_STATUS.IS_REQUIRED}],
            onFocus:()=>{},
            onBlur:()=>{},
        },
        {
            label:"Are you the owner of the website",
            name: "websiteOwner",
            CustomInput : LabeledSingleSelect,
            options : [{id:1, option: "Yes"},{id:2, option: "No"}],
            onFocus:()=>{},
            onBlur:()=>{},
        }
    ],
    [
        {
            label:"Worksite Address 1",
            name: "websiteAddressOne",
            fieldType:"text",
            errorTypes:[{type:ERROR_STATUS.IS_REQUIRED}],
            PlaceHolder:"Enter Street address, P.O. box, company name, c/o",
            onFocus:()=>{},
            onBlur:()=>{},
        },
        {
            label:"Worksite Address 2",
            name: "websiteAddressTwo",
            fieldType:"text",
            errorTypes:[{type:ERROR_STATUS.IS_REQUIRED}],
            PlaceHolder:"Enter Apartment, suite, unit, building, floor, etc. (If applicable)",
            onFocus:()=>{},
            onBlur:()=>{},
        },
        {
            label:"Worksite Email",
            name: "websiteEmail",
            fieldType:"text",
            errorTypes:[{type:ERROR_STATUS.IS_REQUIRED}],
            PlaceHolder:"Enter Worksite Email",
            onFocus:()=>{},
            onBlur:()=>{},
        },
        {
            label:"Contact Number",
            name: "ContactNumber",
            CustomInput: InputWithSuffix,
            errorTypes:[{type:ERROR_STATUS.IS_REQUIRED}],
            PlaceHolder: "Enter worksite number",
            onFocus:()=>{},
            onBlur:()=>{},
        },
    ]
]
}

export { getCurrentFormFields}