import { useState } from "react";

import { getTextInitials } from "../../../../helpers/TextManipulations.helpers";
import { getMoveToArchieveAction } from "./heading.async";

import styles from "./heading.module.css";

const Heading = ({coachDetails={}}) => {

    const [options, setOptions] = useState(false);

    const {firstName="",lastName =""} = coachDetails;

    const handleMoveToArchieveAction = ()=>{
        getMoveToArchieveAction()
    }

    return (

        <div className={styles.heading}>
            <div className={styles.practiceLogo}>
                {getTextInitials(firstName+" "+lastName)}
            </div>
            <div className={styles.practiceName}>
                {firstName+" "+lastName}
            </div>
            <div className={styles.buttonGroup}>
                <div className={styles.buttonExport} onClick={()=>{setOptions(!options)}}><img src={require("../../../../../assets/images/more_options.svg").default}/></div>
                {options && <div className={styles.moveToArchive} onClick={handleMoveToArchieveAction}>
                    <div className={styles.moveToArchiveContainer}>Move to Archive</div>
                    <div className={styles.moveToArchiveSpace}></div>
                </div>}
            </div>
        </div>
    )
}

export default Heading;