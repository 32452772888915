import React, { useEffect, useState } from "react";

import { PATIENT_INVITE_CONNECT } from "../../../../patientInviteConnect.constants";
import FormField from "../../../../../../reusables/formManager/components/formField";
import { getTermsAndConditionsField } from "../../../../patientInviteConnect.field";

import styles from "./body.module.css"
import withError from "../../../../../../hoc/withError";
import StyledButton from "../../../../../../molecules/baseComponents/buttons/styledButton";
import BorderedButton from "../../../../../../molecules/baseComponents/buttons/borderedButton/BorderedButton";

const Body = ({patientName = "You ",coachName="Medical Group", fieldError, patientInviteAction, handleFieldChange = ()=>()=>{}, ...rest}) =>{

    const [consentField, setConcentField] = useState(fieldError?.concent && fieldError?.concent?.IS_CHECKED ? {IS_CHECKED : "Please check the consent form to accept the invitation"} : {});

    useEffect(()=>{
        setConcentField(()=>{
            return fieldError?.concent && fieldError?.concent?.IS_CHECKED ? {IS_CHECKED : "Please check the consent form to accept the invitation"} : {}
        })
    },[fieldError?.concent, fieldError?.concent?.IS_CHECKED])

    const handleAcceptButtonAction = ()=>{
        if( fieldError?.concent && !fieldError?.concent?.IS_CHECKED)
            patientInviteAction("ACCEPT");
        else
            setConcentField({IS_CHECKED : "Please check the consent form to accept the invitation"})
    }

    const handleRejectButtonAction = ()=>{
        patientInviteAction("REJECT");
    }

    return (
        <div className={styles.container}>
            <div className={styles.upperContainer}>
                <div className={styles.primaryText}>
                    <span className={styles.infoText}>{patientName}</span> {PATIENT_INVITE_CONNECT.PRIMARY_TEXT.INVITE_TEXT}<span className={styles.infoText}>{coachName}</span>{PATIENT_INVITE_CONNECT.PRIMARY_TEXT.RENEWRX_TEXT}
                </div>
                <div>
                    {PATIENT_INVITE_CONNECT.BODY_SECONDARY_TEXT}
                </div>
            </div>
            <FormField value={""} {...getTermsAndConditionsField()} {...rest} fieldError={consentField} handleFieldChange={handleFieldChange}/>
            <div className={styles.buttonContainer}>
                <StyledButton className={styles.basicButton} content={"Accept Invitation"} handleButtonAction={handleAcceptButtonAction} />
                <BorderedButton className={styles.basicButton} handleClickAction={handleRejectButtonAction} content={"Decline"} />
            </div>
        </div>
    )
}

export default withError({Component : Body});