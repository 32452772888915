import React, { useState } from "react";
import { BASE_URL } from "../../../constants/api";
import { getSubmitFormattedData } from "../practiceManagement.helpers";

const usePracticeForm = (setIsLoading)=>{
    const [formValues, setFormValues] = useState({
        practiceCountry : "United States of America",
        practiceType : "Private Practice"
    });

    const submitFormValues = async()=>{
        try{
            setIsLoading(true);
            let requestData = getSubmitFormattedData(formValues);
            const response = await fetch(`${BASE_URL}/dashboard/admin/api/rrxsuperboss/users/addNewPractice`,{
                method : "POST",
                headers:{
                    'content-type':'application/json',
                    "authorization" : "Bearer " + window?.localStorage?.getItem("Authorization"),
                },
                body:JSON.stringify(requestData),
            });
            setIsLoading(false);
            return response.status == '201' || response.status == '200' || response.status == '204'    
        }
        catch(e){
            setIsLoading(false);
            console.log('ERROR IS : ',e);
        }
    }

    const updateFormValues = ({type, value})=>{
        setFormValues({
            ...formValues,
            [type]: value
        })
    }

    const resetForm= ()=>{
        setFormValues({
            practiceCountry : "United States of America",
            practiceType : "Private Practice"
        });
    }

    return [
        formValues,
        submitFormValues,
        updateFormValues,
        resetForm
    ]
}

export default usePracticeForm;