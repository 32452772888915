import styles from "./overview.module.css";

const Overview = ({ practiceData, worksiteList }) => {

    const { practiceName = "", practiceWebsite = "", practiceEmail = "", practiceType = "", practiceAdminName = "", practiceAdminEmail = "", practiceAdminPhoneNumber = "" } = practiceData || {};

    return (
        <div className={styles.mainContainer}>

            <div className={styles.practiceInfo}>
                <div className={styles.container}>
                    <div className={styles.practiceDetail}>Practice Detail</div>
                    <div className={styles.fields}>
                        <div className={styles.field}>
                            <div className={styles.fieldType}>Practice Name</div>
                            <div className={styles.fieldValue}>{practiceName}</div>
                        </div>
                        <div className={styles.field}>
                            <div className={styles.fieldType}>Practice Type</div>
                            <div className={styles.fieldValue}>{practiceType}</div>
                        </div>
                        <div className={styles.field}>
                            <div className={styles.fieldType}>Website</div>
                            <div className={styles.fieldValue}>{practiceWebsite}</div>
                        </div>
                    </div>

                    <div className={styles.practiceEdit}>Edit</div>
                </div>

                <div className={styles.container}>
                    <div className={styles.practiceDetail}>Admin Detail</div>
                    <div className={styles.fields}>
                        <div className={styles.field}>
                            <div className={styles.fieldType}>Admin</div>
                            <div className={styles.fieldValue}>{practiceAdminName}</div>
                        </div>
                        <div className={styles.field}>
                            <div className={styles.fieldType}>Email</div>
                            <div className={styles.fieldValue}>{practiceAdminEmail}</div>
                        </div>
                        <div className={styles.field}>
                            <div className={styles.fieldType}>Contact Number</div>
                            {practiceAdminPhoneNumber === "" ? <div className={styles.contactNumber}>Add number</div> :
                                <div className={styles.fieldValue}>{practiceAdminPhoneNumber}</div>}
                        </div>
                    </div>

                    <div className={styles.practiceEdit}>Edit</div>
                </div>
            </div>

            {worksiteList.length >= 1 && <div className={styles.allWorksites}>All Worksites</div>}

            {worksiteList.length >= 1 &&
                <div className={styles.practiceInfo}>{
                    worksiteList.map((item, index) => {
                        const { country = "", address = "", addressLineTwo = "", state = "", city = "", zipcode = "", divisionAdminName = "", autoGeneratedDivisionId = "" } = item || {};
                        if(worksiteList.length%2==0)
                        return (

                            <div className={styles.container}>
                                <div className={styles.worksiteAndDivisionIdContainer}>
                                    <div className={styles.practiceDetail}>Worksite {index + 1}</div>
                                    <div className={styles.worksiteId}>{autoGeneratedDivisionId}</div>
                                </div>
                                <div className={styles.fields}>
                                    <div className={styles.field}>
                                        <div className={styles.fieldType}>Country</div>
                                        <div className={styles.fieldValue}>{country}</div>
                                    </div>
                                    <div className={styles.field}>
                                        <div className={styles.fieldType}>Address 1</div>
                                        <div className={styles.fieldValue}>{address}</div>
                                    </div>
                                    <div className={styles.field}>
                                        <div className={styles.fieldType}>Address 2</div>
                                        <div className={styles.fieldValue}>{addressLineTwo}</div>
                                    </div>
                                    <div className={styles.field}>
                                        <div className={styles.fieldType}>State</div>
                                        <div className={styles.fieldValue}>{state}</div>
                                    </div>
                                    <div className={styles.field}>
                                        <div className={styles.fieldType}>City</div>
                                        <div className={styles.fieldValue}>{city}</div>
                                    </div>
                                    <div className={styles.field}>
                                        <div className={styles.fieldType}>Zipcode</div>
                                        <div className={styles.fieldValue}>{zipcode}</div>
                                    </div>
                                    <div className={styles.field}>
                                        <div className={styles.fieldType}>Worksite Admin</div>
                                        <div className={styles.contactNumber}>{divisionAdminName}</div>
                                    </div>
                                    <div className={styles.buttonGroup}>
                                        <div className={styles.practiceEdit}>Edit</div>
                                        <div className={styles.practiceDelete}>Delete Worksite</div>
                                    </div>
                                </div>
                            </div>

                        )
                        else
                        return(
                            <div className={styles.worksite}>
                            <div className={styles.worksiteHeadingContainer}>
                                <div className={styles.worksiteHeading}>Worksite {index+1}</div>
                                <div className={styles.worksiteId}>{autoGeneratedDivisionId}</div>
                            </div>
                            <div className={styles.worksiteLayout}>
                                <div className={styles.worksiteFields}>
                                    <div className={styles.worksiteField}>
                                        <div className={styles.fieldType}>Country</div>
                                        <div className={styles.fieldValue}>{country}</div>
                                    </div>
                                    <div className={styles.worksiteField}>
                                        <div className={styles.fieldType}>Address 2</div>
                                        <div className={styles.fieldValue}>{addressLineTwo}</div>
                                    </div>
                                    <div className={styles.worksiteField}>
                                        <div className={styles.fieldType}>City</div>
                                        <div className={styles.fieldValue}>{city}</div>
                                    </div>
                                    <div className={styles.worksiteField}>
                                        <div className={styles.fieldType}>Worksite Admin</div>
                                        <div className={styles.contactNumber}>{divisionAdminName}</div>
                                    </div>
                                </div>
        
                                <div className={styles.worksiteFieldsSecondary}>
                                    <div className={styles.worksiteField} >
                                        <div className={styles.fieldType}>Address 1</div>
                                        <div className={styles.fieldValue}>{address}</div>
                                    </div>
                                    <div className={styles.worksiteField}>
                                        <div className={styles.fieldType}>State</div>
                                        <div className={styles.fieldValue}>{state}</div>
                                    </div>
                                    <div className={styles.worksiteField}>
                                        <div className={styles.fieldType}>Zipcode</div>
                                        <div className={styles.fieldValue}>{zipcode}</div>
                                    </div>
                                </div>
        
                            </div>
                            <div className={styles.practiceEdit}>Edit</div>
                        </div>
                    )
                    })
                }</div>
            }



        </div>


    )
}

export default Overview;