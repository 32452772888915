import React, {useEffect} from "react";

import withActionState from "../../../../../../hooks/withActionState";
import {Spinner} from "react-bootstrap";

import actions from "./bloodGlucosePopup.actions";
import ContentBody from "./../Popup/Body";

import styles from "./bloodGlucosePopup.module.css"

import actionTypes from "./bloodGlucosePopup.actionTypes";
import { getFormattedGlucoseData, getFormattedMealLogs } from "./bloodGlucosePopup.helpers";
import GlucoseDataChart from "../GlucoseDataChart";

const BloodGlucosePopup = ({ modalMetrics , handleTabChange,mealLogs, glucoseData={}, dispatch=()=>{}}) =>{

    useEffect(()=>{
        dispatch({actionType: actionTypes.INIT_ACTION})
    },[]);

    if(!modalMetrics || !mealLogs){
        return <div className={styles.mainSpinner}><Spinner /></div>
    }

    const {glucoseChartData, offset} = getFormattedGlucoseData(glucoseData);

    const formattedMealLogs = getFormattedMealLogs(mealLogs)

    return (
        <div className={styles.container}>
            <ContentBody modalMetrics={modalMetrics} handleTabChange={handleTabChange} hasButton>
                <GlucoseDataChart offset={offset} glucoseChartData={glucoseChartData} mealLogs={mealLogs} formattedMealLogs={formattedMealLogs} glucoseData={glucoseData}/>
            </ContentBody>
        </div>
    )
}

export default withActionState({Component: BloodGlucosePopup, action: actions});