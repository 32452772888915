import React,{useState} from "react";

import cx from "classnames";

import ErrorComponent from "./../../../../../../atoms/errorComponent/ErrorComponent";
import useFocus from "./../../../../../../hooks/useFocus";

import styles from "./body.module.css";
import { PIN_CODE_ARRAY } from "../../../../../../molecules/LoginPinCard/loginPinCard.constants";


const Body = ({setEnableButton, hasError = false, handleValueChange = ()=>{}})=>{

    const [currentValue,setCurrentValue] = useState(new Array(6));

    const [currentCheckBox,setCurrentCheckBox] = useState(false);

    const [currentPointer,setCurrentPointer] = useState(0);

    const [inputFocus,setInputFocus] = useState([useFocus(),useFocus(),useFocus(),useFocus(),useFocus(),useFocus()])

    const handleFieldChange =(val) => (event) =>{
        event.preventDefault();

        if(event?.target?.value == "" || event?.target?.value == undefined)
        {
            setCurrentValue((prev)=>{
                prev[val] = '';
                let min = prev.filter((val)=>{
                    if(val>=0 && val<10)
                    {
                        return true;
                    }
                })
                if(min.length <= 5)
                {
                    setEnableButton(false);
                }
                handleValueChange(prev)
                return prev;
            });
            setCurrentPointer(currentPointer-1);    
        }
        else{
             if(event?.target?.value?.length >1)
             {
                setCurrentValue((prev)=>{
                    let currentPoint = currentPointer;
                    let count=0;
                    while(count<event?.target?.value?.length)
                    {
                        prev[currentPoint] = event?.target?.value?.charAt(count);
                        count++;
                        currentPoint++;
                    }
                    setCurrentPointer(currentPoint%6);
                    let min = prev.filter((val)=>{
                        if(val>=0 && val<10)
                        {
                            return true;
                        }
                    })
                    if(min.length > 5)
                    {
                        setEnableButton(true);
                    }
                    handleValueChange(prev)
                    return prev;
                });
             }
             else{
                setCurrentValue((prev)=>{
                    if(event?.target?.value /10 <1)
                      prev[val] = event?.target?.value;
                    let min = prev.filter((val)=>{
                        if(val>=0 && val<10)
                        {
                            return true;
                        }
                    })
                    if(min.length > 5)
                    {
                        setEnableButton(true);
                    }
                    handleValueChange(prev)
                    return prev
                });
                setCurrentPointer(currentPointer+1);
                if(val<5)
                {
                    inputFocus[val+1][1]();
                }    
             }
        }
    }

    const handleKeyPress = () =>{}

    const handleCheckBoxChange = ()=>{
        setCurrentCheckBox(!currentCheckBox)
    }

    return (
        <div className={cx(styles.bodyContainer,styles.container)}>
            <div className={styles.mainPrimaryTextTypo}>Verification Code</div>
            <div className={styles.pinContainer}>
                <div className={styles.pinCodeMiniContainer}>
                    {PIN_CODE_ARRAY.slice(0,3).map((val)=>{
                        return (
                            <input type="number" ref={inputFocus[val][0]} value={currentValue[val]} className={styles.inputField} id={val} onChange={handleFieldChange(val)} onKeyDown={handleKeyPress} />
                        )
                    })}
                </div>
                <div className={styles.pinCodeMiniContainer}>
                    {PIN_CODE_ARRAY.slice(3,6).map((val)=>{
                        return (
                            <input type="number"  ref={inputFocus[val][0]} className={styles.inputField} value={currentValue[val]} id={val} onChange={handleFieldChange(val)} onKeyDown={handleKeyPress} />
                        )
                    })}
                </div>
            </div>
            {hasError && <ErrorComponent fieldError={{message : "Verification code in incorrect. Please try again"}}/>}
        </div>
    )
}

export default Body;