import React from "react";

import styles from "./header.module.css";
import { TERMS_AND_PRIVACY_POLICY } from "../../termsAndConditionsModal.constants";

const Header = ({CloseComponent, modalType, ...rest})=>{
    return (
        <div className={styles.container}>
            <div className={styles.textContainer}>
                <div className={styles.primaryText}>
                    {TERMS_AND_PRIVACY_POLICY[modalType].HEADER_DATA.HEADING}
                </div>
                <div className={styles.secondaryText}>
                    {TERMS_AND_PRIVACY_POLICY[modalType].HEADER_DATA.SECONDARY}
                </div>
            </div>
            <CloseComponent />
        </div>
    )
}

export default Header;