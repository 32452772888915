import React from "react";

import styles from "./header.module.css";

const Header = () =>{
    return (
        <div className={styles.container}>
            <div className={styles.primaryHeader}>
                Welcome to RenewRx 👋
            </div>
            <div className={styles.secondaryHeader}>
                Get started with monitoring with this quick setup
            </div>
        </div>
    )
}

export default Header;