
const INIT_ACTION = "INIT_ACTION";
const MOVE_TO_NEXT_PAGE = "MOVE_TO_NEXT_PAGE";
const PAGE_SUBMIT_ACTION="PAGE_SUBMIT_ACTION";
const FORM_SUBMIT_ACTION = "FORM_SUBMIT_ACTION";
const FORM_VALUES_UPDATE = "FORM_VALUES_UPDATE";
const GO_TO_PREVIOUS_PAGE = "GO_TO_PREVIOUS_PAGE";
const DISPLAY_PHONE_OTP_POPUP = "DISPLAY_PHONE_OTP_POPUP";
const SEND_PHONE_OTP = "SEND_PHONE_OTP";
const UPDATE_USER_STATUS = "UPDATE_USER_STATUS";
const FINISH_COMPLETE = 'FINISH_COMPLETE';
const TOGGLE_LOADER = "TOGGLE_LOADER";

export default {
    DISPLAY_PHONE_OTP_POPUP,
    GO_TO_PREVIOUS_PAGE,INIT_ACTION, 
    MOVE_TO_NEXT_PAGE,PAGE_SUBMIT_ACTION, 
    FORM_SUBMIT_ACTION, 
    FORM_VALUES_UPDATE,
    SEND_PHONE_OTP,
    UPDATE_USER_STATUS,
    FINISH_COMPLETE,
    TOGGLE_LOADER
}