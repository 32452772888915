import React,{useLayoutEffect, useRef, useState} from "react";
import cx from "classnames";
import {map} from "lodash"

import styles from "./customActionDropdown.module.css";
import ListContent from "./components/ListContent/ListContent";

const CustomActionDropdown = ({options,Component, className="", optionClassName="", displayDropdown=false, toggleDropDownAction = ()=>{}}) =>{
    
    const [isDropDownVisible, setIsDropDownVisible] = useState(displayDropdown);
    const [currentOptionContainerWidth, setCurrentOptionContainerWidth] = useState(0);
    const [currentOptionContainerHeight, setCurrentOptionContainerHeight] = useState(0);

    const ref = useRef();

    useLayoutEffect(()=>{
        setCurrentOptionContainerWidth(ref.current.offsetWidth);
        setCurrentOptionContainerHeight(ref.current.offsetHeight+ref.current.offsetTop+30)
    },[])

    const toggleDropdown = () =>{
        setIsDropDownVisible(!isDropDownVisible);
        toggleDropDownAction(!isDropDownVisible)
    }

    const openDropDown = () =>{
      setIsDropDownVisible(true);
      toggleDropDownAction(true);
    }
  
    const closeDropDown = () =>{
      setIsDropDownVisible(false);
      toggleDropDownAction(false);
    }

    return (
        <div className={styles.container}  onMouseEnter={openDropDown} onMouseLeave={closeDropDown}>
            <div className={cx(styles.primaryContainer, className)}>
                <Component toggleDropdown={toggleDropdown} dropdownState={isDropDownVisible} closeDropDown={closeDropDown} openDropDown={openDropDown} ref={ref}/>
            </div>
            {
                isDropDownVisible && <div className={cx( styles.optionContainer,optionClassName,{[styles.dropDownOptionsMainHidden]: !isDropDownVisible})}  style={{"minWidth": `${currentOptionContainerWidth}px`, "marginTop": `${currentOptionContainerHeight}px`}}>
                    {map(options,(option)=>{
                        return <ListContent {...option} closeDropDown={closeDropDown}/>
                    })}
                </div>
            }
        </div>
    )
}

export default CustomActionDropdown;