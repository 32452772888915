import styles from "./userSetting.module.css";

const USER_SETTING_PROFILE = {
    firstName : "First Name",
    lastName : "Last Name",
    middleName : "Middle Name",
    email : "Email",
    gender : "Gender",
    phoneNumber : "Phone Number",
    speciality: "Speciality",
    credentials : "Credentials",
}

const SIDE_TAB_OPTIONS =  (handleOptionClick)=>[
    {
        name : "setting",
        label : "Settings",
        className : styles.outerClass,
        textClassName : styles.outerClassText,
        options : [
            {
                name : "userSettings",
                label : "User Settings",
                textClassName : styles.primaryInnerClassText,
                className : styles.primaryInnerClass,
                options : [
                    {
                        name : "profile",
                        label : "Profile",
                        className : styles.secondaryClassName,
                        containerAction : handleOptionClick
                    },
                    {
                        name : "security",
                        label : "Security",
                        className : styles.secondaryClassName,
                        containerAction : handleOptionClick
                    }           
                ]
            },
            {
                name : "practiceSettings",
                label : "Practice Settings",
                className : styles.primaryInnerClass,
                textClassName : styles.primaryInnerClassText,
                options : [
                    {
                        name : "Practice Detail",
                        label : "Practice Detail",
                        className : styles.secondaryClassName,
                        containerAction : handleOptionClick
                    },
                ]
            }
        ]

    },
];

const USER_DETAILS = [
    {
        primary : 'Basic Detials',
        options : ["firstName","middleName","lastName","gender",'timezoneOffsetHours'],
    },
    {
        primary : 'Professional Details',
        options : ["speciality","credentials"],
    },
    {
        primary : 'Contact Details',
        options : ["workEmail","phone"],
    },
];

const SECURITY_DETAILS = [
    {
        primary : 'Password',
        options : ["password"],
    },
];

const PRACTICE_DETAILS = [{
    primary : "Basic details",
    options : ["practiceName","practiceWebsite"]
}]


const PROFILE_BASIC_DATA= [
    {
        label : "Basic details",
        className : styles.profileTabOption,
        textClassName : styles.profileTabOptionText,
        innerContainerClass : styles.innerContainerClass,
        options : [
            {
                label : "Profile Photo",
                value : "Abc",
                className : styles.infoTextContainer
            },
            {
                label : "First Name",
                value : "Abc",
                className : styles.infoTextContainer
            },
        ]
    },
    {
        label : "Professional details",
        className : styles.profileTabOption,
        textClassName : styles.profileTabOptionText,
        innerContainerClass : styles.innerContainerClass,
        options : [
            {
                label : "Profile Photo",
                value : "Abc",
                className : styles.infoTextContainer
            },
            {
                label : "First Name",
                value : "Abc",
                className : styles.infoTextContainer
            },
        ]
    },
    {
        label : "Contact details",
        className : styles.profileTabOption,
        textClassName : styles.profileTabOptionText,
        innerContainerClass : styles.innerContainerClass,
        options : [
            {
                label : "Profile Photo",
                value : "Abc",
                className : styles.infoTextContainer
            },
            {
                label : "First Name",
                value : "Abc",
                className : styles.infoTextContainer
            },
        ]
    }
]


const TAB_OPTION_MAPPING ={
    Profile : USER_DETAILS,
    Security : SECURITY_DETAILS,
    "Practice Detail" : PRACTICE_DETAILS
}

export {
    SIDE_TAB_OPTIONS,
    PROFILE_BASIC_DATA,
    USER_DETAILS,
    SECURITY_DETAILS,
    TAB_OPTION_MAPPING,
    USER_SETTING_PROFILE
}