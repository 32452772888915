import React from "react";
import styles from "./fields.module.css";
import hideValue from "../../../../../../assets/images/hide_value.svg";
import { useState } from "react";
import warningLogo from "../../../../../../assets/images/warning.svg"
import ErrorPopup from "../../../../../molecules/ErrorPopup/ErrorPopup";
import { ERROR_STATUS } from "../../../../../constants/errorStatus";
import { handleErrorStatus } from "../../../../../helpers/ErrorStatusCheck.helper";
import { spaceRemovalKeyDownEvent } from "../../../../../helpers/TextManipulations.helpers";

const errorTypes=[{type: ERROR_STATUS.HAS_LOWERCASE},{type: ERROR_STATUS.HAS_UPPERCASE},{type: ERROR_STATUS.HAS_NUMBERS},{type : ERROR_STATUS.MIN_LENGTH,length : 8}];

const Fields = ({ setPassword, setConfirmPassword, setIsPasswordsMatching, isPasswordsMatching, password, confirmPassword, email }) => {

    const [hidePassword, setHidePassword] = useState(true);

    const [hasPasswordBeenAction, setPasswordActive] = useState(false);
    const [passwordFieldErrors , setPasswordFieldErrors]= useState({});

    const [hideConfirmPassword, setHideConfirmPassword] = useState(true);

    const handleSetPassword = (e)=>{
        setPasswordActive(true);
        let currentFieldErrors = handleErrorStatus({value:e.target.value, errorStatus : errorTypes});
        setPasswordFieldErrors(currentFieldErrors);
        setPassword(e.target.value);
    }

    return (
        <div className={styles.container}>

            <div className={styles.field}>
                <div className={[styles.fieldName, styles.email]}>Email</div>
                <input disabled onKeyDown={spaceRemovalKeyDownEvent} className={styles.fieldValue} value={email || ""} placeholder={email}></input>
            </div>

            <div className={styles.field}>
                <div className={styles.fieldName}>Password</div>
                <div className={styles.passwordField}>
                    <input onChange={handleSetPassword} onKeyDown={spaceRemovalKeyDownEvent} type={hidePassword ? "password" : "text"} className={styles.passwordFieldValue} placeholder="Create new Password"></input>
                    <img src={hideValue} className={styles.hidePasswordIcon} onClick={() => { hidePassword ? setHidePassword(false) : setHidePassword(true) }}></img>
                    { hasPasswordBeenAction &&
                    <div className={styles.errorPopup}>
                        <ErrorPopup fieldError={passwordFieldErrors} className={styles.errorPopContainer} errorTypes={errorTypes}/>
                    </div>
                    }
                </div>
            </div>

            <div className={styles.field}>
                <div className={styles.fieldName}>Password</div>
                <div className={styles.passwordField}>
                    <input onChange={(e) => { setConfirmPassword(e.target.value); }} onKeyDown={spaceRemovalKeyDownEvent} type={hideConfirmPassword ? "password" : "text"} className={styles.passwordFieldValue} placeholder="Re-enter your Password"></input>
                    <img src={hideValue} className={styles.hidePasswordIcon} onClick={() => { hideConfirmPassword ? setHideConfirmPassword(false) : setHideConfirmPassword(true) }}></img>
                </div>
                {isPasswordsMatching &&
                    <div className={styles.notMatching}>
                        <img src={warningLogo}></img>
                        <div>Passwords do not match</div>
                    </div>
                }
            </div>

        </div>
    )
}

export default Fields;