import React from "react";
import {map} from "lodash";

import TabOption from "./../../../TabOption";

import styles from "./body.module.css";

const Body = ({options, tabClickAction }) =>{
    return (
        <div className={styles.container}>
            {map(options,({label, className, innerContainerClass, textClassName, options: tabOptions})=>{
                return (
                    <div className={className}>
                        <div className={textClassName}>
                            {label}
                        </div>
                        <div className={innerContainerClass}>
                            <div className={styles.optionsContainer}>
                                {map(tabOptions,(tabOpt)=>{
                                    return <TabOption tabClickAction={tabClickAction} {...tabOpt}/>
                                })}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default Body;