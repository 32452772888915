import React from "react";

import styles from "./header.module.css"

const Header = ({label}) =>{
    return (
        <div className={styles.container}>
            {label}
        </div>
    )
}

export default Header;