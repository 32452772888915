import React from "react";

import styles from "./body.module.css";

const Body = ({handleDisplayNotes, handleDiscrdNotesDisplay}) =>{
    return (
        <div className={styles.container}>
            <div className={styles.mainContent}>
                You haven’t finished you notes yet. Do you want to leave without finishing?
            </div>
            <div className={styles.footerContainer}>
                <div className={styles.discardButton} onClick={handleDiscrdNotesDisplay}>
                    Leave anyways
                </div>
                <div className={styles.acceptButton} onClick={handleDisplayNotes}>
                    Continue to Notes
                </div>
            </div>
        </div>
    )
}

export default Body