import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./pageHeaderContext.module.css"

const PageHeaderContext = ({coachDetails={}}) => {

    const {firstName="", lastName=""} = coachDetails;

    const navigate = useNavigate();

    const handleRrxAction = useCallback(()=>{
        navigate("/rrxCoach");
    },[navigate]);

    return (
            <div className={styles.pageHeaderContext}>
                <div className={styles.subHeading}><div onClick={handleRrxAction} className={styles.subHeadingPractice}> RRX Coach </div>/  {firstName} {lastName}</div>
            </div>
    )
}

export default PageHeaderContext;