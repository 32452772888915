import React from "react";

import BorderedButton from "../../../../../../molecules/baseComponents/buttons/borderedButton/BorderedButton";
import androidIcon from "./../../../../../../../assets/images/android_icon.svg";
import iphoneIcon from "./../../../../../../../assets/images/iphone_icon.svg";

import styles from "./acceptanceBody.module.css";
import { useNavigate } from "react-router-dom";

const AcceptanceBody = () =>{

    const handleAndriodButtonClick = ()=>{
        window.open("https://free-nylon-2f3.notion.site/Android-Installation-Guide-4256f9ad38b54399ab7838ff9a319da7?pvs=74")
    }

    const handleIphoneButtonClick = ()=>{
        window.open("https://free-nylon-2f3.notion.site/iPhone-Installation-Guide-96c6810b1931462ab4e56c4eadbddf88?pvs=74")
    }

    const getButtonContent = (image,content)=>{
        return (
            <div>
                <img src={image} className={styles.buttonIcon} />
                {content}
            </div>
        )
    }
    
    return (
        <div className={styles.container}>
            <div className={styles.headingContainer}>
                <div className={styles.headingTypography}>Invitation successfully accepted</div>
                <div>Let’s get your onboarded to the app. Choose your device type to continue</div>
            </div>
            <div className={styles.buttonContainer}>
                <BorderedButton handleClickAction={handleAndriodButtonClick} className={styles.buttonsContainer} content={getButtonContent(androidIcon,"Android")}/>
                <BorderedButton handleClickAction={handleIphoneButtonClick} className={styles.buttonsContainer} content={getButtonContent(iphoneIcon,"Iphone")}/>
            </div>
        </div>
    )
}

export default AcceptanceBody;