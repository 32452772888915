import React, { useEffect, useState } from "react";
import cx from "classnames";

import addIcon from "./../../../assets/images/add_icon.svg"
import CustomWebcam from "../CustomWebcam";

import styles from "./photoSelect.module.css";

const PhotoSelect = ({handleFieldChange,name, value,...rest}) =>{

    const [isPopupVisible,setPopupVisible] = useState(false);
    const [displayCamera,setCameraDisplay] = useState(false);
    const [currentImage,setCurrentImage] = useState(value);

    const handleFileSelect = (event)=>{
        const file = event.target.files[0];
        handleFieldChange({label:name})(file);
        setCurrentImage(URL.createObjectURL(file));
        handlePopupVisibility();
    }

    const handlePhotoRemove = ()=>{
        handleFieldChange({label : name})("");
        setCurrentImage('');
    }

    const handlePopupVisibility = () =>{
        setPopupVisible(!isPopupVisible);
    }

    const handleCameraDisplay = () =>{
        setCameraDisplay(!displayCamera)
    }

    let currentImageNew = value || addIcon

    return (
        <div className={styles.container}>
            <div className={styles.photoContainer}>
                {currentImage ? <img src={currentImage} className={styles.profileImage} /> : <>
                    <input type="file"  accept="image/*" className={styles.fileSelect} id="actual-btn" hidden onChange={handleFileSelect}/>
                    <img for="actual-btn" src={currentImageNew} className={cx(styles.addIcon,{[styles.actualImg]:value})} onClick={handlePopupVisibility} />
                </>}
            </div>
            <div className={styles.rightContainer}>
                <div className={styles.textContainer}>
                    <div className={styles.headingText}>
                        Profile Photo
                    </div>
                    <div className={styles.contextText}>
                        Max Upload size 5mb . 1:1 Ratio
                    </div>
                </div>
                <div className={styles.uploadContainer}>
                    <div className={styles.uploadText} onClick={handlePopupVisibility}>
                        {currentImage ? 'Change Photo' : 'Upload'}
                    </div>
                    { currentImage && <div className={styles.removePhoto} onClick={handlePhotoRemove}>
                            Remove
                        </div>
                    }
                </div>
                {
                    isPopupVisible && <div className={styles.popupContainer}>
                        <div className={styles.popupOptions}>
                            <input type="file"  accept="image/*" className={styles.fileSelect} id="actual-btn" hidden onChange={handleFileSelect}/>
                            <label for="actual-btn">UploadFile</label>
                        </div>
                        </div>
                }
                {displayCamera && <CustomWebcam name={name} handleFieldChange={handleFieldChange} displayCamera={displayCamera} setCameraDisplay={handleCameraDisplay} />}
            </div>
        </div>
    )
}

export default PhotoSelect;