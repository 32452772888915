import React from "react";

import styles from "./home.module.css"
import UserDashboard from "../../organisms/UserDashboard/UserDashboard";

const HomePage = () =>{
    return (
        <div className={styles.container}>
            <UserDashboard />
        </div>
    )
}

export default HomePage;