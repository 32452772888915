import React from "react";

import styles from "./body.module.css"
import FormManager from "../../../../reusables/formManager/FormManager";

const Body = ({content, ...rest}) =>{
    return (
        <div className={styles.container}>
            {content && <div className={styles.textContainer}>
                {content}
            </div>}
            <FormManager {...rest}/>
        </div>
    )
}

export default Body;