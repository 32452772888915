import React from "react";

import BasicUserProfile from "./../../../BasicUserProfile";

import styles from "./patientInfo.module.css";

const PatientInfo = ({patientDetails}) =>{
    return (
        <div className={styles.container}>
            <BasicUserProfile options={patientDetails} />
        </div>
    )
}

export default PatientInfo;