import lowIcon from "./../../../../../../../../../../../assets/images/criticleSquare.svg";
import highIcon from "./../../../../../../../../../../../assets/images/exclamation/yellowFilledExclamation.svg";

const COLOR_MAPPINGS ={
    RED : lowIcon,
    YELLOW : highIcon,
}

export {
    COLOR_MAPPINGS
}