

const getValueOnKeyBasis = (data={},type="") =>{
    let result={};
    Object.keys(data).forEach((key)=>{
        if(key.includes(type))
        {
            result=data[key];
        }
    })
    return result;
}

const getBasicMapDataFormatter= (data=[])=>{
    const newData={};
    data?.forEach((key)=>{
        const { metricRequest = {} , metricsNameGlucoseDetailsMap } = key || {};
        const { metricType="", mealType="" } = metricRequest;
        newData[`${metricType} ${mealType}`]={
            below : getValueOnKeyBasis(metricsNameGlucoseDetailsMap,"_LESS_THAN"),
            inRange : getValueOnKeyBasis(metricsNameGlucoseDetailsMap,"_BETWEEN"),
            above : getValueOnKeyBasis(metricsNameGlucoseDetailsMap,"_MORE_THAN"),
        };
    })
    return newData;
}



export {
    getBasicMapDataFormatter,
}