const METRIC_COPY_PREFIX = {
    LOGIC_BASED : "•",
    USER_LOG_BASED : "*"
}

const TAB_OPTIONS = {
    HYBRID : "Hybrid" ,
    CGM : "CGM",
    BGM : "BGM"
} 

export {
    METRIC_COPY_PREFIX,
    TAB_OPTIONS
}