import React from "react";

import LeftHeaderContainer from "./LeftHeaderContainer";
import RightHeaderContainer from "./RightHeaderContainer";

import styles from "./header.module.css";

const Header = ({buttonProps = {},rightHeaderText}) =>{
    return (
        <div className={styles.header}>
            <LeftHeaderContainer />
            <RightHeaderContainer rightHeaderText={rightHeaderText} buttonProps={buttonProps} />
        </div>
    )
}

export default Header;