import React from "react";

import styles from "./header.module.css";
import WeeklyFilter from "../../../../../WeeklyFilter";
import { getWeeklyFormattedWeeklyDates } from "../../../../../../../../../../helpers/dateFormatter";

const Header = ({
    getFilterChangeAction= ()=>{},
    filterDuration
    }) =>{
    return (
        <div className={styles.container}>
            <div className={styles.maintext}>
                Balanced Plate
            </div>
            <div className={styles.filterContainer}>
                <WeeklyFilter 
                    name={"current"}
                    previous={filterDuration}
                    handlePrimaryDurationChange={getFilterChangeAction}
                    {...getWeeklyFormattedWeeklyDates({prev:filterDuration,format:"ddd, MMM DD"})}
                />
            </div>
        </div>
    )
}

export default Header;