import React, { useMemo } from "react";

import { getModalBody, getModalFooter,getModalHeader } from "./glucoseLevelDisplayModal.components";
import ModalContainer from "../../molecules/modals/Modal.container";

import styles from "./glucoseLevelDisplayModal.module.css";

const GlucoseLevelDisplayModal = ({postPrandial = {},timeInRange, isLoading = false, day,... props})=>{
    return (
        <ModalContainer
            HeaderComponent={getModalHeader({day})} 
            BodyComponent={getModalBody(postPrandial?.metricsResponses, timeInRange, isLoading)} 
            FooterComponent={getModalFooter()}
            className={styles.alignLeft}
            {...props}
        />
    )
}

export default GlucoseLevelDisplayModal;