import React from "react";

import styles from "./header.module.css";

const Header = ({logo}) =>{
    return (
        <div className={styles.container}>
            <img src={logo} className={styles.inviteImg}/>
        </div>
    )
}

export default Header;