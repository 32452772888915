import React, { useState } from "react";
import cx from "classnames";
import {map} from "lodash";
import { useNavigate } from "react-router-dom";

import styles from './sidebarTabs.module.css';

const SidebarTabs = ({tabs, activeTab,handleTabChange}) =>{

    const [isHovered, setHovered] = useState("");

    const navigate = useNavigate();

    const handleIconHover = (label)=>()=>{
        setHovered(label);
    }

    const handleIconOut = () =>{
        setHovered("");
    }

    const handlePageNavigation = (link,name) =>()=>{
        handleTabChange(name)
        navigate(link);
    }
    
    return (
        <div className={styles.container}>
            {
                map(tabs,({ImageIcon,label,CustomIcon,onClick,link,name})=>{
                    return (
                        <div className={styles.tabContainer} onClick={link ? handleTabChange(name,link) : onClick} onMouseEnter={handleIconHover(label)} onMouseLeave={handleIconOut}>
                            {CustomIcon ? CustomIcon : <>
                                <div className={cx(styles.iconInnerContainer,{[styles.activeIconContainer]: activeTab == name,[styles.iconHover]: isHovered==label && activeTab!=name, [styles.activeHovered]: isHovered==label && activeTab==name})}>
                                    <ImageIcon fill={activeTab == name ? ( isHovered==label ? "#163C33" :"#2A8466") : ( isHovered==label ? "#F3F2F8" :"#C5B9F4")} />
                                </div>
                                <label className={cx(styles.iconText,{[styles.activeLabel]: activeTab==name,[styles.hovered]: isHovered==label })}>
                                    {label}
                                </label>
                            </>}
                        </div>
                    )
                })
            }
        </div>
    )
}

export default SidebarTabs;