import FormManager from "../../../../reusables/formManager/FormManager";

import styles from "./createNewPracticeForm.module.css";

const getCurrentForm= (currentFormFields,handleFormValueChange, handleFormSubmit, handlePageSubmit)=>{
    return [
        {
            Component:FormManager,
            formHeaderProps:{
                primaryHeaderText: "Basic Details",
                containerClassname: styles.formHeaderProps
            },
            formContent : currentFormFields[0],
            formFooterProps:{
                butttonText : "Continue",
                enabledClassname: styles.enabledClassname
            },
            formBodyClassname: styles.formBodyContainer,
            formContainerProps:{
                containerClassname : styles.formContainer
            },
            handleValueChange: (payload)=>{
                handleFormValueChange(payload);
            },
            formSubmitAction: (payload)=> {
                handlePageSubmit(payload);
                // dispatch({actionType:actionType.MOVE_TO_NEXT_PAGE})
            },
        },
        {
            Component:FormManager,
            formHeaderProps:{
                primaryHeaderText: "Worksite Detail",
                containerClassname: styles.formHeaderProps
            },
            formContent : currentFormFields[1],
            formFooterProps:{
                butttonText : "Continue",
                enabledClassname: styles.enabledClassname
            },
            formBodyClassname: styles.formBodyContainer,
            formContainerProps:{
                containerClassname : styles.formContainer
            },
            handleValueChange: (payload)=>{
                handleFormValueChange(payload);
            },
            formSubmitAction: (payload)=> {
                handlePageSubmit(payload);
                // dispatch({actionType:actionType.MOVE_TO_NEXT_PAGE})
            },
        },
        {
            Component:FormManager,
            formHeaderProps:{
                primaryHeaderText: "Contact Detail",
                containerClassname: styles.formHeaderProps
            },
            formContent : currentFormFields[2],
            formFooterProps:{
                butttonText : "Finish",
                enabledClassname: styles.enabledClassname
            },
            formBodyClassname: styles.formBodyContainer,
            formContainerProps:{
                containerClassname : styles.formContainer
            },
            handleValueChange: (payload)=>{
                handleFormValueChange(payload);
            },
            formSubmitAction: (payload)=> {
                handleFormSubmit(payload);
                // dispatch({actionType:actionType.MOVE_TO_NEXT_PAGE})
            },
        }
    ]
}

export { getCurrentForm}