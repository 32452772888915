import { map, reduce } from "lodash"
import { MAPPED_TYPES, MAPPED_TYPES_SUFFIX, NUTRIENT_ORDER, SPIKE_ANALYSIS_METRICS } from "./modalBody.constants"

import styles from "./modalBody.module.css";

const COLOR_TYPES={
  RED: styles.redText,
  GREEN: styles.greenText,
  YELLOW: styles.yellowText
}

const getRespectiveMicroNutrients = (data={}) =>{
    const nutrients = map(NUTRIENT_ORDER, (key) => {
    return {
      type: MAPPED_TYPES?.[key],
      value: data?.[key]?.value,
      suffix: MAPPED_TYPES_SUFFIX?.[key],
      fallbackText: "No Data",
      fallbackClass : styles.fallback,
    };
  });

  return nutrients;
}

const getMetricTypeToKey = (data)=>{
  return reduce(data,(prev,innerData)=>{
    const {metricRequest={}, glucoseDetails} = innerData;
    const {metricType="" }= metricRequest;
    return {
      ...prev,
      [metricRequest?.metricType] : {
        metricRequest,
        glucoseDetails
      }
    }
  })
}

const getRespectiveSpikeAnalysis = (data=[]) =>{
  return map(Object.keys(SPIKE_ANALYSIS_METRICS),(key)=>{
    return {
      type: SPIKE_ANALYSIS_METRICS?.[key],
      value : data?.filter((val)=> val?.metricRequest?.metricType == key )[0]?.glucoseDetails?.glucose && Math.round(data?.filter((val)=> val?.metricRequest?.metricType == key )[0]?.glucoseDetails?.glucose || 0),
      suffix : "mg/dL",
      mainMetrics : data?.filter((val)=> val?.metricRequest?.metricType == key )[0],
      textClassName: COLOR_TYPES?.[data?.filter((val)=> val?.metricRequest?.metricType == key )[0]?.glucoseDetails?.['glucose-color']?.split("_")?.[0]]
    }
  })
}

export {
    getRespectiveMicroNutrients,
    getRespectiveSpikeAnalysis
}