import React, { useCallback, useState } from "react";
import cx from "classnames";
import { useNavigate } from "react-router-dom";

import SidebarTabs from "./components/SidebarTabs";
import { getCommonSidebarTabs } from "./commonSidebar.tabs";

import styles from "./commonSidebar.module.css";

const CommonSidebar = ({innerContainerClass="", childComponentContainer="", customTabs, handleSubscriberUpdate=()=>{},...props}) =>{

    const [activeTab, setActiveTab] = useState("invitePatient");
    const [subscriber, setSubscriber] = useState({
        callback: null
    });

    const navigate = useNavigate();

    const tabs = customTabs || getCommonSidebarTabs();

    const handleSetSubscriber = useCallback((callback)=>{
        setSubscriber((prev)=>({
            callback
        }))
    },[setSubscriber])

    const handleTabChange = (name,link)=>()=>{
        if(subscriber?.callback){
            subscriber?.callback(function(){
                setActiveTab(name);
                navigate(link)
            })
            return;
        }
        navigate(link);
        setActiveTab(name)
    }

    const renderChildren = useCallback(()=>{
        return React.Children.map(props.children,(child)=>{
            return React.cloneElement(child,{
                handleSetSubscriber,
                handleSubscriberUpdate
            })
        })
    },[props?.children])

    return (
        <div className={styles.sideBarOuterContainer}>
            <div className={`${styles.container} ${innerContainerClass}`}>
                <div className={styles.mainLogo}>
                    <img src={require("../../../assets/images/logo.svg").default} className={styles.mainIcon}/>
                </div>
                <div className={styles.mainSideBar}>
                    <SidebarTabs activeTab={activeTab} handleTabChange={handleTabChange} tabs={tabs} {...props} />
                </div>
            </div>
            <div className={`${styles.childComponents} ${childComponentContainer}`}>
                {renderChildren()}
            </div>
        </div>
    )
}

export default CommonSidebar