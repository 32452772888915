import React from "react";

import Header from "./components/Header/Header";
import Body from "./components/Body/Body";
import { PROFILE_BASIC_DATA } from "./../userSetting/userSetting.constants";

import styles from "./basicUserProfile.module.css"

const Profile = ({options = PROFILE_BASIC_DATA, headerProps, tabClickAction, ...rest}) =>{
    return (
        <div className={styles.container}>
            <Header {...headerProps}/>
            <Body options={options} tabClickAction={tabClickAction} {...rest}/>
        </div>
    )
}

export default Profile;