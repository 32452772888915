import React from "react";

import styles from "./dateDisplay.module.css"

const DateDisplay = ({startDate, endDate})=>{
    return (
        <div className={styles.container}>
            <span>{startDate}</span>
            <span>-</span>
            <span>{endDate}</span>
        </div>
    )
}

export default DateDisplay;