import React from "react";

import styles from "./header.module.css";

const Header = ({labelPrefix="a",label="abc",RedirectComponent="->"}) =>{
    return (
        <div className={styles.container}>
            <div className={styles.leftContainer}>
                <span>
                    {labelPrefix}
                </span>
                <span>
                    {label}
                </span>
            </div>
            <div className={styles.rightContainer}>
                {RedirectComponent}
            </div>
        </div>
    )
}

export default Header;