import styles from "./averagePlateSummary.module.css";

const AVERAGE_PLATE_SUMMARY_STYLES_MAPPING = {
    UNBALANCED: styles.unbalancedPlate,
    BALANCED: styles.balancedPlate,
    CRITICAL: styles.criticalPlate
}

export {
    AVERAGE_PLATE_SUMMARY_STYLES_MAPPING
}