import React from "react";
import cx from "classnames"

import { Spinner } from "react-bootstrap";

import styles from "./spinner.module.css";

const DefaultSpinner = ({className})=>{
    return (
        <div className={cx(styles.container,className)}>
            <Spinner />
        </div>
    )
}

export default DefaultSpinner;