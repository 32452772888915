import React from "react";

import MainContainer from "../../../commonHeader/containers/mainContainer";
import CommonSidebar from "../../../commonSidebar/CommonSidebar";

import styles from "./infoCard.module.css";

const InfoCard = ({customTabs,...rest}) =>{
    return (
        <div className={styles.container}>
            <CommonSidebar customTabs={customTabs} {...rest} innerContainerClass={styles.sideBarClass} childComponentContainer={styles.sideBarChildComponents}>
                <div className={styles.navbar}>
                    <MainContainer />
                </div>
            </CommonSidebar>
        </div>
    )
}

export default InfoCard