import React from "react";
import cx from "classnames";

import styles from "./comparisonAnalysisTable.module.css";
import DateDisplay from "../../../../../../../../atoms/dateDisplay/DateDisplay";
import { getWeeklyFormattedWeeklyDates } from "../../../../../../../../helpers/dateFormatter";
import WeeklyFilter from "../../../WeeklyFilter";

const getTableFields = (handleProgressMonitoringDurationChange, filterDate=1) =>{
    return [{
        id : 1,
        label: "Timings",
        name : "timing",
        className : cx(styles.tableColumnContainer, styles.primaryColumn),
        innerClassName : styles.innerClassName
    },
    {
        id : 2,
        name : "gant2",
        label : <DateDisplay {...getWeeklyFormattedWeeklyDates({prev:0,format:"MMM DD"})}/>,
        className : cx(styles.tableColumnContainer, styles.columnDateContainer)
    },
    {
        id : 3,
        name : "gant1",
        label : <WeeklyFilter name={"comparisonAnalysis"} handlePrimaryDurationChange={handleProgressMonitoringDurationChange} previous={filterDate} {...getWeeklyFormattedWeeklyDates({prev:filterDate,format:"MMM DD"})}/>,
        className : cx(styles.tableColumnContainer, styles.columnDateContainer)
    },
]
}

export {
    getTableFields,
}