import React, { useEffect, useState} from "react";

import styles from "./basicToast.module.css";
import Body from "./components/Body";

const BasicToast = ({message="",handleToastToggle=()=>{},display=true, displayIcon=true,  type="success", autoHide= true, delay=2000})=>{
    const [toastDisplay,setToastDisplay] = useState(display);

    useEffect(()=>{
        if(autoHide)
        {
            setTimeout(()=>{
                setToastDisplay(false);
                handleToastToggle();
            },delay)
        }
    },[display])

    return (
        <div className={styles.container}>
            {
                toastDisplay && <Body message={message} type={type} displayIcon={displayIcon} />
            }
        </div>
    )
}

export default BasicToast;