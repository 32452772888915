import React from "react";
import { useState } from "react";

import { useNavigate } from "react-router-dom";

const withActionState = ({Component,action={}}) =>(props={}) =>{

    const [state, setState] = useState(props);

    const {dispatch = ()=>{}} = props;

    const navigate = useNavigate();

    const withDispatch = ({actionType = 0,...rest})=>{
        action[actionType]({state,setState,navigate,...rest})
        dispatch({actionType,...rest});
    }

    return <Component {...state} {...props} dispatch={withDispatch} navigate={navigate} />
}

export default withActionState;

