import React from "react";
import { isNaN } from "lodash";

import RangeSlider from "../../molecules/RangeSlider";
import { getTimeInRange } from "./recentActivity.helpers";

const RecentActivityReports = ({type,val, clickHandler, recentActivity=[]}) =>{
    const timeInRange = getTimeInRange({details : recentActivity[1]?.metricsNameGlucoseDetailsMap});

    const { glucose = 98 } = recentActivity[0]?.glucoseDetails || {};

    const handleRecentActivityPopup= ()=>{
      clickHandler(type)
    }

    return (
        <div onClick={handleRecentActivityPopup}>
            <div className="recent-activity-data-main-div">
                <div className="recent-activity-data-left-main">
                  <div className="recent-activity-fasting-glucose-div">
                    <span className="recent-activity-fasting-glucose-div-span">
                      {type}
                    </span>
                  </div>
                  <div className="recent-activity-fasting-glucose-data-div">
                    <div className="recent-activity-fasting-glucose-data-div-first">
                      <span className="recent-activity-fasting-glucose-data-div-first-span">
                        {" "}
                        5% worse
                      </span>
                    </div>
                    <div className="recent-activity-fasting-glucose-data-div-second">
                      <span className="recent-activity-fasting-glucose-data-div-second-span">
                        Intervention needed
                      </span>
                    </div>
                  </div>
                </div>
                  <RangeSlider val={glucose} glucoseColor={recentActivity[0]?.glucoseDetails?.["glucose-color"] ? recentActivity[0]?.glucoseDetails?.["glucose-color"] : "red"}/>
                <div className="recent-activity-data-right-main">
                  <div className="recent-activity-time-in-range">
                    <span className="recent-activity-time-in-range-span">
                      Time in range
                    </span>
                  </div>
                  <div className="recent-activity-time-in-range-data">
                    <span className="recent-activity-time-in-range-data-span">
                      {isNaN(timeInRange) ? "---" : `${timeInRange} %`}
                    </span>
                  </div>
                </div>
              </div>
              <div className="recent-activity-data-underline">
                <img
                  src={
                    require("../../../assets/images/recent-acitivity-underline.svg")
                      .default
                  }
                />
              </div>
          </div>
    )
}

export default RecentActivityReports;