import male from "../../../../../assets/images/male_icon.svg"
import female from "../../../../../assets/images/female_user_icon.svg";
import mainMale from "../../../../../assets/images/main_male_icon.svg"

const GENDER_TYPES = {
    Male:mainMale,
    Female:female,
    "Non-Binary":male,
}

export {GENDER_TYPES}