import styles from "./search.module.css";
// import AddNewMemberForm from "../../../form/AddNewMemberForm";
import { useState } from "react";
// import AddNewPatientForm from "../form/AddNewPatientForm";

const Search = () => {

    const [open, setIsOpen] = useState(false);

    const openForm = () => setIsOpen(!open);

    return (

        <div className={styles.searchContainer}>
            <div className={styles.search}>
                <input placeholder="Search by Practice Name, Email ID" className={styles.searchBar}></input>
                <img src={require("../../../../../../../assets/images/search-icon.svg").default} className={styles.searchLogo}></img>
            </div>
        </div>

    )
}

export default Search;