import React, { useState } from "react";

import UserProfile from "../UserProfile";
import DashBoardDescription from "../DashBoardDescription/DashBoardDescription";
import NoodlesPic from "../../../assets/images/Noodles.png"

import styles from "./userDashBoard.module.css";

const UserDashboard = () =>{
    const [prandialDialogBoxDisplay,setPrandialDialogBoxDisplay] = useState(false)

    const [prandialDialogBoxLabel,setPrandialDialogBoxLabel] = useState("")

    const togglePrandialDialogBox = (type) =>{
        setPrandialDialogBoxDisplay(!prandialDialogBoxDisplay);
        setPrandialDialogBoxLabel(type);
    }

    return (
        <div className={styles.container}>
           { prandialDialogBoxDisplay && <div id="prandialDialogBox">
                <div id="closeButton" onClick={togglePrandialDialogBox}>
                <img src={require("../../../assets/images/cross.svg").default}
                    />
                </div>
                <div className="first-div-prandial">
                <div className="first-div-prandial-first">
                    <img src={require("../../../assets/images/dinner.svg").default}
                    />
                </div>
                <div className="first-div-prandial-second">
                    <span className="first-div-prandial-second-span">
                    {prandialDialogBoxLabel}
                    </span>
                </div>
                </div>

                <div className="second-div-prandial">
                <div className="second-div-prandial-first"></div>
                <div className="second-div-prandial-second">
                    <div className="trend-status-div">
                    <div className="trend-status-div-first ">
                        <div className="in-trend-status-div-first">
                        <span className="in-trend-span-first">Trend</span>
                        </div>
                        <div className="in-trend-status-div-second">
                        <span className="in-trend-span-second">5% worse</span>
                        </div>
                    </div>
                    <div className="trend-status-div-second">
                        <div className="in-trend-second-first-div">
                        <span className="in-trend-second-first-div-span">Status</span>
                        </div>
                        <div className="in-trend-second-second-div">
                        <span className="in-trend-second-second-div-span">
                            Intervention needed
                        </span>
                        </div>
                    </div>
                    </div>
                    <div className="time-glucose-div">
                    <div className="time-glucose-div-first">
                        <div className="time-in-range-div">
                        <span className="time-in-range-div-span">Time in Range</span>
                        </div>
                        <div className="time-in-range-percent-div">
                        <span className="time-in-range-percent-div-span">64.81%</span>
                        </div>
                    </div>
                    <div className="time-glucose-div-second">
                        <div className="average-glucose-div">
                        <span className="average-glucose-div-span">
                            Average Glucose
                        </span>
                        </div>
                        <div className="average-glucose-percent-div">
                        <span className="average-glucose-percent-div-span">
                            110.1 mg/dL
                        </span>
                        </div>
                    </div>
                    <div className="time-glucose-div-third">
                        <div className="glucose-variability-div">
                        <span className="glucose-variability-div-span">
                            Glucose Variability
                        </span>
                        </div>
                        <div className="glucose-variability-percent-div">
                        <span className="glucose-variability-percent-div-span">
                            12.93%
                        </span>
                        </div>
                    </div>
                    </div>
                </div>
                </div>

                <div className="third-div-prandial">
                <div className="contributing-data-highlight-div">
                    <span className="contributing-data-highlight-span">
                    Contributing Data Highlights
                    </span>
                </div>

                <div className="highlights-left-section">
                    <div className="prandial-food">
                    <span className="prandial-food-span">Food</span>
                    </div>
                    <div className="prandial-medications">
                    <span className="prandial-medications-span">Medications</span>
                    </div>
                    <div className="prandial-movement">
                    <span className="prandial-movement-span">Movement</span>
                    </div>
                    <div className="prandial-sleep">
                    <span className="prandial-sleep-span">Sleep</span>
                    </div>
                    <div className="prandial-mood">
                    <span className="prandial-mood-span">Mood & Stress</span>
                    </div>
                </div>

                <div className="highlights-right-section">
                    <div className="highlights-right-section-heading">
                    <div className="highlights-right-section-heading-left">
                        <div className="highlights-right-section-heading-left-first">
                        <span className="highlights-right-section-heading-left-first-span">
                            Food Highlights
                        </span>
                        </div>
                        <div className="highlights-right-section-heading-left-second">
                        <span className="highlights-right-section-heading-left-second-span">
                            3 highlights
                        </span>
                        </div>
                    </div>

                    <div className="highlights-right-section-heading-right">
                        <span className="highlights-right-section-heading-span-1">
                        Largest Spikes
                        </span>
                        <span className="highlights-right-section-heading-span-2">
                        {" "}
                        • Healthiest Spikes
                        </span>
                    </div>
                    </div>

                    <div className="prandial-table-header">
                    <div className="prandial-header-heading">
                        <span className="prandial-header-heading-span">Heading</span>
                    </div>

                    <div className="prandial-heading-2">
                        <span className="prandial-heading-2-span">Heading 2</span>
                    </div>

                    <div className="prandial-elevation">
                        <span className="prandial-elevation-span">
                        Max Point Elevation
                        </span>
                    </div>

                    <div className="prandial-spike">
                        <span className="prandial-spike-span">Spike Duration</span>
                    </div>

                    <div className="prandial-one-pp">
                        <span className="prandial-one-pp-span">1-hr PP Value</span>
                    </div>

                    <div className="prandial-two-pp">
                        <span className="prandial-two-pp-span">2-hr PP Value</span>
                    </div>
                    </div>

                    <div className="prandial-table-content">
                    <div className="prandial-table-content-first">
                        <div className="prandial-table-content-first-one">
                        <img src={NoodlesPic}
                    />
                        </div>

                        <div className="prandial-table-content-first-two">
                        <div className="prandial-table-content-first-two-name">
                            <span className="prandial-table-content-first-two-name-span">
                            Chinese noodles
                            </span>
                        </div>
                        <div className="prandial-table-content-first-two-date">
                            <span className="prandial-table-content-first-two-date-span">
                            April 26th 2023 @ 11:11am
                            </span>
                        </div>
                        </div>
                    </div>

                    <div className="prandial-table-content-second">
                        <div className="prandial-table-content-second-one">
                        <span className="prandial-table-content-second-one-span">
                            Lunch
                        </span>
                        </div>
                        <div className="prandial-table-content-two">
                        <div className="prandial-table-content-two-one">
                            <img src={require("../../../assets/images/red-dot-prandial.svg").default}
                    />
                        </div>
                        <div className="prandial-table-content-two-two">
                            <span className="prandial-table-content-two-two-span">
                            Red Plate
                            </span>
                        </div>
                        </div>
                    </div>

                    <div className="prandial-table-content-third">
                        <div className="prandial-table-content-third-one">
                        <span className="prandial-table-content-third-one-span">
                            + 30 points
                        </span>
                        </div>
                    </div>

                    <div className="prandial-table-content-fourth">
                        <div className="prandial-table-content-fourth-one">
                        <span className="prandial-table-content-fourth-span">
                            1hr 30min
                        </span>
                        </div>
                    </div>

                    <div className="prandial-table-content-fifth">
                        <div className="prandial-table-content-fifth-one">
                        <span className="prandial-table-content-fifth-one-span">
                            180
                        </span>
                        </div>
                    </div>

                    <div className="prandial-table-content-sixth">
                        <div className="prandial-table-content-sixth-one">
                        <span className="prandial-table-content-sixth-one-span">
                            160
                        </span>
                        </div>
                    </div>
                    </div>

                    <div className="prandial-table-content">
                    <div className="prandial-table-content-first">
                        <div className="prandial-table-content-first-one">
                        <img src={NoodlesPic}
                    />
                        </div>

                        <div className="prandial-table-content-first-two">
                        <div className="prandial-table-content-first-two-name">
                            <span className="prandial-table-content-first-two-name-span">
                            Chinese noodles
                            </span>
                        </div>
                        <div className="prandial-table-content-first-two-date">
                            <span className="prandial-table-content-first-two-date-span">
                            April 26th 2023 @ 11:11am
                            </span>
                        </div>
                        </div>
                    </div>

                    <div className="prandial-table-content-second">
                        <div className="prandial-table-content-second-one">
                        <span className="prandial-table-content-second-one-span">
                            Lunch
                        </span>
                        </div>
                        <div className="prandial-table-content-two">
                        <div className="prandial-table-content-two-one">
                            <img src={require("../../../assets/images/red-dot-prandial.svg").default}
                    />
                        </div>
                        <div className="prandial-table-content-two-two">
                            <span className="prandial-table-content-two-two-span">
                            Red Plate
                            </span>
                        </div>
                        </div>
                    </div>

                    <div className="prandial-table-content-third">
                        <div className="prandial-table-content-third-one">
                        <span className="prandial-table-content-third-one-span">
                            + 30 points
                        </span>
                        </div>
                    </div>

                    <div className="prandial-table-content-fourth">
                        <div className="prandial-table-content-fourth-one">
                        <span className="prandial-table-content-fourth-span">
                            1hr 30min
                        </span>
                        </div>
                    </div>

                    <div className="prandial-table-content-fifth">
                        <div className="prandial-table-content-fifth-one">
                        <span className="prandial-table-content-fifth-one-span">
                            180
                        </span>
                        </div>
                    </div>

                    <div className="prandial-table-content-sixth">
                        <div className="prandial-table-content-sixth-one">
                        <span className="prandial-table-content-sixth-one-span">
                            160
                        </span>
                        </div>
                    </div>
                    </div>

                    <div className="prandial-table-content">
                    <div className="prandial-table-content-first">
                        <div className="prandial-table-content-first-one">
                        <img src={NoodlesPic}
                    />
                        </div>

                        <div className="prandial-table-content-first-two">
                        <div className="prandial-table-content-first-two-name">
                            <span className="prandial-table-content-first-two-name-span">
                            Chinese noodles
                            </span>
                        </div>
                        <div className="prandial-table-content-first-two-date">
                            <span className="prandial-table-content-first-two-date-span">
                            April 26th 2023 @ 11:11am
                            </span>
                        </div>
                        </div>
                    </div>

                    <div className="prandial-table-content-second">
                        <div className="prandial-table-content-second-one">
                        <span className="prandial-table-content-second-one-span">
                            Lunch
                        </span>
                        </div>
                        <div className="prandial-table-content-two">
                        <div className="prandial-table-content-two-one">
                            <img src={require("../../../assets/images/red-dot-prandial.svg").default}
                    />
                        </div>
                        <div className="prandial-table-content-two-two">
                            <span className="prandial-table-content-two-two-span">
                            Red Plate
                            </span>
                        </div>
                        </div>
                    </div>

                    <div className="prandial-table-content-third">
                        <div className="prandial-table-content-third-one">
                        <span className="prandial-table-content-third-one-span">
                            + 30 points
                        </span>
                        </div>
                    </div>

                    <div className="prandial-table-content-fourth">
                        <div className="prandial-table-content-fourth-one">
                        <span className="prandial-table-content-fourth-span">
                            1hr 30min
                        </span>
                        </div>
                    </div>

                    <div className="prandial-table-content-fifth">
                        <div className="prandial-table-content-fifth-one">
                        <span className="prandial-table-content-fifth-one-span">
                            180
                        </span>
                        </div>
                    </div>

                    <div className="prandial-table-content-sixth">
                        <div className="prandial-table-content-sixth-one">
                        <span className="prandial-table-content-sixth-one-span">
                            160
                        </span>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>}

            <UserProfile />
            <DashBoardDescription togglePrandialDialogBox={togglePrandialDialogBox} />
        </div>
    )
}

export default UserDashboard;