import { includes, some, filter } from "lodash";

const getTextInitials = (text="")=>{
    return (text?.split(" ")?.map((val)=>{
        return val.substring(0,1).toUpperCase();
    }))?.join("");
}

const getUpperCaseFormatter = (text="") =>{
    return (text.split(" ").map((val)=>{
        return val.substring(0,1).toUpperCase()+val.substring(1,val.length);
    })).join(" ");
} 

const getFilteredData = ({data, text=""})=>{
    if(text.length==0){
        return data;
    }
    
    return filter(data,(dataItem)=>{
        const includesValue = (val, obj) => some(obj, v => includes(v?.toLowerCase(), val?.toLowerCase()))
        return includesValue(text, dataItem);
    })
}

const spaceRemovalKeyDownEvent = (event)=>{
    if(event.key == " "){
        event.preventDefault();
    }
}

export {
    getTextInitials,
    getUpperCaseFormatter,
    getFilteredData,
    spaceRemovalKeyDownEvent 
}