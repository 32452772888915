import React from "react";
import {map} from "lodash";

import styles from "./body.module.css";
import ContentBlock from "../ContentBlock";

const Body = ({cardContent=[]}) =>{
    return (
        <div className={styles.container}>
            {map(cardContent,({heading, content})=>{
                return <ContentBlock heading={heading} content={content} />
            })}
        </div>
    )
}

export default Body;