import React from "react";

import styles from "./header.module.css";

const Header = ({label, className}) =>{
    return (
        <div className={className}>
            {label}
        </div>
    )
}

export default Header;