import React from "react";

import cx from "classnames"

import styles from "./dateDisplayContent.module.css";

const DateDisplayContent = ({className, data}) =>{
    const {className: outerClass}  = className;
    return (
        <div className={cx(styles.container, outerClass)}>
            {data}
        </div>
    )
}

export default DateDisplayContent;