const TOAST_MESSAGE={
    success : "Practice Added successfully",
    failed : "Failed To Add"
}

const FORM_KEYS = {
    PRACTICE_NAME : "practiceName",
    PRACICE_TYPE : "practiceType",
    PRACTICE_WEBSITE : "practiceWebsite",
    PRACICE_ADDRESS : 'practiceAddress',
    PRACTICE_CITY : "practiceCity",
    PRACTICE_STATE : "practiceState",
    PRACTICE_ZIPCODE : "practiceZipcode",
    PRACTICE_ADMIN_NAME : 'practiceAdminName',
    PRACTICE_ADMIN_EMAIL : "practiceAdminEmail",    
    PRACTICE_ADMIN_PHONE_NUMBER : "practiceAdminPhoneNumber"
}

export {
    TOAST_MESSAGE,
    FORM_KEYS
}