import React, { useState } from "react";

import LeftContainer from "./containers/LeftContainers";
import RightContainer from "./containers/RightContainer";
import { getCurrentTabProps } from "./practiceAccountSetup.tabs";
import { getFormFields } from "./practiceAccountSetup.fields";

import { getRightContainerDisplayFields } from "./practiceAccountSetup.displayFields";
import { getCurrentForm } from "./practiceAccountSetup.forms";
import withActionState from "../../hooks/withActionState";

import actionTypes from "./practiceAccountSetup.actionTypes";
import actions from "./practiceAccountSetup.actions";

import styles from "./practiceAccountSetup.module.css";
import { FORM_TYPES, PRIMARY_FORM_TYPES } from "./practiceAccountSetup.constants";
import { getFormProps } from "./practiceAccountSetup.helpers";
import GroupSetupSuccess from "./components/GroupSetupSuccess/GroupSetupSuccess";

const PracticeAccountSetup = ({dispatch , submitSuccess = false , currentForm = 0,...rest}) =>{

    const [currentTab,setCurrentTab] = useState(0);

    const formBody = getCurrentTabProps(getFormFields()[currentTab]);

    const rightContainerTabs = getRightContainerDisplayFields({...rest?.PRIMARY,...rest?.SECONDARY});

    const currentPageForm = getCurrentForm()[currentForm];

    const handleTabSwitch = (tab) =>{
        setCurrentTab(tab);
    }

    const handlePrimaryFormFieldChange = ({label}) => (value) =>{
        dispatch({actionType : actions.UPDATE_FORM_STATE, payload:{
            [label]: value,
            formType: FORM_TYPES.PRIMARY
        }})
    }

    const handleSecondaryFormFieldChange = ({formValues}) =>{
        dispatch({actionType : actions.UPDATE_FORM_STATE, payload:{
            ...formValues,
            formType: FORM_TYPES.SECONDARY
        }})
    }

    const primaryFormSubmit = () =>{
        dispatch({
            actionType: actions.PRIMARY_FORM_SUBMIT,
            payload:{
                pageType : PRIMARY_FORM_TYPES.NEW_USER,
            }
        })
    }

    const secondaryFormSubmit =()=>{
        dispatch({
            actionType : actions.SECONDARY_FORM_SUBMIT
        })
    }

    const formProps = getFormProps({currentPage: currentForm, currentTab, primaryFormSubmit,secondaryFormSubmit,handlePrimaryFormFieldChange,handleSecondaryFormFieldChange});

    return (
        <>
            <div className={styles.modalContainer}>
                {submitSuccess && <GroupSetupSuccess />}
                { !submitSuccess && <div className={styles.container}>
                    <img src={require("../../../assets/images/cancel_icon.svg").default} className={styles.cancelIcon} />
                    <LeftContainer {...formProps} body={formBody} currentTab={currentTab} handleTabSwitch={handleTabSwitch} currentPageFormProps={{...currentPageForm,handlePageFormDisplay: ()=>{}}} />
                    <RightContainer customTabs={rightContainerTabs} />
                </div>}
            </div>
        </>
    )
}

export default withActionState({Component: PracticeAccountSetup,action: actionTypes });