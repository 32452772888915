import React from "react";
import {map} from "lodash"
import cancelTick from "../../../../assets/images/cancel-cross.svg";
import rightTick from "../../../../assets/images/right_tick.svg";

import styles from "./errorContent.module.css";
import { PASSWORD_ERROR_MESSAGES } from "../../../constants/errorStatus";

const ErrorContent = ({errorTypes,fieldError = {}}) =>{
    return (
        <div className={styles.container}>
            <div className={styles.headerContainer}>
                Your Password must be
            </div>
            <div className={styles.textContainer}>
                {
                    map(errorTypes,({type})=>{
                        return (
                            <div className={styles.textRow}>
                                <img src={fieldError[type]? cancelTick : rightTick} />
                                <div className={styles.textContent}>
                                    {PASSWORD_ERROR_MESSAGES[type]}
                                </div>
                            </div>        
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ErrorContent;