import React, { useCallback, useState } from "react";

import cx from "classnames";

import ExclamationTriangle from "../../../../../../../../../../../assets/iconComponents/ExclamationTriangle";
import { COLOR_MAPPINGS } from "./customDataBlock.mapping";
import { getCalculatedType, getRespectiveColorRanges, getRespectiveIcon, getRespectiveModelContent } from "./customDataBlock.helpers";
import Modal from "../../../../../../../../../../molecules/modals/Modal";
import withActionState from "../../../../../../../../../../hooks/withActionState";
import redirectIcon from "./../../../../../../../../../../../assets/images/redirect/purpleRedirect.svg";
import crossIcon from "./../../../../../../../../../../../assets/images/cancel/transparent_cacel.svg";

import actions from "./customDataBlock.actions";
import actionTypes from "./customDataBlock.actionTypes";

import styles from "./customDataBlock.module.css";

const RANGE_TYPE={
    YELLOW : styles.aboveRange,
    RED : styles.belowRange
}

const CustomDataBlock = ({data, className, dispatch , nourishmentNotebookData , responseMetrics}) =>{

    const [isModalVisible , setModalVisibility] = useState(false);
    const [ishovered, setHovered] = useState(false);

    const handleHoverAction = useCallback(()=>{
        setHovered(!ishovered)
    },[ishovered]);
    
    const { glucose, date, meal } = data || {};

    const color = data?.["glucose-color"]?.split("_")[0];

    const icon = getRespectiveIcon(data);

    const handleModalToggle = ()=>{
        if(!isModalVisible){
            dispatch({actionType: actionTypes.LOAD_MODAL_DATA, payload : meal})
        }
        setModalVisibility(!isModalVisible);
    }

    const calculatedType= getCalculatedType(data);

    const modalContent = getRespectiveModelContent({loggedTime: nourishmentNotebookData?.[0]?.["logged_time"] || date})

    return (
        <>
            { isModalVisible && meal!="FASTING" && <div className={styles.modalContainer}>
                <Modal crossIcon={crossIcon} toggleModal={handleModalToggle} {...modalContent} spikeAnalysis={responseMetrics} meal={nourishmentNotebookData?.[0]} className={styles.modalMainContainer} />
            </div>}
            <div className={cx(styles.container,{[styles.dataIcon] : icon })} onClick={handleModalToggle} onMouseEnter={handleHoverAction} onMouseLeave={handleHoverAction}>
                <div className={styles.leftContainer}>
                    {icon && <img src={icon} className={styles.primaryIcon} />}
                    <div className={styles.sourceTabType}>
                        {calculatedType}
                    </div>
                    <div>
                        {glucose && Math.round(glucose)}
                        {!glucose && <div className={styles.noDataContainer}>-</div>}
                    </div>
                    { data?.["glucose-color"]?.split("_")?.length>1 &&  color != "GREEN" &&  <img className={styles.iconStyles} src={COLOR_MAPPINGS[color]} />}
                </div>
                {ishovered && meal!="FASTING" && <img src={redirectIcon} className={styles.primaryIcon} />}
            </div>
        </>
    )
}

export default withActionState({Component: CustomDataBlock, action: actions});