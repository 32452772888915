import React from "react";
import Webcam from "react-webcam";
import { useRef, useState, useCallback } from "react";

import cancelTick from "../../../assets/images/cancel-cross.svg";
import styles from "./customWebcam.module.css";

const CustomWebcam = ({displayCamera,name, setCameraDisplay,handleFieldChange}) =>{
    const webcamRef = useRef(null);
    const [imgSrc, setImgSrc] = useState(null);

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImgSrc(imageSrc);
        handleFieldChange({label:name})(imageSrc);
      }, [webcamRef]);

      const handleCaptureClose = ()=>{
        setCameraDisplay();
      }

    return (
        <div className={styles.container}>
            <div className={styles.cameraContainer}>
                <button className={styles.captureButton} onClick={capture}>Capture</button>
                <button className={styles.closeButton} onClick={handleCaptureClose}>Close</button>
                {imgSrc ? (
                    <img src={imgSrc} alt="webcam" />
                ) : (
                    <Webcam height={600} width={600} ref={webcamRef} />
                )}
            </div>
      </div>
    )
}

export default CustomWebcam;