import React from "react";
import cx from "classnames";

import styles from "./tabContent.module.css";

const TabContent = ({id="",content="", activeTab=0, setActiveTab=()=>{}}) =>{
    
    const handleTabHeaderClick = () =>{
        setActiveTab(id);
    }

    return (
        <div className={cx(styles.tabContainer,styles["non-active-tab"],{[styles["active-tab"]]:activeTab})} onClick={handleTabHeaderClick}>
            {content}
        </div>
    )
}

export default TabContent;