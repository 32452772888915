import React from "react";

import { map } from "lodash";

import CardNoDatFallback from "../../../../../../molecules/cardNoDataFallback";

import styles from "./body.module.css";
import VerticalBarChart from "../../../../../../charts/VerticalBarChart/VerticalBarChart";

const Body = ({
    statistics=[],
    chartStatistics = [],
    CardComponent = CardNoDatFallback,
    categories
}) =>{
    if(statistics?.length == 0)
    {
        return <CardComponent />
    }
    return (
        <div className={styles.container}>
            <div className={styles.leftContainer}>
                {map(statistics, ({label="avg glucose level",value="-",suffix="mg"})=>{
                    return (
                        <div className={styles.statField}>
                            <div className={styles.valueContainer}>
                                <div className={styles.valueText}>{value}</div>
                                <div className={styles.labelText}>{suffix}</div>
                            </div>
                            <div className={styles.labelText}>
                                {label}
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className={styles.rightContainer}>
                <VerticalBarChart categories={categories} chartStatistics={chartStatistics}/>    
            </div>
        </div>
    )
}

export default Body;