import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import FixedSliderChart from "../../../../../../../../../../../../charts/CustomChart/FixedSliderChart";

import styles from "./tableGraphField.module.css";

const TableGraphField = ({data}) =>{

    if(!data){
        return <div className={cx(styles.noDataContainer,styles.fullContainer)}>No Data</div>
    }

    return (
        <div className={styles.container}>
            <div className={styles.primaryText}>
                {data}
            </div>
            <FixedSliderChart polygonValue={data}/>
        </div>
    )

}

TableGraphField.propTypes={
    data: PropTypes.object || PropTypes.string
}

export default TableGraphField;