import { map } from "lodash"
import moment from "moment";
import { BALANCE_PLATE_BORDER_COLOR_CODING, BALANCE_PLATE_COLOR_CODINGS } from "./balancePlateCard.constants";

const getRespectiveStats = (statistics, micronutrientsInsightsResponse) =>{
    return map(statistics,(val)=>{
        const {name}=val;
        return {
            ...val,
            value :  micronutrientsInsightsResponse?.[name] ? Math.floor(micronutrientsInsightsResponse?.[name]*10)/10 : 'No Data',
        }
    })
}

const getRespectiveColumns =(prev=0) =>{
    let days=[];
    let i=0;
    moment.updateLocale('en', { weekdaysMin: 'S_M_T_W_T_F_S'.split('_') });
    for(i=i;i<6;i++){
        days.push(moment().subtract((6-i)+(prev*7),'d').format("dd"))
    }
    if(prev==0){
        days.push("Today");
        i=1;
    }
    else{
        days.push(moment().subtract((prev*7),'d').format("ddd"))
    }
    return days
}

const getRespectiveChartData = (chartData)=>{
    return map(Object.keys(chartData),(key)=>{
        return {
            y : chartData?.[key]?.avgPlateScore,
            color : BALANCE_PLATE_COLOR_CODINGS[chartData?.[key]?.balancedPlateMealStatus],
            borderColor : BALANCE_PLATE_BORDER_COLOR_CODING[chartData?.[key]?.balancedPlateMealStatus],
        }
    })
}

export {
    getRespectiveStats,
    getRespectiveChartData,
    getRespectiveColumns
}