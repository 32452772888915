import { BASE_URL } from "../../../../../../../../constants/api";
import { LOCAL_STORAGE_KEYS } from "../../../../../../../../constants/keyConstants";
import { getWeeklyFormattedWeeklyDates } from "../../../../../../../../helpers/dateFormatter";

const getWeeklyPlateScore = async({state, setState, payload})=>{
    try{
        const {userId="", prev=0 } = payload ||{};
        const {startDate : start_date, endDate: end_date} = getWeeklyFormattedWeeklyDates({prev:prev,format:'YYYY-MM-DD'});

        const previousWeekResponse = await fetch(`${BASE_URL}/nourishmentNotebook/dashboard/macronutrientsInsights/${userId}/${start_date}/${end_date}`,{
            method:"GET",
            headers:{
                'content-type':"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            }
        });

        const previousWeekMicroNutritionalValues = await previousWeekResponse.json();

        setState((prev)=>{
            return {
                ...prev,
                previousWeekMicroNutritionalValues
            }
        })
    }
    catch(e){
        setState((prev)=>{
            return {
                ...prev,
                error:e
            }
        })
    }
}

const getNutritionalInsights = async({state, setState,payload}) =>{
    try{
        const {userId="" ,startDate="", endDate=""} = payload;

        const nutritionalInsights = await fetch(`${BASE_URL}/nourishmentNotebook/dashboard/macronutrientsInsights/${userId}/${startDate}/${endDate}`,{
            method:"GET",
            headers:{
                'content-type':"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            }
        });

        const nutritionalInsightsDaily = await nutritionalInsights.json();

        const {micronutrientsInsightsResponseMap} = nutritionalInsightsDaily;

        setState((prev)=>{
            return {
                ...prev,
                micronutrientsInsightsResponseMap
            }
        })

    }
    catch(e){
        setState((prev)=>{
            return {
                ...prev,
                error:e
            }
        })
    }
}

const getSummaryBlockDetails = async({state, setState, payload})=>{
    try{
        const {userId, startDate, endDate} = payload;

        const response = await fetch(`${BASE_URL}/nourishmentNotebook/dashboard/macronutrientsInsights/${userId}/${startDate}/${endDate}`,{
            method:"GET",
            headers:{
                'content-type':"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            }
        });
        const microNutritionalValues = await response.json();

        const {percentageOfBalancedPlateMealStatusMap, micronutrientsInsightsResponse} = microNutritionalValues;

        setState((prev)=>{
            return {
                ...prev,
                micronutrientsInsightsResponse,
                percentageOfBalancedPlateMealStatusMap
            }
        })
    }
    catch(e){
        setState((prev)=>{
            return {
                ...prev,
                error: e,
            }
        })
    }
}

const getSummaryDetails = async({state, setState, payload})=>{
    try{
        const {userId, startDate, endDate} = payload;

        const {startDate: start_date, endDate : end_date} = getWeeklyFormattedWeeklyDates({prev:1,format:"YYYY-MM-DD"});

        const response = await fetch(`${BASE_URL}/nourishmentNotebook/dashboard/macronutrientsInsights/${userId}/${startDate}/${endDate}`,{
            method:"GET",
            headers:{
                'content-type':"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            }
        });
        const microNutritionalValues = await response.json();

        const previousWeekResponse = await fetch(`${BASE_URL}/nourishmentNotebook/dashboard/macronutrientsInsights/${userId}/${start_date}/${end_date}`,{
            method:"GET",
            headers:{
                'content-type':"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            }
        });
        const previousWeekMicroNutritionalValues = await previousWeekResponse.json();

        setState((prev)=>{
            return {
                ...prev,
                ...microNutritionalValues,
                previousWeekMicroNutritionalValues,
            }
        })
    }
    catch(e){
        setState((prev)=>{
            return {
                ...prev,
                error: e,
            }
        })
    }
}

const getInitAction = async({state, setState}) =>{
    try{
        const {currentPreviousWeek = {}, weeklyFilter={}} = state;

        const url = new URL(window.location.href)
        const pathname = url.pathname.split("/");

        const {startDate, endDate} = getWeeklyFormattedWeeklyDates({prev:0,format:"YYYY-MM-DD"});
        
        getSummaryDetails({state, setState, payload:{
            userId : pathname?.[2],
            startDate,
            endDate
        }})

        
        setState((prev)=>{
            return {
                ...prev,
                weeklyFilter:{
                    "current":0,
                    "comparisonAnalysis":1
                }
            }
        })
    }
    catch(e){
        setState((prev)=>{
            return {
                ...prev,
                error: e,
            }
        })
    }
}

const getFilterChangeAction = ({state, setState, payload}) =>{
    try{
        const {handleToggleLoaderAction} = state;
        const {name, prev} = payload;
        const url = new URL(window.location.href)
        const pathname = url.pathname.split("/");

        handleToggleLoaderAction({isLoading : true});

        const {startDate, endDate} = getWeeklyFormattedWeeklyDates({prev,format:"YYYY-MM-DD"});
        
        switch(name){
            case "comparisonAnalysis":
                getWeeklyPlateScore({
                    state,
                    setState,
                    payload:{
                        userId : pathname?.[2],
                        startDate,
                        endDate,
                        prev
                    },
                })
                .then(()=>{
                    handleToggleLoaderAction({isLoading : false});
                })
                break;
            default:
                getNutritionalInsights({state,setState,payload:{
                    userId : pathname?.[2],
                    startDate,
                    endDate
                }});                        
                getSummaryBlockDetails({state, setState,payload:{
                    userId: pathname?.[2],
                    startDate,
                    endDate                    
                }})
                .then(()=>{
                    handleToggleLoaderAction({isLoading : false});
                })
                break;
        }

        setState((previousState)=>{
            const {weeklyFilter} = previousState;
            return {
                ...previousState,
                weeklyFilter:{
                    ...weeklyFilter,
                    [name]: prev
                }
            }
        });
    }
    catch(e){
        handleToggleLoaderAction({isLoading : false});
        setState((prev)=>{
            return {
                ...prev,
                error:e
            }
        })
    }
}

export default {
    INIT_ACTION : getInitAction,
    FILTER_UPDATE_ACTION : getFilterChangeAction
}