import React from "react";

import NutritionalBalanceChart from "../../../../../../../../../../../../../../charts/patientProfile/progressMonitoring/balancePlate/nutritionalBalanceChart/NutritionalBalance";

import styles from "./nutritionalBalanceCard.module.css";
import { NUTRITIONAL_BALANCE_RANGE_STYLES } from "../../nutritionalBalance.mapping";
import { NUTRITIONAL_BALANCE_RANGES } from "../../nutritionalBalance.constants";

const NutritionalBalanceCard = ({label,value=19,suffix="g",type="atTarget", target, ...rest}) =>{
    return (
        <div className={styles.container}>
            <div className={styles.primaryLabel}>
                {label}
            </div>
            <div className={styles.secondaryContainer}>
                <div className={styles.primaryTextContainer}>
                    <p className={styles.mainValueText}>{value}</p>
                    <p>{suffix}</p>
                </div>
                <div className={NUTRITIONAL_BALANCE_RANGE_STYLES[type]}>
                    {NUTRITIONAL_BALANCE_RANGES[type]}
                </div>
            </div>
            <NutritionalBalanceChart value={[value]} target={target} {...rest} />
        </div>
    )
}

export default NutritionalBalanceCard;