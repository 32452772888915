import { useCallback } from "react";
import {useNavigate} from "react-router-dom";

import styles from "./pageHeaderContext.module.css"

const PageHeaderContext = () => {
    const navigate = useNavigate();

    const handleClickAction = useCallback(()=>{
        navigate("/superUser");
    },[]);

    return (
            <div className={styles.pageHeaderContext}>
                <div className={styles.subHeading} onClick={handleClickAction}>Practice Management</div>
            </div>

    )
}

export default PageHeaderContext;