import styles from "./overview.module.css"

const Overview = ({coachDetails}) => {

    const {firstName="", lastName="", phoneNumber="", ethnicity="",email="",languages=[], speciality=[], credentials=[], workEmail="",city="",state="" } = coachDetails || {};
    
    return (
        <div className={styles.mainContainer}>

            <div className={styles.worksite}>
                <div className={styles.worksiteHeadingContainer}>
                    <div className={styles.worksiteHeading}>Personal Detail</div>
                </div>
                <div className={styles.worksiteLayout}>
                    <div className={styles.worksiteFields}>
                        <div className={styles.worksiteField}>
                            <div className={styles.fieldType}>Name</div>
                            <div className={styles.fieldValue}>{firstName+" "+lastName}</div>
                        </div>
                        <div className={styles.worksiteField}>
                            <div className={styles.fieldType}>Contact Number</div>
                            <div className={styles.fieldValue}>{phoneNumber || "-"}</div>
                        </div>
                        <div className={styles.worksiteField}>
                            <div className={styles.fieldType}>Ethnicity</div>
                            <div className={styles.fieldValue}>{ethnicity || "-"}</div>
                        </div>
                    </div>

                    <div className={styles.worksiteFieldsSecondary}>
                        <div className={styles.worksiteField} >
                            <div className={styles.fieldType}>Email</div>
                            <div className={styles.fieldValue}>{email || "-"}</div>
                        </div>
                        <div className={styles.worksiteField}>
                            <div className={styles.fieldType}>Language</div>
                            <div className={styles.fieldValue}>{languages || "-"}</div>
                        </div>
                    </div>

                </div>
            </div>

            <div className={styles.worksite}>
                <div className={styles.worksiteHeadingContainer}>
                    <div className={styles.worksiteHeading}>Professional Details</div>
                </div>
                <div className={styles.worksiteLayout}>
                    <div className={styles.worksiteFields}>
                        <div className={styles.worksiteField}>
                            <div className={styles.fieldType}>Speciality</div>
                            <div className={styles.fieldValue}>{speciality || "-"}</div>
                        </div>
                    </div>

                    <div className={styles.worksiteFieldsSecondary}>
                        <div className={styles.worksiteField} >
                            <div className={styles.fieldType}>Credentials</div>
                            <div className={styles.fieldValue}>{credentials || "-"}</div>
                        </div>
                    </div>

                </div>
            </div>

            <div className={styles.worksite}>
                <div className={styles.worksiteHeadingContainer}>
                    <div className={styles.worksiteHeading}>Contact Detail</div>
                </div>
                <div className={styles.worksiteLayout}>
                    <div className={styles.worksiteFields}>
                        <div className={styles.worksiteField}>
                            <div className={styles.fieldType}>Work Email</div>
                            <div className={styles.fieldValue}>{workEmail || "-"}</div>
                        </div>
                        <div className={styles.worksiteField}>
                            <div className={styles.fieldType}>City</div>
                            <div className={styles.fieldValue}>{city || "-"}</div>
                        </div>
                    </div>

                    <div className={styles.worksiteFieldsSecondary}>
                        <div className={styles.worksiteField} >
                            <div className={styles.fieldType}>State</div>
                            <div className={styles.fieldValue}>{state || "-"}</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>


    )
}

export default Overview;