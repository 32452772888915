import { ERROR_MESSAGES } from "../constants/errorStatus";
import { isNil } from "lodash";

const checkMaxCharacterError = ({value,length})=>{
    return value?.length < length ;
}

const checkPhoneNumber = ({value}) =>{
    return value.length >=5;
}

const hasValue = ({value})=>{
    return !isNil(value) && value?.toString()?.length >0;
}

const checkMinCharacterError = ({value, length}) =>{
    return value?.length >= length;
}

const checkIsEmailError = ({value}) =>{
    // const emailRegex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/, "gm");
    // return emailRegex.test(value);
    return true;
}

const hasLowerCase = ({value}) =>{
    return value !== value.toUpperCase()
}

const hasUpperCase = ({value}) =>{
    return value !== value.toLowerCase();
}

const hasNumber = ({value}) =>{
    return /\d/.test(value)
}

const hasSpecialCharanters = ({value}) =>{
    //write the implemetation later
}

const isCheckedType = ({value}) =>{
    return value
}

const RESPECTIVE_ERROR_STATUS = {
    MIN_LENGTH : checkMinCharacterError,
    MAX_LENGTH : checkMaxCharacterError,
    IS_EMAIL : checkIsEmailError,
    HAS_LOWERCASE : hasLowerCase,
    HAS_UPPERCASE : hasUpperCase,
    HAS_NUMBERS : hasNumber,
    HAS_SPECIAL_CHARACTER : hasSpecialCharanters,
    IS_REQUIRED: hasValue,
    IS_PHONE_NUMBER: checkPhoneNumber,
    IS_CHECKED : isCheckedType
}

const handleErrorStatus = ({value, errorStatus}) =>{
    const currentErrors = {};
    for(let x of errorStatus)
    {
        const errorStatus = RESPECTIVE_ERROR_STATUS[x.type]({value,...x})
        if(!errorStatus)
        {
            currentErrors[x.type] = ERROR_MESSAGES[x.type];
        }
    }
    return currentErrors;
}

export { handleErrorStatus, checkMinCharacterError, checkMaxCharacterError, checkIsEmailError, hasLowerCase, hasUpperCase, hasNumber, hasSpecialCharanters }