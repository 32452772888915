import React from "react";
import cx from "classnames";

import { MANDATORY_FIELDS } from "./form.fields";
import { hasFields } from "./form.helpers";

import styles from "./form.module.css"

const AddNewPracticeFormStepThree = ({setIsOpen, setStepCounter, updateFormValues, handleFormSubmitAction, formValues}) => {
    
    const closeForm = () =>{
        setStepCounter("ONE");
        setIsOpen(false);
    }

    const buttonActiveStatus = hasFields(MANDATORY_FIELDS[2],formValues)

    const prevStep = () => setStepCounter("TWO");
    
    const nextStep = () => {
        if(!buttonActiveStatus){
            return;
        }
        setStepCounter("ONE")
    };

    const handleStateUpdate = (type) =>(event)=>{
        event.preventDefault();
        switch(type){
            case "practiceAdminPhoneNumber":
                if(event.target.value?.length >10){
                    return;
                }
                break;
            default :
                break;
        }
        updateFormValues({type,value: event.target.value})
    }

    const handleKeyDownEvent = (event)=>{
        if(event.key==" "){
            event.preventDefault();
        }
    }

    return (

        <div className={styles.container}>
            <div className={styles.upperContainer}>
                <div className={styles.formName}>Add new practice</div>
                <img className={styles.cancel} onClick={closeForm} src={require("../../../../../assets/images/cancel/transparent_cacel.svg").default }></img>
            </div>
            <div className={styles.bottomContainer}>
                <div className={styles.step}>
                    Step 3/3
                </div>
                <div className={styles.stepBar}>
                    <div className={styles.stepBarOne}></div>
                    <div className={styles.stepBarOne}></div>
                    <div className={styles.stepBarOne}></div>
                </div>
                <div className={styles.practiceDetail}>
                    Admin Detail
                </div>

                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        Practice Admin Name
                    </div>
                    <input value={formValues?.["practiceAdminName"]} onChange={handleStateUpdate('practiceAdminName')} className={styles.fieldValue} placeholder="Enter Practice Admin Name"></input>
                </div>

                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        Practice Admin Email
                    </div>
                    <input  onKeyDown={handleKeyDownEvent} value={formValues?.["practiceAdminEmail"]} onChange={handleStateUpdate('practiceAdminEmail')} className={styles.fieldValue} placeholder="Enter Practice Admin Email"></input>
                </div>

                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        Practice Admin Phone Number
                    </div>
                    <input type="number" onKeyDown={handleKeyDownEvent} value={formValues?.["practiceAdminPhoneNumber"]} onChange={handleStateUpdate('practiceAdminPhoneNumber')} className={styles.fieldValue} placeholder="Enter Practice Admin Phone Number"></input>
                </div>


                <div className={styles.backAndContinue}>
                    <div className={styles.back} onClick={prevStep}>Back</div>
                    <div className={cx(styles.continueSmall,{[styles.nonActiveButton] : !buttonActiveStatus})} onClick={()=>{
                        nextStep();
                        closeForm();
                        handleFormSubmitAction();
                        }}>
                        Continue
                    </div>
                </div>

            </div>
        </div>

    )
}

export default AddNewPracticeFormStepThree;