import { map} from "lodash";
import { COMPARISON_ANALYSIS_MEALS } from "./comparisonAnalysis.constants";

const getFormattedData = (currentWeek, previousWeek,numberOfMealsByMealMap) =>{
    return map(COMPARISON_ANALYSIS_MEALS,(val)=>{
        return {
            timing : {
                value : val,
                numberOfMeals : numberOfMealsByMealMap?.[val]
            },
            currentWeekData : previousWeek?.avgPlateScoreByMealMap?.[val],
            previousWeekData  : currentWeek?.avgPlateScoreByMealMap?.[val],
        }
    });
}

export {
    getFormattedData
}