import React from "react";
import drillDown from "highcharts/modules/drilldown";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import bullet from "highcharts/modules/bullet.js";

import styles from "./nutritionalBalance.module.css";
import { getDataToChartFormatting } from "./nutritionalBalance.helpers";


HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
bullet(Highcharts);

Highcharts.setOptions({
    exporting: {
      enabled: false
    }
});

const NutritionalBalance = ({value,target,maxRange,min,max}) =>{
    
    drillDown(Highcharts);

    const formattedData = getDataToChartFormatting(value,target,min,max);

    const options = {
        chart: {
            inverted: true,
            type: 'bullet',
            height:20,
            margin:0,
            padding:0,
            width: 305
        },
        title: {
            text: null
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                pointPadding: 0.25,
                borderWidth: 0,
                color: '#000',
                targetOptions: {
                    width: '200%'
                }
            }
        },
        tooltip:{
            enabled: false 
        },
        xAxis:{
            visible : false
        },
        yAxis: {
            max:maxRange,
            plotBands: [{
                from: 0,
                to: min,
                color: '#FAF9FB'
            },
            {
                from: min,
                to: max,
                color: '#E5FAF2'
            },
            {
                from: max,
                to: maxRange,
                color: '#FAF9FB'
            }],
            gridLineWidth: 0,
            title: null,
            labels:{
                enabled : false,
            }
        },
        series: [{
            data: formattedData,
        }],    
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        }    
    };

    return (
        <div className={styles.container}>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    )
}

export default NutritionalBalance;