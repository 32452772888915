import React from "react";
import { map } from "lodash";
import cx from "classnames";

import { MEDICATION_TRACER_FORMAT } from "../../../../../../constants/metricTypes";
import styles from "./body.module.css";
import RecentActivity from "../../../recentActivity";
import TrendAnalysis from "../../../../../../charts/TrendAnalysis/TrendAnalysis";
import ActivityAnalysis from "../../../../../../charts/ActivityAnalysis/ActivityAnalysis";
import ComparisonAnalysisTable from "./components/ComparisonAnalysisTable";
import GlucoseMetricsCard from "./components/GlucoseMetricsCard";
import IndicatorOption from "./components/indicatorOption/IndicatorOption";
import TirCard from "./components/TirCard/TirCard";
import exclamationIcon from "./../../../../../../../assets/images/exclamation/greyExclamation.svg";
import {
  COMPARISON_ANALYSIS_METRIC_TYPES,
  COMPARISON_ANALYSIS_METRIC_TYPES_MAPPING,
  PROGRESS_MONITORING_CARDS_TYPES,
  PROGRESS_MONITORING_TYPE,
} from "../../progressMonitoring.constants";
import WeeklyFilter from "../WeeklyFilter/WeeklyFilter";
import { getWeeklyFormattedWeeklyDates} from "../../../../../../helpers/dateFormatter";
import BasicDropdown from "../../../../../../molecules/baseComponents/dropDowns/basicDropdown";
import BalancedPlate from "./components/BalancedPlate/BalancedPlate";
import CustomBasicDropdown from "../../../../../../molecules/baseComponents/dropDowns/customBasicDropdown";
import TrendAnalysisTable from "./components/TrendAnalysisTable";
import { getSummaryCopyAction } from "./body.helpers";

const Body = ({
    summary,
    categories,
    handleToastUpdateAction,
    activityAnalysisData=[],
    comparisonAnalysisColumns = {},
    tableChartData,
    trendAnalysisTableData,
    handleGlucoseLogsTabUpdateAction,
    glucoseLogMetricsSourceTab,
    trendAnalysisIndicators = [
      { content: "In Range", color: "inRange" },
      { content: "Below Range", color: "belowRange" },
      { content: "Above Range", color: "aboveRange" },
    ],
    activeDurations = MEDICATION_TRACER_FORMAT.WEEKLY,
    primaryMonitoringOptions = [
      {
        CustomComponent: GlucoseMetricsCard,
        heading: "Glucose Metrics",
        containerClass: styles.containerClass,
      },
      {
        CustomComponent: TirCard,
        heading: "Time-in-Range",
        containerClass: styles.containerClass,
      },
      3
    ],
    trendAnalysisMainData,
    handleProgressMonitoringDurationChange = () => {},
    recentTrends,
    filterDuration
  }) => {

  const handlePrimaryDurationChange = (name, prev = 0) => {
    handleProgressMonitoringDurationChange({ name, prev });
  };

  const handleSummaryCopyAction=()=>{
    getSummaryCopyAction({summaryData : summary, prev: filterDuration?.current || 0, recentTrends, handleToastUpdateAction})
  }

  return (
    <div className={styles.container}>
        <div className={styles.innerContainer}>
          <div className={styles.primaryLogsContainer}>
            <div className={styles.headerContainer}>
                <div>
                    <div className={styles.primaryText}>
                          <span>Overall Glucose Summary</span>
                          <img src={exclamationIcon} className={styles.exclamationIcon}/>
                    </div>
                    <div className={styles.secondaryText}>
                      Macro Level Overview on Blood Glucose Tracking
                    </div>
                </div>
                <div>
                  <div className={styles.copyDataOuterContainer} onClick={handleSummaryCopyAction}>
                        <div className={styles.copyDataInnerContainer}>Copy Data</div>
                  </div>
                </div>
            </div>
            <div className={styles.primaryLogsInnerContainer}>
              {map(
                primaryMonitoringOptions,
                ({ CustomComponent, heading, containerClass }) => (
                  <RecentActivity
                    key={heading}
                    className={styles.cardContainerClass}
                    data={summary}
                    bodyProps={CustomComponent ? { CustomComponent } : {}}
                    headerProps={{ heading, containerClass }}
                    recentTrends={recentTrends}
                  />
                )
              )}
            </div>
          </div>
          <TrendAnalysisTable
            previous={filterDuration?.current || 0}
            trendAnalysisTableData={trendAnalysisTableData} 
            handleToastUpdateAction={handleToastUpdateAction}
            glucoseLogMetricsSourceTab={glucoseLogMetricsSourceTab}
            handleGlucoseLogsTabUpdateAction={handleGlucoseLogsTabUpdateAction}
          />
          
          <div className={styles.primaryLogsContainer}>
            <div className={styles.headerContainer}>Comparison Analysis
            </div>
            <div className={styles.optionsContainer}>
              <CustomBasicDropdown selectClassname={styles.selectTimeZoneClass} options={COMPARISON_ANALYSIS_METRIC_TYPES} name={PROGRESS_MONITORING_CARDS_TYPES.COMPARISON_ANALYSIS} value={COMPARISON_ANALYSIS_METRIC_TYPES_MAPPING[activeDurations?.[PROGRESS_MONITORING_CARDS_TYPES.COMPARISON_ANALYSIS]] || "1 Hr PP"} handleMetricTypeChange={handlePrimaryDurationChange}  />
            </div>
            <ComparisonAnalysisTable
              handleProgressMonitoringDurationChange={handlePrimaryDurationChange}
              columnsData={comparisonAnalysisColumns}
              tableChartData={tableChartData}
              filterDuration={filterDuration?.comparisonAnalysis}
            />
          </div>

          <div className={styles.primaryLogsContainer}>
            <div className={styles.headerContainer}>Consolidated Analysis</div>
            <ActivityAnalysis {...activityAnalysisData} />
          </div>

          <div className={cx(styles.primaryLogsContainer, styles.activityAnalysisContainer)}>
          <div className={styles.headerContainer}>Trend Analysis
          <div className={styles.betaIconContainer}>
                Beta
            </div>
          </div>
            <TrendAnalysis
              categories={categories}
              comparisonAnalysisData={trendAnalysisMainData}
            />
            <div className={styles.trendAnalysisIndicator}>
              {map(trendAnalysisIndicators, (field) => (
                <IndicatorOption key={field.content} {...field} />
              ))}
            </div>
          </div>
        </div>
    </div>
  );
};

export default Body;
