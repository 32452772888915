import { useEffect, useMemo, useState } from "react";
import cx from "classnames";
import { map } from "lodash";
import {useNavigate} from "react-router-dom";

import { getPopupForm } from "./form.mapping";

import { getAddPatientsAction, getPatientsList } from "./form.async";

import styles from "./form.module.css"
import { PRACTICE_FORM_TOAST_MESSAGE, TOAST_TYPE_CONSTANTS } from "../../../../practiceDetail.constants";

const AddNewPatientForm = ({ setIsOpen, handleListReFetch, updateToast, setIsLoading }) => {

    const [newPatient,setNewPatients] = useState([]);
    const [patientsList, setPatientsList] = useState([]);

    useEffect(()=>{
        setIsLoading(true);
        getPatientsList()
        .then((data)=>{
            setPatientsList(data || []);
        })
        .catch((e)=>{
            console.log('ERROR IS : ',e);
        })
        .finally(()=>{
            setIsLoading(false);
        })
    },[])

    const {labels, buttonContent, fields} = useMemo(()=>getPopupForm(),[])[0];

    const closeForm = () => setIsOpen(false);

    const handleAddPatientToList = (patientId)=>{
        let patientList = [...newPatient]
        patientList.push(patientId);
        setNewPatients(patientList);
    }

    const handleRemovePatientsFromList = (patientId)=>{
        let patientList = [...newPatient]
        patientList = patientList.filter((activePatientId)=> patientId!=activePatientId);
        setNewPatients(patientList);
    }

    const handleFormSubmit = ()=>{
        if(newPatient.length==0){
            return;
        }
        setIsLoading(true);
        getAddPatientsAction(newPatient)
        .then(()=>{
            updateToast(TOAST_TYPE_CONSTANTS.SUCCESS, PRACTICE_FORM_TOAST_MESSAGE);
        })
        .catch((e)=>{
            updateToast(TOAST_TYPE_CONSTANTS.FAILED, PRACTICE_FORM_TOAST_MESSAGE);
            console.log('FAILED AS : ',e);
        })
        .finally(()=>{
            setIsLoading(false);
            setIsOpen(false);
            handleListReFetch();
        })
    }

    return (

        <div className={styles.container}>
            <div className={styles.upperContainer}>
                <div className={styles.formName}>Add new patient</div>
                <img className={styles.cancel} src={require("../../../../../../../assets/images/cancel/transparent_cacel.svg").default} onClick={closeForm}></img>
            </div>
            <div className={styles.bottomContainer}>
                <div className={styles.header}>
                    <div className={styles.headerMainText}>
                        {labels}
                    </div>
                </div>

                <div className={styles.body}>
                    {map(fields,(field)=>{
                        const { Component, ...rest } = field;
                        return <Component {...rest} 
                                    activePatientList={newPatient} 
                                    handleAddPatientToList={handleAddPatientToList} 
                                    handleRemovePatientsFromList={handleRemovePatientsFromList}
                                    patientsList={patientsList}
                                />
                    })}
                </div>

                <div className={cx(styles.continue,{[styles.nonActiveButton] : newPatient.length ==0})} onClick={handleFormSubmit}>
                    {buttonContent}
                </div>
            </div>
        </div>

    )
}

export default AddNewPatientForm;
