import React from "react";
import {map} from "lodash";

import emptyNotification from "./../../../../../../../../../assets/images/empty_notification.svg"

import styles from "./body.module.css"
import NotesCard from "./component/NotesCard";

const Body = ({notes=[]}) =>{

    if(notes.length == 0 ){
        return (
            <div className={styles.container}>
                <img src={emptyNotification} className={styles.emptyIcon}/>
                <div className={styles.noContent}>
                    No Notes Added Yet!
                </div>
            </div>
        )
    }

    return (
        <div className={styles.container}>
            {map(notes,(note)=>{
                return <NotesCard {...note}/>
            })}
        </div>
    )
}

export default Body;