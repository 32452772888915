import moment from "moment";

import styles from "./glucoseLevelDisplayModal.module.css";
import { Spinner } from "react-bootstrap";

const getModalHeader = ({day}) => ()=>{
    return (
        <div className="first-div-dialog-box">
          <p className="date-dialog-box">
             {moment().subtract(7-(day%9),'d').format("dddd MMMM Do YYYY")} 
             </p>
          <div className="blood-glucose-dialog-box-div">
            <p className="blood-glucose-dialog-box-text">
              Blood Glucose Daily Averages
            </p>
          </div>
        </div>
    )
}

const textTheme = {
  GREEN_IN_TARGET: styles.greenText,
  RED_IN_TARGET: styles.redText,
  YELLOW_IN_TARGET: styles.yellowText,
  RED: styles.redText,
  GREEN: styles.greenText,
  YELLOW: styles.yellowText
}

const getModalBody = (postPrandial =[], timeInRange, isLoading) =>()=>{

  const {glucoseDetails: glucoseBreakfast}  = postPrandial[1] || {};
  const {glucose : glucosefast = ""} = glucoseBreakfast || {};

  const {glucoseDetails: glucoseLunch}  = postPrandial[2] || {};
  const {glucose : glucoseLunchValue = ""} = glucoseLunch || {};

  const {glucoseDetails: glucoseDinner}  = postPrandial[3] || {};
  const {glucose : glucoseDinnerValue = ""} = glucoseDinner || {};

  const {glucose: timeInRangeGlucose ,glucoseColor : timeInRangeGlucoseColor} = timeInRange;

    if(isLoading)
    {
      return <div className={styles.spinner}><Spinner /></div>
    }
    
    return (
        <>
        <div className="glucose-fasting">
          <div className="glucose-range-div">
            <p className="glucose-range-text">Glucose Range</p>
          </div>

          <div className="glucose-range-div">
            <p className="glucose-range-text">Fasting</p>
          </div>
        </div>

        <div className="percent-div-dialog">
          <div className="first-div-percent-dialog">
            <span className={`first-div-percent-dialog-span ${textTheme[timeInRangeGlucoseColor]}`}>{timeInRangeGlucose}%</span>
          </div>

          <div className="second-div-percent-dialog">
            <div className="second-div-percent-dialog-first">
              <span className={`second-div-percent-dialog-first-span  ${textTheme[postPrandial[0]?.glucoseDetails["glucose-color"]]}`}>{postPrandial[0]?.glucoseDetails?.glucose}</span>
            </div>
            <div className="second-div-percent-dialog-second">
              <span className="second-div-percent-dialog-second-span">
                mg/dL
              </span>
            </div>
          </div>
        </div>

        <div className="time-fasting-div">
          <div className="time-fasting-first">
            <span className="time-fasting-first-span">Time in range</span>
          </div>
          <div className="time-fasting-second">
            <span className="time-fasting-second-span">Fasting</span>
          </div>
        </div>

        <div className="post-prandials-div">
          <span className="post-prandials-div-span">
            1 - Hour Post Prandials
          </span>
        </div>

        <div className="meal-number">
          <div className="meal-number-first">
            <p className={`meal-number-first-p ${textTheme[postPrandial[1]?.glucoseDetails["glucose-color"]]}`}>{glucosefast}</p>
            <p className="meal-second-p">mg/dl</p>
          </div>
          <div className="meal-number-second">
            <p className={`meal-number-second-p ${textTheme[postPrandial[2]?.glucoseDetails["glucose-color"]]}`}>{glucoseLunchValue}</p>
            <p className="meal-second-p">mg/dl</p>
          </div>
          <div className="meal-number-third">
            <p className={`meal-number-third-p ${textTheme[postPrandial[3]?.glucoseDetails["glucose-color"]]}`}>{glucoseDinnerValue}</p>
            <p className="meal-second-p">mg/dl</p>
          </div>
        </div>
        <div className="meal-text-div">
          <div className="meal-text-div-inside">
            <span className="meal-text-div-span">BREAKFAST</span>
          </div>
          <div className="meal-text-div-inside">
            <span className="meal-text-div-span">LUNCH</span>
          </div>
          <div className="meal-text-div-inside">
            <span className="meal-text-div-span">DINNER</span>
          </div>
        </div>
        <div className="related-trends-dialog">
          <div className="related-trends-dialog-first">
            <img
              src={require("../../../assets/images/down-arrow-dialog.svg").default}
            />
          </div>
          <div className="related-trends-dialog-second">
            <span className="related-trends-dialog-second-p">
              show related trends
            </span>
          </div>
        </div>

        </>
    )
}

const getModalFooter =() => ()=>{
    return (
    <div className="dialog-button">
      <div className="dialog-button-first">
        <span className="dialog-button-first-span">
          View Advanced Daily
        </span>
      </div>
    </div>
    )
}

export {
    getModalHeader,
    getModalBody,
    getModalFooter
}