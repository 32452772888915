import moment from "moment"

import ModalBody from "../../../BalancedPlate/components/TableContent/components/ModalBody/ModalBody"
import ModalHeader from "../../../BalancedPlate/components/TableContent/components/ModalHeader/ModalHeader"


import enabledWakeUpIcon from "./../../../../../../../../../../../assets/images/timer/basicEnabledTimer.svg";
import disabledWakeUpIcon from "./../../../../../../../../../../../assets/images/timer/basicDisabledTimer.svg";

import disabledFoodIcon from "./../../../../../../../../../../../assets/images/meals/curtalary/disabledCurtalary.svg";
import enabledFoodIcon from "./../../../../../../../../../../../assets/images/meals/curtalary/enabledCurtalary.svg";
import { GLUCOSE_METRICS_SOURCE_TYPES } from "../../../../../../../../../../constants/metricTypes";

const getRespectiveColorRanges = (data)=>{
    return data<=63 ? 'RED' : data>=140 ? "YELLOW" : 'GREEN'
}

const getRespectiveModelContent = ({loggedTime}) =>{
    return {
        HeaderComponent : ModalHeader,
        BodyComponent : ModalBody,
        headerProps :{
            date : moment(loggedTime).format("MMM DD,YYYY - ddd "),
            time : moment(loggedTime).format("h:mm A"),
            label : "Day-by-Day Glucose Trend Analysis"
        }
    }
}

const getRespectiveIcon = (data)=>{
    switch(data?.meal){
        case "FASTING" : 
            return data?.isFastingBGBasedOnWakeUp ? enabledWakeUpIcon : disabledWakeUpIcon;
            break;
        default:
            return data?.isMetricsCalculatedFromMeal ? enabledFoodIcon : disabledFoodIcon;
            break;
    }
}

const getCalculatedType = (data) =>{
    if(!data?.metricsDataSourceType){
        return ""
    }

    if(data?.metricsDataSourceType?.includes(GLUCOSE_METRICS_SOURCE_TYPES.CGM)){
        return "C";
    }

    return "B"
}


export {
    getRespectiveColorRanges,
    getRespectiveModelContent,
    getRespectiveIcon,
    getCalculatedType
}