import { atom } from "recoil";

const start = new Date();
start.setDate(start.getDate())
const end = new Date();

const formattedStartISO = start.toISOString().split('T')[0];
const formattedEndISO = end.toISOString().split('T')[0];

export const startDateRecoil = atom({
  key: 'startDateRecoil',
  default: formattedStartISO
});

export const endDateRecoil = atom({
  key: 'endDateRecoil',
  default: formattedEndISO
});