import React from "react";
import BasicUserProfile from "./../../../BasicUserProfile";

const RightContainer = ({type, ...rest}) =>{
    return (
        <>
            <BasicUserProfile {...rest}/>
        </>
    )
}

export default RightContainer;