import { useCallback, useEffect, useMemo, useState } from "react";

import BasicToast from "../../molecules/baseComponents/toasts/BasicToast";
import DefaultSpinner from "../../atoms/spinner/Spinner";

import useToast from "./customHooks/useToast";
import { getPaginatedList, getSearchTextFilteredList } from "./practiceManagement.helpers";
import { getResendInviteAction } from "./practiceManagement.async";
import Pagination from "./components/pagination/Pagination";
import PageHeaderContext from "./components/pageHeaderContext/PageHeaderContext";
import Heading from "./components/heading/Heading";
import Tabs from "./components/tabs/Tabs";
import Search from "./components/search/Search";
import EmptyBag from "./components/emptyBag/EmptyBag";
import usePracticeForm from "./customHooks/usePracticeform";
import usePracticeList from "./customHooks/usePracticeList";
import Table from "./components/table/Table";

import styles from "./practiceManagement.module.css";
import withPagination from "../../hoc/withPagination";


const PracticeManagement = () => {
    const [ isLoading, setIsLoading ] = useState(false);

    const [currentTab, setCurrentTab]= useState('ACTIVE');
    const [formValues,submitFormValues,updateFormValues,resetForm] = usePracticeForm(setIsLoading);
    const [practiceList=[],updatePracticeTab] = usePracticeList(currentTab,setIsLoading);
    const [ handleCloseToastDisplay=()=>{},handleOpenToastDisplay=()=>{},toastType,displayToast,message] = useToast();
    const [ searchText, updateSearchText] = useState("");
    const [ currentPagination, setCurrentPagination] = useState(1);

    useEffect(()=>{
        updatePracticeTab(currentTab)
    },[currentTab]);

    const updateCurrentTab = useCallback((tab)=>{

        setCurrentTab(tab);
    },[setCurrentTab]);

    const resentInviteAction = (baseId)=>{
        getResendInviteAction({baseId}).then(()=>{
            handleOpenToastDisplay("success","Invite Send Successfully");
        })
        .catch((e)=>{
            handleOpenToastDisplay("success","Invite Failed");
            console.log(e);
        })
    }

    const handlePagincationAction = (updatedPagination) =>{
        if(updatedPagination<1 || updatedPagination>Math.ceil((practiceList.length || 0)/10)){
            return;
        }
        setCurrentPagination(updatedPagination);
    }

    const handleFormSubmitAction = ()=>{
        let promise = submitFormValues();
        promise.then((data)=>{
            if(data){
                handleOpenToastDisplay("success")
            }
            else{
                handleOpenToastDisplay('failed')
            }
            resetForm();
        })
    };

    const PaginatedTable = withPagination(Table);

    const filteredList = useMemo(()=>getSearchTextFilteredList(practiceList,searchText),[practiceList,searchText]);    

    return (
        <div className={styles.mainContainer}>
            {displayToast && 
            <BasicToast 
                type={toastType} 
                display={displayToast} 
                handleToastToggle={handleCloseToastDisplay}
                message={message}
            />}
            { isLoading && <DefaultSpinner className={styles.loader} />}
            <PageHeaderContext />
            <Heading resetForm={resetForm} formValues={formValues} updateFormValues={updateFormValues} handleFormSubmitAction={handleFormSubmitAction}/>
            <Tabs updateCurrentTab={updateCurrentTab} currentTab={currentTab} />
            <Search updateSearchText={updateSearchText} />
            {filteredList.length==0 && <EmptyBag resetForm={resetForm} formValues={formValues} updateFormValues={updateFormValues} handleFormSubmitAction={handleFormSubmitAction}/>}
            {filteredList.length>0 && <PaginatedTable limit={10} tab={currentTab} resentInviteAction={resentInviteAction} tableData={filteredList} />}
        </div>
    )
}

export default PracticeManagement;