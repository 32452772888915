import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import cx from "classnames";
import {map} from "lodash";

import styles from "./fixedSliderChart.module.css";
import DropdownIcon from "../../../../assets/iconComponents/DropdownIcon";

const FixedSliderChart = ({ranges=[{
            from:6,
            to:10.8,
            style:{
                backgroundColor:"pink"
            },
            color:"#F6B4A7",
            polygonColor:"#F6B4A7"
        },
        {
            from:10.8,
            to:15.3,
            style:{
                backgroundColor:"green"
            },
            color:"#FFF0A8",
            polygonColor :"#FFF0A8"
        },
        {
            from:15.3,
            to:18,
            style:{
                backgroundColor:"green"
            },
            color:"#7ACCAE",
            polygonColor:"#7ACCAE"
        }
    ], 
    className,
    start=6,
    total=18,
    polygonValue=16,
    }) =>{

    const [currentWidth,setCurrentWidth] = useState();
    const [currentRange,setCurrentRange] = useState(ranges);
    const [polygonProps, setPolygonProps] = useState({});

    const chartRef= useRef();

    const getCorrespondingWidth = ({totalWidth=0,total=0,current=0})=>{
        return (current/total)*totalWidth;
    }

    const getCorrespondingFill = (value) =>{
        let val=""
        currentRange?.forEach(({from, to, polygonColor})=>{
            if(value>=from && value<=to){
                val=polygonColor;
            }
        })
        return val;
    }

    useLayoutEffect(()=>{
        setCurrentWidth(chartRef?.current?.offsetWidth);
        setCurrentRange((currentRange)=>{
            return currentRange?.map((range)=>{
                return {
                    ...range,
                    width:getCorrespondingWidth({totalWidth:chartRef?.current?.offsetWidth,total:total-start,current:(range?.to-range?.from) || 0})
                }
            })
        });

        setPolygonProps(()=>{
            return {
                fill: getCorrespondingFill(polygonValue),
                leftMargin: getCorrespondingWidth({totalWidth:chartRef?.current?.offsetWidth,total : total-start,current:polygonValue-start})
            }
        })
    },[chartRef?.current?.offsetWidth])

    return (
        <div className={cx(styles.container, className)} ref={chartRef}>
            <div style={{marginLeft:`${polygonProps?.leftMargin}px`}}>
                <DropdownIcon fill={polygonProps?.fill} />
            </div>
            <div className={styles.secondaryInnerContainer}>
                {map(currentRange,(range)=>{
                    const { style, color, width}= range;
                    return (
                        <div style={{...style,minWidth:`${width}px`, height:"100%", backgroundColor:color}}/>
                    )
                })}
            </div>
        </div>
    )
}

export default FixedSliderChart;