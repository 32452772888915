import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./pageHeaderContext.module.css"

const PageHeaderContext = ({practiceName, memberName, practiceId=""}) => {

    const navigate = useNavigate();

    const handlePracticeManagementClick = useCallback(()=>{
        navigate("/superUser");
    },[navigate])

    const handleBackToPracticeAction = useCallback(()=>{
        navigate(`/superUser/${practiceId}/${practiceName}`)
    },[navigate]);

    return (    
            <div className={styles.pageHeaderContext}>
                <div className={styles.subHeading}><div className={styles.subHeadingPractice}><span onClick={handlePracticeManagementClick}>Practice Management</span> /  <span onClick={handleBackToPracticeAction}>{practiceName}</span>  </div>/  {memberName}</div>
            </div>

    )
}

export default PageHeaderContext;