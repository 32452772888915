import React, { useState } from "react";

// import Notifications from "../components/notifications/Notifications";
import CustomActionDropdown from "../../../molecules/baseComponents/dropDowns/customActionDropdown/CustomActionDropdown";
import UserProfile from "../components/userProfile";

import styles from "./container.module.css";

const RightContainer = ({ options }) =>{

    return (
        <div className={styles.rightContainer}>
            <CustomActionDropdown optionClassName={styles.optionClassName} Component={UserProfile} options={options} />
        </div>
    )
}

export default RightContainer;