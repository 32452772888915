import NutritionalCard from "./components/NutritionalCard/NutritionalCard"
import { spikeAnalysisBaseData } from "./nutritionalDetails.data"

const getNutritionalDetailsOptions = ({nutritionalBreakdown, spikeAnalysis}) =>{
    return [{
        Component : NutritionalCard,
        headerProps:{
            label : "Nutritional Breakdown"
        },
        bodyProps:{
            options : nutritionalBreakdown
        }
    },
    {
        Component : NutritionalCard,
        headerProps:{
            label : "Spike Analysis"
        },
        bodyProps:{
            options : spikeAnalysis,
        }
    }]
}

export {
    getNutritionalDetailsOptions
}