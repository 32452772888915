import React,{useState} from "react";

import TableHeader from "./components/tableHeader/TableHeader";
import TableBodyContainer from "./components/tableBody/TableBody.container";

import styles from "./ganntOverviewTable.module.css"

const GanttOverViewTable = (state) =>{
    const [calenderUpdate,setCalenderUpdate] = useState(0);

    return (
        <div>
            <TableHeader calenderUpdate={calenderUpdate} setCalenderUpdate={setCalenderUpdate} />
            <div className={styles["tableBodyContainer"]}>
                <TableBodyContainer calenderUpdate={calenderUpdate} {...state}/>
            </div>
        </div>
    )
}

export default GanttOverViewTable;