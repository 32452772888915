import React from "react";
import cx from "classnames";

import styles from "./button.module.css";
import handleClick from "../form.async";
import { useNavigate } from "react-router-dom";

const Button = ({password, isPasswordsMatching}) =>{

    const navigate = useNavigate();

    const handleButtonClick = ()=>{
        if(isPasswordsMatching){
            return;
        }
        handleClick(navigate, password);
    }
    return (
        <div className={cx(styles.container,{[styles.inActive]: isPasswordsMatching})} onClick={handleButtonClick}>
            Create Account
        </div>
    )
}

export default Button;