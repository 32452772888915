import React from 'react';

import AveragePlateSummaryHeader from "./components/AveragePlateSummary/components/Header";
import AveragePlateSummary from './components/AveragePlateSummary/AveragePlateSummary';

import NutritionalBalance from './components/NutritionalBalance';
import NutritionalBalanceHeader from './components/NutritionalBalance/components/Header';

import styles from "./summary.module.css";

const getSummaryFields = (microNutritionalValues={},nutritionalValues={},rangeByMicroNutrientMap) =>{
    return [{
        HeaderComponent : AveragePlateSummaryHeader ,
        headerProps :{
            label : "Average Plate Summary",
            className : styles?.summaryHeaderClassname
        },
        BodyComponent : AveragePlateSummary,
        bodyProps:{
            bodyClassname : styles?.averagePlateSummaryBodyClass,
            nutritionalValues : microNutritionalValues,
        },
        containerClassName : styles.averagePlateSummaryClass
    },{
        HeaderComponent : NutritionalBalanceHeader,
        BodyComponent : NutritionalBalance,
        bodyProps:{
            bodyClassname : styles?.nutritionalBalanceBodyClass,
            microNutritionalValues : nutritionalValues,
            rangeByMicroNutrientMap
        },
        containerClassName : styles.averagePlateSummaryClass,
        headerProps :{
            label : "Nutritional Balance",
            className : styles?.summaryHeaderClassname
        },
    }
]
}

export {getSummaryFields}