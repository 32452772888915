const BALANCE_PLATE_COLOR_CODINGS = {
    BALANCED : "#E5FAF2",
    CRITICAL : "#FEEFEB",
    UNBALANCED : "#FFF6BD"
};

const BALANCE_PLATE_BORDER_COLOR_CODING = {
    BALANCED : "#7ACCAE",
    CRITICAL : "#EE9887",
    UNBALANCED : "#E4A944",
}


export {
    BALANCE_PLATE_COLOR_CODINGS,
    BALANCE_PLATE_BORDER_COLOR_CODING
}