import ErrorComponent from "../../atoms/errorComponent/ErrorComponent";

import { ERROR_STATUS } from "../../constants/errorStatus";
import { spaceRemovalKeyDownEvent } from "../../helpers/TextManipulations.helpers";
import CustomCheckbox from "../../molecules/customCheckbox";
import CustomPasswordInput from "../../molecules/CustomPasswordInput";
import ErrorPopup from "../../molecules/ErrorPopup/ErrorPopup";
import { getPasswordLabel } from "./login.component";

import styles from "./login.module.css";

const LoginFormFields = (navigate)=> [{
    name:"Email",
    label: "Email",
    fieldType : "email",
    autoFocus:true,
    ErrorComponent : ErrorComponent,
    PlaceHolder: "name@gmail.com",
    onKeyDown:spaceRemovalKeyDownEvent,
    onFocus:()=>{},
},
{
    name:"Password",
    label: getPasswordLabel("Password", navigate),
    errorTypes : [{type: ERROR_STATUS.IS_REQUIRED}],
    fieldType : "password",
    CustomInput : CustomPasswordInput,
    inputFieldRow: styles.inputFieldRow,
    errorProps: {
        className: styles.errorClassName
    },
    fieldClassname: styles.passwordInputField,
    PlaceHolder: "Enter the password",
    onBlur:()=>{},
    onKeyDown:spaceRemovalKeyDownEvent,
}
,
{
    name:"trustDevice",
    label: "",
    fieldType : "",
    CustomInput : CustomCheckbox,
    onBlur:()=>{},
    onFocus:()=>{}
}
];

export {LoginFormFields}