
import styles from "./indicator.module.css"

const BAR_TYPE={
    RED: styles.redIndicator,
    YELLOW: styles.yellowIndicator,
    GREEN: styles.greenIndicator,
    GREEN_IN_TARGET: styles.greenIndicator,
    UNKNOWN: styles.redIndicator
}

export {
    BAR_TYPE
}