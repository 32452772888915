import React from 'react';

import styles from "./notes.module.css";
import Header from './components/Header/Header';
import Body from './components/Body/Body';

const Notes = ({notes, headerProps, bodyProps}) =>{
    return (
        <div className={styles.container}>
            <Header {...headerProps} />
            <Body {...bodyProps} notes={notes}/>
        </div>
    )
}

export default Notes;