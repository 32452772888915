import React from "react";
import {map} from "lodash";

import { getSecondaryHeaderFields } from "./secondaryHeader.fields";

import styles from "./secondaryHeader.module.css";
const SecondaryHeader = ({balancedPlateData, date}) =>{
    return (
        <div className={styles.container}>
            {map(getSecondaryHeaderFields(balancedPlateData?.micronutrientsInsightsResponseMap?.[date]),(field)=>{
                const {name, label , suffix , value} = field || {};
                return (
                    <div className={styles.cardTypes}>
                        <div className={styles.primaryText}>
                            {label}
                        </div>
                        { (value ==0 || value == undefined ) && <div className={styles.secondaryText}> No Data</div>}
                        { (value !=0 && value != undefined ) &&<div className={styles.primaryText}>
                            <span className={styles.secondaryText}>{value}</span>
                            {suffix}
                        </div>}
                    </div>
                )
            })}
        </div>
    )
}

export default SecondaryHeader;