import React from "react";
import cx from "classnames";

import styles from "./threeLinedCard.module.css";

const ThreeLinedCard = ({type,value,suffix, fallbackClass="", fallbackText="Not Available", textClassName=""}) =>{
    return (
        
        <div className={styles.container}>
            <div className={styles.primaryText}>
                {type}
            </div>
            {type && value == undefined && <div className={cx(styles.noData,fallbackClass)}>{fallbackText}</div>}
            { value != undefined&&
            <div className={styles.primaryText}>
                <span className={cx(styles.secondaryText,textClassName)}>{value}</span>
                <span>{suffix}</span>
            </div>
            }
        </div>
    )
}

export default ThreeLinedCard;