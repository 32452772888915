import React from "react";
import DailyGlucoseChart from "../../../../../../charts/patientProfile/overView/DailyGlucoseChart";

import styles from "./glucoseDataChart.module.css";

const GlucoseDataChart = ({glucoseChartData, mealLogs, formattedMealLogs, glucoseData, offset=0})=>{
    return (
        <>
            <div className={styles.horizontalDivider} />
            <DailyGlucoseChart
                comparisonAnalysisData={glucoseChartData}
                date={Object?.keys(glucoseData)[0]}
                mealLogs={formattedMealLogs}
                offset={offset}
                originalMealLogs={mealLogs}
            />
        </>
    )
}

export default GlucoseDataChart