import React from "react";
import cx from "classnames";

import styles from "./footer.module.css";

const Footer = ({formSubmitHandler, buttonText})=>{
    return (
        <div className={styles.container}>
            <button className={cx(styles.createButton,styles.enabledClassname)} onClick={formSubmitHandler}>
                {buttonText}
            </button>
            <div className={styles.individualPractionerButton}>
                I’m an Individual Practitioner
                <img src={require("../../../../../assets/images/info_icon.svg").default} className={styles.infoIcon} />
            </div>
        </div>
    )
}

export default Footer;