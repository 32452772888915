import React from "react";

import { HEADER_ENUMS } from "../../receentActivity.constants";

import styles from "./header.module.css";

const Header = ({heading = HEADER_ENUMS.RECENT_ACTIVITY_HEADER,containerClass, img="", imageClass=""}) =>{
    return (
        <div className={`${styles.container} ${containerClass}`}>
            <img src={img} className={imageClass} />
            <span >{heading}</span>
        </div>
    )
}

export default Header;