import React, { useLayoutEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import profile_image from "../../../assets/images/profile_image.png";
import care_team_image from "../../../assets/images/ic_profile.jpeg";
import NoodlesPic from "../../../assets/images/Noodles.png"

import EclipseRange from "../../molecules/eclipseRange";
import MyChart from "../../MyChart";
import actions from "./dashBoardInformation.actions";
import GanttOverViewTableContainer from "../ganttOverviewTable/GanttOverViewTableContainer";
import withActionState from "../../hooks/withActionState";
import RecentActivityReports from "../RecentActivityReports";

import actionTypes from "./dashBoardInformaton.actionTypes";

const DashBoardInformation = ({dispatch, recentActivity=[],togglePrandialDialogBox=()=>{}, isLoading  ,...rest}) =>{

    useLayoutEffect(()=>{
      dispatch({actionType: actionTypes.INIT_STATE})
    },[]);

    if(isLoading)
    {
      return <></>
    }

  
    return (
        <div className="gantt-overview-div">
        <div className="recent-activity-section-main-div">
            <div className="recent-activity-text-div">
              <span className="recent-activity-text-div-span">
                Recent Activity Reports
              </span>
            </div>

            <div className="recent-activity-heading-div">
              <div className="recent-activity-blood-glucose-div">
                <span className="recent-activity-blood-glucose-div-span">
                  Blood Glucose
                </span>
              </div>
              <div className="recent-activity-food-log-div">
                <span className="recent-activity-food-log-div-span">
                  Food Log
                </span>
              </div>
              <div className="recent-activity-movement-div">
                <span className="recent-activity-movement-div-span">
                  Movement
                </span>
              </div>
              <div className="recent-activity-mood-div">
                <span className="recent-activity-mood-div-span">
                  Mood & Stress
                </span>
              </div>
              <div className="recent-activity-rest-recovery-div">
                <span className="recent-activity-rest-recovery-div-span">
                  Rest & Recovery
                </span>
              </div>
              <div className="recent-activity-black-underline"></div>
            </div>
            <div className="recent-activity-data">
              <RecentActivityReports clickHandler={togglePrandialDialogBox} recentActivity={recentActivity.slice(0,2)} type={"Fasting Glucose"} val={98} />


              <RecentActivityReports clickHandler={togglePrandialDialogBox} recentActivity={recentActivity.slice(2,4)} type={"Post Prandial Breakfast"} val={90} />


              <RecentActivityReports clickHandler={togglePrandialDialogBox} recentActivity={recentActivity.slice(4,6)} type={"Post Prandial Lunch"} val={155} />


              <RecentActivityReports clickHandler={togglePrandialDialogBox} recentActivity={recentActivity.slice(6,8)} type={"Post Prandial dinner"} val={140} />

              <div className="recent-activity-graph">
                <figure className="highcharts-figure">
                  <MyChart />
                </figure>
              </div>
            </div>
        </div>
        <div className="gantt-second-div">
          <div className="data-stream-div">
            <div className="data-stream-text-div">
              <span className="data-stream-text">Data Stream</span>
            </div>
          </div>
          <div className="log-name-div">
          <div className={"log-name-div-content gannt-overview-container"}>
                  <span className="gantt-overview">Gantt Overview</span>
            </div>
            <div className="log-name-div-content">
              <div className="icon-div">
                <div>
                  <img
                    className="blood-glucose-log-icon"
                    src={
                      require("../../../assets/images/blood-glucose-log-icon.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-name-trend">
                <div className="log-name">
                  <span className="log-name-span">Blood Glucose Logs</span>
                </div>
                <div className="log-trend">
                  <span className="log-trend-span">5 Trends</span>
                </div>
              </div>
            </div>

            <div className="log-name-div-content">
              <div className="icon-div">
                <div>
                  <img
                    className="blood-glucose-log-icon"
                    src={
                      require("../../../assets/images/food-log.svg").default
                    }
                  />
                </div>
              </div>
              <div className="log-name-trend">
                <div className="log-name">
                  <span className="log-name-span">Food Logs</span>
                </div>
                <div className="log-trend">
                  <span className="log-trend-span">4 Trends</span>
                </div>
              </div>
            </div>
            <div className="log-name-div-content">
              <div className="icon-div">
                <div>
                  <img
                    className="blood-glucose-log-icon"
                    src={
                      require("../../../assets/images/moment-activity.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-name-trend">
                <div className="log-name">
                  <span className="log-name-span">
                    Movement & Activity Log
                  </span>
                </div>
                <div className="log-trend">
                  <span className="log-trend-span">2 Trends</span>
                </div>
              </div>
            </div>
            <div className="log-name-div-content">
              <div className="icon-div">
                <div>
                  <img
                    className="blood-glucose-log-icon"
                    src={
                      require("../../../assets/images/mood-stress-log.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-name-trend">
                <div className="log-name">
                  <span className="log-name-span">Mood & Stress Log</span>
                </div>
                <div className="log-trend">
                  <span className="log-trend-span">5 Trends</span>
                </div>
              </div>
            </div>
            <div className="log-name-div-content">
              <div className="icon-div">
                <div>
                  <img
                    className="blood-glucose-log-icon"
                    src={
                      require("../../../assets/images/rest-recovery-log.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-name-trend">
                <div className="log-name">
                  <span className="log-name-span">Rest & Recovery Log</span>
                </div>
                <div className="log-trend">
                  <span className="log-trend-span">5 Trends</span>
                </div>
              </div>
            </div>

            <div className="log-name-div-content-last">
              <div className="icon-div-last">
                <div className="last-icon">
                  <img
                    src={
                      require("../../../assets/images/down-arrow-log.svg")
                        .default
                    }
                  />
                </div>
                <div className="log-show-div">
                  <span className="log-show-text">Show</span>
                </div>
              </div>
              <div className="more-icon-div">
                <div className="show-icons-div">
                  <img
                    src={require("../../../assets/images/syringe.svg").default}
                  />
                </div>
                <div className="show-icons-div">
                  <img
                    src={
                      require("../../../assets/images/blood-pressure.svg")
                        .default
                    }
                  />
                </div>
                <div className="show-icons-div">
                  <img
                    src={
                      require("../../../assets/images/hydration.svg").default
                    }
                  />
                </div>
                <div className="show-icons-div">
                  <img
                    src={
                      require("../../../assets/images/mobility.svg").default
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <GanttOverViewTableContainer />
        </div>
        <div className="gantt-third-div">
          <div className="communications-report-div">
            <div className="communication-report-text-div">
              <span className="communication-report-text">
                Communication & Reports
              </span>
            </div>
          </div>
          <div className="report-notes-div">
            <div className="gantt-chart-second-row-second-column">
              <div className="log-name-div-content">
                <div className="icon-div">
                  <div>
                    <img
                      className="blood-glucose-log-icon"
                      src={
                        require("../../../assets/images/daily-weekly.svg")
                          .default
                      }
                    />
                  </div>
                </div>
                <div className="log-name-trend">
                  <div className="log-name">
                    <span className="log-name-span">
                      Daily & Weekly Reports
                    </span>
                  </div>
                  <div className="log-trend below-reports">
                    <span className="log-trend-span">3 unreviewed</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="gantt-chart-second-row-second-column">
              <div className="log-name-div-content">
                <div className="icon-div">
                  <div>
                    <img
                      className="blood-glucose-log-icon"
                      src={
                        require("../../../assets/images/coach-notes.svg")
                          .default
                      }
                    />
                  </div>
                </div>
                <div className="log-name-trend">
                  <div className="log-name">
                    <span className="log-name-span">
                      Coach Notes & Sessions
                    </span>
                  </div>
                  <div className="log-trend below-reports">
                    <span className="log-trend-span">all caught up</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="gantt-chart-second-row-second-column">
              <div className="log-name-div-content">
                <div className="icon-div">
                  <div>
                    <img
                      className="blood-glucose-log-icon"
                      src={
                        require("../../../assets/images/provider-notes.svg")
                          .default
                      }
                    />
                  </div>
                </div>
                <div className="log-name-trend">
                  <div className="log-name">
                    <span className="log-name-span">
                      Provider Notes & Sessions
                    </span>
                  </div>
                  <div className="log-trend below-reports">
                    <span className="log-trend-span">2 unreviewed</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="log-chart-div log-chart-div-second-row ">
            <div className="log-chart-row gantt-second-row-third-column-div">
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice ">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice ">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice ">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice ">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice ">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice ">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice ">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
            </div>
            <div className="log-chart-row gantt-second-row-third-column-div">
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
            </div>
            <div className="log-chart-row  gantt-second-row-third-column-div">
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="log-chart-row-single-tab report-div-for-flex">
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
                <div className="image-div-invoice opacity-div-second-report">
                  <img
                    className="report-first-image"
                    src={
                      require("../../../assets/images/invoice-logo.svg")
                        .default
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="gantt-fourth-div">
          <div className="communications-report-div">
            <div className="communication-report-text-div">
              <span className="communication-report-text">
                Prescribed Action Plans
              </span>
            </div>
          </div>
          <div className="report-notes-div">
            <div className="gantt-chart-second-row-second-column">
              <div className="log-name-div-content">
                <div className="icon-div">
                  <div>
                    <img
                      className="blood-glucose-log-icon"
                      src={
                        require("../../../assets/images/educational.svg")
                          .default
                      }
                    />
                  </div>
                </div>
                <div className="log-name-trend">
                  <div className="log-name">
                    <span className="log-name-span">
                      Educational Content
                    </span>
                  </div>
                  <div className="log-trend below-reports">
                    <span className="log-trend-span">1 unreviewed</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="gantt-chart-second-row-second-column">
              <div className="log-name-div-content">
                <div className="icon-div">
                  <div>
                    <img
                      className="blood-glucose-log-icon"
                      src={
                        require("../../../assets/images/one-thing-svg.svg")
                          .default
                      }
                    />
                  </div>
                </div>
                <div className="log-name-trend">
                  <div className="log-name">
                    <span className="log-name-span">One th1ng</span>
                  </div>
                  <div className="log-trend below-reports">
                    <span className="log-trend-span">1 unreviewed</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="gantt-chart-second-row-second-column">
              <div className="log-name-div-content">
                <div className="icon-div">
                  <div>
                    <img
                      className="blood-glucose-log-icon"
                      src={
                        require("../../../assets/images/mindful.svg").default
                      }
                    />
                  </div>
                </div>
                <div className="log-name-trend">
                  <div className="log-name">
                    <span className="log-name-span">Mindful Moment</span>
                  </div>
                  <div className="log-trend below-reports">
                    <span className="log-trend-span">2 unreviewed</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="gantt-chart-second-row-second-column">
              <div className="log-name-div-content">
                <div className="icon-div">
                  <div>
                    <img
                      className="blood-glucose-log-icon"
                      src={
                        require("../../../assets/images/challenge.svg").default
                      }
                    />
                  </div>
                </div>
                <div className="log-name-trend">
                  <div className="log-name">
                    <span className="log-name-span">Challenge</span>
                  </div>
                  <div className="log-trend below-reports">
                    <span className="log-trend-span">2 unreviewed</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="log-chart-div last-row-last-column-chart"></div>
        </div>
      </div>
    )
}

export default withActionState({Component: DashBoardInformation,action:actions}); 