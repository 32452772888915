import React from "react";
import cx from "classnames";

import styles from "./baseTableBlock.module.css";

const BaseTableBlock = ({label = ()=>{}, className = "", innerClassName="",typography="",...rest})=>{
    return (
        <div className={cx(styles.container,className)}>
            <div className={cx(styles.innerTextContainer,innerClassName,typography)}>
                {label(rest)}
            </div>
        </div>
    )
}

export default BaseTableBlock;