
const PATIENT_LIST_STATUS = {
    ADD : "Add",
    REMOVE : "Remove"
}

const PATIENTS_SEARCH_BOX = {
    PLACEHOLDER : "Enter The Patient's Name"
}

export {
    PATIENT_LIST_STATUS,
    PATIENTS_SEARCH_BOX
}