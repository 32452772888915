import { v4 as uuidv4 } from 'uuid';

import {filter} from "lodash";
import { FORM_KEYS } from './practiceManagement.constants';

const getSearchTextFilteredList = (list=[],searchText="")=>{
    return filter(list,(listItem)=>{
        const {practiceName="", practiceAdminName="", practiceWebsite="",practiceAdminEmail=""} = listItem;

        return (practiceName && practiceName.includes(searchText)) || (practiceAdminName && practiceAdminName.includes(searchText)) || (practiceWebsite && practiceWebsite.includes(searchText)) || (practiceAdminEmail &&  practiceAdminEmail.includes(searchText));
    })
}

const getPaginatedList = (list=[], currentPagination) =>{
    return list.slice((currentPagination-1)*10,Math.min(list.length,((currentPagination-1)*10)+10));
}

const getSubmitFormattedData = (data)=>{
    let resultObj={
        practiceAutoGeneratedDivisionId : uuidv4()
    };
    Object.values(FORM_KEYS).forEach((val)=>{
        switch(val){
            case FORM_KEYS.PRACICE_ADDRESS:
                resultObj[val]= (data?.['practiceAddressOne'] || "")+" "+(data?.['practiceAddressTwo'] || "");
                break;
            default:
                resultObj[val] = data?.[val] || "";
                break;
        }
    })
    return resultObj;
}

export {
    getSearchTextFilteredList,
    getPaginatedList,
    getSubmitFormattedData
}