import { useState } from "react";

import styles from "./tabs.module.css"

const Tabs = ({activeTab, updateActiveTab}) => {

    const handleClick = (currentValue) => {
        updateActiveTab(currentValue);
    };

    return (

        <div className={styles.tabOptions}>
            <div className={activeTab == "ACTIVE" ? styles.tabActive : styles.tab}
                onClick={() => handleClick("ACTIVE")}>Active</div>
            <div className={activeTab == "INVITED" ? styles.tabActive : styles.tab}
                onClick={() => handleClick("INVITED")}>Invited</div>
            <div className={activeTab == "ARCHIVED" ? styles.tabActive : styles.tab}
                onClick={() => handleClick("ARCHIVED")}>Archived</div>
        </div>

    )
}

export default Tabs;
