import React from "react";

import cx from "classnames";

import styles from "./loginPinCardComponent.module.css";

const CardFooter = ({enableButton,handleButtonSubmit, resendInvite}) =>{
    return (
        <div className={styles.cardFooterContainer}>
            <button className={cx(styles.footerButton,{[styles.enabled]:enableButton})} onClick={handleButtonSubmit} disabled={!enableButton}>
                Verify
            </button>
            <div className={cx(styles.footerContent)}>
                <span>Didn't recieve the code?</span> <span className={styles.resendInvite} onClick={resendInvite}> Resend</span>
            </div>
        </div>
    )
}

export default CardFooter;