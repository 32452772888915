import React from "react";
import { map } from "lodash";

import NutritionalBalanceCard from "./components/NutritionalBalanceCard";
import { getNutritionalBalance } from "./nutritionalBalance.fields";
import CardNoDataFallback from "../../../../../../../../../../../../molecules/cardNoDataFallback";

import styles from "./nutritionalBalance.module.css";

const NutritionalBalance = ({microNutritionalValues,rangeByMicroNutrientMap}) =>{

    const nutritionalBalanceOptions  = getNutritionalBalance(rangeByMicroNutrientMap);

    if(Object.keys(microNutritionalValues).length ==0){
        return <CardNoDataFallback textClass={styles.noDataTextClass}/>
    }

    return (
        <div className={styles.container}>
            {map(nutritionalBalanceOptions,(option)=>{
                const {name="", baseName = "" } = option || {};
                return <NutritionalBalanceCard {...option} type={microNutritionalValues?.targetColorByMicroNutrientMap?.[baseName]} value={ Math.round((microNutritionalValues?.[name] || 0)*10)/10} />
            })}
        </div>
    )
}

export default NutritionalBalance;