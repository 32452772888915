import React from "react";

import styles from "./invitePatients.module.css";

const InvitePatients = () =>{
    return (
        <div className={styles.container}>
            <div className={styles.items}>
                Invite your clients/Patients to setup remote monitoring
            </div>
            <div className={styles.items}>
                <div className={styles.inviteButtonContainer}>
                    <div className={styles.inviteButton}>
                        Invite
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InvitePatients;