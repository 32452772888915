
const UPDATE_CURRENT_FORM = "UPDATE_CURRENT_FORM";
const UPDATE_FORM_STATE = "UPDATE_FORM_STATE";
const PRIMARY_FORM_SUBMIT = "PRIMARY_FORM_SUBMIT";
const UPDATE_NEW_ACCOUNT_SETUP_FORM = "UPDATE_NEW_ACCOUNT_SETUP_FORM";
const SECONDARY_FORM_SUBMIT = "SECONDARY_FORM_SUBMIT";


export default {
    UPDATE_CURRENT_FORM,
    UPDATE_FORM_STATE,
    PRIMARY_FORM_SUBMIT,
    UPDATE_NEW_ACCOUNT_SETUP_FORM,
    SECONDARY_FORM_SUBMIT,
}