import InputWithPrefix from "../../molecules/InputWithPrefix"

const getFormFields = ()=>{
    return [[{
        label:"Practice Name",
        name: "practiceName",
        fieldType:"text",
        PlaceHolder:"Eg. Johns Hopkins Hospital",
        onFocus:()=>{},
        onBlur:()=>{},
    },
    {
        label: "Practice Handle",
        name: "practiceHandle",
        CustomInput:InputWithPrefix,
        inputType:"Text",
        placeHolder:"your-practice-name",
        prefixText: "www.renewrx.health/",
        onBlur:()=>{},
        onFocus:()=>{},
        PlaceHolder: "your-practice-name"
    }],
    [
        {
            label: "Practice Handle",
            name: "practiceHandle",
            CustomInput:InputWithPrefix,
            inputType:"Text",
            placeHolder:"your-practice-name",
            prefixText: "www.renewrx.health/",
            onBlur:()=>{},
            onFocus:()=>{},
            PlaceHolder: "your-practice-name"
        }   
    ]
]
}

export {getFormFields}