import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import PageHeaderContext from "./components/pageHeaderContext/PageHeaderContext";
import getMemberDetail from "./memberDetail.async";
import Heading from "./components/heading/Heading";
import Tabs from "./components/tabs/Tabs";
import styles from "./memberDetail.module.css";

const MemberDetail = () => {

    const { memberId, practiceName, practiceId =""} = useParams();

    const [memberDetail, setMemberDetail] = useState();

    useEffect(() => {
        getMemberDetail(memberId, setMemberDetail);
    }, []);

    const {firstName, lastName} = memberDetail || {};

    return (
        <div className={styles.mainContainer}>
            <PageHeaderContext practiceId={practiceId} practiceName={practiceName} memberName={firstName + ' '+ lastName}></PageHeaderContext>
            <Heading memberName={firstName + ' '+ lastName}></Heading>
            <Tabs memberDetail={memberDetail}></Tabs>
        </div>
    )
}

export default MemberDetail;