import { useRef } from "react"

const useFocus = () => {
	const elementRef = useRef(null)
	const setFocus = () => {
        elementRef.current &&  elementRef.current.focus();
    }

	return [ elementRef,  setFocus ]
}

export default useFocus;