import React from "react";

import withActionState from "../../../../hooks/withActionState";

import { getCurrentFormFields } from "./createNewPracticeForm.fields";
import { getCurrentForm } from "./createnewPacticeForm.pages";
import StepBasedHeader from "../../components/StepBasedHeader";

import actionTypes from "./createNewPracticeForm.actionTypes";
import actions from "./createNewPracticeForm.actions";

const CreateNewPracticeForm = ({dispatch, currentPage = 0, ...rest}) =>{

    const currentFormFields = getCurrentFormFields();

    const handleFormValueChange = (payload)=>{
        dispatch({actionType: actionTypes.FORM_VALUES_UPDATE, payload: payload })
    }

    const handlePageSubmit = (payload)=>{
        dispatch({actionType: actionTypes.PAGE_SUBMIT_ACTION, payload: payload })
    };

    const handleFormSubmit = (payload)=>{
        dispatch({actionType: actionTypes.FORM_SUBMIT_ACTION, payload: payload})
    }

    const currentForm = getCurrentForm(currentFormFields,handleFormValueChange, handleFormSubmit, handlePageSubmit);

    const {Component, ...remainingFormProps} = currentForm[currentPage];

    return (
        <>
        <StepBasedHeader currentPage={currentPage+1} currentSpan={[1,2,3]} />
        <Component formProps={{...remainingFormProps}} />
        </>
    )
}

export default withActionState({ Component: CreateNewPracticeForm , action : actions});