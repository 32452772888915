import React from 'react';
import styles from "./tableColumn.module.css";
import {NUTRIENT_TYPES} from "./tableColumn.constants"

export default function TableColumn({logIcon}) {
  return (
    <div className={styles.tableFields}>
      <div className={styles.dateContainer}>
        <span className={styles.dateContent}>LOGS <img src={logIcon} className={styles.logIcon} /></span>
      </div>
      <div className={styles.mealContainer}>
        <span className={styles.mealContent}>MEAL</span>
      </div>
      <div className={styles.plateScore}>PLATE SCORE </div>
      
      {NUTRIENT_TYPES.map((nutrient, index) => (
        <div key={index} className={styles.microNutrientsContainer}>
          <span className={styles.microNutrientsContent}>{nutrient}</span>
        </div>
      ))}
    </div>
  );
}
