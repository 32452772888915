import {map, isEmpty} from "lodash";
import { MAPPED_METRIC_TYPES, MEAL_TYPES } from "../../../../fieldMapping/metricTypes";
import { LABEL_SUFFIX } from "./progressChart.constants";

const getRespectiveFormattedData = (metrics) =>{
    return map(metrics,(metric)=>{
        const {metricRequest, metricsNameGlucoseDetailsMap, glucoseDetails } = metric;
        if(!isEmpty(metricsNameGlucoseDetailsMap)){
            return {
                label: MAPPED_METRIC_TYPES[metricRequest?.metricType],
                value: metricsNameGlucoseDetailsMap[Object.keys(metricsNameGlucoseDetailsMap)?.filter((val)=> val.includes("RANGE_BETWEEN"))?.[0]]?.glucose,
                suffix: "%",
            }
        }
        return {
            label: MAPPED_METRIC_TYPES[metricRequest?.metricType] +" "+ (MEAL_TYPES?.[metricRequest?.mealType] || ""),
            labelSuffix : LABEL_SUFFIX?.[metricRequest?.metricType],
            value :  glucoseDetails?.glucose && Math.trunc(glucoseDetails?.glucose || 0),
            color: glucoseDetails?.['glucose-color']?.split("_")[0],
            suffix : "mg/dL"
        }
})
}

export {
    getRespectiveFormattedData,
}