import { BASE_URL } from "../../../../constants/api";

const getCoachList = async()=>{
    try{
        const url = new URL(window.location.href)
        const pathname = url.pathname.split("/");

        const practiceId = pathname?.[2] || "";

        const memberListRequest = await fetch(`${BASE_URL}/dashboard/admin/api/rrxsuperboss/users/getMembers/${practiceId}/ACTIVE`, {
            method: "GET",
            headers: {
                'content-type': 'application/json',
                "authorization": "Bearer " + window?.localStorage?.getItem("Authorization"),
            }
        });
        const memberListResponse = await memberListRequest.json();

        return memberListResponse;
    }
    catch(e){
        console.log('ERROR IS : ',e)
    }
}


export {
    getCoachList
}