import React, { useEffect, useState } from "react";
import {map} from "lodash";
import { Link } from "react-router-dom";

import { getTabs } from "./dashBoardDescription.tabs";

import TabContent from "./components/TabContent";

import styles from "./dashBoardDesciption.module.css";
import DashBoardInformation from "../DashBoardInformation";

const tabs = getTabs();

const DashBoardDescription = (props)=>{

    const [activeTab, setActiveTab] = useState(1);

    // const [ChildComponent,setChildComponent] = useState(tabs[0].ChildComponent);

    // useEffect(()=>{
    //     console.log("Rendering the data ", new Date().getMilliseconds());
    //     console.log(activeTab,"    ", ChildComponent)
    //     setChildComponent(tabs[activeTab-1].ChildComponent);
    // },[activeTab])

    const handleTabUpdate = () =>{
        setChildComponent(tabs[activeTab-1].ChildComponent);
    }

    return (
        <div className={styles.container}>
            <div className={styles.secondaryHeader}>
                <div className={styles.tabContainer}>
                    {map(tabs,(val)=>{
                        return (
                            <TabContent {...val} innerContent={props} activeTab={activeTab} setActiveTab={setActiveTab}/>
                        )
                    })}
                </div>
                <Link to="/manageActionPlan">
                    <div className={styles["manage-action-plan-div"]}>
                        <span className={styles["manage-action-plan-span"]}>
                            Manage Action Plans
                        </span>
                    </div>
                </Link>
            </div>
            {/* {ChildComponent && */}
            {/* <div> */}
                <DashBoardInformation {...props} />
            {/* </div> */}
            {/* } */}
        </div>
    )
}

export default DashBoardDescription;