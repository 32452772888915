import React from "react";

import styles from "./header.module.css";

const Header = ({content, CloseComponent}) =>{
    return (
        <div className={styles.container}>
            <span>{content}</span>
            <CloseComponent />
        </div>
    )
}

export default Header;