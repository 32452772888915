import React from "react";

import cx from "classnames"

import styles from "./iconedValue.module.css";

const typeClass={
    Balanced : styles.balanced,
    Unbalanced : styles.unbalanced,
    Critical : styles.critical
}

const IconedValue = ({value, type, ...rest}) =>{
    return (
        <div className={styles.container}>
            <div className={styles.primaryTextContainer}>{value}</div>
            <div className={cx(typeClass?.[type],styles.typeContainer)}>{type}</div>
        </div>
    )
}

export default IconedValue