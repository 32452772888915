import React from "react";

import { HEADER_BUTTON } from "../header.constants";

import styles from "./containers.module.css";

const RightContainer = () =>{
    return (
        <div className={styles.baseContainer}>
            <div className={styles.outerButtonContainer}>
                <div className={styles.innerButtonContainer}>
                    {HEADER_BUTTON.LABEL}
                </div>
            </div>
        </div>
    )
}

export default RightContainer;