import React, { useState } from "react";

import cx from "classnames";

import DisplayToggle from "../displayToggle/DisplayToggle";

const CustomPasswordInput = ({type,className,errorClassname,fieldError={},...rest})=>{
    const [inputType,setInputType] = useState(true);

    const toggleInputType = ()=>{
        setInputType(!inputType);
    }

    return (
        <>
        <input type={inputType ? type : "text"} className={cx(className,{[errorClassname]: Object.values(fieldError).length>0})} {...rest}/>
        <DisplayToggle toggleValue={toggleInputType} />
        </>
    )
}

export default CustomPasswordInput;