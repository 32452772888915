import styles from "./login.module.css";

const getFormProps = ({LoginFormFields, getFormSubmitAction, navigate}) =>{
    return {
        formContent : LoginFormFields(navigate),
        formHeaderProps :{
            primaryHeaderText: "Login",
            secondaryHeaderText: "Continue to Renewrx",
            footerContainerClassname: styles.footerContainerClassname,
            secondaryHeaderClassname: styles.secondaryHeaderClassname
        },
        formFooterProps:{
            butttonText : "Login",
            enabledClassname: styles.enabledClassname
        },
        formContainerProps:{
            containerClassname : styles.formContainer
        },
        formSubmitAction : getFormSubmitAction,
    }
}

const getSecondaryFormProps = (formSubmitAction, resendInviteAction) =>{
    return {
        resendInvite : resendInviteAction,
        formSubmitAction : formSubmitAction
    }
}

export {getFormProps,  getSecondaryFormProps}