import React, { useEffect, useState, useRef } from 'react';
import {map} from "lodash";
import moment from 'moment';

import TableColumn from './components/TableColumn';
import TableContent from './components/TableContent/TableContent';
import NoData from './components/NoData/NoData';
import WeeklyFilter from './components/WeeklyFilter/WeeklyFilter';
import { useRecoilValue } from 'recoil';
import { startDateRecoil, endDateRecoil } from '../../../../../../../../../store/progressMonitoringAtoms/weeklyRange';
import { BASE_URL } from '../../../../../../../../constants/api';
import { useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { getBalancedPlateCards } from './balancedPlate.fields';
import LargeCards from '../../../../../LargeCards';
import sortIcon from "./../../../../../../../../../assets/images/sort/sort.svg"

import actions from "./balancedPlate.action";
import actionTypes from "./balancedPlate.actionTypes";

import styles from "./balancedPlate.module.css";
import withActionState from '../../../../../../../../hooks/withActionState';
import SecondaryHeader from './components/MealLogs/SecondaryHeader';
import Header from './components/Header/Header';

function BalancedPlate({handleToggleLoaderAction=()=>{}, dispatch, weeklyFilter, ...restProps}) {
  const [loggedMeal, setLoggedMeal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [oneHourPP,setOneHourPP] = useState([]);
  const [balancedPlateData, setBalancedPlateData] = useState({});
  const [previous,setPrevious] = useState(0);

  const end = moment().subtract(previous,'d').format("YYYY-MM-DD");
  const start=end;

  const { id } = useParams();
  const debounceTimeoutRef = useRef(null);

  useEffect(()=>{
    dispatch({actionType: actionTypes.INIT_ACTION});
  },[])

  const handleFilterUpdate = (prev)=>{
    setPrevious(prev);
  }

  const getLoggedMeal = async () => {
    try {
      handleToggleLoaderAction({isLoading : true});
      setLoggedMeal([]);
      const response = await fetch(`${BASE_URL}/nourishmentNotebook/dashboard/${id}/${start}/${end}`, {
        method: "GET",
        headers: {
          "Authorization": "Bearer " + window.localStorage.getItem("Authorization")
        }
      });

      const oneHourPPResponse = await fetch(`${BASE_URL}/dashboard/glucoseMetrics`,{
        method:"POST",
        headers:{
            "content-type":"application/json",
            'authorization' : 'Bearer '+ window?.localStorage?.getItem("Authorization"),
        },
        body: JSON.stringify({
            userId: id,
            metricRequests:[
                {
                  metricType: "ONE_HOUR_POST_PRANDIAL",
                  mealType: 'BREAKFAST',
                  date : start
                },
                {
                    metricType: "ONE_HOUR_POST_PRANDIAL",
                    mealType: 'LUNCH',
                    date : start
                },
                {
                  metricType: "ONE_HOUR_POST_PRANDIAL",
                  mealType: 'DINNER',
                  date : start
                }
          ]
        })
    })

      const oneHourPPResponseMetrics = await oneHourPPResponse.json();
    
      const data = await response.json();

      
      const balancedPlateResponse = await fetch(`${BASE_URL}/nourishmentNotebook/dashboard/macronutrientsInsights/${id}/${start}/${end}`,{
        method:"GET",
        headers:{
            'content-type':"application/json",
            'authorization' : 'Bearer '+ window?.localStorage?.getItem("Authorization"),
        }
      });

      const balancedPlateData = await balancedPlateResponse.json();

      setBalancedPlateData(balancedPlateData)
      setOneHourPP(oneHourPPResponseMetrics?.metricsResponses)
      setLoggedMeal(data);

    } catch (err) {
      setLoggedMeal([]);
    } finally {
      handleToggleLoaderAction({isLoading : false});
    }
  };

  const debounceGetLoggedMeal = () => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(() => {
      getLoggedMeal();
    }, 500);
  };

  useEffect(() => {
    debounceGetLoggedMeal();
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [start, end]);

  const balancedPlateCards = getBalancedPlateCards();

  const getFilterChangeAction = (name, currentChange) =>{
    dispatch({actionType : actionTypes.FILTER_UPDATE_ACTION, payload:{
      name,
      prev: currentChange
    }});
  }

  return (
    <div className={styles.outerContainer}>
      <Header getFilterChangeAction={getFilterChangeAction} filterDuration={weeklyFilter?.current}/>
      <div className={styles.mainContainer}>
          
          {map(balancedPlateCards,(val)=>{

            const { bodyProps={} } = val;
            
            const { name="" } = bodyProps;

            return <LargeCards weeklyFilter={weeklyFilter} {...val} {...restProps} getFilterChangeAction={getFilterChangeAction} />
          
          })}
          <div className={styles.cardContainer}>

            <div className={styles.logsText}>
                  MEAL LOGS BY DAY
              <div className={styles.betaIconContainer}>
                  Beta
              </div>
            </div>

            <WeeklyFilter start={start} end={end} handleFilterUpdate={handleFilterUpdate} previous={previous}/>
            <SecondaryHeader date={start} balancedPlateData={balancedPlateData} />
            {loading ? (
              <></>       
            ) : loggedMeal.length > 0 ? (
              <div className={styles.container}>
                <TableColumn logIcon={sortIcon}/>
                {loggedMeal.map((meal) => (
                  <div key={meal.id} className={styles.container}>
                    <TableContent meal={meal} oneHourPP={oneHourPP}/>
                  </div>
                ))}
              </div>
            ) : (
              <NoData />
            )}
          </div>
      </div>
    </div>
  );
}

export default withActionState({Component : BalancedPlate, action: actions})