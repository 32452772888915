import React from "react";

import WeeklyFilter from "../../../../../progressMonitoring/components/WeeklyFilter/WeeklyFilter";
import { getWeeklyFormattedWeeklyDates } from "../../../../../../../../helpers/dateFormatter";
import { DURATION_CHANGE_TYPES } from "../../../../../../patientProfile.constants";

import styles from "./body.module.css"
import { getTableFields } from "./body.fields";
import BaseTable from "../../../../../../../tables/BaseTable";
import { getFormattedRowData } from "./body.helper";

const Body = ({tableData,wrapperFields, handleOverAllDurationChange, overViewDurationTypes = {}, name = DURATION_CHANGE_TYPES.DAILY_MONITORING_LOGS}) =>{

    const tableFields = getTableFields({prev : overViewDurationTypes?.[DURATION_CHANGE_TYPES.DAILY_MONITORING_LOGS]});

    const dummyData = tableData ? getFormattedRowData({primaryRow: tableData?.[0], secondaryRow : tableData?.[1] , tertiaryRow : tableData?.[2] || []}) : getFormattedRowData({primaryRow : []});
    
    const handleWeeklyDurationChange =(name , previous)=>{
        handleOverAllDurationChange({name , prev : previous})
    };

    return (
        <div className={styles.container}>
            <div className={styles.secondaryHeader}>
                <WeeklyFilter previous={overViewDurationTypes?.[DURATION_CHANGE_TYPES.DAILY_MONITORING_LOGS] || 0} name={name} handlePrimaryDurationChange={handleWeeklyDurationChange} {...getWeeklyFormattedWeeklyDates({prev : overViewDurationTypes?.[DURATION_CHANGE_TYPES.DAILY_MONITORING_LOGS],format:"MMM DD"})} />
            </div>
            <div className={styles.tableContainer}>
                <BaseTable wrapperFields={wrapperFields} columnProps={{className : styles.rowColumnHeader}} columnsData={tableFields} tableData={dummyData} tableDataProps={{className : styles.tableRowEffect}} />
            </div>
        </div>
    )
}

export default Body;