import { BASE_URL } from "../../../../constants/api"
import { LOCAL_STORAGE_KEYS } from "../../../../constants/keyConstants"

const getCodeVerificationAction = async({userId,countryCode,phoneNumber,code})=>{
    try{
        const request = await fetch(`${BASE_URL}/api/dashboard/users/verifyPhoneNumberCode`,{
            method:"POST",
            headers : {
                "Authorization": "Bearer " + window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
                'content-type':"application/json"
            },
            body: JSON.stringify({
                userId,
                countryCode,
                phoneNumber,
                code
            })
        })

        return request.status;

    }
    catch(e){
        console.log('ERROR IS : ',e);
    }
}

const getResendInviteAction = async({userId,countryCode,phoneNumber})=>{
    try{
        const request = await fetch(`${BASE_URL}/api/dashboard/users/verifyPhoneNumber`,{
            method:"POST",
            headers : {
                "Authorization": "Bearer " + window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
                'content-type':"application/json"
            },
            body: JSON.stringify({
                userId,
                countryCode,
                phoneNumber
            })
        })

        return request.status
    }
    catch(e){
        console.log('ERROR IS : ', e);
    }
}

export {
    getCodeVerificationAction,
    getResendInviteAction
}