import React, {useState} from "react";
import { Toast , ToastContainer } from "react-bootstrap";

import styles from "./toast.module.css";

const ToastBar = ({headerText = "hello", bodyText, color}) =>{
    const [show, setShow] = useState(true);

    return (
        <div className={styles.container}>
            <ToastContainer position="top-center">
                <Toast
                    className="d-inline-block m-1"
                    bg={color}
                    onClose={() => setShow(false)}
                    show={show}
                    delay={3000} 
                    autohide
                >
                    <Toast.Header>
                        <div className={styles.headerText}>{headerText}</div>
                    </Toast.Header>
                    <Toast.Body>
                        {bodyText}
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    )
}

export default ToastBar;