
const getFooterButtonText = () =>{
    return [
        "Create",
        "Request Access"
    ]
}

export {
    getFooterButtonText,
}