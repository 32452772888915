import React from "react";

import styles from "./header.module.css"

const Header = ({handleDisplayIndividualInfo})=>{
    return (
        <div className={styles.container}>
            <div className={styles.textContainer}>
                Support And Learn
            </div>
            <img src={require("../../../../../assets/images/cancel_icon.svg").default} onClick={handleDisplayIndividualInfo} className={styles.cancelIcon} />
        </div>
    )
}

export default Header;
