const TABLE_COLOR_MAPPING={
    GREEN:"#E5FAF2",
    RED:"#FEEFEB",
    YELLOW:"#FFF6BD"
}

const BORDER_COLOR_MAPPING ={
    GREEN:"#9EDBC5",
    RED:"#EAACC3",
    YELLOW:"#EAC56B"
}

export{
    TABLE_COLOR_MAPPING,
    BORDER_COLOR_MAPPING
}