import React from "react";

import StyledButton from "../../../../molecules/baseComponents/buttons/styledButton/StyledButton";
import dropdown from "../../../../../assets/images/dropdown_arrow.svg"

import styles from "./header.module.css";

const Header = ({inviteButtonAction}) =>{
    return (
        <div className={styles.container}>
            <div className={styles.leftContainer}>
                <div className={styles.mainText}>
                    Patients
                </div>
            </div>
            <StyledButton content={"Invite Patients"} className={styles.inviteButton} onClick={inviteButtonAction} />
        </div>
    )
}

export default Header;