import React from "react";
import cx from "classnames";

import SummaryHeadder from "./components/Summary/components/Header";
import Summary from "./components/Summary";
import TrendAnalysis from "./components/TrendAnalysis";
import TrendAnalysisHeader from "./components/TrendAnalysis/components/Header";
import ComparisonAnalysis from "./components/ComparisonAnalysis/ComparisonAnalysis";
import ComparisonAnalysisHeader from "./components/ComparisonAnalysis/components/Header";
import styles from "./balancedPlate.module.css";

const getBalancedPlateCards = () =>{
    return [
        {
        HeaderComponent : SummaryHeadder,
        BodyComponent : Summary,
        headerProps : {
            label : "SUMMARY",
            className : styles?.summaryHeaderClassname
        },
        bodyProps:{
            name : "summary"
        },
        containerClassName : styles.summaryContainerClass
    },
    {
        HeaderComponent : TrendAnalysisHeader,
        BodyComponent : TrendAnalysis,
        headerProps : {
            label : "PLATE SCORE (DAY BY DAY)",
            className : styles?.summaryHeaderClassname
        },
        bodyProps:{
            name : "trendAnalysis"
        },
        containerClassName : styles.summaryContainerClass
    },
    {
        HeaderComponent : ComparisonAnalysisHeader,
        BodyComponent : ComparisonAnalysis,
        headerProps : {
            label : "WEEKLY PLATE SCORE COMPARISON  (BY MEAL TYPE)",
            className : styles?.summaryHeaderClassname
        },
        bodyProps:{
            name: "comparisonAnalysis",
            bodyClassname: styles.comparisonBodyClass
        },
        containerClassName : styles.summaryContainerClass
    }
]
}

export {
    getBalancedPlateCards
}