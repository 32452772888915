import React, { useState } from "react";

import Header from "./component/Header";
import Body from "./component/Body";

import { getRespectiveFormData } from "./notesFormPopup.helpers";
import { getNotesPopupFields } from "./notesFormPopup.fields";

import SecondaryHeader from "./component/SecondaryHeader";

import styles from "./notesFormPopup.module.css";
import { NOTES_DISPLAY_STATUS } from "../../patientProfile.constants";

const NotesFormPopup = ({
        displayStatus,
        handleNotesToggleStatusUpdate,
        handleNotesFormSubmitAction,
        handleNotesCloseAction,
        handleNotesFormUpdate,
        formState
    }) =>{

    const [formValues  , setFormValues ]= useState({})

    const handleFormValuesUpdate = ({name, value })=>{
        setFormValues((prev)=>{
            return {
                ...prev,
                [name]:value
            }
        })
    }

    const handleFormSubmit = (payload)=>{
        handleNotesFormSubmitAction({...formValues,...payload })
    }

    const formProps = getRespectiveFormData(getNotesPopupFields(),handleFormSubmit, handleNotesFormUpdate);

    const handleNotesPartialDisplay = (event)=>{
        event?.stopPropagation();
        if( displayStatus === NOTES_DISPLAY_STATUS.DISPLAY){
            handleNotesToggleStatusUpdate({status : NOTES_DISPLAY_STATUS.PARTIAL_DISPLAY});
            return;            
        }
        handleNotesToggleStatusUpdate({ status : NOTES_DISPLAY_STATUS.DISPLAY});
    }
    
    return (
        <div className={styles.container}>
            <Header displayStatus={displayStatus} handleNotesPartialDisplay={handleNotesPartialDisplay} handleNotesDisplayClose={handleNotesCloseAction} />
            {displayStatus == NOTES_DISPLAY_STATUS.DISPLAY && <SecondaryHeader handleFormValuesUpdate={handleFormValuesUpdate} />}
            {displayStatus == NOTES_DISPLAY_STATUS.DISPLAY && <Body formProps={formProps} formState={formState}/>}
        </div>
    )
}

export default NotesFormPopup;