import React from "react";
import moment from "moment";

import styles from "./dateDisplay.module.css";

const DateDisplay = ({primaryText = "27 Feb, Tue", day, prev=0}) =>{
    return (
        <div className={styles.container}>
            <div className={styles.primaryText}>
                {moment().subtract(6-(day%8)+(7*prev),"d").format("DD MMM, ddd")}
            </div>
            <div className={styles.secondaryText}>
                DAY {day}
            </div>
        </div>
    )
}

export default DateDisplay;