import overViewIcon from "./../../../assets/images/overview.svg";
import glucoseIcon from "./../../../assets/images/nonActive/glucose.svg";
import foodIcon from "./../../../assets/images/nonActive/food_logs.svg";
import maleIcon from "./../../../assets/images/male_icon.svg";

const PATIENT_TABS = [
    {
        label: "Overview",
        icon : overViewIcon
    },
    {
        label: "Glucose",
        icon : glucoseIcon
    },
    {
        label: "Balanced Plate",
        icon : foodIcon
    },
    {
        label: "Patient Info",
        icon : maleIcon
    },
]

export {
    PATIENT_TABS,
}