import React from "react";

import styles from "./secondaryHeader.module.css";

const getSecondaryHeaderFields = (balancedPlateData)=>{

    const PLATE_TYPES={
        BALANCED : styles.balanced,
        UNBALANCED : styles.unbalanced,
        CRITICAL : styles.critical
    }

    return [
        {
            name: "overallCalories",
            label : "Overall Day Calories",
            suffix : "mg/dL",
            value : Math.round((balancedPlateData?.overallCalories || 0)*10)/10,
        },
        {
            name : "",
            label : "Highest Day Spike",
            suffix : "mg/dL",
            value:0
        },
        {
            name : "avgPlateScore",
            label : "Overall Day Status",
            value : balancedPlateData?.avgPlateScore,
            suffix: <div className={PLATE_TYPES?.[balancedPlateData?.balancedPlateMealStatus]}>{balancedPlateData?.balancedPlateMealStatus?.[0]+balancedPlateData?.balancedPlateMealStatus?.substring(1,balancedPlateData?.balancedPlateMealStatus?.length)?.toLowerCase() || " "}</div>
        }
    ]
}

export {
    getSecondaryHeaderFields
}