import React from 'react'
import styles from "./status.module.css"
import { STATUS } from '../TableContent/tableContent.constants'

export function Balanced() {
  return (
    <div className={styles.balancedContainer}>
        <div className={styles.balanced}>{STATUS.BALANCED}</div>
    </div>
  )
}

export function Unbalanced(){
  return (
    <div className={styles.unbalancedContainer}>
        <div className={styles.unbalanced}>{STATUS.UNBALANCED}</div>
    </div>
  )
}

export function Critical(){
  return (
    <div className={styles.criticalContainer}>
        <div className={styles.critical}>{STATUS.CRITICAL}</div>
    </div>
  )
}



