import moment from "moment";

import styles from "./termsAndConditionsModal.module.css";

const TERMS_AND_CONDITIONS_CONTENT = {
    HEADER_DATA:{
        HEADING : "Terms And Conditions",
        SECONDARY: "Last Updated on - "+ moment().format("MMM DD,YYYY")
    },
    BODY_DATA:{
        CONTENT :[
        {
            BODY:[
                {
                    content :"This Privacy Policy Supplement for California, Colorado, Connecticut, Virginia, and Utah Residents (the “Supplement”) supplements the information contained in Nourish Ventures, LLCd/b/a RenewRx’s (“RenewRx”, “we”, “us”, or “our”) Privacy Policy",
                },
                {
                    content : " https://www.renewrx.health/legal/privacy ",
                    className: styles.linkContent
                },
                {
                    content : " (the“General Privacy Policy”) and applies solely to those consumers who reside in the States of California, Colorado, Connecticut, Virginia, Utah(“consumers” or “you”). The terms of this Supplement shall govern over any conflict with the General Privacy Policy. We adopt this Supplement to comply with the California Consumer Privacy Act of 2018 (“CCPA”), Colorado Privacy Act (“CPA”), Connecticut Data Privacy Act (“CTDPA”), Virginia Consumer Data Protection Act (“VCDPA”), and Utah Consumer Privacy Act (“UCPA”) and any terms defined in the CCPA, CPA, CTDPA, VCDPA, UCPA have the same meaning when used in this Supplement"
                }
            ]
        },
        {
            HEADING : "Information We Collect",
            BODY :[{content:"Our website (www.renewrx.health) and their subpages and content, collectively the “Site”) collects information that identifies, relates to, describes, references, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer, household, or device (“personal information”). Personal information does not include:"}],
            SECONDARY: [{content:"Publicly available information from government records. \
            Deidentified or aggregated consumer information. \
            Other information deemed under applicable California, Colorado, Connecticut, Virginia, Utah law not to be personal information."}]
        },
        {
            BODY : [{content:"In particular, our Site has collected within the last twelve (12) months, and intends to continue collecting, the following categories of personal information from consumers:"}]
        },
        {
            BODY : [{content :"This Privacy Policy Supplement for California, Colorado, Connecticut, Virginia, and Utah Residents (the “Supplement”) supplements the information contained in Nourish Ventures, LLCd/b/a RenewRx’s (“RenewRx”, “we”, “us”, or “our”) Privacy Policy https://www.renewrx.health/legal/privacy (the“General Privacy Policy”) and applies solely to those consumers who reside in the States of California, Colorado, Connecticut, Virginia, Utah(“consumers” or “you”). The terms of this Supplement shall govern over any conflict with the General Privacy Policy. We adopt this Supplement to comply with the California Consumer Privacy Act of 2018 (“CCPA”), Colorado Privacy Act (“CPA”), Connecticut Data Privacy Act (“CTDPA”), Virginia Consumer Data Protection Act (“VCDPA”), and Utah Consumer Privacy Act (“UCPA”) and any terms defined in the CCPA, CPA, CTDPA, VCDPA, UCPA have the same meaning when used in this Supplement"}]
        }
    ]
    }
}

const PRIVACY_POLICY_CONTENT = {
    HEADER_DATA:{
        HEADING : "Privacy Policy",
        SECONDARY: "Last Updated on - "+ moment().format("MMM DD,YYYY")
    },
    BODY_DATA:{
        CONTENT :[{
            BODY:[
                {
                    content :"This Privacy Policy Supplement for California, Colorado, Connecticut, Virginia, and Utah Residents (the “Supplement”) supplements the information contained in Nourish Ventures, LLCd/b/a RenewRx’s (“RenewRx”, “we”, “us”, or “our”) Privacy Policy",
                },
                {
                    content : " https://www.renewrx.health/legal/privacy ",
                    className: styles.linkContent
                },
                {
                    content : " (the“General Privacy Policy”) and applies solely to those consumers who reside in the States of California, Colorado, Connecticut, Virginia, Utah(“consumers” or “you”). The terms of this Supplement shall govern over any conflict with the General Privacy Policy. We adopt this Supplement to comply with the California Consumer Privacy Act of 2018 (“CCPA”), Colorado Privacy Act (“CPA”), Connecticut Data Privacy Act (“CTDPA”), Virginia Consumer Data Protection Act (“VCDPA”), and Utah Consumer Privacy Act (“UCPA”) and any terms defined in the CCPA, CPA, CTDPA, VCDPA, UCPA have the same meaning when used in this Supplement"
                }
            ]
        },
        {
            HEADING : "Information We Collect",
            BODY :[{content:"Our website (www.renewrx.health) and their subpages and content, collectively the “Site”) collects information that identifies, relates to, describes, references, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer, household, or device (“personal information”). Personal information does not include:"}],
            SECONDARY: [{content:"Publicly available information from government records. \
            Deidentified or aggregated consumer information. \
            Other information deemed under applicable California, Colorado, Connecticut, Virginia, Utah law not to be personal information."}]
        },
        {
            BODY : [{content:"In particular, our Site has collected within the last twelve (12) months, and intends to continue collecting, the following categories of personal information from consumers:"}]
        },
        {
            BODY : [{content :"This Privacy Policy Supplement for California, Colorado, Connecticut, Virginia, and Utah Residents (the “Supplement”) supplements the information contained in Nourish Ventures, LLCd/b/a RenewRx’s (“RenewRx”, “we”, “us”, or “our”) Privacy Policy https://www.renewrx.health/legal/privacy (the“General Privacy Policy”) and applies solely to those consumers who reside in the States of California, Colorado, Connecticut, Virginia, Utah(“consumers” or “you”). The terms of this Supplement shall govern over any conflict with the General Privacy Policy. We adopt this Supplement to comply with the California Consumer Privacy Act of 2018 (“CCPA”), Colorado Privacy Act (“CPA”), Connecticut Data Privacy Act (“CTDPA”), Virginia Consumer Data Protection Act (“VCDPA”), and Utah Consumer Privacy Act (“UCPA”) and any terms defined in the CCPA, CPA, CTDPA, VCDPA, UCPA have the same meaning when used in this Supplement"}]
        }
    ]
    }
}

const TERMS_AND_POLICY={
    TERMS : 'terms',
    PRIVACY_POLICY : "privacyPolicy"
}

const TERMS_AND_PRIVACY_POLICY = {
    [TERMS_AND_POLICY.TERMS] : TERMS_AND_CONDITIONS_CONTENT,
    [TERMS_AND_POLICY.PRIVACY_POLICY] :PRIVACY_POLICY_CONTENT,
}

export {
    TERMS_AND_PRIVACY_POLICY,
    TERMS_AND_CONDITIONS_CONTENT
}