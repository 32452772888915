import React from "react";

import styles from "./body.module.css";
import FormManager from "../../../../../../reusables/formManager";

const Body = ({formProps, formState}) =>{
    return (
        <div className={styles.container}>
            <FormManager formProps={formProps} initialState={formState}/>
        </div>
    )
}

export default Body