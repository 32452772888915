import React from "react";
import CardLeftContainer from "./components/containers/CardLeftContainer";
import CardRightContainer from "./components/containers/CardRightContainer";

import actionTypes from "./practiceCreateAccountCard.actionTypes";
import styles from "./practiceCreateAccountCard.module.css";
import DefaultSpinner from "../../atoms/spinner/Spinner";
import Modal from "../../molecules/modals/Modal";
import PhoneOtpPopup from "./components/phoneOtpPopup/phoneOtpPopup";
import SetupComplete from "./components/SetupComplete/SetupComplete";

const PracticeCreateAccountCard = ({
        dispatch,
        currentPage = 1,
        isLoading,
        formValues,
        finishComplete=false,
        // displayPhoneTwoFA=true,
        phoneOtpModalDisplay=false,
        ...props
    }) =>{

    const handlePageSubmit = (payload)=>{
        dispatch({actionType: actionTypes.PAGE_SUBMIT_ACTION, payload: payload })
    };

    const handleFormSubmit = (payload)=>{
        dispatch({actionType: actionTypes.FORM_SUBMIT_ACTION, payload: payload})
    }

    const handleFormValueChange = (payload)=>{
        dispatch({actionType: actionTypes.FORM_VALUES_UPDATE, payload: payload })
    }

    const handleGoToPreviousPage = () =>{
        dispatch({actionType : actionTypes.GO_TO_PREVIOUS_PAGE})
    }

    const handlePhoneOtpStage = (payload)=>{
        dispatch({actionType : actionTypes.SEND_PHONE_OTP});
        dispatch({actionType: actionTypes.DISPLAY_PHONE_OTP_POPUP});
    }

    const handlePhoneOtpModalToggle = ()=>{
        dispatch({actionType: actionTypes.DISPLAY_PHONE_OTP_POPUP});
    }

    const handleSetUpCompletedAction = ()=>{
        dispatch({actionType: actionTypes.FINISH_COMPLETE});
    }

    const handlePhoneOtpSucess = ()=>{
        dispatch({actionType : actionTypes.MOVE_TO_NEXT_PAGE});
    }

    const updateLoaderStatus = (payload)=>{
        dispatch({actionType : actionTypes.TOGGLE_LOADER,payload:{status : payload}})
    }

    return (
        <>
            {isLoading && <DefaultSpinner className={styles.spinnerContainer} />}
            <div className={styles.outerContainer}>
            <div className={styles.mainContainer}>
                {/* {isLoading && <DefaultSpinner className={styles.spinnerContainer} />} */}
                { currentPage > 1 &&
                <div className={styles.upperCard} onClick={handleGoToPreviousPage }>
                    <img src={require("../../../assets/images/arrow_left.svg").default} />
                    Back
                </div>
                }
                {phoneOtpModalDisplay && <div className={styles.phoneOtpModal}>
                    <Modal BodyComponent={PhoneOtpPopup} handleLoaderUpdate={updateLoaderStatus} handlePhoneOtpModalToggle={handlePhoneOtpModalToggle} className={styles.modalInnerContainer} {...formValues} handlePhoneOtpSucess={handlePhoneOtpSucess}/>
                </div>}
                {finishComplete && <div className={styles.phoneOtpModal}>
                    <Modal BodyComponent={SetupComplete}/>
                </div>}
                <div className={styles.container}>
                    <CardLeftContainer {...props} handleSetUpCompletedAction={handleSetUpCompletedAction} handlePhoneOtpStage={handlePhoneOtpStage} initialState={formValues} currentPage={currentPage} dispatch={dispatch} handlePageSubmit={handlePageSubmit} handleFormValueChange={handleFormValueChange} handleFormSubmit={handleFormSubmit}/>
                    <CardRightContainer {...props} formValues={formValues} currentPage={currentPage} dispatch={dispatch}/>
                </div>
            </div>
            </div>
        </>
    )
}

export default PracticeCreateAccountCard;