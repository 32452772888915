import React from "react";

import styles from "./header.module.css";

const Header = ({heading, primaryText}) =>{
    return (
        <div className={styles.container}>
            <div className={styles.mainText}>
                {heading}
            </div>
            <div className={styles.secondaryText}>
                {primaryText}
            </div>
        </div>
    )
}

export default Header;