import React from "react";

import emptyNotification from "../../../../../../../assets/images/empty_notification.svg";

import NO_DATA_ENUMS from "./noDataFallback.constants";
import styles from "./noDataFallback.module.css";

const NoDataFallback = () =>{
    return (
        <div className={styles.container}>
            <img src={emptyNotification} alt="" className={styles.emptyIcon} />
            <div className={styles.textContainer}>
                <div className={styles.primaryText}>
                    {NO_DATA_ENUMS.PRIMARY_TEXT}
                </div>
                {/* <div className={styles.secondaryText}>
                    {NO_DATA_ENUMS.SECONDARY_TEXT}
                </div> */}
            </div>
        </div>
    )
}

export default NoDataFallback;