import { useEffect, useState } from "react";
import cx from "classnames";
import {map} from "lodash";

import styles from "./form.module.css"
import { getDivisionList } from "./form.async";

const AddNewMemberForm = ({setIsOpen,setIsLoading,handleOpenToastDisplay,  updateFormValues, formValues, submitFormValues}) => {

    const [currentRole, setCurrentRole]= useState("RENEWRX_CARE_PRO_ADMIN");
    const [divisionList, setDivisionList] = useState([]);


    useEffect(()=>{
        getDivisionList()
        .then((data)=>{
            setDivisionList(data?.divisionDataList || []);
            updateFormValues('divisionId',data?.divisionDataList?.[0]?.id);
        })
        .catch((e)=>{
            console.log('ERROR IS : ',e)
        })
    },[]);

    const updateRole= (role)=>()=>{
        setCurrentRole(role)
        updateFormValues('role',role);
    }

    const closeForm = () => setIsOpen(false);

    const handleValuesUpdate =(type)=> (event) =>{
        updateFormValues(type,event.target.value);
    }

    const handleFormSubmitAction = ()=>{
        setIsLoading(true);
        submitFormValues()
        .then((status)=>{
            if(status){
                handleOpenToastDisplay("success");
            }
            else{
                handleOpenToastDisplay("failed");
            }
            setIsLoading(false);
        })
        .catch(()=>{
            handleOpenToastDisplay("failed");
            setIsLoading(false);
        })
        .finally(()=>{
            setIsOpen(false);
            setIsLoading(false);
        });
    }

    return (

        <div className={styles.container}>
            <div className={styles.upperContainer}>
                <div className={styles.formName}>Add new member</div>
                <img className={styles.cancel} onClick={closeForm}src={require("../../../../../assets/images/cancel/transparent_cacel.svg").default}></img>
            </div>
            <div className={styles.bottomContainer}>
                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        Name
                    </div>
                    <input className={styles.fieldValueName} value={formValues?.['name']} onChange={handleValuesUpdate('name')} placeholder="Enter Team Member Name"></input>
                </div>

                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        Email
                    </div>
                    <input className={styles.fieldValue} value={formValues?.['email']} onChange={handleValuesUpdate('email')} placeholder="Enter Email Id"></input>
                </div>

                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        Role
                    </div>
                    <div className={styles.memberRole}>
                        <div onClick={updateRole("RENEWRX_CARE_PRO_ADMIN")} className={cx(styles.roleMember,{[styles.roleAdmin] : currentRole == "RENEWRX_CARE_PRO_ADMIN"})}>
                            Admin
                        </div>
                        <div onClick={updateRole("RENEWRX_CARE_PRO")} className={cx(styles.roleMember,{[styles.roleAdmin] : currentRole == "RENEWRX_CARE_PRO"})}>
                            Member
                        </div>
                    </div>
                </div>

                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        Division
                    </div>
                    <div className={styles.dropdown}>
                        <select className={styles.fieldValueDropdown} onChange={handleValuesUpdate('divisionId')}>
                            <option className={styles.fieldValueDropdown}>Choose a division</option>
                            {map(divisionList,(data)=>{
                                const {city="", id=""} = data;
                                return (
                                    <option value={id} className={styles.fieldValueDropdown}>{city}</option>
                                )
                            })}
                        </select>
                        <img className={styles.dropdownArrow} src={require("../../../../../assets/images/dropdown_arrow.svg").default}></img>
                    </div>
                </div>


                <div className={styles.continue} onClick={handleFormSubmitAction}>
                    Send Invite
                </div>
            </div>
        </div>

    )
}

export default AddNewMemberForm;