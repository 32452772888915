import React from "react";

import styles from "./userProfile.module.css"
import CareTeam from "./components/CareTeam";
import UserCard from "./components/UserCard";

const UserProfile = () =>{
    return (
        <div className={styles.container}>
            <UserCard />
            <CareTeam />
        </div>
    )
}

export default UserProfile;