import React from "react";

import LogChartTab from "../../../../molecules/LogChartTab/LogChartTab";

import styles from "./tableRow.module.css";

const TableRow = ({viewMore=false,calenderUpdate, timeInRange=[1,2,3,4,5,6,7,8]})=>{

    return (
        <div className={styles["log-chart-row"]}>
            {timeInRange.map((val,key)=>{
                return (
                    <LogChartTab viewMore={viewMore} id={key} timeInRange={val?.metricsNameGlucoseDetailsMap} calenderUpdate={calenderUpdate} />
                ) 
            })}
        </div>
    )
}

export default TableRow;