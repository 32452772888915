const DURATION_CHANGE_TYPES = {
    DAILY_MONITORING_LOGS : "DAILY_MONITORING_LOGS",
    BASIC_LOGS : "BASIC_LOGS",
}

const NOTES_DISPLAY_STATUS = {
    DISPLAY : "display",
    HIDDEN : "hidden",
    PARTIAL_DISPLAY : 'partialDisplay'
}

export {
    DURATION_CHANGE_TYPES,
    NOTES_DISPLAY_STATUS
}