import PracticeSetupBaseForm from "./forms/practiceSetupBaseForm";
import CreateNewPracticeForm from "./forms/createNewPracticeForm";

const getCurrentForm = ()=>{
    return [{
        id:0,
        Component : PracticeSetupBaseForm,
    },
    {
        id:1,
        Component : CreateNewPracticeForm
    }
]
}

export {
    getCurrentForm,
}