import React from "react";
import Login from "./Login";
import Toast from "../../molecules/toast";

const LoginContainer = () =>{
    return (
            <Login />
    )
}

export default LoginContainer;