import { BASE_URL } from "../../constants/api";

const getMemberDetail = async(memberId, setMemberDetail) =>{
    try{
        const response = await fetch(`${BASE_URL}/dashboard/admin/api/rrxsuperboss/users/getMember/${memberId}`,{
            method: "GET",
            headers:{
                'content-type': 'application/json',
                "authorization": "Bearer " + window?.localStorage?.getItem("Authorization"),
            },
        });

        const data = await response.json();
        setMemberDetail(data);
    }
    catch(e){        
        console.log(e);
    }
}


export default getMemberDetail;