import React from "react";

import InfoCard from "./../components/InfoCard"

import styles from "./containers.module.css";

const RightContainer = ({customTabs,...rest}) =>{
    return (
        <div className={styles.rightContainer}>
            <InfoCard customTabs={customTabs} {...rest}/>
        </div>
    )
}

export default RightContainer;