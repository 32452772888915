import { filter, forEach } from "lodash";

const getSearchFilteredText = (searchText="", list) =>{

    let filteredList = filter(list,({firstName, lastName})=>{
        return firstName?.length >0 || lastName?.length >0
    })

    if(!searchText || searchText.length==0){
        return filteredList;
    }

    return filter(filteredList,(listItem)=>{
        const {firstName="", lastName=""} = listItem || {};
        return firstName.toLowerCase()?.includes(searchText.toLowerCase()) || lastName.toLowerCase()?.includes(searchText.toLowerCase());
    })
}

const getActivePatientListToMap = (list) =>{
    let map = new Map();

    forEach(list,(val)=>{
        map.set(val,true);
    })

    return map;
}

export {
    getSearchFilteredText,
    getActivePatientListToMap
}