const CREATE_ACCOUNT_FIELD = {
    Authentication:"Authentication",
    profilePhoto:"profile_photo",
    firstName : "first_name",
    lastName : "last_name",
    middleName : "middle_name",
    gender : "gender",
    speciality : "speciality",
    credentials : "credentials",
    workEmail : "email",
    phone : "phone",
    practiceName : "practice_name",
    practiceWebsite : "practice_website",
    practiceEmail : "practice_email",
};

export {
    CREATE_ACCOUNT_FIELD,
}