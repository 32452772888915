
const INIT_ACTION = "INIT_ACTION";
const CHANGE_PATIENT_PAGE_STATUS = "CHANGE_PATIENT_PAGE_STATUS";
const RESEND_PATIENT_INVITE_ACTION = "RESEND_PATIENT_INVITE_ACTION";
const CLOSE_TOAST_STATUS = "CLOSE_TOAST_STATUS";
const TABLE_FILTER_ACTION = "TABLE_FILTER_ACTION";
const OPEN_TOAST_ACTION = "OPEN_TOAST_ACTION";

export default {
    INIT_ACTION,
    CHANGE_PATIENT_PAGE_STATUS,
    RESEND_PATIENT_INVITE_ACTION,
    CLOSE_TOAST_STATUS,
    TABLE_FILTER_ACTION,
    OPEN_TOAST_ACTION
}