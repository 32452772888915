import React from "react";

import styles from "./practiceCardContainer.module.css";
import UserDetailsCard from "../userDetailsCard";

const CardRightContainer = (props)=>{
    return (
        <div className={styles.rightContainer}>
            <UserDetailsCard {...props}/>
        </div>
    )
}

export default CardRightContainer;