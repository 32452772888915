import React from "react";

import styles from "./tirSingleBlock.module.css";

const TirSingleBlock = ({primaryText, rangeText, currentValue, type}) =>{

    const colorRanges = {
        veryHigh : styles.veryHighIndicator,
        above : styles.highIndicator,
        inRange : styles.timeInRangeIndicator,
        below : styles.lowIndicator,
        veryLow : styles.veryLowIndicator
    }

    return (
        <div className={styles.container}>
            <div className={colorRanges[type]}>
            </div>
            <div className={styles.textContainer}>
                <div className={styles.primaryText}>
                    <span>{primaryText}</span>
                    <span className={styles.secondaryText}>
                        {rangeText}
                    </span>
                </div>
                <div className={styles.mainText}>
                    {currentValue}
                </div>
            </div>
        </div>
    )
}

export default TirSingleBlock;