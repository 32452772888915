import React from 'react'
import styles from './noData.module.css'
import noData  from "./../../../../../../../../../../../assets/images/no_data_bunny.svg"

export default function NoData() {
  return (
    <div className={styles.container}>
          <div>
            <img src={noData} className={styles.noDataImage} />
          </div>
          <div  className={styles.noDataText}>
              No Data
          </div>
    </div>
  )
}
