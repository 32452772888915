import React from "react";
import { ERROR_STATUS } from "../../constants/errorStatus";
import ErrorComponent from "../../atoms/errorComponent/ErrorComponent";
import Multiselect from "../../molecules/Multiselect";
import PhotoSelect from "../../molecules/PhotoSelect/PhotoSelect";
import InputWithSuffix from "../../molecules/InputWithSuffix";

import {State, City} from "country-state-city";

import styles from "./practiceCreateAccountCard.module.css";
import { CITIES, ETHNICITY, LANGUAGE_OPTIONS, STATES } from "./practiceCreateAccountCard.constants";
import withSelectWithDecline from "../../reusables/formManager/fieldHocs/SelectWithDecline/SelectWithDecline";
import withCities from "./components/customHOCs/CitiesSelector";
import { spaceRemovalKeyDownEvent } from "../../helpers/TextManipulations.helpers";
import PhoneConcentCheckBox from "./components/PhoneConcentCheckBox/PhoneConcentCheckBox";

const getRespectiveCities = (stateName)=>{
    const states = State.getStatesOfCountry('US');

    const state = states.find(s => s.name.toLowerCase() === stateName.toLowerCase());
    
    const cities = City.getCitiesOfState('US', state.isoCode);

    return cities?.map(({name})=>({value : name , id : Math.random()*100000000}));

}

const PRACTICE_CREATE_ACCOUNT_FIELDS = [
    [
    {
        label:"Profile Photo",
        name:"profilePhoto",
        CustomInput : PhotoSelect,
        ErrorComponent: ErrorComponent,
        onChange:()=>{},
        onFocus:()=>{},
    },
    {
        name: "gender",
        label : "Pronouns",
        CustomInput : Multiselect,
        isMulti:false,
        options : [
            {id:1,label:"He/Him", value: "Male"},
            {id:2,label:"She/Her", value: "Female"},
            {id:3,label:"They/Them", value: "Other"},
        ],
        placeholder: "Choose your pronoun",
        componentContainer: styles.customBasicDropdown,
        innerPrimaryContainer: styles.innerPrimaryContainer,
        selectClassname:styles.selectTimeZoneClass,
        errorTypes:[{type:ERROR_STATUS.IS_REQUIRED}],
        inputFieldRow: styles.inputFieldRow,
        errorProps: {
            className: styles.errorClassName
        },
        className : styles.commonFieldContainer,
        fieldClassname: styles.passwordInputField,
    }
    ,
    {
        label:"First Name",
        name:"firstName",
        fieldType:"text",
        errorTypes:[{type:ERROR_STATUS.IS_REQUIRED}],
        ErrorComponent: ErrorComponent,
        PlaceHolder: "Enter your first name",
        onBlur:()=>{},
        onFocus:()=>{},
        className : styles.commonFieldContainer,
    },
    {
        label:"Middle Name",
        name:"middleName",
        fieldType:"text",
        ErrorComponent: ErrorComponent,
        PlaceHolder: "Enter your middle name(optional)",
        // onChange:()=>{},
        onFocus:()=>{},
        onBlur:()=>{},
        className : styles.commonFieldContainer,
    }
    ,
    {
        label:"Last Name",
        name:"lastName",
        fieldType:"text",
        errorTypes:[{type:ERROR_STATUS.IS_REQUIRED}],
        ErrorComponent: ErrorComponent,
        PlaceHolder: "Enter your last name",
        // onChange:()=>{},
        onFocus:()=>{},
        onBlur:()=>{},
        className : styles.commonFieldContainer,
    },
    {
        label:"Language",
        name:"languages",
        CustomInput : Multiselect,
        options:LANGUAGE_OPTIONS,
        errorTypes:[{type:ERROR_STATUS.IS_REQUIRED}],
        ErrorComponent: ErrorComponent,
        placeholder:"Choose your languages",
        onFocus:()=>{},
        onBlur:()=>{},
        className : styles.commonFieldContainer,
    },
    {
        name: "ethnicity",
        label : "Ethnicity",
        CustomInput : withSelectWithDecline({Component: Multiselect, placeHolder:'I decline to identify my race & ethnicity' }),
        placeholder:'Choose your preferred Ethnicity',
        isMulti: false,
        options : ETHNICITY,
        componentContainer: styles.customBasicDropdown,
        innerPrimaryContainer: styles.innerPrimaryContainer,
        selectClassname:styles.selectTimeZoneClass,
        errorTypes:[{type:ERROR_STATUS.IS_REQUIRED}],
        inputFieldRow: styles.inputFieldRow,
        errorProps: {
            className: styles.errorClassName
        },
        className : styles.commonFieldContainer,
        fieldClassname: styles.passwordInputField,
        dropdownContainer : styles.multiSelectDropDownContainer
    },
    {
        label:"Bio",
        name:"bio",
        fieldType:"textArea",
        errorTypes:[{type:ERROR_STATUS.IS_REQUIRED}],
        ErrorComponent: ErrorComponent,
        PlaceHolder: "Enter your Bio",
        onBlur:()=>{},
        onFocus:()=>{},
        className : styles.commonFieldContainer,
    },
    ],
    [
    {
        label:"Speciality",
        name:"speciality",
        CustomInput : Multiselect,
        options:[
            {id:1,value: "Certified Nurse-Midwife (CNMs)"},
            {id:2,value: "Endocrinology"},
            {id:3,value: "Family Practice"},
            {id:4,value: "Obstetrics And gynaecology (OB/GYN)"},
            {id:5,value: "Women's Health Nurse Practitioner (WHMP)"},
            {id:6,value: "Other"}
        ],
        errorTypes:[{type:ERROR_STATUS.IS_REQUIRED}],
        ErrorComponent: ErrorComponent,
        // onChange:()=>{},
        onFocus:()=>{},
        onBlur:()=>{},
        className : styles.commonFieldContainer,
    },
    {
        label:"Credentials",
        name:"credentials",
        CustomInput : Multiselect,
        options:[{id:1,value:"CDCES"},{id:2,value:"CNM"},{id:3,value:"CPM"},{id:4,value:"DO"},{id:5,value:"MD"}],
        errorTypes:[{type:ERROR_STATUS.IS_REQUIRED}],
        ErrorComponent: ErrorComponent,
        // onChange:()=>{},
        onFocus:()=>{},
        onBlur:()=>{},
        className : styles.commonFieldContainer,
    }
    ],
    [
        {
            label:"Work Email",
            name:"workEmail",
            fieldType:"text",
            errorTypes:[{type:ERROR_STATUS.IS_EMAIL}],
            ErrorComponent: ErrorComponent,
            PlaceHolder: "Enter your work email",
            onFocus:()=>{},
            className : styles.commonFieldContainer,
            onKeyDown : spaceRemovalKeyDownEvent,
        },    
        {
            label:"Phone",
            name:"phone",
            CustomInput: InputWithSuffix ,
            fieldType:"number",
            errorTypes:[{type:ERROR_STATUS.IS_PHONE_NUMBER}],
            ErrorComponent: ErrorComponent,
            PlaceHolder: "Enter your phone number",
            onFocus:()=>{},
            onBlur:()=>{},
            className : styles.commonFieldContainer,
        },    
        {
            name: "state",
            label : "State",
            CustomInput : Multiselect,
            isMulti:false,
            options : STATES,
            placeholder: "Choose your state",
            componentContainer: styles.customBasicDropdown,
            innerPrimaryContainer: styles.innerPrimaryContainer,
            selectClassname:styles.selectTimeZoneClass,
            errorTypes:[{type:ERROR_STATUS.IS_REQUIRED}],
            inputFieldRow: styles.inputFieldRow,
            errorProps: {
                className: styles.errorClassName
            },
            className : styles.commonFieldContainer,
            fieldClassname: styles.passwordInputField,
            dropdownContainer : styles.multiSelectDropDownContainer
        },
        {
            name: "city",
            label : "City",
            CustomInput : withCities(Multiselect),
            getCities : getRespectiveCities,
            isMulti:false,
            options : CITIES,
            placeholder: "Choose your city",
            componentContainer: styles.customBasicDropdown,
            innerPrimaryContainer: styles.innerPrimaryContainer,
            selectClassname:styles.selectTimeZoneClass,
            errorTypes:[{type:ERROR_STATUS.IS_REQUIRED}],
            inputFieldRow: styles.inputFieldRow,
            errorProps: {
                className: styles.errorClassName
            },
            className : styles.commonFieldContainer,
            fieldClassname: styles.passwordInputField,
            dropdownContainer : styles.multiSelectDropDownContainer
        }
    ],
    [
        {
            label:"PhoneNumber",
            name:"phoneNumber",
            CustomInput: InputWithSuffix ,
            fieldType:"number",
            errorTypes:[{type:ERROR_STATUS.IS_PHONE_NUMBER}],
            ErrorComponent: ErrorComponent,
            PlaceHolder: "Enter your phone number",
            codeCombined : false,
            countryCodeLabel : 'countryCode',
            onFocus:()=>{},
            onBlur:()=>{},
            className : styles.commonFieldContainer,
        },    
        {
            label:"",
            name:"phoneConcent",
            fieldType:"text",
            CustomInput : PhoneConcentCheckBox,
            content : "By providing your phone number, you agree to receive transactional SMS messages from RenewRx Health. You can revoke consent any time by replying “STOP”",
            errorTypes:[{type:ERROR_STATUS.IS_REQUIRED}],
            onFocus:()=>{},
            onBlur:()=>{},
            className : styles.commonFieldContainer,
        }
    ],
    [
        {
            label:"Email",
            name:"workEmail",
            fieldType:"text",
            ErrorComponent: ErrorComponent,
            PlaceHolder: "Enter your Email Address",
            fieldClassname : styles.disabledEmailBox,
            onFocus:()=>{},
            onBlur:()=>{},
            className : styles.commonFieldContainer,
            disabled : true
        },    
        {
            label:"",
            name:"emailConcent",
            fieldType:"text",
            content:"By providing your phone number, you agree to receive transactional SMS messages from RenewRx Health. You can revoke consent any time by replying “STOP”",
            CustomInput : PhoneConcentCheckBox,
            errorTypes:[{type:ERROR_STATUS.IS_REQUIRED}],
            onFocus:()=>{},
            onBlur:()=>{},
            className : styles.commonFieldContainer,
        }
    ]

];

export {
    PRACTICE_CREATE_ACCOUNT_FIELDS
}