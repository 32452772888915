import React from "react";

import styles from "./loginPinCardComponent.module.css";

const Header = ({email = ""}) =>{
    return (
        <div className={styles.containerHeader}>
            <div className={styles.topHeader}>
                Two-Factor Authentication
            </div>
            <div className={styles.bottomHeader}>
                Enter the verication code sent to your email or phone number
            </div>
        </div>
    )
}

export default Header;