import React from "react";
import cx from "classnames";

import styles from "./labelDisplay.module.css";

const LabelDisplay = ({label, customComponentProps = {}, value = "No data"}) =>{

    const {Component, ...rest} =  customComponentProps


    if(Component)
    {
        return <Component {...rest} label={label} value={value} />
    }

    return (
        <div className={styles.container}>
            <div className={styles.innerClass}>
                {label}
            </div>
            <div className={styles.boldFontContainer}>
                {value} 
            </div>
        </div>
    )
}

LabelDisplay.defaultProps = {
    customComponentProps : {}
}

export default LabelDisplay;