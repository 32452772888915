import React from "react";

import { REJECTED_BODY } from "../../../../patientInviteConnect.constants";
import styles from './rejectedBody.module.css';

const RejectedBody = () =>{
    return (
        <div className={styles.container}>
            {REJECTED_BODY.TEXT}
        </div>
    )
}

export default RejectedBody;