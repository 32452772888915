import AccountSetup from "./components/accountSetup/AccountSetup"
import InvitePatients from "./components/invitePatients"

const getCheckListItems = (handleDisplayIndividualInfo,handleModalDisplay)=>{
    return [{
        id:1,
        name:"createProfile",
        label : "Create Your Profile",
        complete: true
    },
    {
        id:2,
        name:"setupProfile",
        label : "Setup Practice Account",
        SiblingComponent: <AccountSetup handleModalDisplay={handleModalDisplay} handleDisplayIndividualInfo={handleDisplayIndividualInfo} />,
        active: true
    },
    {
        id:3,
        name:"invitePatients",
        label : "Invite your Patients",
        SiblingComponent: <InvitePatients />
    },{
        id:4,
        name:"createPatientsDashBoard",
        label : "Setup Patient's Monitoring DashBoard",
    }
]
}

export {
    getCheckListItems
}