import styles from "./body.module.css";
import DateDisplay from "./components/dateDisplay/DateDisplay";

const getTableFields = ({ prev=0 }) =>{
    return [{
        id : 1,
        label: "Data Streams",
        name : "dataStreams",
        className : styles.titleClass
    },
    {
        id : 2,
        name : "day 0",
        label: <DateDisplay day={0} prev={prev}/>,
        className : styles.columnContainer,
    },
    {
        id : 3,
        name : "day 1",
        label: <DateDisplay day={1} prev={prev}/>,
        className : styles.columnContainer,
    },
    {
        id : 4,
        name : "day 2",
        label: <DateDisplay day={2} prev={prev}/>,
        className : styles.columnContainer,
    },
    {
        id : 5,
        name : "day 3",
        label: <DateDisplay day={3} prev={prev}/>,
        className : styles.columnContainer,
    },
    {
        id : 6,
        name : "day 4",
        label: <DateDisplay day={4} prev={prev}/>,
        className : styles.columnContainer,
    },
    {
        id : 7,
        name : "day 5",
        label: <DateDisplay day={5} prev={prev}/>,
        className : styles.columnContainer,
    },
    {
        id : 8,
        name : "day 6",
        label: <DateDisplay day={6} prev={prev}/>,
        className : styles.columnContainer,
    },
]
}

export {
    getTableFields,
}