import moment from "moment";

const getRespectiveDay = (day)=>{
    return (day%7)==0 ? (day-1)%7 : (day%7)-1;
}

const getMonthStatus = ({val,dayJump})=>{
    const currentDate = val<=7 ? moment().subtract(8-(val%8)-dayJump,"d") : moment().add((val%8)+dayJump,"d");
    return {end : currentDate.endOf("month").date() , start : currentDate.startOf("month").date()};
}

const getRespectiveCalender = ({val,type, dayJump}) =>{
    switch(type){
        case "DD": return val<=7 ? moment().subtract(8-(val%8)-dayJump,"d").format("DD") : moment().add((val%8)+dayJump,"d").format("DD");
        case "MMM": return val<=7 ? moment().subtract(8-(val%8)-dayJump,"d").format("MMM") : moment().add((val%8)+dayJump,"d").format("MMM");
        case "WD" : return val<=7 ? moment().subtract(8-(val%8)-dayJump,"d").format("ddd") : moment().add((val%8)+dayJump,"d").format("ddd");
    }    
}

export {
    getRespectiveDay,
    getRespectiveCalender,
    getMonthStatus
}