import React from "react";
import cx from "classnames";

import styles from "./formContent.module.css";

const FormFooter = ({butttonText="", buttonClassname = "", footerContainerClassname = "", formSubmitAction, enabled = true, enabledClassname = ""}) =>{
    return (
        <div className={cx(styles.footerContainer,footerContainerClassname)}>
            <button onClick={formSubmitAction} className={cx(styles.footerButton,buttonClassname,{[enabledClassname]: enabled})} disabled={!enabled}>{butttonText}</button>
        </div>
    )
}

export default FormFooter;