import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import SignUpBasePage from "../../organisms/SignUpBasePage";
import { LoginFormFields } from "./login.fields";
import FormManager from "../../reusables/formManager";
import { getFormProps, getSecondaryFormProps } from "./login.helper";
import LoginPinCard from "../../molecules/LoginPinCard/LoginPinCard";
import withActionState from "../../hooks/withActionState";

import actions from "./login.actions";
import actionTypes from "./login.actionTypes";
import Toast from "../../molecules/toast";
import { LOGIN_ENUMS } from "./login.constants";
import EmailConfirmationCard from "../../molecules/emailConfirmationCard/EmailConfirmationCard";
import Spinner from "../../atoms/spinner";
import BasicToast from "../../molecules/baseComponents/toasts/BasicToast";

const Login = ({dispatch, verification, stage, email, initialPageState, displayToast, toastProps : primaryToastProps, isLoading }) =>{

    useEffect(()=>{
        dispatch({actionType : actionTypes.INIT_ACTION})
    },[])

    const navigate = useNavigate();

    const getFormSubmitAction = (payload) =>{
        dispatch({actionType : actionTypes.PRIMARY_FORM_SUBMIT, payload})
    }

    const getSecondaryFormAction = (payload)=>{
        dispatch({ actionType : actionTypes.SECONDARY_FORM_SUBMIT,payload})
    }

    const getResendInviteAction = () =>{
        dispatch({actionType : actionTypes.RESEND_INVITE})
    }

    const formProps = getFormProps({LoginFormFields, getFormSubmitAction, navigate});
    
    const buttonProps = {
        buttonContent : "Signup"
    };

    const secondaryFormProps = getSecondaryFormProps(getSecondaryFormAction,getResendInviteAction);

    const toastProps = primaryToastProps ? primaryToastProps : !displayToast ?( verification == 'success' ? {
        message : LOGIN_ENUMS.TOAST.BODY_TEXT.SUCCESS,
        type : "success"
    } : {
        message : LOGIN_ENUMS.TOAST.BODY_TEXT.FAILED,
        type : "failed"
    }) : (
        {
            message : LOGIN_ENUMS.TOAST.BODY_TEXT.ANAUTHORIZED,
            type : "failed"
        }   
    );

    return (<>
        {isLoading && <Spinner />}
        { (verification || displayToast) && <BasicToast {...toastProps} display={displayToast} />}
        <SignUpBasePage initialPageState={initialPageState} secondaryFormProps={secondaryFormProps} SecondaryComponent={LoginPinCard} SecondaryComponentProps={getSecondaryFormProps} rightHeaderText={"New User!"} FormComponent={FormManager} formProps={formProps} buttonProps={buttonProps} stage={stage} email={email}/>
    </>)
}

export default withActionState({Component: Login, action : actions});