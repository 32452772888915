import React from "react";
import cx from "classnames";

import styles from "./footer.module.css";

const Footer = ({inputValue=[], handleButtonSubmit, handleResendInvite}) =>{
    const updateValue = inputValue.join("") || "";

    return (
        <div className={styles.container}>
            <div onClick={handleButtonSubmit} className={cx(styles.buttonContainer,{[styles.activeButton]: updateValue.length==6})}>
                Confirm Code
            </div>
            <div className={styles.mainTextContainer}>
                <span className={styles.textPrimaryTypo}>Didn’t receive a code?</span>
                <span className={styles.textLinkTypo} onClick={handleResendInvite}>Resend</span>
            </div>
        </div>
    )
}

export default Footer;