import React from "react";

import errorIcon from "../../../assets/images/error_icon.svg";

import styles from "./errorComponent.module.css";

const ErrorComponent = ({fieldError={}}) =>{
    const errorText = Object.values(fieldError)[0];
    if(Object.keys(fieldError).length ==0 )
    {
        return <></>
    }
    return (<div className={styles.errorContainer}>
        <img src={errorIcon} />
        <div className={styles.textContainer}>
            {errorText}
        </div>
    </div>)
}

export default ErrorComponent;