import moment from "moment-timezone";

import { ERROR_STATUS } from "../../constants/errorStatus"
import CustomPasswordInput from "../../molecules/CustomPasswordInput";
import Multiselect from "../../molecules/Multiselect";
import BasicDropdown from "../../molecules/baseComponents/dropDowns/basicDropdown/BasicDropdown";
import CustomBasicDropdown from "../../molecules/baseComponents/dropDowns/customBasicDropdown/CustomBasicDropdown";
import { USER_SETTING_PROFILE } from "./userSetting.constants"

import { spaceRemovalKeyDownEvent } from "../../helpers/TextManipulations.helpers";

import styles from './userSetting.module.css';

const getCorrespondingProps = (name)=>{
    switch(name){
        case "speciality":
            return {
                CustomInput : Multiselect,
                options:[{id:1,value:"CNMs"},{id:2,value:"Endocrilogy"},{id:3,value:"Family Practice"},{id:4,value:"OB/GYN"},{id:5,value:"Other"}],
            };
        case "credentials":
            return {
                CustomInput : Multiselect,
                options:[{id:1,value:"CDCES"},{id:2,value:"CNM"},{id:3,value:"CPM"},{id:4,value:"DO"},{id:5,value:"MD"}],
            };
        case 'gender':
            return {
                CustomInput : BasicDropdown,
                metricTypes : [{id:1,value:"male",label:"Male"},{id:2,value:"female",label:"Female"},{id:2,value:"non-binary",label:"Non-Binary"}],
                selectClassname: styles.selectClass,
                componentContainer : styles.componentContainer
            }
        case 'timezoneOffsetHours':
            return {
                CustomInput : CustomBasicDropdown,
                options : [
                    {id:1,label:"Denver (GMT-6)", value: parseFloat((moment().tz("America/Denver")).utcOffset()/60).toFixed(2)},
                    {id:2,label:"Chicago (GMT-5)", value: parseFloat((moment().tz("America/Chicago")).utcOffset()/60).toFixed(2)},
                    {id:3,label:"Phoenix (GMT-7)", value: parseFloat((moment().tz("America/Phoenix")).utcOffset()/60).toFixed(2)},
                    {id:4,label:"Los Angeles (GMT-7)", value: parseFloat((moment().tz("America/Los_Angeles")).utcOffset()/60).toFixed(2)},
                    {id:5,label:"Anchorage (GMT-8)", value: parseFloat((moment().tz("America/Anchorage")).utcOffset()/60).toFixed(2)},
                    {id:6,label:"Honolulu (GMT-10)", value: parseFloat((moment().tz("Pacific/Honolulu")).utcOffset()/60).toFixed(2)},
                    {id:6,label:"Kolkata (India)", value: parseFloat((moment().tz("Asia/Calcutta")).utcOffset()/60).toFixed(2)}
                ],
                componentContainer: styles.customBasicDropdown,
                innerPrimaryContainer: styles.innerPrimaryContainer,
                selectClassname:styles.selectTimeZoneClass
            }
        case 'workEmail':
            return {
                onKeyDown : spaceRemovalKeyDownEvent
            }
        default: return {}
    }
}

const getModalField = ({name}) =>{
    return [{
        name,
        label: USER_SETTING_PROFILE[name],
        errorTypes : [{type: ERROR_STATUS.IS_REQUIRED}],
        fieldType : "text",
        inputFieldRow: styles.inputFieldRow,
        errorProps: {
            className: styles.errorClassName
        },
        className : styles.commonFieldContainer,
        fieldClassname: styles.passwordInputField,
        PlaceHolder: "Enter your details",
        onBlur:()=>{},
        ...getCorrespondingProps(name)
    }]
}

const CHANGE_PASSWORD_FIELDS = [
    {
        name:"currentPassword",
        label: "Current Password",
        errorTypes : [{type: ERROR_STATUS.IS_REQUIRED}],
        fieldType : "password",
        CustomInput : CustomPasswordInput,
        inputFieldRow: styles.inputFieldRow,
        errorProps: {
            className: styles.errorClassName
        },
        className : styles.commonFieldContainer,
        fieldClassname: styles.passwordInputField,
        PlaceHolder: "Enter your current password",
        onBlur:()=>{},
        onKeyDown : spaceRemovalKeyDownEvent
    },
    {
        name:"newPassword",
        label: "New Password",
        errorTypes : [{type: ERROR_STATUS.IS_REQUIRED}],
        fieldType : "password",
        CustomInput : CustomPasswordInput,
        inputFieldRow: styles.inputFieldRow,
        errorProps: {
            className: styles.errorClassName
        },
        className : styles.commonFieldContainer,
        fieldClassname: styles.passwordInputField,
        PlaceHolder: "Enter your new password",
        onBlur:()=>{},
        onKeyDown : spaceRemovalKeyDownEvent
    }
];

export {
    getModalField,
    CHANGE_PASSWORD_FIELDS,
}