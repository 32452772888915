const COPY_COLOR_FORMATS ={
    GREEN : "•",
    YELLOW : "*",
    RED : "!"
}

const METRIC_SUFFIX ={
    AVERAGE_GLUCOSE : "mg/dL",
    GMI : "",
    GLUCOSE_VERIABILITY : ""
}

const METRICS_LABEL = {
    AVERAGE_GLUCOSE : "Average Glucose",
    GMI : "GMI",
    GLUCOSE_VERIABILITY : "Glucose variability"
}

export {
    COPY_COLOR_FORMATS,
    METRICS_LABEL,
    METRIC_SUFFIX
}