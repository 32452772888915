import React from "react";

import styles from "./contentBlock.module.css";

const ContentBlock = ({heading, content}) =>{
    return (
        <div className={styles.container}>
            <div className={styles.headerContent}>
                {heading}
            </div>
            <div className={styles.content}>
                {content || "-"}
            </div>
        </div>
    )
}

export default ContentBlock