

const COLOR_CODING = {
    green:"rgb(158, 219, 197)",
    yellow:"rgb(234, 197, 107)",
    red:"rgb(246, 180, 167)"
}

const LABEL_SUFFIX ={
    AVERAGE_ONE_HOUR_POST_PRANDIAL : ' 1hr'
}

export {
    COLOR_CODING,
    LABEL_SUFFIX
}