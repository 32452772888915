import styles from "./search.module.css";
import AddNewMemberForm from "../../../form/AddNewMemberForm";
import { useState, useCallback } from "react";

const Search = ({ worksiteList, practiceId, setRefreshMemberList, updateToast, setIsLoading, updateFormValues, submitFormValues, updateSearchText }) => {

    const [open, setIsOpen] = useState(false);

    const openForm = () => setIsOpen(!open);

    const handleTextChange = useCallback((event)=>{
        event.preventDefault();
        updateSearchText(event?.target?.value);
    },[updateSearchText]);


    return (

        <div className={styles.searchContainer}>
            <div className={styles.search}>
                <input onChange={handleTextChange} placeholder="Search by Practice Name, Email ID" className={styles.searchBar}></input>
                <img src={require("../../../../../../../assets/images/search-icon.svg").default} className={styles.searchLogo}></img>
            </div>
            <div className={styles.rightActions}>
                <div className={styles.dropdown}>
                    <select className={styles.fieldValueDropdown}>
                        <option className={styles.fieldValueDropdown}>All Worksites</option>
                    </select>
                    <img className={styles.dropdownArrow} src={require("../../../../../../../assets/images/dropdown_arrow.svg").default}></img>
                </div>

                <div className={styles.dropdown}>
                    <select className={styles.fieldValueDropdown}>
                        <option className={styles.fieldValueDropdown}>All Specialities</option>
                    </select>
                    <img className={styles.dropdownArrow} src={require("../../../../../../../assets/images/dropdown_arrow.svg").default}></img>
                </div>

                <div className={styles.dropdown}>
                    <select className={styles.fieldValueDropdown}>
                        <option className={styles.fieldValueDropdown}>All Roles</option>
                    </select>
                    <img className={styles.dropdownArrow} src={require("../../../../../../../assets/images/dropdown_arrow.svg").default}></img>
                </div>

                <div className={styles.addNewMemberBorder} onClick={openForm}>
                    <div className={styles.addNewMemberStyle}>Add New Member</div>
                </div>
                {open && <div className={styles.formBackground}>
                    <AddNewMemberForm setIsLoading={setIsLoading} updateToast={updateToast} setRefreshMemberList={setRefreshMemberList} practiceId={practiceId} updateFormValues={updateFormValues} submitFormValues={submitFormValues} worksiteList={worksiteList} setIsOpen={setIsOpen} className={styles.addNewPracticeFormStepOne} />
                </div>}
            </div>

        </div>

    )
}

export default Search;