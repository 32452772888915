const INIT_ACTION = "INIT_ACTION";
const CHANGE_TAB_ACTION = "CHANGE_TAB_ACTION";
const OVERVIEW_DURATION_CHANGE = "OVERVIEW_DURATION_CHANGE";
const HANDLE_LOADER_TOGGLE = "HANDLE_LOADER_TOGGLE";
const HANDLE_DURATION_UPDATE = "HANDLE_DURATION_UPDATE";

const HANDLE_NOTES_DISPLAY = "HANDLE_NOTES_DISPLAY";
const HANDLE_NOTES_STATE_UPDATE = "HANDLE_NOTES_STATE_UPDATE";

const HANDLE_NOTES_DISCARD_POPUP_TOGGLE = "HANDLE_NOTES_DISCARD_POPUP_TOGGLE";
const HANDLE_NOTES_FORM_DATA_UPDATE = "HANDLE_NOTES_FORM_DATA_UPDATE";
const  NOTES_FORM_SUBMIT = "NOTES_FORM_SUBMIT";
const CHECK_NOTES_POPUP_STATUS = "CHECK_NOTES_POPUP_STATUS";
const HANDLE_TOAST_UPDATE =  'HANDLE_TOAST_UPDATE';
const HANDLE_TOAST_CLOSE =  'HANDLE_TOAST_CLOSE';

export default {
    INIT_ACTION,
    CHANGE_TAB_ACTION,
    OVERVIEW_DURATION_CHANGE,
    HANDLE_LOADER_TOGGLE,
    HANDLE_DURATION_UPDATE,
    HANDLE_NOTES_DISPLAY,
    HANDLE_NOTES_STATE_UPDATE,
    HANDLE_NOTES_DISCARD_POPUP_TOGGLE,
    NOTES_FORM_SUBMIT,
    CHECK_NOTES_POPUP_STATUS,
    HANDLE_NOTES_FORM_DATA_UPDATE,
    HANDLE_TOAST_UPDATE,
    HANDLE_TOAST_CLOSE
}