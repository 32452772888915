import React from "react";
import {map} from "lodash";

import { getWeeklyFormattedWeeklyDates } from "../../../../../../../../../../helpers/dateFormatter";

import WeeklyFilter from "../../../../../WeeklyFilter/WeeklyFilter";

import styles from "./summary.module.css";
import { getSummaryFields } from "./summary.fields";
import LargeCards from "../../../../../../../LargeCards";
import RecentActivity from "../../../../../../../recentActivity/RecentActivity";

const Summary = (props) =>{

    const {percentageOfBalancedPlateMealStatusMap = {}, rangeByMicroNutrientMap = {}, micronutrientsInsightsResponse = {}}= props;

    const fieldListOptions = getSummaryFields(percentageOfBalancedPlateMealStatusMap, micronutrientsInsightsResponse,rangeByMicroNutrientMap);

    return (
        <div className={styles.container}>
            <div className={styles.contentContainer}>
                <div className={styles.secondaryContainer}>
                    {map(fieldListOptions,(option)=>{
                        return (<LargeCards {...option}/>)
                    })}
                </div>
                <RecentActivity className={styles.recentActivity} />
            </div>
        </div>
    )
}

export default Summary;