import React from "react";
import styles from "./modalHeader.module.css";

const ModalHeader = ({ date = "", time="", label="Balanced Plate Logs",CloseComponent = () => <></> }) => {

  return (
    <div className={styles.outerContainer}>
      <div className={styles.container}>
        <div className={styles.primaryText}>
          {label}
          <div className={styles.betaIconContainer}>
                Beta
          </div>
        </div>
        <div className={styles.secondaryText}>
          <span>{date}</span>
          <span>{time}</span>
        </div>
      </div>
      <CloseComponent />
    </div>
  );
};

export default ModalHeader;