import { FORM_BUTTON_CONTENT, FORM_LABELS } from "./form.constants"
import { getFormFields } from "./form.fields"

const getPopupForm = ()=>{
    const fields = getFormFields();
    return [{
        labels : FORM_LABELS[0],
        fields : fields[0],
        buttonContent : FORM_BUTTON_CONTENT[0]
    },{
        labels : FORM_LABELS[1],
        fields : fields[1],
        buttonContent : FORM_BUTTON_CONTENT[1]
    }]
}

export {
    getPopupForm
}