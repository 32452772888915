import RowIndicator from "./components/RowIndicator";
import TableGraphField from "./components/TableGraphField/TableGraphField";

const getWrapperFields = ()=> [{
    label : "timing",
    field : RowIndicator
  },
  {
    label : 'previousWeekData',
    field : TableGraphField
  },
  {
    label : "currentWeekData",
    field : TableGraphField
  }
];

export {
  getWrapperFields
};