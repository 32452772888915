import React from "react";

import styles from "./ganttTableDateColumn.module.css"
import { getMonthStatus, getRespectiveCalender,  getRespectiveDay } from "./ganttTableDateColumn.helper";

const GanttTableDateColumn = ({day, dayJump }) =>{

    return (
        <div className={styles["calender-column"]}>
            <div className={styles["day-first-div"]}>
                <p className={styles["day-first-div-text"]}>Day {getRespectiveDay(day)}</p>
            </div>
            <div className={`${styles["month-first-div"]} ${styles["border-month-start"]}`}>
                <p className={styles["month-first-div-text"]}>{ (getMonthStatus({val:day, dayJump}).end == getRespectiveCalender({val:day,type:"DD",dayJump}) || getMonthStatus({val:day, dayJump}).start == getRespectiveCalender({val:day,type:"DD",dayJump}) ) && getRespectiveCalender({val:day,type:"MMM", dayJump})}</p>
              </div>
            <div className={styles["date-first-div"]}>
                <p className={styles["date-first-div-text"]}>
                {getRespectiveCalender({val:day,type:"WD", dayJump})} <span className={styles["week-text"]}>{getRespectiveCalender({val:day,type:"DD",dayJump})}</span>
                </p>
              </div>
        </div>
    )
}

export default GanttTableDateColumn;