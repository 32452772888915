import React, { useState } from "react";
import drillDown from "highcharts/modules/drilldown";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';

import styles from "./verticalBarChart.module.css";


HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);

Highcharts.setOptions({
    exporting: {
      enabled: false
    }
});
  
const VerticalBarChart = ({categories =[], ...rest}) =>{

    const { chartStatistics : data = [{y:5, color :'red'}, {y:10,color :'green'}, {y:15,color :'red'}, {y:20,color :'red'}, {y:25,color : 'blue'},{y:20,color : 'yellow'},{y:25,color : 'yellow'}] } = rest;

    drillDown(Highcharts);

    const options = {
        chart: {
            type: 'column',
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            height : 120,
        },
        title: {
            text: null
        },
        exporting: {
            enabled: false
        },
        legend: {
            enabled: false,
        },
        xAxis: {
            categories: categories,
            lineColor: 'transparent',
            gridLineWidth: 0,
            // tickWidth: 0,
            title:{
                text:null,
            },
            labels: {
                padding: 20,
                step:1,
                rotation:0
            },      
        },
        yAxis: {
            plotLines:[{
                value: 15,
                color: '#9EDBC5',
                dashStyle: 'Dash',
                width: 1.8,
            }],
            min:-1,
            max:22,
            startOnTick: false,
            labels: {
                enabled: false,
            },
            gridLineWidth: 0,
            title: {
              text: '',
            },
        },
        plotOptions: {
            series: {
                borderRadius: 3
            },
            column: {
                borderRadius: 3,
                borderWidth: 1.5
            }
        },
        tooltip: {
            backgroundColor: '#2A292D',
            width:"243px",
            style: {
                width: 400,
                display: "flex",
                minWidth:"243",
                padding: "12px",
                flexDirection: "column",
                gap: "12px",
                borderRadius: '8px',
                color: 'white',
                width: "243px"
            },
            formatter: function(data) {
                return '<span><b>' + this.y +  '</b></span>';
            },
            positioner: function(boxWidth, boxHeight, point) {
                return {
                    x: point.plotX,
                    y: point.plotY
                };
            }
        },
        series: [{
            type: 'column',
            name : null,
            data: data,
        }],
        credits:{
            enabled : false,
        }
    }

    return (
        <div className={styles.container}>
            <HighchartsReact highcharts={Highcharts} options={options}/>
        </div>
    )
}

export default VerticalBarChart;