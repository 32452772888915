import React from "react";
import { map } from "lodash";

import styles from "./body.module.css";
import ThreeLinedCard from "../ThreeLinedCard";

const Body = ({options=[]}) =>{
    return (
        <div className={styles.container}>
            {map(options,(option)=>{
                return (
                    <ThreeLinedCard {...option}/>
                )
            })}
        </div>
    )
}

export default Body;