import React from "react";
import {map} from "lodash";

import styles from "./body.module.css";
import CheckListItem from "./components/checkListItem";
import { getCheckListItems } from "./body.fields";

const Body = ({handleDisplayIndividualInfo = ()=>{}, handleModalDisplay}) =>{
    const fields = getCheckListItems(handleDisplayIndividualInfo,handleModalDisplay);
    return (
        <div className={styles.container}>
            {map(fields,(val)=>{
                return <CheckListItem {...val}/>
            })}
        </div>
    )
}

export default Body;