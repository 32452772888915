import { BASE_URL } from "../../constants/api";

const getMemberList = async (practiceId, practiceTab, setMemberList) => {

    try {
        const memberListRequest = await fetch(`${BASE_URL}/dashboard/admin/api/rrxsuperboss/users/getMembers/${practiceId}/${practiceTab}`, {
            method: "GET",
            headers: {
                'content-type': 'application/json',
                "authorization": "Bearer " + window?.localStorage?.getItem("Authorization"),
            }
        });
        const memberListResponse = await memberListRequest.json();
        setMemberList(memberListResponse.dashboardUserDataList);
    }
    catch (e) {
        console.log('error is : ', e);
    }

}

const getPracticeData = async (practiceId, setPracticeData) => {

    try {
        const practiceDataRequest = await fetch(`${BASE_URL}/dashboard/admin/api/rrxsuperboss/users/getPracticeData/${practiceId}`, {
            method: "GET",
            headers: {
                'content-type': 'application/json',
                "authorization": "Bearer " + window?.localStorage?.getItem("Authorization"),
            }
        });
        const practiceDataResponse = await practiceDataRequest.json();
        setPracticeData(practiceDataResponse.practiceData);
    }
    catch (e) {
        console.log('error is : ', e);
    }

}

const getWorksiteList = async (practiceId, setWorksiteList) => {

    try {
        const worksiteListRequest = await fetch(`${BASE_URL}/dashboard/admin/api/rrxsuperboss/users/getDivisionData/${practiceId}`, {
            method: "GET",
            headers: {
                'content-type': 'application/json',
                "authorization": "Bearer " + window?.localStorage?.getItem("Authorization"),
            }
        });
        const worksiteListResponse = await worksiteListRequest.json();
        setWorksiteList(worksiteListResponse.divisionDataList);
    }
    catch (e) {
        console.log('error is : ', e);
    }

}

export {getMemberList, getPracticeData, getWorksiteList};