import React from "react";

import styles from "./popupContent.module.css";

const PopupContent = ({formData}) =>{
    const {practiceName , practiceHandle, websiteName ,websiteAddressOne} = formData || {};

    return (
        <div className={styles.container}>
            <div className={styles.upperContainer}>
                <div className={styles.mainRow}>
                    {practiceName ? practiceName: <div className={styles.nonData} />}
                </div>
                <div className={styles.row}>
                    {practiceHandle ? practiceHandle :<div className={styles.smallNoData} />}
                </div>
                <div className={styles.worksite}>
                    {websiteName && <div className={styles.worksiteHeading}>
                        <div className={styles.verticalSeperator} />
                            <div className={styles.worksiteHeadingText}>
                                {websiteName}
                            </div>
                        </div>}
                    {
                        websiteAddressOne && 
                        <div className={styles.addressText}>
                            {websiteAddressOne}
                        </div>
                    }
                </div>
            </div>
            <div className={styles.lowerContainer}>
                <div className={styles.iconContainer}>
                    <img src={require("../../../../../assets/images/add_icon.svg").default} className={styles.addIcon}/>
                </div>
                <div className={styles.row}>
                    <div className={styles.smallNoData} />
                </div>
            </div>
        </div>
    )
}

export default PopupContent;