import { GLUCOSE_METRICS_TYPES } from "../../../../constants/metricTypes"

const PROGRESS_MONITORING_CARDS_TYPES = {
    PRIMARY_CARD : "PrimaryCard",
    TREND_ANALYSIS : "TrendAnalysis",
    COMPARISON_ANALYSIS : "ComparisonAnalysis",
    ACTIVITY_ANALYSIS : "ActivityAnalysis",
    DAY_BY_DAY_TREND_ANALYSIS : "dayByDayTrendAnalysis"
}

const COMPARISON_ANALYSIS_MAPPING = {
    ONE_HOUR:{
        PRIMARY:GLUCOSE_METRICS_TYPES.POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE,
        SECONDARY:"AVERAGE_ONE_HOUR_POST_PRANDIAL",
    },
    TWO_HOUR:{
        PRIMARY:GLUCOSE_METRICS_TYPES.POST_PRANDIAL_TWO_HOUR_TIME_IN_RANGE,
        SECONDARY:"AVERAGE_TWO_HOUR_POST_PRANDIAL"
    }
}

const TREND_ANALYSIS_TIME_DIFFERENCE_IN_MILLISECONDS = 1800000 

const PROGRESS_MONITORING_TYPE={
  BLOOD_GLUCOSE:"Glucose",
  BALANCED_PLATE:"Balanced Plate"
}

const PROGRESS_MONITORING_PRIMARY_CARD_BASIC_METRICS={
    AVERAGE_GLUCOSE:"averageGlucose",
    GLUCOSE_VERIABILITY : "glucoseVariability",
    GMI : "gmi",
    STANDARD_DEVIATION : "standardDeviation"
}

const COMPARISON_ANALYSIS_METRIC_TYPES = [{
    label : "1 Hr PP",
    value : "ONE_HOUR"
},
{
    label : "2 Hr PP",
    value : "TWO_HOUR"
}
]

const COMPARISON_ANALYSIS_METRIC_TYPES_MAPPING = {
    ONE_HOUR:"1 Hr PP",
    TWO_HOUR:"2 Hr PP",
}

export {
    PROGRESS_MONITORING_CARDS_TYPES,
    COMPARISON_ANALYSIS_METRIC_TYPES,
    PROGRESS_MONITORING_TYPE,
    PROGRESS_MONITORING_PRIMARY_CARD_BASIC_METRICS,
    COMPARISON_ANALYSIS_MAPPING,
    COMPARISON_ANALYSIS_METRIC_TYPES_MAPPING,
    TREND_ANALYSIS_TIME_DIFFERENCE_IN_MILLISECONDS
}