import React from "react";
import cx from "classnames";

import styles from "./circularLabel.module.css";

const CircularLabel = ({content,className, ...rest})=>{
    return (
        <div className={cx(styles.container, className)} {...rest}>
            {content}
        </div>
    )
}

export default CircularLabel;