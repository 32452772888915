import React, { useState } from "react";
import styles from "./customCheckbox.module.css";

const CustomCheckbox = ({type,className, name ,errorClassname,fieldError={},onChange,handleFieldChange,...rest}) => {
    const [inputType, setInputType] = useState(false);

    const toggleInputType = (e) => {
        setInputType(!inputType);
        handleFieldChange({label : name})(!inputType)
    }

    return (
        <div className={styles.checkBoxContainer}>
            <input type="checkbox" className={styles.checkbox} onClick={toggleInputType} {...rest}/>
            <div className={styles.checkboxData}> Trust this device for 30 days</div>
        </div>)
}



export default CustomCheckbox;