import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./pageHeaderContext.module.css"

const PageHeaderContext = ({practiceName}) => {

    const navigate = useNavigate();

    const handlePracticeManagementClick = useCallback(()=>{
        navigate("/superUser")
    },[navigate])

    return (
        
            <div className={styles.pageHeaderContext}>
                <div className={styles.subHeading}><div className={styles.subHeadingPractice} onClick={handlePracticeManagementClick}>Practice Management </div>/ {practiceName}</div>
            </div>

    )
}

export default PageHeaderContext;