import React from "react";

import styles from "./containers.module.css";

const LeftContainer = ({ currentPageFormProps,...rest}) =>{

    const {Component, ...remainingCurrentPageProps} = currentPageFormProps;

    return (
        <div className={styles.leftContainer}>
            <Component {...remainingCurrentPageProps} {...rest} />
        </div>
    )
}

export default LeftContainer