import React from "react";

import styles from "./tooltip.module.css";

const Tooltip = ({text,className})=>{
    return (
        <div className={`${styles.container} ${className}`}>
            {text}
        </div>
    )
}

export default Tooltip;