export const STATUS = {
  BALANCED: "Balanced",
  UNBALANCED: "Unbalanced",
  CRITICAL: "Critical",
};

export const MONTH = {
  JAN: "Jan",
  FEB: "Feb",
  MAR: "Mar",
  APR: "Apr",
  MAY: "May",
  JUN: "Jun",
  JUL: "Jul",
  AUG: "Aug",
  SEP: "Sep",
  OCT: "Oct",
  NOV: "Nov",
  DEC: "Dec",
};