import React from "react";
import {map} from "lodash";

import VerticalBarChart from "../../../../../../charts/VerticalBarChart/VerticalBarChart";

import styles from "./balancePlateCard.module.css";
import { getRespectiveChartData, getRespectiveColumns, getRespectiveStats } from "./balancePlateCard.helpers";

const BalancePlateCard = ({
  statistics,
  micronutrientsInsightsResponseMap=[],
  micronutrientsInsightsResponse={}
  }) =>{

    const mainStatistics = getRespectiveStats(statistics,micronutrientsInsightsResponse);
    const chartData = getRespectiveChartData(micronutrientsInsightsResponseMap);
    const columns = getRespectiveColumns();

    return (
        <div className={styles.container}>
            <div className={styles.leftContainer}>
                {map(mainStatistics, ({label,value})=>{
                    return (
                        <div className={styles.statField}>
                            <div className={styles.valueContainer}>
                                <div className={styles.valueText}>{value}</div>
                            </div>
                            <div className={styles.labelText}>
                                {label}
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className={styles.rightContainer}>
                {chartData && chartData?.filter((val)=>val?.y).length >0 && <VerticalBarChart categories={columns} chartStatistics={chartData}/>}
            </div>
        </div>
    )
}

export default BalancePlateCard;