import React from "react";
import cx from "classnames";

import checkIcon from "./../../../../../../../assets/images/complete_check_icon.svg";
import radioIcon from "../../../../../../../assets/images/radio_button_icon.svg";
import nonActiveRadioIcon from "../../../../../../../assets/images/non_active_radio_button_icon.svg";

import styles from "./checkListItem.module.css";

const CheckListItem = ({label,SiblingComponent = <></>,containerClassname="", complete = false, active = false}) =>{
    return (
        <div className={cx(styles.externalContainer,containerClassname)}>
            <div className={cx(styles.container,{[styles.nonActiveRadios]: !active})}>
                <img src={ complete ? checkIcon : (active ? radioIcon : nonActiveRadioIcon)} className={cx(styles.radioButton)} />
                <label>{label}</label>
            </div>
            {active && SiblingComponent}
        </div>
    )
}

export default CheckListItem;