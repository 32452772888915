import React from 'react';
import cx from "classnames";

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import noDataToDisplay from 'highcharts/modules/no-data-to-display';
import drillDown from "highcharts/modules/drilldown";

import styles from "./averagePlateSummaryDonut.module.css";

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
noDataToDisplay(Highcharts);
drillDown(Highcharts);

Highcharts.setOptions({
    exporting: {
      enabled: false
    }
});  

const AveragePlateSummaryDonut = ({data=[], className}) =>{

    const options = {
            chart: {
                type: 'pie',
                colors : ["#E5FAF2","#FEEFEB","#FFF6BD"],
                height : 192,
                width : 192
            },
            title: {
                text: ""
            },
            plotOptions: {
                pie: {
                    innerSize: '50%',
                    borderColor: 'white',
                    borderWidth: 2,
                    dataLabels: {
                        enabled: false
                    },
                    distance:30
                },
                series: {
                    pointPadding: 0.6, // Increase space between points
                    groupPadding: 0.6 // Increase space between groups
                }
            },
            series: [{
                name: 'Data',
                data: data
            }],
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },          
        }

    return (
        <div className={cx(styles.container,className)}>
            <HighchartsReact Highcharts={Highcharts} options={options} />
        </div>
    )
}

export default AveragePlateSummaryDonut;