import React from "react";
import cx from "classnames";

import DateDisplay from "./../../../../../../../../../../atoms/dateDisplay/DateDisplay";
import { getWeeklyFormattedWeeklyDates } from "./../../../../../../../../../../helpers/dateFormatter";

import styles from "./comparisonAnalysis.module.css";
import WeeklyFilter from "../../../../../WeeklyFilter";

const getTableFields = (prev=0, getFilterChangeAction) =>{
    return [{
        id : 1,
        label: "Timings",
        name : "timing",
        className : cx(styles.tableColumnContainer, styles.primaryColumn),
        innerClassName : styles.innerClassName
    },
    {
        id : 2,
        name : "currentWeekData",
        label : <DateDisplay {...getWeeklyFormattedWeeklyDates({prev:0,format:"MMM DD"})}/>,
        className : cx(styles.tableColumnContainer, styles.columnDateContainer)
    },
    {
        id : 3,
        name : "previousWeekData",
        label : <WeeklyFilter 
                    name={"comparisonAnalysis"} 
                    previous={prev} 
                    handlePrimaryDurationChange={getFilterChangeAction} 
                    {...getWeeklyFormattedWeeklyDates({prev: prev,format:"MMM DD"})}
                />,
        className : cx(styles.tableColumnContainer, styles.columnDateContainer)
    },
]
}

export {
    getTableFields,
}