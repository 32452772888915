import React from "react";

import cancelIcon from "./../../../assets/images/cancel/basic_cancel.svg"

import Modal from "../modals/Modal";

import Header from "./components/Header";
import Body from "./components/Body";

import styles from "./termsAndConditionsModal.module.css";

const TermsAndConditionsModal = ({displayModal, setModalDisplay, modalType})=>{
    return (
        <div className={styles.container}>
            <Modal toggleModal={()=>setModalDisplay(!displayModal)} modalType={modalType} crossIcon={cancelIcon} className={styles.modalContainer} HeaderComponent={Header} BodyComponent={Body}/>
        </div>
    )
}

export default TermsAndConditionsModal;