import React from "react";
import {map} from "lodash";

import { TERMS_AND_PRIVACY_POLICY } from "../../termsAndConditionsModal.constants";

import styles from "./body.module.css";
import Content from "./components/Content/Content";

const Body = ({modalType})=>{
    return (
        <div className={styles.container}>
            {map(TERMS_AND_PRIVACY_POLICY[modalType].BODY_DATA.CONTENT,(data)=>{
                const {HEADING , BODY , SECONDARY} = data;
                return (
                    <div className={styles.contentContainer}>
                        {HEADING && <div className={styles.heading}>{HEADING}</div>}
                        {BODY && <div className={styles.content}>
                            {
                                map(BODY,({content, className})=>{
                                    return <Content content={typeof content == "string" ? content : ""} className={className}/>
                                })
                            }
                        </div>}
                        {SECONDARY && <div className={styles.content}>
                            {
                                map(SECONDARY,({content="", className=""})=>{
                                    return <Content content={typeof content == "string" ? content : ""} className={className}/>
                                })
                            }
                        </div>}
                    </div>
                )
            })}
        </div>
    )
}

export default Body;