import { useState } from "react";
import styles from "./form.module.css";
import { map } from "lodash";
import { CARE_PRO_USER_TYPE } from "./form.constants";
import { MEMBER_FORM_TOAST_MESSAGE, TOAST_TYPE_CONSTANTS } from "../../practiceDetail.constants";

const AddNewMemberForm = ({ setIsOpen, updateFormValues, submitFormValues, worksiteList, practiceId, setRefreshMemberList, updateToast, setIsLoading }) => {
    const closeForm = () => setIsOpen(false);

    const handleStateUpdate = (type) => (event) => {
        event.preventDefault();
        updateFormValues({ type, value: event.target.value });
    }

    const handleFormSubmitAction = () => {
        setIsLoading(true);
        let promise = submitFormValues(practiceId);
        closeForm();
        promise.then((data)=>{
            setIsLoading(false);
            if(data){
                setRefreshMemberList(true);
                updateToast(TOAST_TYPE_CONSTANTS.SUCCESS, MEMBER_FORM_TOAST_MESSAGE);
            }
            else{
                updateToast(TOAST_TYPE_CONSTANTS.FAILED, MEMBER_FORM_TOAST_MESSAGE);
            }
        })
    }

    const handleRoleSelection = (role, event) => {
        setRole(role);
        event.target.value = role;
        handleStateUpdate('role')(event);
    }

    const [role, setRole] = useState(CARE_PRO_USER_TYPE.NON_RENEWRX_CARE_PRO_ADMIN);
    return (

        <div className={styles.container}>
            <div className={styles.upperContainer}>
                <div className={styles.formName}>Add new member</div>
                <img className={styles.cancel} onClick={closeForm} src={require("../../../../../assets/images/cancel/transparent_cacel.svg").default}></img>
            </div>
            <div className={styles.bottomContainer}>
                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        Name
                    </div>
                    <input className={styles.fieldValueName} onBlur={handleStateUpdate('name')} placeholder="Enter Team Member Name"></input>
                </div>

                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        Email
                    </div>
                    <input className={styles.fieldValue} onBlur={handleStateUpdate('email')} placeholder="Enter Email Id"></input>
                </div>

                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        Role
                    </div>
                    {/* <input className={styles.fieldValue} placeholder="Enter Email Id"></input> */}
                    <div className={styles.memberRole}>
                        <div className={role == CARE_PRO_USER_TYPE.NON_RENEWRX_CARE_PRO_ADMIN ? styles.roleAdmin : styles.roleMember} onClick={(event) => { handleRoleSelection(CARE_PRO_USER_TYPE.NON_RENEWRX_CARE_PRO_ADMIN, event) }}>
                            Admin
                        </div>
                        <div className={role == CARE_PRO_USER_TYPE.NON_RENEWRX_CARE_PRO ? styles.roleAdmin : styles.roleMember} onClick={(event) => { handleRoleSelection(CARE_PRO_USER_TYPE.NON_RENEWRX_CARE_PRO, event) }}>
                            Member
                        </div>
                    </div>
                </div>

                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        Division
                    </div>
                    <div className={styles.dropdown}>
                        <select className={styles.fieldValueDropdown} onBlur={handleStateUpdate('divisionId')}>
                            {map(worksiteList, (worksite) => {
                                const { id = "", address = "" } = worksite || {};
                                return (
                                    <option className={styles.fieldValueDropdown} value={id}>{address}</option>
                                )
                            })}
                        </select>
                        <img className={styles.dropdownArrow} src={require("../../../../../assets/images/dropdown_arrow.svg").default}></img>
                    </div>
                </div>


                <div className={styles.continue} onClick={handleFormSubmitAction}>
                    Send Invite
                </div>
            </div>
        </div >

    )
}

export default AddNewMemberForm;