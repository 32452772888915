import React from "react";

import styles from "./rowIndicator.module.css";

const RowIndicator = (props) =>{

    const {timing = {} } = props;
    const {value ="", numberOfMeals = 0} = timing
    
    return (
        <div className={styles.container}>
            <div className={styles.primaryText}>
                {value}
            </div>
            <div className={styles.secondaryText}>
                Logged {numberOfMeals} out of 7 meals
            </div>
        </div>
    )
}

export default RowIndicator;