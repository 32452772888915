
const getInitAction = ({state,setState})=>{
    const currentPage = 0;
    setState({...state,currentPage});
}

const setPointerToNextPage = ({state, setState})=>{
    const {currentPage = 0} = state;

    setState({...state,currentPage: currentPage+1});
}

const setUpdatedValues = ({state,setState,payload})=>{
    const {formValues = {}, handleFieldChange} = state;
    setState((prev)=>({...prev, formValues:{...formValues,...payload}}));
    handleFieldChange({formValues:{...formValues,...payload}});
}

const setPageSubmit = ({state,setState, payload})=>{
    const {formValues = {},currentPage = 0} = state;
    setState({...state, formValues:{...formValues,...payload},currentPage: currentPage+1})
}

const getFormSubmitAction = ({state,setState,payload,navigate}) =>{
    const {formValues = {}, formSubmitHandler} = state;
    const requestPayload = {...formValues,...payload};
    formSubmitHandler()
    // navigate("/dashBoard");
}

const setPageStateToPrevious = ({state,setState})=>{
    setState((prev)=>{
        return {...prev,currentPage: (prev?.currentPage || 2) -1}
    })
}

export default {
    INIT_ACTION : getInitAction,
    MOVE_TO_NEXT_PAGE: setPointerToNextPage,
    PAGE_SUBMIT_ACTION: setPageSubmit,
    FORM_SUBMIT_ACTION: getFormSubmitAction,
    FORM_VALUES_UPDATE: setUpdatedValues,
    GO_TO_PREVIOUS_PAGE:setPageStateToPrevious
}