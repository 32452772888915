import React from "react";

import styles from "./dropdownOption.module.css";

const DropdownOption = ({label,onClick,numericalHint}) =>{
    return (
        <div className={styles.container} onClick={onClick}>
            <div className={styles.primaryText}>
                {label}
            </div>
            <div className={styles.secondaryText}>
                {numericalHint}
            </div>
        </div>
    )
}

export default DropdownOption