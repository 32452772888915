const getHeaderOptions = ({handlePageUpdateAction, logOutAction, logOutClass, settingsAction, settingsClass }) => [{
    label: "Settings",
    name: 'settings',
    action: handlePageUpdateAction(settingsAction),
    className: settingsClass
},
{
    label: "Log Out",
    name: 'logOut',
    action: handlePageUpdateAction(logOutAction),
    className: logOutClass
}];

export {
    getHeaderOptions
}