import React from "react";

import styles from "./threeRowDataDisplay.module.css";

const ThreeRowDataDisplay = ({primaryClassname="",secondaryClassname="",tertiaryClassname="",primaryContent="",secondaryContent="", tertiaryContent=""}) =>{
    return (
        <div className={styles.container}>
            <div className={primaryClassname}>
                {primaryContent}
            </div>
            <div className={secondaryClassname}>
                { !secondaryContent ?
                    <div className={styles.noDataContainer}>No Data</div> : secondaryContent
                }
            </div>
            <div className={tertiaryClassname}>
                {tertiaryContent}
            </div>
        </div>
    )
}

export default ThreeRowDataDisplay;