import React, { useCallback, useState } from "react";
import cx from "classnames";

import Header from "./containers/Header/Header";
import Body from "./containers/Body"

import styles from "./dashBoardConfigurator.module.css";
import ToggableSideBar from "../../molecules/toggableSideBar/ToggableSideBar";
import PracticeAccountSetup from "../practiceAccountSetup";

const DashBoardConfigurator = () =>{

    const [displayIndividualInfo,setDisplayIndividualInfo] = useState(false);

    const [displayModal,setModalDisplay] = useState();

    const handleModalDisplay = () =>{
        setModalDisplay(!displayModal);
    }

    const handleDisplayIndividualInfo = useCallback(()=>{
        setDisplayIndividualInfo(!displayIndividualInfo)
    },[setDisplayIndividualInfo,displayIndividualInfo]);
    
    return (
        <div className={styles.container}>
            {displayModal && <PracticeAccountSetup />}
            {!displayModal && <>
                <Header />
                <Body handleDisplayIndividualInfo={handleDisplayIndividualInfo} handleModalDisplay={handleModalDisplay}/>
                {displayIndividualInfo && <ToggableSideBar handleDisplayIndividualInfo={handleDisplayIndividualInfo} />}
            </>
            }
        </div>
    )
}

export default DashBoardConfigurator;