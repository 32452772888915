import React from "react";
import Header from "../Header";
import "../../css/action-plan.css";
import profile_image from "../../assets/images/profile_image.png"
import pathway_card_image from "../../assets/images/recommended-pathways-card-image.png"
import Sidebar from "../Sidebar";
export default function () {
  return (
    <>
      <div className="parent-view">

        <div className="main-page-view">
          <div className="action-plan-heading">
            <img
              className="action-plan-heading-profile-image"
              src={profile_image}
                  />
            <p className="action-plan-heading-name">
              Liz Samsonite's Action Plans{" "}
            </p>
            <p className="action-plan-heading-create-plan">
              / Create Action Plan
            </p>
          </div>

          <div className="create-action-plan">
            <p className="create-action-plan-text">Create Action Plan</p>
            <p className="create-action-plan-next">Next</p>
          </div>

          <div className="create-action-plan-step-one">
            <p className="create-action-plan-step-one-text">
              Step 1 - Target Primary Core Problem & Contributing Triggers
            </p>
            <div className="create-action-plan-step-one-text-div">
              <p className="create-action-plan-step-one-text-A">A. </p>
              <p className="create-action-plan-step-one-text-A-heading">
                Select a Core Problem Trend that this patient is facing
              </p>
            </div>
            <div className="recommended-div">
              <p className="recomended-text">Recommended</p>
              <img className="drop-image" src={
                      require("../../assets/images/drop.svg")
                        .default
                    }
                  />
              <img
                className="drop-image-one"
                src={
                      require("../../assets/images/cuttlery.svg")
                        .default
                    }
                  />
              <img
                className="drop-image-one"
                src={
                      require("../../assets/images/syringe.svg")
                        .default
                    }
                  />
              <img
                className="drop-image-one"
                src={
                      require("../../assets/images/dumbell.svg")
                        .default
                    }
                  />
              <img className="drop-image-one" src={
                      require("../../assets/images/sleep.svg")
                        .default
                    }
                  />
              <img className="drop-image-one" src={
                      require("../../assets/images/drop.svg")
                        .default
                    }
                  />
              <img
                className="drop-image-one"
                src={
                      require("../../assets/images/bloodpressure.svg")
                        .default
                    }
                  />
              <img
                className="drop-image-one"
                src={
                      require("../../assets/images/hydration.svg")
                        .default
                    }
                  />
              <img
                className="drop-image-one"
                src={
                      require("../../assets/images/mobility.svg")
                        .default
                    }
                  />
            </div>
            <div className="recommended-primary-care-problems">
              <p className="recommended-primary-care-problems-text">
                Recommended Primary Core Problems Targets
              </p>
              <p className="recommended-primary-care-problems-subtext">
                {" "}
                These are 5 recommendations based on ADA & other standardized
                guidelines.
              </p>
            </div>
            <div className="recomended-cards">
              <div className="card-element">
                <img className="card-image" src={
                      require("../../assets/images/drop.svg")
                        .default
                    }
                  />
                <div className="card-elements">
                  <p className="card-text-head">High Fasting Blood Glucose</p>
                  <p className="card-text-desc">Trend Age: 7 days</p>
                  <div className="card-yellow-element">
                    <img
                      className="yellow-dot"
                      src={
                      require("../../assets/images/yellow-dot.svg")
                        .default
                    }
                  />
                    <p className="yellow-dot-text">View Trend</p>
                  </div>
                </div>
              </div>

              <div className="card-element">
                <img className="card-image" src={
                      require("../../assets/images/drop.svg")
                        .default
                    }
                  />
                <div className="card-elements">
                  <p className="card-text-head">High Fasting Blood Glucose</p>
                  <p className="card-text-desc">Trend Age: 7 days</p>
                  <div className="card-yellow-element">
                    <img
                      className="yellow-dot"
                      src={
                      require("../../assets/images/yellow-dot.svg")
                        .default
                    }
                  />
                    <p className="yellow-dot-text">View Trend</p>
                  </div>
                </div>
              </div>

              <div className="card-element">
                <img className="card-image" src={
                      require("../../assets/images/drop.svg")
                        .default
                    }
                  />
                <div className="card-elements">
                  <p className="card-text-head">High Fasting Blood Glucose</p>
                  <p className="card-text-desc">Trend Age: 7 days</p>
                  <div className="card-yellow-element">
                    <img
                      className="yellow-dot"
                      src={
                      require("../../assets/images/yellow-dot.svg")
                        .default
                    }
                  />
                    <p className="yellow-dot-text">View Trend</p>
                  </div>
                </div>
              </div>

              <div className="card-element">
                <img className="card-image" src={
                      require("../../assets/images/drop.svg")
                        .default
                    }
                  />
                <div className="card-elements">
                  <p className="card-text-head">High Fasting Blood Glucose</p>
                  <p className="card-text-desc">Trend Age: 7 days</p>
                  <div className="card-yellow-element">
                    <img
                      className="yellow-dot"
                      src={
                      require("../../assets/images/yellow-dot.svg")
                        .default
                    }
                  />
                    <p className="yellow-dot-text">View Trend</p>
                  </div>
                </div>
              </div>

              <div className="card-element">
                <img className="card-image" src={
                      require("../../assets/images/drop.svg")
                        .default
                    }
                  />
                <div className="card-elements">
                  <p className="card-text-head">High Fasting Blood Glucose</p>
                  <p className="card-text-desc">Trend Age: 7 days</p>
                  <div className="card-yellow-element">
                    <img
                      className="yellow-dot"
                      src={
                      require("../../assets/images/yellow-dot.svg")
                        .default
                    }
                  />
                    <p className="yellow-dot-text">View Trend</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="create-action-plan-step-one-text-div">
              <p className="create-action-plan-step-one-text-A">B.</p>
              <p className="create-action-plan-step-one-text-A-heading">
                Select up to 5 Linked Contributing Triggers to improve
              </p>
            </div>

            <div className="recommended-div">
              <p className="recomended-text">Recommended</p>
              <img className="drop-image" src={
                      require("../../assets/images/drop.svg")
                        .default
                    }
                  />
              <img
                className="drop-image-one"
                src={
                      require("../../assets/images/cuttlery.svg")
                        .default
                    }
                  />
              <img
                className="drop-image-one"
                src={
                      require("../../assets/images/syringe.svg")
                        .default
                    }
                  />
              <img
                className="drop-image-one"
                src={
                      require("../../assets/images/dumbell.svg")
                        .default
                    }
                  />
              <img className="drop-image-one" src={
                      require("../../assets/images/sleep.svg")
                        .default
                    }
                  />
              <img className="drop-image-one" src={
                      require("../../assets/images/drop.svg")
                        .default
                    }
                  />
              <img
                className="drop-image-one"
                src={
                      require("../../assets/images/bloodpressure.svg")
                        .default
                    }
                  />
              <img
                className="drop-image-one"
                src={
                      require("../../assets/images/hydration.svg")
                        .default
                    }
                  />
              <img
                className="drop-image-one"
                src={
                      require("../../assets/images/mobility.svg")
                        .default
                    }
                  />
            </div>

            <div className="select-primary-problem">
              <p className="select-primary-problem-text">
                Select a Primary Core Problem Target First 👆👆👆
              </p>
            </div>
          </div>

          <div className="create-action-plan-step-one-second">
            <p className="create-action-plan-step-one-text">
              Step 2 of 3 - Select One Th1ng Prescribed Pathway
            </p>
            <div className="create-action-plan-step-one-text-div">
              <p className="create-action-plan-step-one-text-A">A.</p>
              <p className="create-action-plan-step-one-text-A-heading">
                Select and Review which One Th1ng Pathway for your patient
              </p>
            </div>
            <div className="recommended-buttons">
              <p className="recomended-text-pathways">Recommended Pathways</p>
              <p className="create-your-path-text">Create Your Own Pathway</p>
            </div>

            <div className="recommended-pathways-cards">
              <div className="recommended-pathways-cards-item">
                <img
                  className="recommended-pathways-cards-image"
                  src={
                    pathway_card_image
                    }
                  />
                <p className="recommended-pathways-card-heading">
                  Balance Your Plate
                </p>
                <p className="recommended-pathways-card-sub-heading">
                  Learn what to eat and how much
                </p>
                <div className="recommended-pathways-cards-duration">
                  <p className="recommended-pathways-cards-duration-text">
                    Duration:
                  </p>
                  <p className="recommended-pathways-cards-duration-text-value">
                    4 weeks
                  </p>
                </div>
                <div className="recommended-pathways-cards-duration">
                  <p className="recommended-pathways-cards-duration-text">
                    Dependencies:
                  </p>
                  <p className="recommended-pathways-cards-duration-text-value">
                    Renewed Plate Series
                  </p>
                </div>
                <div className="recommended-pathways-cards-duration">
                  <p className="recommended-pathways-cards-duration-text">
                    Ideal for:
                  </p>
                  <p className="recommended-pathways-cards-duration-text-value">
                    High Post Prandial Meal Spikes
                  </p>
                </div>

                <div className="recommended-pathways-cards-bottomtext-buttons">
                  <p className="preview">Preview</p>
                  <p className="select-this-pathway">Deselect pathway</p>
                </div>
              </div>

              <div className="recommended-pathways-cards-item-second">
                <img
                  className="recommended-pathways-cards-image"
                  src={
                    pathway_card_image
                    }
                  />
                <p className="recommended-pathways-card-heading">
                  Balance Your Plate
                </p>
                <p className="recommended-pathways-card-sub-heading">
                  Learn what to eat and how much
                </p>
                <div className="recommended-pathways-cards-duration">
                  <p className="recommended-pathways-cards-duration-text">
                    Duration:
                  </p>
                  <p className="recommended-pathways-cards-duration-text-value">
                    4 weeks
                  </p>
                </div>
                <div className="recommended-pathways-cards-duration">
                  <p className="recommended-pathways-cards-duration-text">
                    Dependencies:
                  </p>
                  <p className="recommended-pathways-cards-duration-text-value">
                    Renewed Plate Series
                  </p>
                </div>
                <div className="recommended-pathways-cards-duration">
                  <p className="recommended-pathways-cards-duration-text">
                    Ideal for:
                  </p>
                  <p className="recommended-pathways-cards-duration-text-value">
                    High Post Prandial Meal Spikes
                  </p>
                </div>

                <div className="recommended-pathways-cards-bottomtext-buttons">
                  <p className="preview">Preview</p>
                  <p className="select-this-pathway">Select this pathway</p>
                </div>
              </div>

              <div className="recommended-pathways-cards-item-second ">
                <img
                  className="recommended-pathways-cards-image"
                  src={
                    pathway_card_image
                    }
                  />
                <p className="recommended-pathways-card-heading">
                  Balance Your Plate
                </p>
                <p className="recommended-pathways-card-sub-heading">
                  Learn what to eat and how much
                </p>
                <div className="recommended-pathways-cards-duration">
                  <p className="recommended-pathways-cards-duration-text">
                    Duration:
                  </p>
                  <p className="recommended-pathways-cards-duration-text-value">
                    4 weeks
                  </p>
                </div>
                <div className="recommended-pathways-cards-duration">
                  <p className="recommended-pathways-cards-duration-text">
                    Dependencies:
                  </p>
                  <p className="recommended-pathways-cards-duration-text-value">
                    Renewed Plate Series
                  </p>
                </div>
                <div className="recommended-pathways-cards-duration">
                  <p className="recommended-pathways-cards-duration-text">
                    Ideal for:
                  </p>
                  <p className="recommended-pathways-cards-duration-text-value">
                    High Post Prandial Meal Spikes
                  </p>
                </div>

                <div className="recommended-pathways-cards-bottomtext-buttons">
                  <p className="preview">Preview</p>
                  <p className="select-this-pathway">Select this pathway</p>
                </div>
              </div>

              <div className="recommended-pathways-cards-item-second ">
                <img
                  className="recommended-pathways-cards-image"
                  src={
                    pathway_card_image
                    }
                  />
                <p className="recommended-pathways-card-heading">
                  Balance Your Plate
                </p>
                <p className="recommended-pathways-card-sub-heading">
                  Learn what to eat and how much
                </p>
                <div className="recommended-pathways-cards-duration">
                  <p className="recommended-pathways-cards-duration-text">
                    Duration:
                  </p>
                  <p className="recommended-pathways-cards-duration-text-value">
                    4 weeks
                  </p>
                </div>
                <div className="recommended-pathways-cards-duration">
                  <p className="recommended-pathways-cards-duration-text">
                    Dependencies:
                  </p>
                  <p className="recommended-pathways-cards-duration-text-value">
                    Renewed Plate Series
                  </p>
                </div>
                <div className="recommended-pathways-cards-duration">
                  <p className="recommended-pathways-cards-duration-text">
                    Ideal for:
                  </p>
                  <p className="recommended-pathways-cards-duration-text-value">
                    High Post Prandial Meal Spikes
                  </p>
                </div>

                <div className="recommended-pathways-cards-bottomtext-buttons">
                  <p className="preview">Preview</p>
                  <p className="select-this-pathway">Select this pathway</p>
                </div>
              </div>
            </div>

            <div className="create-action-plan-step-one-text-div">
              <p className="create-action-plan-step-one-text-A">B.</p>
              <p className="create-action-plan-step-one-text-A-heading">
                Review Pathway Setup and Customize as needed (optional)
              </p>
            </div>
          </div>

          <div className="create-action-plan-step-one-third">
            <p className="create-action-plan-step-one-text">
              Step 3 of 3 - Review & Publish the One Th1ng Prescribed Pathway to
              your Patient’s Action Plan
            </p>
            <div className="create-action-plan-step-one-text-div">
              <p className="create-action-plan-step-one-text-A">A.</p>
              <p className="create-action-plan-step-one-text-A-heading">
                Select and Review which One Th1ng Pathway, Core Problem &
                Targeted Triggers for your patient
              </p>
            </div>

            <div className="one-thing-pathway">
              <p className="one-thing-pathway-text">One Th1ng Pathway</p>
              <p className="one-thing-pathway-text-edit">Edit</p>
            </div>

            <div className="recommended-pathways-cards">
              <div className="recommended-pathways-cards-item">
                <img
                  className="recommended-pathways-cards-image"
                  src={
                    pathway_card_image
                    }
                  />
                <p className="recommended-pathways-card-heading">
                  Balance Your Plate
                </p>
                <p className="recommended-pathways-card-sub-heading">
                  Learn what to eat and how much
                </p>
                <div className="recommended-pathways-cards-duration">
                  <p className="recommended-pathways-cards-duration-text">
                    Duration:
                  </p>
                  <p className="recommended-pathways-cards-duration-text-value">
                    4 weeks
                  </p>
                </div>
                <div className="recommended-pathways-cards-duration">
                  <p className="recommended-pathways-cards-duration-text">
                    Dependencies:
                  </p>
                  <p className="recommended-pathways-cards-duration-text-value">
                    Renewed Plate Series
                  </p>
                </div>
                <div className="recommended-pathways-cards-duration">
                  <p className="recommended-pathways-cards-duration-text">
                    Ideal for:
                  </p>
                  <p className="recommended-pathways-cards-duration-text-value">
                    High Post Prandial Meal Spikes
                  </p>
                </div>
              </div>
            </div>

            <div className="one-thing-pathway">
              <p className="one-thing-pathway-text">
                Primary Core Problems Target
              </p>
            </div>

            <div className="recomended-cards">
              <div className="card-element">
                <img className="card-image" src={
                      require("../../assets/images/drop.svg")
                        .default
                    }
                  />
                <div className="card-elements">
                  <p className="card-text-head">High Fasting Blood Glucose</p>
                  <p className="card-text-desc">Trend Age: 7 days</p>
                  <div className="card-yellow-element">
                    <img
                      className="yellow-dot"
                      src={
                      require("../../assets/images/yellow-dot.svg")
                        .default
                    }
                  />
                    <p className="yellow-dot-text">View Trend</p>
                  </div>
                </div>
              </div>

              <div className="card-element">
                <img className="card-image" src={
                      require("../../assets/images/drop.svg")
                        .default
                    }
                  />
                <div className="card-elements">
                  <p className="card-text-head">High Fasting Blood Glucose</p>
                  <p className="card-text-desc">Trend Age: 7 days</p>
                  <div className="card-yellow-element">
                    <img
                      className="yellow-dot"
                      src={
                      require("../../assets/images/yellow-dot.svg")
                        .default
                    }
                  />
                    <p className="yellow-dot-text">View Trend</p>
                  </div>
                </div>
              </div>

              <div className="card-element">
                <img className="card-image" src={
                      require("../../assets/images/drop.svg")
                        .default
                    }
                  />
                <div className="card-elements">
                  <p className="card-text-head">High Fasting Blood Glucose</p>
                  <p className="card-text-desc">Trend Age: 7 days</p>
                  <div className="card-yellow-element">
                    <img
                      className="yellow-dot"
                      src={
                      require("../../assets/images/yellow-dot.svg")
                        .default
                    }
                  />
                    <p className="yellow-dot-text">View Trend</p>
                  </div>
                </div>
              </div>

              <div className="card-element">
                <img className="card-image" src={
                      require("../../assets/images/drop.svg")
                        .default
                    }
                  />
                <div className="card-elements">
                  <p className="card-text-head">High Fasting Blood Glucose</p>
                  <p className="card-text-desc">Trend Age: 7 days</p>
                  <div className="card-yellow-element">
                    <img
                      className="yellow-dot"
                      src={
                      require("../../assets/images/yellow-dot.svg")
                        .default
                    }
                  />
                    <p className="yellow-dot-text">View Trend</p>
                  </div>
                </div>
              </div>

              <div className="card-element">
                <img className="card-image" src={
                      require("../../assets/images/drop.svg")
                        .default
                    }
                  />
                <div className="card-elements">
                  <p className="card-text-head">High Fasting Blood Glucose</p>
                  <p className="card-text-desc">Trend Age: 7 days</p>
                  <div className="card-yellow-element">
                    <img
                      className="yellow-dot"
                      src={
                      require("../../assets/images/yellow-dot.svg")
                        .default
                    }
                  />
                    <p className="yellow-dot-text">View Trend</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="one-thing-pathway">
              <p className="one-thing-pathway-text">Targeted Triggers</p>
            </div>

            <div className="recomended-cards">
              <div className="card-element">
                <img className="card-image" src={
                      require("../../assets/images/drop.svg")
                        .default
                    }
                  />
                <div className="card-elements">
                  <p className="card-text-head">High Fasting Blood Glucose</p>
                  <p className="card-text-desc">Trend Age: 7 days</p>
                  <div className="card-yellow-element">
                    <img
                      className="yellow-dot"
                      src={
                      require("../../assets/images/yellow-dot.svg")
                        .default
                    }
                  />
                    <p className="yellow-dot-text">View Trend</p>
                  </div>
                </div>
              </div>

              <div className="card-element">
                <img className="card-image" src={
                      require("../../assets/images/drop.svg")
                        .default
                    }
                  />
                <div className="card-elements">
                  <p className="card-text-head">High Fasting Blood Glucose</p>
                  <p className="card-text-desc">Trend Age: 7 days</p>
                  <div className="card-yellow-element">
                    <img
                      className="yellow-dot"
                      src={
                      require("../../assets/images/yellow-dot.svg")
                        .default
                    }
                  />
                    <p className="yellow-dot-text">View Trend</p>
                  </div>
                </div>
              </div>

              <div className="card-element">
                <img className="card-image" src={
                      require("../../assets/images/drop.svg")
                        .default
                    }
                  />
                <div className="card-elements">
                  <p className="card-text-head">High Fasting Blood Glucose</p>
                  <p className="card-text-desc">Trend Age: 7 days</p>
                  <div className="card-yellow-element">
                    <img
                      className="yellow-dot"
                      src={
                      require("../../assets/images/yellow-dot.svg")
                        .default
                    }
                  />
                    <p className="yellow-dot-text">View Trend</p>
                  </div>
                </div>
              </div>

              <div className="card-element">
                <img className="card-image" src={
                      require("../../assets/images/drop.svg")
                        .default
                    }
                  />
                <div className="card-elements">
                  <p className="card-text-head">High Fasting Blood Glucose</p>
                  <p className="card-text-desc">Trend Age: 7 days</p>
                  <div className="card-yellow-element">
                    <img
                      className="yellow-dot"
                      src={
                      require("../../assets/images/yellow-dot.svg")
                        .default
                    }
                  />
                    <p className="yellow-dot-text">View Trend</p>
                  </div>
                </div>
              </div>

              <div className="card-element">
                <img className="card-image" src={
                      require("../../assets/images/drop.svg")
                        .default
                    }
                  />
                <div className="card-elements">
                  <p className="card-text-head">High Fasting Blood Glucose</p>
                  <p className="card-text-desc">Trend Age: 7 days</p>
                  <div className="card-yellow-element">
                    <img
                      className="yellow-dot"
                      src={
                      require("../../assets/images/yellow-dot.svg")
                        .default
                    }
                  />
                    <p className="yellow-dot-text">View Trend</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="create-action-plan-step-one-text-div">
              <p className="create-action-plan-step-one-text-A">B.</p>
              <p className="create-action-plan-step-one-text-A-heading">
                Leave any notes that are relevant to your decision in this
                pathway (optional)
              </p>
            </div>

            <p className="action-plan-notes-text">Action Plan Notes</p>
            <p className="action-plan-edit-box">
              Enter any additional notes needed for this action plan here...
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
