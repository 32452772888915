import React from 'react'

const withCities = (Component) =>(props)=>{
    const { getCities =()=>{} ,options =[], formState ={}} = props;

    const updatedOptions = formState?.state ?  getCities(formState?.state?.[0]) : options;

    return <Component {...props} options={updatedOptions}/>
}

export default withCities;