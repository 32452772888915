
import inviteImage from "./../../../assets/images/iniviteImage.svg";
import inviteAccept from "./../../../assets/images/inviteAccept.svg";
import inviteReject from "./../../../assets/images/inviteRejected.svg";

import AcceptanceBody from "./components/Body/components/AcceptanceBody";
import CardBody from "./components/Body/components/Body";
import RejectedBody from "./components/Body/components/RejectedBody";
import { ACTIVE_CARD_STATE } from "./patientInviteConnect.constants";

const getCardBodyComponent = (cardState) =>{
    switch(cardState)
    {
        case ACTIVE_CARD_STATE.COMPLETE_STATE:
            return {
                Component : AcceptanceBody,
                headerProps :{
                    logo : inviteAccept
                }
            }
            break;
        case ACTIVE_CARD_STATE.REJECTED_STATE:
            return {
                Component : RejectedBody,
                headerProps :{
                    logo : inviteReject
                }
            }
            break;
        default :
            return {
                Component : CardBody,
                headerProps :{
                    logo : inviteImage
                }
            }
            break;
    }
}

export {
    getCardBodyComponent,
}