import moment from "moment-timezone";

const TIME_ZONE_MAPPING = {
    [(moment().tz("America/Denver")).utcOffset()] : "Denver (GMT-6)",
    [(moment().tz("America/Chicago")).utcOffset()] : "Chicago (GMT-5)",
    [(moment().tz("America/Phoenix")).utcOffset()] : "Phoenix (GMT-7)",
    [(moment().tz("America/Los_Angeles")).utcOffset()] : "Los Angeles (GMT-7)",
    [(moment().tz("America/Anchorage")).utcOffset()] : "Anchorage (GMT-8)",
    [(moment().tz("Pacific/Honolulu")).utcOffset()] : "Honolulu (GMT-10)",
    [(moment().tz("Asia/Calcutta")).utcOffset()] : "Kolkata (India)"
}

export {
    TIME_ZONE_MAPPING
}