import React from "react";
import cx from "classnames";

import styles from "./listContent.module.css";

const ListContent = ({action,className,label,name})=>{
    
    const handleItemClick = ()=>{
        action(name);
    }

    return (
        <div className={cx(styles.container,className)} onClick={handleItemClick}>
            {label}
        </div>
    )
}

export default ListContent;