const MAPPED_TYPES = {
    calories : "Calories",
    carbohydrates: "Carbs",
    proteins : "Protein",
    fats: "Fat",
    fiber: "Fiber",
    sodium: "Sodium"
}

const MAPPED_TYPES_SUFFIX ={
    calories : "Kcal",
    carbohydrates: "g",
    proteins : "g",
    fats: "g",
    fiber: "g",
    sodium: "mg"
}

const NUTRIENT_ORDER = [
  'calories',
  'carbohydrates',
  'proteins',
  'fats',
  'fiber',
  'sodium'
];

const SPIKE_ANALYSIS_METRICS = {
    "ONE_HOUR_POST_PRANDIAL" : '1 Hr PP',
    "TWO_HOUR_POST_PRANDIAL" : '2 Hr PP',
    'POST_PRANDIAL_HIGH_RATE' : 'Rise Rate',
    'IMPACT' : 'Impact'
}

export {
    MAPPED_TYPES,
    MAPPED_TYPES_SUFFIX,
    NUTRIENT_ORDER,
    SPIKE_ANALYSIS_METRICS
}