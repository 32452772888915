import { BASE_URL } from "../../../../constants/api";

const getMoveToArchieveAction = async()=>{
    try{

        const url = new URL(window.location.href)
        const pathname = url.pathname.split("/");

        await fetch(`${BASE_URL}/dashboard/admin/api/rrxsuperboss/users/archiveMember/${pathname?.[4]}`,{
            method : "GET",
            headers:{
                'content-type':'application/json',
                "authorization" : "Bearer " + window?.localStorage?.getItem("Authorization"),
            }
        })

    }
    catch(e){
        console.log('ERROR IS : ',e);
    }
}

export{
    getMoveToArchieveAction
}