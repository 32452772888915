
const getTimeInRange = ({details})=>{
    let count =0;
    let mainValue =0;
    for(let val in details)
    {
        if(val == "ONE_HOUR_POST_PRANDIAL_BREAKFAST_TIME_IN_RANGE_BETWEEN_63_TO_140" || val=="ONE_HOUR_POST_PRANDIAL_DINNER_TIME_IN_RANGE_BETWEEN_63_TO_140" || val == "ONE_HOUR_POST_PRANDIAL_LUNCH_TIME_IN_RANGE_BETWEEN_63_TO_140" )
        {
            mainValue=details[val]?.glucose || 0;
        }
        count+=details[val]?.glucose || 0;
    }
    return Math.floor((mainValue/count)*100)
}

export {
    getTimeInRange,
}