import React from "react";

import WeeklyFilter from "../../../../../WeeklyFilter";
import { getWeeklyFormattedWeeklyDates } from "../../../../../../../../../../helpers/dateFormatter";
import TrendAnalysisChart from "../../../../../../../../../../charts/patientProfile/progressMonitoring/balancePlate/trendAnalysis/TrendAnalysis";

import styles from "./trendAnalysis.module.css";

const TrendAnalysis = ({
    micronutrientsInsightsResponseMap=[],
    name,
    weeklyFilter={}
    }) =>{

    const {trendAnalysis=0} = weeklyFilter;
    
    return (
        <div className={styles.container}>
            <div className={styles.chartContainer}>
                <TrendAnalysisChart prev={trendAnalysis} nutritionalInsightsDaily={micronutrientsInsightsResponseMap} name={name}/>
            </div>
        </div>
    )
}

export default TrendAnalysis;