import React from "react";
import cx from "classnames";

import { SUCCESS_MODAL } from "../../practiceAccountSetup.constants";

import styles from "./groupSetupSuccess.module.css";

const GroupSetupSuccess = ()=>{
    
    return (
        <div className={styles.container}>
            <div className={styles.upperContainer}>
                <img className={styles.successIcon} src="https://s3-alpha-sig.figma.com/img/19d1/2ddf/9a16e27d0beb9127f809bcee839690e6?Expires=1711324800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=YPCwFtIeHxSA9ZS1aaCFOgsPeoUqjSArjxainTW9zJTkWMxeTEr9fT9hQw789SWJxEjJtk-hun5rkAlPbzMhr2f0bVZ6X4RIDNNueKpImGlzoy-MnyZpCxELmj0PZGZVWmf0KEL~SZBWFTlsIHRb4enCXYkXXudnw9-c5RU2HDQau46VBC9~g4WysmebwWsa6Btvt9Tg7PmDxuP9H0-Mqjm4H0elgBdJ4lNVIauSc6-lzklJmFtJQwlLRIqaoPQupdRqudbGs4IxU3S2nJ0EnBV1SZ4WLmlGwzohAad5smO493cFrXrA7RcVWPuWiU1-9bcitoXFjhHjqkzdoaRwSQ__" />
                <div className={styles.successMessage}>
                    {SUCCESS_MODAL.SUCCESS_MESSAGE}
                </div>
                <div className={styles.upperContainerLowerText}>
                    {SUCCESS_MODAL.TEAM_INVITE}
                </div>
            </div>
            <div className={styles.lowerContainer}>
                <button className={cx(styles.createButton,styles.enabledClassname)}>
                    {SUCCESS_MODAL.BUTTON_TEXT}
                </button>
                <div className={styles.individualPractionerButton}>
                    {SUCCESS_MODAL.SKIP_TEXT}
                </div>
            </div>
        </div>
    )
}

export default GroupSetupSuccess;