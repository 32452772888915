import React, { useState } from "react";
import IndicatorBar from "../../atoms/indicatorBar/IndicatorBar";

import GlucoseLevelDisplayModalContainer from "../../organisms/glucoseLevelDisplayModal/GlucoseLevelDisplayModal.container";

import styles from "./logChartTab.module.css";

import "./logChartTab.module.css"

const textTheme = {
    GREEN_IN_TARGET: styles.greenText,
    RED_IN_TARGET: styles.redText,
    YELLOW_IN_TARGET: styles.yellowText,
    RED: styles.redText,
    GREEN: styles.greenText,
    YELLOW: styles.yellowText
}

const LogChartTab = ({viewMore=false, id,calenderUpdate, timeInRange={}}) =>{
    const [modalDisplay, setModalDisplay] = useState(false);

    const [viewMoreDisplay,setViewMoreDisplay] = useState(false);

    const handleModalDisplayToggle = ()=>{
        setModalDisplay(!modalDisplay);
    }

    const handleViewMoreOpen = () =>{
        setViewMoreDisplay(true);
    }

    const handleViewMoreClose = () =>{
        setViewMoreDisplay(false);
    }

    const {glucose = "-" } = timeInRange["OVERALL_TIME_IN_RANGE_BETWEEN_63_TO_140"] || {};

    const glucoseValue = timeInRange["OVERALL_TIME_IN_RANGE_BETWEEN_63_TO_140"] || {}

    const glucoseColor = glucoseValue["glucose-color"] || "RED";

    return (
        <div className="log-chart-row-single-tab">
            <div onMou className={styles["bar-zero-zero"]} id="bar-zero-zero">
                <IndicatorBar viewMoreDisplay={viewMoreDisplay} type={glucoseColor} onClick={handleModalDisplayToggle} onMouseLeave={handleViewMoreClose} onMouseEnter={handleViewMoreOpen}/>
            </div>
            { modalDisplay && <GlucoseLevelDisplayModalContainer timeInRange={{glucose,glucoseColor}} toggleModal={handleModalDisplayToggle} id={id} calenderUpdate={calenderUpdate} />}
            <div className="bar-percentage-div">
                <div className="bar-percentage-span">{glucose} %</div>
            </div>
      </div>
    )
}

export default LogChartTab;