const BALANCE_PLATE_MICRO_NUTRIENTS = {
    CALORIES : "CALORIES",
    SODIUM : "SODIUM",
    CARBOHYDRATES : "CARBOHYDRATES",
    FATS : "FATS",
    FIBER : "FIBER",
    PROTEINS : "PROTEINS"
}

export {
    BALANCE_PLATE_MICRO_NUTRIENTS
}