
import ManageRrxCoach from "../../../assets/iconComponents/ManageRrxCoach";
import PatientIcon from "../../../assets/iconComponents/PatientIcon";

const getCommonSidebarTabs = () =>{
    return [
    {
        label:"Manage Practice",
        name:"managePractice",
        ImageIcon: PatientIcon,
        link:"/superUser"
    },
    {
        label:"Manage RRX Coach",
        name:"manageRrxCoach",
        ImageIcon: ManageRrxCoach,
        link:"/rrxCoach"
    },
]
} 

export {
    getCommonSidebarTabs,
}