import React from "react";

import linkIcon from "./../../../assets/images/arrow_right_scroll.svg";

import styles from "./tabOption.module.css";

const TabOption = ({name,className,label,value, tabClickAction=()=>{}, CustomComponent}) =>{
    
    const handleTabClickOption = (event)=>{
        event.preventDefault();
        tabClickAction(name)
    }

    return (
        <div className={className} onClick={handleTabClickOption}>
            <div>
                {label}
            </div>
            <div className={styles.optionContainer}>
                {CustomComponent ? <CustomComponent value={value} label={label} /> :<div>{value}</div>}
                <img src={linkIcon} className={styles.icon} />
            </div>
        </div>
    )
}

export default TabOption;