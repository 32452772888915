import React from "react";
import {map} from "lodash";

import styles from "./footer.module.css";

const Footer = ({options=[{
        label : "Terms & Conditions"
    },
    {
        label : "Privacy Policy"
    }
]}) =>{
    return (
        <div className={styles.container}>
            {
                map(options,({label})=>{
                    return (
                        <div className={styles.optionContainer}>
                            {label}
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Footer