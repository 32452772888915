import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../constants/api";

const usePracticeList = (tab,setIsLoading)=>{
    const [practiceList, setPracticeList] = useState();
    const [practiceTab, setPracticeTab] = useState(tab);

    useEffect(()=>{
        getPraticeList();
    },[practiceTab]);

    async function getPraticeList(){
        try{
            setIsLoading(true);
            const practiceListRequest = await fetch(`${BASE_URL}/dashboard/admin/api/rrxsuperboss/users/getPractices/${practiceTab}`,{
                method:"GET",
                headers:{
                    'content-type':'application/json',
                    "authorization" : "Bearer " + window?.localStorage?.getItem("Authorization"),
                }
            })
            const practiceListResponse = await practiceListRequest.json();
            setIsLoading(false);
            setPracticeList(practiceListResponse.practiceDataList);    
        }
        catch(e){
            setIsLoading(false);
            console.log('ERROR IS : ',e);
        }
    }

    const updatePracticeTab = (tab)=>{
        setPracticeTab(tab);
    }

    return [
        practiceList,
        updatePracticeTab
    ]
}

export default usePracticeList;