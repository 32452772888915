import { ERROR_STATUS } from "../../constants/errorStatus"
import ErrorComponent from "../../atoms/errorComponent/ErrorComponent"
import ConsentCheckBox from "./components/consentCheckBox/ConcentCheckBox";

const getTermsAndConditionsField = () =>{
    return {
        name:"concent",
        label: "",
        errorTypes : [{type : ERROR_STATUS.IS_CHECKED}],
        fieldType : "concent",
        autoFocus:true,
        CustomInput : ConsentCheckBox ,
        ErrorComponent : ErrorComponent,
        PlaceHolder: "name@gmail.com",
        onChange:()=>{},
        onFocus:()=>{},
    };
}

export {
    getTermsAndConditionsField,
}