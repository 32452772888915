import {map} from "lodash";
import { BORDER_COLOR_MAPPING, TABLE_COLOR_MAPPING } from "./bloodGlucose.constants";

const getFormattedTableData = (mainData)=>{

    return map(mainData,(data)=>{
        const {glucose = ""} = data;
        return {
            name: "",
            showInLegend: false,
            color: TABLE_COLOR_MAPPING?.[data?.["glucose-color"]],
            borderColor: BORDER_COLOR_MAPPING?.[data?.["glucose-color"]],
            data: [
                glucose
            ]
        }
    })
}

export {
    getFormattedTableData
}