import React from "react";
import cx from "classnames";

import { MANDATORY_FIELDS } from "./form.fields";
import { hasFields } from "./form.helpers";

import styles from "./form.module.css"

const AddNewPracticeFormStepTwo = ({setIsOpen, setStepCounter,updateFormValues, formValues}) => {
    
    const closeForm = () => {
        setStepCounter("ONE");
        setIsOpen(false);
    }

    const buttonActiveStatus = hasFields(MANDATORY_FIELDS[1],formValues);

    const nextStep = () => {
        if(!buttonActiveStatus){
            return;
        }
        setStepCounter("THREE")
    };
    const prevStep = () => setStepCounter("ONE");


    const handleStateUpdate = (type) =>(event)=>{
        // event.preventDefault();
        switch(type){
            case "practiceZipcode":
                if(event?.target?.value?.length>5){
                    return;
                }
                break;
            default:
                break;
        }
        updateFormValues({type,value: event.target.value})
    }

    return (

        <div className={styles.container}>
            <div className={styles.upperContainer}>
                <div className={styles.formName}>Add new practice</div>
                <img className={styles.cancel} src={require("../../../../../assets/images/cancel/transparent_cacel.svg").default} onClick={closeForm}></img>
            </div>
            <div className={styles.bottomContainer}>
                <div className={styles.step}>
                    Step 2/3
                </div>
                <div className={styles.stepBar}>
                    <div className={styles.stepBarOne}></div>
                    <div className={styles.stepBarOne}></div>
                    <div className={styles.stepBarThree}></div>
                </div>
                <div className={styles.practiceDetail}>
                    Address/Worksite Detail
                </div>

                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        Country
                    </div>
                    <div className={styles.dropdown}>
                        <select className={styles.fieldValueDropdown} value={formValues?.["practiceCountry"] || "None"}  onChange={handleStateUpdate('practiceCountry')}>
                            <option className={styles.fieldValueDropdown}>None</option>
                            <option className={styles.fieldValueDropdown}>United States of America</option>
                        </select>
                        <img className={styles.dropdownArrow} src={require("../../../../../assets/images/dropdown_arrow.svg").default}></img>
                    </div>
                </div>

                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        Address 1
                    </div>
                    <input className={styles.fieldValue} value={formValues?.["practiceAddressOne"] || ""} onChange={handleStateUpdate('practiceAddressOne')} placeholder="Enter Practice Street Address"></input>
                </div>

                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        Address 2
                    </div>
                    <input className={styles.fieldValue} value={formValues?.["practiceAddressTwo"] || ""} onChange={handleStateUpdate('practiceAddressTwo')} placeholder="Enter Practice Street Address"></input>
                </div>

                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        City
                    </div>
                    <div className={styles.dropdown}>
                        <select className={styles.fieldValueDropdown} value={formValues?.["practiceCity"] || "None"} onChange={handleStateUpdate('practiceCity')}>
                            <option className={styles.fieldValueDropdown}>None</option>
                            <option className={styles.fieldValueDropdown}>Littleton</option>
                        </select>
                        <img className={styles.dropdownArrow} src={require("../../../../../assets/images/dropdown_arrow.svg").default}></img>
                    </div>
                </div>

                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        State
                    </div>
                    <div className={styles.dropdown}>
                        <select className={styles.fieldValueDropdown} value={formValues?.["practiceState"] || "None"} onChange={handleStateUpdate('practiceState')}>
                            <option className={styles.fieldValueDropdown}>None</option>
                            <option className={styles.fieldValueDropdown}>New York</option>
                        </select>
                        <img className={styles.dropdownArrow} src={require("../../../../../assets/images/dropdown_arrow.svg").default}></img>
                    </div>
                </div>

                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        Zipcode
                    </div>
                    <input type="number" value={formValues?.['practiceZipcode']} onChange={handleStateUpdate('practiceZipcode')} className={styles.fieldValue} placeholder="Enter Practice Zipcode"></input>
                </div>

                <div className={styles.worksiteId}>
                    <img className={styles.worksiteIdInfo} src={require("../../../../../assets/images/alert_message.svg").default}></img>
                    <div className={styles.workisteIdContent}>Worksite ID</div>
                    <div className={styles.worksiteIdValue}>pg-littleton01</div>
                </div>

                <div className={styles.backAndContinue}>
                    <div className={styles.back} onClick={prevStep}>Back</div>
                    <div className={cx(styles.continueSmall,{[styles.nonActiveButton] : !buttonActiveStatus})} onClick={nextStep}>
                        Continue
                    </div>
                </div>

            </div>
        </div>

    )
}

export default AddNewPracticeFormStepTwo;