import React, { useEffect } from "react";
import {map} from "lodash";
import { Spinner } from "react-bootstrap";

import StyledButton from "../../../../../../../molecules/baseComponents/buttons/styledButton";

import styles from "./body.module.css";
import ExclamationTriangle from "../../../../../../../../assets/iconComponents/ExclamationTriangle";

const ICON_COLORS ={
    YELLOW:"#DCAB38",
    RED:"#AE4B37"
}

const Body = ({ modalMetrics : data, handleTabChange, hasButton=true, children})=>{

    if(!data){
        return <div className={styles.mainSpinner}><Spinner /></div>
    }

    return (
        <div className={styles.container}>
            <div className={styles.primaryContainer}>
                {map(data,(option)=>{
                    const {label, value , suffix, color, labelSuffix} = option;
                    return (
                        <div className={styles.dataCard}>
                            <div className={styles.primaryLabelText}>{label}<span className={styles.valueText}>{labelSuffix}</span></div>
                            <div className={styles.primaryLabelText}>
                              {!value && <span className={styles.noText}>NA</span>}
                              { (value && value!=='0' && value !==0) ? <><span className={styles.valueText}> {value} </span>
                                    <span>{suffix}</span>
                                </> : <></>}
                              {color=='YELLOW' && color=='RED' && <ExclamationTriangle color={ICON_COLORS[color]} />}
                            </div>
                        </div>
                    )
                })}
            </div>
            {children}
            {hasButton && 
                <StyledButton
                content={"View Detailed Analysis"}
                className={styles.viewMoreDetailsButton}
                onClick={()=>{handleTabChange({label :"Glucose"})}} 
                />
            }
        </div>
    )
}

export default Body;