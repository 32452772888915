const LOGIN_ENUMS ={
    TOAST : {
        HEADER_TEXT : {
            SUCCESS : "Verification Success",
            FAILED : "Verification Failed",
            ANAUTHORIZED: "Invalid Username/Password",
            WRONG_CODE: "Invalid Code",
            EXPIRED_CODE : "Login Expired"
        },
        BODY_TEXT : {
            SUCCESS : "Email has been verified successfully",
            FAILED : "Email verification has failed",
            ANAUTHORIZED : "Please enter a valid username/password.",
            WRONG_CODE: "Please enter a valid code.",
            EXPIRED_CODE : "Login expired. Please login again.",
            EMAIL_SENT : "Authentication code sent to your registered email id"
        }
    }
}

export {
    LOGIN_ENUMS,
}