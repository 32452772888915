import FormContent from "../../reusables/formManager/components/formContent"

const getCurrentTabProps = (fields) =>{
    return [{
        id:0,
        label:"Create New Practice",
        name : "newPractice",
        Component: FormContent,
        formContent: fields,
    },
    {
        id:1,
        label:"Join Existing Practice",
        name: "existingPractice",
        Component: FormContent,
        formContent: fields,
    }
]
}

export {
    getCurrentTabProps,
}