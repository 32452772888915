
import PatientIcon from "../../../assets/iconComponents/PatientIcon";
import SettingsIcon from "../../../assets/iconComponents/SettingsIcon";

const getCommonSidebarTabs = () =>{
    return [
    {
        label:"Patient",
        name:"invitePatient",
        ImageIcon: PatientIcon,
        link:"/"
    },
    {
        label:"Settings",
        name:"settings",
        ImageIcon: SettingsIcon,
        link:"/settings"
    }
]
} 

export {
    getCommonSidebarTabs,
}