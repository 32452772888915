import React from "react";

import Header from "./components/Header";
import Body from "./components/Body";

import styles from "./dailyMonitoringLog.module.css";

const DailyMonitoringLog = ({tableData,wrapperFields,handleOverDurationChange, overViewDurationTypes }) =>{

    const handleOverAllDurationChange = (payload)=>{
        handleOverDurationChange(payload);
    }

    return (
        <div className={styles.container}>
            <Header/>
            <Body  handleOverAllDurationChange={handleOverAllDurationChange} overViewDurationTypes={overViewDurationTypes} tableData={tableData} wrapperFields={wrapperFields}/>
        </div>
    )
}

export default DailyMonitoringLog;