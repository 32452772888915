import React from "react";
import Popup from "../../../../molecules/Popup/Popup";
import { getTextInitials } from "../../../../helpers/TextManipulations.helpers";

import styles from "./customIcon.module.css";
import PopupContent from "../PopupContent";

const CustomIcon = ({formData}) =>{

    const {practiceName = ""} = formData || {}

    const name = getTextInitials(practiceName)

    return (
        <div className={styles.container}>
            <div className={styles.textIcon}>
                {name}
            </div>
            <Popup formData={formData} PopupContent={PopupContent} className={styles.popupContainer} popupBodyClassname={styles.popupBodyClassname} />
        </div>
    )
}

export default CustomIcon;