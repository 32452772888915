

const SUCCESS_MODAL ={
    SUCCESS_MESSAGE : "Group Practice Setup Successful",
    TEAM_INVITE : "Do you want to invite your teammates?",
    BUTTON_TEXT : "Invite Teammates",
    SKIP_TEXT : "Skip, I’ll do it later",
}

const FORM_TYPES = {
    PRIMARY:"PRIMARY",
    SECONDARY: "SECONDARY",
}

const PRIMARY_FORM_TYPES = {
    NEW_USER : "NEW_USER",
    EXISTING_USER : "EXISTING_USER"
}

export {
    SUCCESS_MODAL,
    FORM_TYPES,
    PRIMARY_FORM_TYPES
}