import React from "react";
import {map} from "lodash";

import styles from "./listDisplay.module.css";

const ListDisplay = ({value: options})=>{
    return (
        <div className={styles.container}>
            {map(options,(option)=>{
                return (
                    <div className={styles.optionContainer}>
                        {option}
                    </div>
                )
            })}
        </div>
    )
}

ListDisplay.defaultProps={
    value : []
}

export default ListDisplay;