import { BALANCE_PLATE_MICRO_NUTRIENTS } from "../../../../../../../../../../../../constants/balancePlate"


const getNutritionalBalance = (rangeByMicroNutrientMap) =>{
    return [{
        label :"Avg Calories /day",
        name : "avgCalories",
        suffix : "Kcal",
        min: rangeByMicroNutrientMap?.[BALANCE_PLATE_MICRO_NUTRIENTS.CALORIES]?.microNutrientColorRangeMap?.GREEN?.startValue,
        max: rangeByMicroNutrientMap?.[BALANCE_PLATE_MICRO_NUTRIENTS.CALORIES]?.microNutrientColorRangeMap?.GREEN?.endValue,
        target:rangeByMicroNutrientMap?.[BALANCE_PLATE_MICRO_NUTRIENTS.CALORIES]?.value,
        maxRange : rangeByMicroNutrientMap?.[BALANCE_PLATE_MICRO_NUTRIENTS.CALORIES]?.value*2,
        baseName : BALANCE_PLATE_MICRO_NUTRIENTS.CALORIES
    },
    {
        label :"Avg Fat /day",
        name : "avgFats",
        suffix : "g",
        min: rangeByMicroNutrientMap?.[BALANCE_PLATE_MICRO_NUTRIENTS.FATS]?.microNutrientColorRangeMap?.GREEN?.startValue,
        max: rangeByMicroNutrientMap?.[BALANCE_PLATE_MICRO_NUTRIENTS.FATS]?.microNutrientColorRangeMap?.GREEN?.endValue,
        target:rangeByMicroNutrientMap?.[BALANCE_PLATE_MICRO_NUTRIENTS.FATS]?.value,
        maxRange : rangeByMicroNutrientMap?.[BALANCE_PLATE_MICRO_NUTRIENTS.FATS]?.value*2,
        baseName : BALANCE_PLATE_MICRO_NUTRIENTS.FATS
    },
    {
        label :"Avg Protein /day",
        name : "avgProteins",
        suffix : "g",
        min: rangeByMicroNutrientMap?.[BALANCE_PLATE_MICRO_NUTRIENTS.PROTEINS]?.microNutrientColorRangeMap?.GREEN?.startValue,
        max: rangeByMicroNutrientMap?.[BALANCE_PLATE_MICRO_NUTRIENTS.PROTEINS]?.microNutrientColorRangeMap?.GREEN?.endValue,
        target:rangeByMicroNutrientMap?.[BALANCE_PLATE_MICRO_NUTRIENTS.PROTEINS]?.value,
        maxRange : rangeByMicroNutrientMap?.[BALANCE_PLATE_MICRO_NUTRIENTS.PROTEINS]?.value*2,
        baseName : BALANCE_PLATE_MICRO_NUTRIENTS.PROTEINS
    },
    {
        label :"Avg Fiber /day",
        name : "avgFiber",
        suffix : "g",
        min: rangeByMicroNutrientMap?.[BALANCE_PLATE_MICRO_NUTRIENTS.FIBER]?.microNutrientColorRangeMap?.GREEN?.startValue,
        max: rangeByMicroNutrientMap?.[BALANCE_PLATE_MICRO_NUTRIENTS.FIBER]?.microNutrientColorRangeMap?.GREEN?.endValue,
        target:rangeByMicroNutrientMap?.[BALANCE_PLATE_MICRO_NUTRIENTS.FIBER]?.value,
        maxRange : rangeByMicroNutrientMap?.[BALANCE_PLATE_MICRO_NUTRIENTS.FIBER]?.value*2,
        baseName : BALANCE_PLATE_MICRO_NUTRIENTS.FIBER
    },
    {
        label :"Avg Carbs /day",
        name : "avgCarbohydrates",
        suffix : "g",
        min: rangeByMicroNutrientMap?.[BALANCE_PLATE_MICRO_NUTRIENTS.CARBOHYDRATES]?.microNutrientColorRangeMap?.GREEN?.startValue,
        max: rangeByMicroNutrientMap?.[BALANCE_PLATE_MICRO_NUTRIENTS.CARBOHYDRATES]?.microNutrientColorRangeMap?.GREEN?.endValue,
        target:rangeByMicroNutrientMap?.[BALANCE_PLATE_MICRO_NUTRIENTS.CARBOHYDRATES]?.value,
        maxRange : rangeByMicroNutrientMap?.[BALANCE_PLATE_MICRO_NUTRIENTS.CARBOHYDRATES]?.value*2,
        baseName : BALANCE_PLATE_MICRO_NUTRIENTS.CARBOHYDRATES
    },
    {
        label :"Avg Sodium /day",
        name : "avgSodium",
        suffix : "g",
        min: rangeByMicroNutrientMap?.[BALANCE_PLATE_MICRO_NUTRIENTS.SODIUM]?.microNutrientColorRangeMap?.GREEN?.startValue,
        max: rangeByMicroNutrientMap?.[BALANCE_PLATE_MICRO_NUTRIENTS.SODIUM]?.microNutrientColorRangeMap?.GREEN?.endValue,
        target:rangeByMicroNutrientMap?.[BALANCE_PLATE_MICRO_NUTRIENTS.SODIUM]?.value,
        maxRange : rangeByMicroNutrientMap?.[BALANCE_PLATE_MICRO_NUTRIENTS.SODIUM]?.value*2,
        baseName : BALANCE_PLATE_MICRO_NUTRIENTS.SODIUM
    }]
}

export { getNutritionalBalance }