import styles from "./emptyBag.module.css";
import { useState } from "react";
import AddNewMemberForm from "../form/AddNewMemberForm";

const EmptyBag = ({ setIsLoading, updateToast, setRefreshMemberList, practiceId, updateFormValues, submitFormValues, worksiteList }) => {
    const [open, setIsOpen] = useState(false);

    const openForm = () => setIsOpen(!open);

    return (

        <div className={styles.outerContainer}>
            <div className={styles.innerContainer}>
                <img src={require("../../../../../assets/images/empty_bag.svg").default} className={styles.icon} />
                <div className={styles.primaryText}>
                    It’s empty in here
                </div>
                <div className={styles.secondaryText}>
                    Get started by inviting your patients to track and monitor securely
                </div>
                <div className={styles.buttonAddNewPractice} onClick={openForm}>Add New Member</div>
                {open && <div className={styles.formBackground}>
                    <AddNewMemberForm setIsLoading={setIsLoading} updateToast={updateToast} setRefreshMemberList={setRefreshMemberList} practiceId={practiceId} updateFormValues={updateFormValues} submitFormValues={submitFormValues} worksiteList={worksiteList} setIsOpen={setIsOpen} className={styles.addNewPracticeFormStepOne} />
                </div>}
            </div>
        </div>

    )
}

export default EmptyBag;
