import React from "react";

import medication from "./../../../../../../../../../assets/images/medication.svg";

import styles from "./header.module.css";
import { HEADER_ENUMS } from "./header.constants";

const Header = () =>{
    return (
        <div className={styles.container}>
            <img src={medication} alt="" className={styles.icon} />
            <div className={styles.content}>
                {HEADER_ENUMS.CONTENT}
            </div>
        </div>
    )
}

export default Header;