import React, { useState } from "react";

import styles from "./phoneConcentCheckBox.module.css";

const ConsentCheckBox = ({handleFieldChange, name, checkErrorState, errorTypes, content="" }) =>{

    const [currentState,setCurrentState] = useState(false);

    const handleInputChange =(event)=>{
        setCurrentState(!currentState);
        handleFieldChange({label: name})(!currentState);
        checkErrorState(!currentState,name , errorTypes)
    }

    return (
        <div className={styles.container}>
            <input type="checkbox" value={"concent"} className={styles.checkBox} onChange={handleInputChange} />
            <label className={styles.concentTypography}>{content}</label>
        </div>
    )
}

export default ConsentCheckBox;