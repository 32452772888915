
const FORM_LABELS = ["Add Patient to Pediatrix Group of Health","Add Patient to Pediatrix Group of Health"]

const FORM_BUTTON_CONTENT = ["Continue","Add Patient"];

const FORM_STEPS = {
    LABEL : 'Steps',
    TOTAL_COUNT : 2
}

const STEPPER_ARRAY = [1,2]

export {
    FORM_LABELS,
    FORM_BUTTON_CONTENT,
    FORM_STEPS,
    STEPPER_ARRAY
}