import React from "react";
import Popup from "../Popup/Popup";
import ErrorContent from "./component/ErrorContent";

const ErrorPopup = (props) =>{
    return (
        <Popup PopupContent={ErrorContent} {...props}/>
    )
}

export default ErrorPopup;