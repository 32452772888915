import styles from "./heading.module.css"
import { getTextInitials } from "../../../../helpers/TextManipulations.helpers";

const Heading = ({memberName}) => {

    return (

        <div className={styles.heading}>
            <div className={styles.practiceLogo}>
                {getTextInitials(memberName)}
            </div>
            <div className={styles.practiceName}>
                {memberName}
            </div>
        </div>

    )
}

export default Heading;