import mealIcon from "./../../../../../../../../../assets/images/meals/basicFoodLog.svg";
import wakeUpIcon from "./../../../../../../../../../assets/images/duration/wakeUp.svg"
import highIcon from "./../../../../../../../../../assets/images/exclamation/yellowFilledExclamation.svg";
import lowIcon from "./../../../../../../../../../assets/images/criticleSquare.svg"

import enabledCurtalary from "./../../../../../../../../../assets/images/meals/curtalary/enabledCurtalary.svg";
import disabledCurtalary from "./../../../../../../../../../assets/images/meals/curtalary/disabledCurtalary.svg";

import enabledTimer from "./../../../../../../../../../assets/images/timer/basicEnabledTimer.svg";
import disabledTimer from "./../../../../../../../../../assets/images/timer/basicDisabledTimer.svg";


const ICON_MARKERS = [{
       icon: mealIcon,
       label : "Meal Logged"
    },
    {
        icon : wakeUpIcon,
        label : "Wakeup Logged"
    }
];

const MEAL_MARKERS = [{
    icon : enabledCurtalary,
    label : 'Logged'
},
{
    icon : disabledCurtalary,
    label : "Not Logged"
}]

const WAKEUP_TIME_MARKER = [{
    icon : enabledTimer,
    label : "Logged"
},
{
    icon : disabledTimer,
    label: "Not Logged"
}]

const ALERT_ICONS = [{
    icon: lowIcon,
    label : "Low"
 },
 {
     icon : highIcon,
     label : "High"
 }];

export {
    ALERT_ICONS,
    MEAL_MARKERS,
    WAKEUP_TIME_MARKER
}

export default ICON_MARKERS;