import React from "react";

import styles from "./dataStreams.module.css";

const DataStreams = ({icon="", primaryText, secondaryText}) =>{
    return (
        <div className={styles.container}>
            <img src={icon} alt="mainIcon" />
            <div className={styles.textContainer}>
                <div className={styles.primaryText}>
                    {primaryText}
                </div>
                <div className={styles.secondaryText}>
                    {secondaryText}
                </div>
            </div>
        </div>
    )
}

export default DataStreams;