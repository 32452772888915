import PageHeaderContext from "./components/pageHeaderContext/PageHeaderContext";
import Heading from "./components/heading/Heading";
import Tabs from "./components/tabs/Tabs";

import useCoachManagement from "./customHooks/useCoachManagement";

import styles from "./coachDetails.module.css";

const CoachDetail = () => {

    const {coachDetails, getCoachDetails} = useCoachManagement();

    return (
        <div className={styles.mainContainer}>
            <PageHeaderContext coachDetails={coachDetails} />
            <Heading coachDetails={coachDetails}/>
            <Tabs coachDetails={coachDetails} />
        </div>
    )
}

export default CoachDetail;