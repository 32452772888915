import React from "react";
import SignUpBasePage from "../../organisms/SignUpBasePage";
import PracticeCreateAccountCard from "../../organisms/PracticeCreateAccountCard";

import styles from "./practiceCreateAccount.module.css";

const PracticeCreateAccount = () =>{
    return (
        <div className={styles.container}>
            <SignUpBasePage hasTermsAndConditions={false} FormComponent={PracticeCreateAccountCard} buttonProps={{buttonContent : "Login"}} />
        </div>
    )
}

export default PracticeCreateAccount;