
import calenderIcon from "../../../assets/images/calender_icon.svg";
import usersIcon from "./../../../assets/images/users_icon.svg";
import windowIcon from "./../../../assets/images/window_icon.svg";
import CustomIcon from "./components/CustomIcon";

import styles from "./practiceAccountSetup.module.css";

const getRightContainerDisplayFields = (formData)=>{
    return [{
        id:0,
        CustomIcon : <CustomIcon formData={formData} />
    },
    {
        id:1,
        CustomIcon : <img src={windowIcon} className={styles.rightContainerIcon} />
    },
    {
        id:2,
        CustomIcon : <img src={usersIcon} className={styles.rightContainerIcon} />
    },
    {
        id:3,
        CustomIcon : <img src={calenderIcon} className={styles.rightContainerIcon} />
    }
]
}

export {
    getRightContainerDisplayFields
}