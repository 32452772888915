import React, { useState } from "react";

import styles from "./selectWithDecline.module.css";

const withSelectWithDecline = ({Component, placeHolder })=>(props) =>{

    const [isSelected , setSelected] = useState(false);

    const { handleFieldChange, name, checkErrorState, errorTypes} = props;

    const handleCheckChange = (event) =>{
        if(!isSelected){
            handleFieldChange({label : name})(" ");
            checkErrorState(" ", name, errorTypes);
        }
        setSelected(!isSelected);
    }

    return (
        <div className={styles.container}>
            <Component {...props} isDisabled={isSelected} />
            <div className={styles.checkBoxContainer}>
                <input type="checkbox" name={name} checked={isSelected} onChange={handleCheckChange} />
                <label htmlFor={name} className={styles.placeHolderTypography}>
                    {placeHolder}
                </label>
            </div>
        </div>
    )

}

export default withSelectWithDecline