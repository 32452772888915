
const BASE_URL = "https://api.renewrx.health";
// const BASE_URL = "http://localhost:80";

const API_STATUS_CODES = {
    NOT_FOUND : 404,
    SUCCES : 200,
    CREATED : 201,
    NO_CONTENT : 204,
    CONFLICT : 409,
    ANAUTHORIZED : 401,
    BAD_REQUEST : 400,
    FORBIDDEN : 403,
}

const API_METHOD_TYPES = {
    GET : "GET",
    POST : "POST"
}

export {
    BASE_URL,
    API_STATUS_CODES,
    API_METHOD_TYPES
}