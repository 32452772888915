import {filter} from "lodash";

const getSearchTextFilteredList = (list=[],searchText="")=>{
    return filter(list,(listItem)=>{
        const {email="", firstName=""} = listItem;

        return (firstName && firstName.includes(searchText)) || (email && email.includes(searchText));
    })
}


export default getSearchTextFilteredList;