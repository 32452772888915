import React, { useState } from "react";
import cx from "classnames";

import BillingPopup from "./components/BillingPopup";
import exclamationIcon from "./../../../../../../../assets/images/exclamation/greyExclamation.svg";
import emptyIcon from "./../../../../../../../assets/images/empty_notification.svg";

import styles from "./billing.module.css";
import BillingList from "./components/BillingList/BillingList";
import { handleAddBillingOption } from "./billing.async";
import Tooltip from "./components/Tooltip";
import { useNavigate } from "react-router-dom";

const Billing = ({
        billingList=[],
    })=>{

    const [displayBillingPopup, setDisplayBillingPopup] = useState(false);
    const [openTooltip, setTooltipDisplay] = useState(false);

    const navigate = useNavigate();

    const handleToggleBillingPopup = ()=>{
        if(billingList?.length==0){
            setDisplayBillingPopup(!displayBillingPopup);
        }
    }

    const handleOpenTooltip = ()=>{
        if(billingList?.length>0){
            setTooltipDisplay(true);
        }
    }

    const handleCloseTooltip = ()=>{
        setTooltipDisplay(false);
    }

    const handleSubmitBillingPopup = (items)=>{
        handleAddBillingOption(items)
        .then(()=>{
            setDisplayBillingPopup(false);
            navigate(0);
        })
        .catch()
    }

    return (
        <div className={styles.container}>
            {displayBillingPopup &&  <BillingPopup handleSubmitBillingPopup={handleSubmitBillingPopup} handleModalToggle={handleToggleBillingPopup}/>}
            <div className={styles.billingContainer}>
                <div className={styles.billingHeader}>
                    <div className={styles.leftHeaderContainer}>
                        <div className={styles.headerPrimaryTextTypo}>
                            <span>Billing</span>
                            <img src={exclamationIcon} className={styles.exclamationIcon} />
                        </div>
                        <div className={styles.headerSecondaryTextTypo}>Recent codes that have been billed for & copied to the EHR</div>
                    </div>
                    <div className={styles.rightContainer}>
                        <div className={cx(styles.rightTextButton, {[styles.nonActive]: billingList?.length>0})} onClick={handleToggleBillingPopup} onMouseEnter={handleOpenTooltip} onMouseLeave={handleCloseTooltip}>
                            Add New Bill
                        </div>
                        {openTooltip && <Tooltip text={'Billing Cycle already utilized Fully'} />}
                    </div>
                </div>
                <div className={styles.billingBody}>
                {billingList.length == 0  &&
                    <div className={styles.container}>
                        <img src={emptyIcon} className={styles.emptyIcon}/>
                        <div className={styles.noContent}>
                            No Bills Added Yet!
                        </div>
                    </div>
                }
                <BillingList listItems={billingList}/>
                </div>
            </div>
        </div>
    )
}

export default Billing;