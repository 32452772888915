import React, { useRef, useState } from "react";

import dropdownButton from "../../../../../../assets/images/dropdown_icon.svg"
import StyledButton from "./../../../../../molecules/baseComponents/buttons/styledButton";

import styles from "./actionButton.module.css";
import "./actionButton.module.css";
import { Tooltip, Overlay } from "react-bootstrap";
import { INVITE_PATIENT_STATUS } from "../../../../../fieldMapping/invitePatients";

const ActionButton = ({handlePatientInviteResend, rowId, activeRow, id, status, className,...rest}) =>{
    const [show, setShow] = useState(false);
    const target = useRef(null);

    const handleItemClick = (event)=>{
        event.preventDefault();
        event.stopPropagation();
        handlePatientInviteResend(rowId);
        setShow(!show);
    }

    const handlePopupDisplay = (event)=>{
        event.preventDefault();
        event.stopPropagation();
        setShow(!show)
    }

    return (
        <>
            <div className={`${styles.container} ${activeRow && styles.hoverEffect}`}>
                { (status == INVITE_PATIENT_STATUS.PENDING || status == INVITE_PATIENT_STATUS.UNSENT) && <div className={styles.linkButton} onClick={handleItemClick}>{"Resend Invite"}</div>}
                { (status != INVITE_PATIENT_STATUS.PENDING && status != INVITE_PATIENT_STATUS.UNSENT) && <img src={dropdownButton} className={styles.icon}  ref={target} onClick={handlePopupDisplay} />}
                <Overlay target={target.current} show={show} placement="left-start" variant="primary">
                {(props) => (
                    <Tooltip id="button-tooltip" {...props} className="myTooltip" >
                        <div className={styles.overlayButton} onClick={handleItemClick}>
                            Move to Archive
                        </div>
                    </Tooltip>
                )}
                </Overlay>
            </div>
      </>
    )
}

export default ActionButton;