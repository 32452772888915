import React, { useEffect, useState } from "react";
import {map} from "lodash";

import { BILLING_GROUP_LABELS, BILLING_VALUE_GROUP } from "../../../../billingPopup.content";

import styles from "./selctedItemList.module.css";

const SelectedItemList = ({listItems, handleSetSelectedActiveList, handleRemoveSelectedActiveList}) =>{
    const [storeItems, setStoreItems] = useState({});

    useEffect(()=>{
        let updatedStoreItems = storeItems;
        let updateItems = {};
        Object.keys(updatedStoreItems).map((key)=>{
            console.log(key);
            if(!listItems.includes(key)){
                updateItems[key]=0;
                handleRemoveSelectedActiveList(key);
            }
        });
        setStoreItems({...storeItems,...updateItems});
    },[listItems]);


    const handleItemSelectOption =(item)=> (val)=>{
        if((storeItems?.[item]+val) == BILLING_VALUE_GROUP[item]?.length){
            handleSetSelectedActiveList(item);
        }
        else{
            handleRemoveSelectedActiveList(item)
        }
        setStoreItems((prev)=>{
            return {
                ...prev,
                [item]: (prev?.[item] || 0)+val
            }
        });
    }

    console.log('ITEM : ', listItems);
    console.log('BILLING GROUP : ', BILLING_VALUE_GROUP)

    return (
        <div className={styles.container}>
            {map(listItems,(item)=>{
                return (
                    <div className={styles.listIemContainer}>
                        <div className={styles.mainContentTypo}>{BILLING_GROUP_LABELS[item]}</div>
                        <div className={styles.listItemOptionsContainer}>
                            {map(BILLING_VALUE_GROUP[item],(contents)=>{
                                const {Component, ...rest}= contents
                                return (
                                    <Component {...rest} handleItemSelectOption={handleItemSelectOption(item)}/>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default SelectedItemList;