import moment from "moment";

import ModalBody from "./components/ModalBody/ModalBody"
import ModalHeader from "./components/ModalHeader/ModalHeader"

const getRespectiveModelContent = ({loggedTime}) =>{
    return {
        HeaderComponent : ModalHeader,
        BodyComponent : ModalBody,
        headerProps :{
            date : moment(loggedTime).format("MMM DD,YYYY - ddd "),
            time : moment(loggedTime).format("h:mm A"),
        }
    }
}

export {
    getRespectiveModelContent
}