import React, { useCallback, useEffect, useState } from "react";

import withActionState from "../../hooks/withActionState";

import DefaultSpinner from "./../../atoms/spinner";
import Header from "./components/Header";
import Body from "./components/Body";
import SecondaryHeader from "./components/SecondaryHeader";
import { PATIENT_INVITE_COLUMN_FIELDS, PATIENT_INVITE_FIELDS } from "./patientInvite.fields";
import PatientInviteModal from "../patientInviteModal/PatientInviteModal";
import actions from "./patientInvite.actions";
import actionTypes from "./patientInvite.actionTypes";

import styles from "./patientsInvite.module.css"
import { getDataByTab, getFormattedData } from "./patientInvite.helpers";
import { TABLE_FIELDS, tableFields } from "./patientInvite.tableFields";
import BasicToast from "../../molecules/baseComponents/toasts/BasicToast";
import { getFilteredData } from "../../helpers/TextManipulations.helpers";

const PatientsInvite = ({dispatch, isLoading, displayToast, toastMessage, filterText="" , type, ...rest}) =>{

    useEffect(()=>{
        dispatch({ actionType : actionTypes.INIT_ACTION})
    },[])

    const [displayInvitePatientModal, setDisplayInvitePatientModal] = useState(false);

    const { status ="ACTIVE", patients = {} }  = rest || {};

    const getPatientStatusChange = useCallback((payload)=>{
        dispatch({actionType : actionTypes.CHANGE_PATIENT_PAGE_STATUS, payload})
    },[]);

    const handlePatientInviteResend = (payload)=>{
        dispatch({actionType : actionTypes.RESEND_PATIENT_INVITE_ACTION, payload})
    }

    const handlePatientInviteModalDisplay = ()=>{
        setDisplayInvitePatientModal(!displayInvitePatientModal)
    }

    const getFilteredTableDataAction = (payload) =>{
        dispatch({actionType : actionTypes.TABLE_FILTER_ACTION, payload})
    }

    const handleToastClose = (payload)=>{
        dispatch({actionType : actionTypes.CLOSE_TOAST_STATUS, payload})
    }

    const handleToastOpenAction = (payload)=>{
        dispatch({actionType: actionTypes.OPEN_TOAST_ACTION, payload})
    }

    return (
        <div className={styles.container}>
            {isLoading && <DefaultSpinner />}
            {displayToast && <BasicToast type={type}  handleToastToggle={handleToastClose} display={displayToast} message={toastMessage} />}
            {displayInvitePatientModal && <PatientInviteModal handleToastOpenAction={handleToastOpenAction} toggleModal={handlePatientInviteModalDisplay} />}
            <Header inviteButtonAction={handlePatientInviteModalDisplay}/>
            <SecondaryHeader getFilteredTableDataAction={getFilteredTableDataAction} statusChangeAction={getPatientStatusChange} status={status}/>
            <Body columnWrapperFields={PATIENT_INVITE_COLUMN_FIELDS} tableDataProps={{className : styles.tableRowEffect}} tableFields={TABLE_FIELDS[status]} status={status} dispatch={dispatch} patients={getFilteredData({data: getDataByTab(status,patients), text:filterText }) } inviteButtonAction={handlePatientInviteModalDisplay} wrapperFields={PATIENT_INVITE_FIELDS(handlePatientInviteResend, status)} />
        </div>
    )
}

export default withActionState({Component: PatientsInvite, action : actions});