import moment from "moment";
import { COPY_COLOR_FORMATS, METRICS_LABEL, METRIC_SUFFIX } from "./body.constants";

const getPrimaryCardContent = (primaryCard=[]) =>{
    let copyStr="";
    primaryCard?.forEach((val)=>{
        const { glucoseDetails ={} , metricRequest = {}} = val || {};
        const { metricType ="" }= metricRequest;
        const { glucose }= glucoseDetails;
        if(!METRICS_LABEL?.[metricType]){
            return;
        }

        copyStr+=METRICS_LABEL?.[metricType]+": ";
        copyStr+= (glucose? glucose+" "+(METRIC_SUFFIX?.[metricType] || "") : "???")+"\n";
    })
    return copyStr;
}

const getTimeInRangeCopyFormat = (timeInRange={})=>{
    let copyStr="Time in Range \n";


    let inRange = timeInRange?.["OVERALL_TIME_IN_RANGE_BETWEEN_63_TO_140"];

    let aboveRange = timeInRange?.["OVERALL_TIME_IN_RANGE_MORE_THAN_140"];

    let lowRange = timeInRange?.["OVERALL_TIME_IN_RANGE_LESS_THAN_63"];

    let check = inRange?.['glucose'] || aboveRange?.['glucose'] || lowRange?.['glucose'];

    copyStr+="High (>141): "+( check ? (aboveRange?.["glucose"] || 0)+"%" : "???")+"\n";

    copyStr+="In Range (63-140): "+ ( check ? (inRange?.["glucose"] || 0)+"%" : "???")+"\n";

    copyStr+="Low (<63): "+ (check? (lowRange?.["glucose"] || 0)+"%" : "???")+"\n";

    return copyStr;
}

const getTrendsFormattedData = (recentTrends=[])=>{
    let copyStr="";
    recentTrends?.forEach((val)=>{
        const {label=""}= val || {};
        copyStr+=label
    })
    return copyStr;
}

const getSummaryCopyFormat = (summaryData,prev,recentTrends)=>{
    let copyString = "";
    copyString+="Date String: ";
    copyString+=moment().subtract(6+(7*prev),'d').format("MMM DD, YYYY")+" to " + moment().subtract((7*prev),'d').format("MMM DD, YYYY");
    copyString+="\n\n";
    copyString+=getPrimaryCardContent(summaryData?.["primaryCardFullData"]);
    copyString+="\n"
    copyString+=getTimeInRangeCopyFormat(summaryData?.["overallTimeInRange"]?.["metricsNameGlucoseDetailsMap"])
    copyString+="\n"

    copyString+='Trends'+"\n";
    if(!recentTrends.length && recentTrends?.length ==0){
        copyString+="???";
    }
    else{
        copyString+=getTrendsFormattedData(recentTrends)
    }

    copyString+="\n\n"
    copyString+="----------------------------------------------------------------------------------------------------------------------------------------------------------";
    copyString+="\n\n"
    copyString+="???:No Data"
    copyString+="\n\n"


    return copyString
}

const getSummaryCopyAction = ({summaryData, handleToastUpdateAction=()=>{},prev, recentTrends})=>{
    try{
        let copyData = getSummaryCopyFormat(summaryData,prev,recentTrends);

        navigator.clipboard.writeText(copyData)
        .then(()=>{handleToastUpdateAction({
            enableToast: true,
            toastProps :{
                message : "Glucose Log Sheet successfully copied to Clipboard",
                type : "success"
            }
        })})
    }
    catch(e){
        console.log('COPY FAILED : ',e)
    }
}

export {
    getSummaryCopyAction,
    getSummaryCopyFormat
}