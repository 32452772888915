const INIT_ACTION = "INIT_ACTION";
const CHANGE_PASSWORD = "CHANGE_PASSWORD";
const CHANGE_INFORMATION = "CHANGE_INFORMATION";
const HANDLE_OPTION_CHANGE = "HANDLE_OPTION_CHANGE";
const DISPLAY_MODAL = "DISPLAY_MODAL";

export default {
    INIT_ACTION,
    CHANGE_PASSWORD,
    HANDLE_OPTION_CHANGE,
    DISPLAY_MODAL,
    CHANGE_INFORMATION
}