const PATIENT_INVITE_CONNECT = {
    PRIMARY_TEXT : {
        INVITE_TEXT: " have been invited to join a ",
        RENEWRX_TEXT : " on RenewRx"
    },
    BODY_SECONDARY_TEXT : "RenewRx helps you to track, monitor and improve your health and your child’s throughout your maternity"
}

const ACTIVE_CARD_STATE = {
    CONCENT_STATE : "CONCENT_STATE",
    COMPLETE_STATE : "COMPLETE_STATE",
    REJECTED_STATE : "REJECTED",
}

const REJECTED_BODY = {
    TEXT : "You have rejected the invitation from RenewRx"
}

export {
    PATIENT_INVITE_CONNECT,
    ACTIVE_CARD_STATE,
    REJECTED_BODY
}