import React from "react";

import ContentBody from "./../../Popup/Body";

import styles from "./body.module.css";
import { PROGRESS_MONITORING_TYPE } from "../../../../progressMonitoring/progressMonitoring.constants";

const Body = ({modalMetrics, handleTabChange}) =>{

    const handleViewMoreDetails = () =>{
        handleTabChange({label : "Balanced Plate", })
    }

    return (
        <div className={styles.container}>
            <ContentBody modalMetrics={modalMetrics?.[0]} hasButton={false} />
            <div className={styles.horizontalDivider} />
            <ContentBody modalMetrics={modalMetrics?.[1]} handleTabChange={handleViewMoreDetails}/>
        </div>
    )
}

export default Body;